import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const PoolItem = styled.div`
  padding: 0 12px;

  &:not(:last-of-type) > :first-child {
    border-bottom: 1px solid ${buildColor("blue", "000")};
  }
`;
