import { forEach, get, has } from "lodash";
import cookie from "react-cookie";

import queryString from "query-string";

const getOptedInPromos = (promos, optedInPromos, isUserLogged = false) => {
  let aboveTheLineRacePromo = null;
  let belowTheLineRacePromo = null;

  promos.forEach((promo) => {
    if (promo && promo.isAboveTheLine && aboveTheLineRacePromo === null) {
      aboveTheLineRacePromo = promo;
    } else if (
      promo &&
      !promo.isAboveTheLine &&
      belowTheLineRacePromo === null &&
      has(optedInPromos, `[${promo.rootParentPromoID}]`, false)
    ) {
      belowTheLineRacePromo = promo;
    }
  });

  if (
    isUserLogged &&
    belowTheLineRacePromo &&
    optedInPromos[belowTheLineRacePromo.rootParentPromoID] !== undefined
  ) {
    return {
      ...belowTheLineRacePromo,
      isOptedIn: optedInPromos[belowTheLineRacePromo.rootParentPromoID]
    };
  }
  if (
    isUserLogged &&
    aboveTheLineRacePromo &&
    optedInPromos[aboveTheLineRacePromo.rootParentPromoID] !== undefined
  ) {
    return {
      ...aboveTheLineRacePromo,
      isOptedIn: optedInPromos[aboveTheLineRacePromo.rootParentPromoID]
    };
  }

  return aboveTheLineRacePromo;
};

export const getHasPromo = (
  useIsPromoTagShownFlag,
  hasPromo,
  isPromoTagShown
) => (useIsPromoTagShownFlag ? hasPromo && isPromoTagShown : hasPromo);

export const hasPromoFromGraph = (race, useIsPromoTagShownFlag = false) => {
  const hasPromo =
    !!get(race, "promos[0].isAboveTheLine", false) ||
    !!get(race, "userPromotions.length", false);

  const isPromoTagShown =
    get(race, "promos[0].isPromoTagShown", false) ||
    get(race, "userPromotions[0].isPromoTagShown", false);

  const isOptedIn = get(race, "userPromotions[0].optedIn", false);

  return (
    (hasPromo && isOptedIn) ||
    getHasPromo(useIsPromoTagShownFlag, hasPromo, isPromoTagShown)
  );
};

export const getRDAOptinStatus = (race) => {
  const userPromotions = get(race, "userPromotions", []);
  let aboveTheLine = null;
  let belowTheLine = null;

  userPromotions.forEach((promo) => {
    if (!promo.isAboveTheLine && !belowTheLine) {
      belowTheLine = promo;
    }

    if (promo.isAboveTheLine && !aboveTheLine) {
      aboveTheLine = promo;
    }
  });

  return belowTheLine
    ? belowTheLine.optedIn
    : get(aboveTheLine, "optedIn", false);
};

export const setRFRCookie = (isApp = false, location) => {
  const { referrer } = document;
  const rfrParameters = {
    "12_001_0001":
      /https?:\/\/(www\.tvg|4njbets\.us\.betfair)(\.[a-z]{2,3}){1,2}\/?/,
    "0_022_0208": /https?:\/\/www\.google(\.[a-z]{2,3}){1,2}\/?/,
    "0_023_0209": /https?:\/\/www\.bing(\.[a-z]{2,3}){1,2}\/?/,
    "0_024_0210": /https?:\/\/[a-z.]+\.yahoo(\.[a-z]{2,3}){1,2}\/?/
  };
  const getCookieValue = (name) =>
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";
  const rfrCookieExists = !document.cookie.includes("RFR=");
  const existingCookie = rfrCookieExists ? "" : getCookieValue("RFR");
  const queryParams = queryString.parse(location.search.toLowerCase());
  const rfr = get(queryParams, "rfr", false);
  const rafCode = get(queryParams, "rcode", false);
  const extraCookieConfig = "/";

  const existingRfrType = (cookieValue) => {
    switch (cookieValue) {
      case "":
        return "empty";
      case Object.keys(rfrParameters)[0]:
        return "tvg";
      case Object.keys(rfrParameters)[1]:
      case Object.keys(rfrParameters)[2]:
      case Object.keys(rfrParameters)[3]:
        return "seo";
      case "10_001_0001":
        return "raf";
      default:
        return "promo";
    }
  };

  const rfrType = existingRfrType(existingCookie);

  if (referrer) {
    forEach(rfrParameters, (value, key) => {
      // Don't override current rfr cookie when referrer is from tvg.com
      if (
        value.test(referrer.toLowerCase()) &&
        key !== Object.keys(rfrParameters)[0] &&
        rfrType !== "raf" &&
        rfrType !== "promo"
      ) {
        // set rfr value for seo journeys
        cookie.save("RFR", key, { path: extraCookieConfig });
      }
    });
  } else if (!isApp && rfrCookieExists && !rfr) {
    // set tvg.com rfr value when no referrer and no cookie is set for direct journey
    cookie.save("RFR", Object.keys(rfrParameters)[0], {
      path: extraCookieConfig
    });
  }

  if (rfr) {
    // set rfr value for url journeys
    cookie.save("RFR", rfr, { path: extraCookieConfig });
  }

  if (rafCode) {
    // set rfr value for refer a friend referral
    cookie.save("RFR", "10_001_0001", { path: extraCookieConfig });
  }
};

export default getOptedInPromos;
