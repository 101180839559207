import { SaddleColors } from "./types";

export const SADDLE_COLORS: SaddleColors = {
  thoroughbred: {
    "1": { numberColor: "#FFFFFF", saddleColor: "#C20E0E" },
    "2": { numberColor: "#000000", saddleColor: "#BCC8C8" },
    "3": { numberColor: "#FFFFFF", saddleColor: "#3B4E8C" },
    "4": { numberColor: "#000000", saddleColor: "#D3B13C" },
    "5": { numberColor: "#FFFFFF", saddleColor: "#116410" },
    "6": { numberColor: "#FFFFFF", saddleColor: "#303030" },
    "7": { numberColor: "#000000", saddleColor: "#D65A2F" },
    "8": { numberColor: "#000000", saddleColor: "#E095AA" },
    "9": { numberColor: "#000000", saddleColor: "#2AAAA5" },
    "10": { numberColor: "#FFFFFF", saddleColor: "#713A98" },
    "11": { numberColor: "#FFFFFF", saddleColor: "#6B6B6B" },
    "12": { numberColor: "#000000", saddleColor: "#4EA958" },
    "13": { numberColor: "#FFFFFF", saddleColor: "#58514D" },
    "14": { numberColor: "#FFFFFF", saddleColor: "#94335A" },
    "15": { numberColor: "#FFFFFF", saddleColor: "#69522F" },
    "16": { numberColor: "#FFFFFF", saddleColor: "#4C6E9F" },
    "17": { numberColor: "#FFFFFF", saddleColor: "#373786" },
    "18": { numberColor: "#FFFFFF", saddleColor: "#285F23" },
    "19": { numberColor: "#FFFFFF", saddleColor: "#32496D" },
    "20": { numberColor: "#FFFFFF", saddleColor: "#671334" }
  },
  harness: {
    "1": { numberColor: "#FFFFFF", saddleColor: "#AF0000" },
    "2": { numberColor: "#FFFFFF", saddleColor: "#253C85" },
    "3": { numberColor: "#000000", saddleColor: "#B2B4B1" },
    "4": { numberColor: "#FFFFFF", saddleColor: "#1C5B1B" },
    "5": { numberColor: "#FFFFFF", saddleColor: "#252525" },
    "6": { numberColor: "#000000", saddleColor: "#B69E00" },
    "7": { numberColor: "#000000", saddleColor: "#B27492" },
    "8": { numberColor: "#FFFFFF", saddleColor: "#6B6B6B" },
    "9": { numberColor: "#FFFFFF", saddleColor: "#610F9A" },
    "10": { numberColor: "#FFFFFF", saddleColor: "#263D88" },
    "11": { numberColor: "#FFFFFF", saddleColor: "#3B5E90" },
    "12": { numberColor: "#FFFFFF", saddleColor: "#A90000" },
    "13": { numberColor: "#FFFFFF", saddleColor: "#1D5E1C" },
    "14": { numberColor: "#FFFFFF", saddleColor: "#725933" },
    "15": { numberColor: "#FFFFFF", saddleColor: "#C0C0C0" }
  }
};
