import { RaceTracksSearchActionTypes } from "../actions/raceTracksSearch";

export interface RaceTracksSearchState {
  isSearchActive: boolean;
  searchQuery: string;
}

export const initialState: RaceTracksSearchState = {
  isSearchActive: false,
  searchQuery: ""
};

const raceTracksSearchReducer = (
  state = initialState,
  action: RaceTracksSearchActionTypes
) => {
  switch (action.type) {
    case "SET_RACE_TRACKS_SEARCH":
      return { ...state, isSearchActive: true, searchQuery: action.payload };

    case "SET_RACE_TRACKS_IS_SEARCH_ACTIVE":
      return { ...state, isSearchActive: action.payload };

    case "CLEAR_RACE_TRACKS_SEARCH":
      return initialState;

    default:
      return state;
  }
};

export default raceTracksSearchReducer;
