import { useState, useEffect, useRef } from "react";
import { intervalToDuration, isValid, compareAsc } from "date-fns";
import { format } from "date-fns-tz";
import { DerbyEventStatus } from "@fdr/types/ts/Derby";
import { CountdownProps } from "./types";

const ONE_SECOND = 1000;
const twoDigits = (num: number) => String(num).padStart(2, "0");

export const useCountdown = ({
  eventStatus = DerbyEventStatus.POST,
  endDate,
  forceFullDate = false
}: CountdownProps) => {
  const intervalIdRef = useRef<number | null>(null);
  const [countdown, setCountdown] = useState(new Date());
  const isEventActive = eventStatus === DerbyEventStatus.DERBY_DAY;

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    if (isEventActive) {
      intervalIdRef.current = setInterval(() => {
        setCountdown(new Date());
      }, ONE_SECOND);
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [eventStatus, isEventActive]);

  const hasValidRaceDate = endDate && isValid(endDate);
  const startDate = countdown && new Date(countdown);
  const { hours, minutes, seconds } =
    isEventActive &&
    countdown &&
    hasValidRaceDate &&
    compareAsc(endDate, startDate) > 0
      ? intervalToDuration({
          start: startDate,
          end: endDate
        })
      : {
          hours: 0,
          minutes: 0,
          seconds: 0
        };

  let endDateCountdown = "";
  if (forceFullDate && hasValidRaceDate && isEventActive) {
    endDateCountdown = format(endDate, "hh:mm a zzz");
  } else {
    endDateCountdown =
      isEventActive || !endDate
        ? `${twoDigits(hours ?? 0)}H ${twoDigits(minutes ?? 0)}M ${twoDigits(
            seconds ?? 0
          )}S`
        : format(endDate, "MMM dd - hh:mm a zzz");
  }

  return { endDateCountdown };
};
