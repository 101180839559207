import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: white;
  overflow: hidden;
`;

const SVG = styled.svg`
  display: block;
  height: 172px;
  width: 1366px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class UpcomingRacesMask extends PureComponent {
  render() {
    return (
      <Container>
        <SVG>
          <g fill="none" fillRule="evenodd">
            <rect width="1366" height="1024" className="pulse" />
            <rect width="1366" height="172" fill="#FFF" fillRule="nonzero" />
            <rect
              width="141"
              height="111"
              x="12.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="141"
              height="111"
              x="162.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="98"
              height="8"
              x="24"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="98"
              height="8"
              x="174"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="24"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="28"
              height="8"
              x="174"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="312.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="74"
              height="8"
              x="324"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="28"
              height="8"
              x="324"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="462.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="114"
              height="8"
              x="474"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="474"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="612.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="98"
              height="8"
              x="624"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="624"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="762.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="90"
              height="8"
              x="774"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="774"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="912.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="141"
              height="111"
              x="1062.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="82"
              height="8"
              x="924"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="82"
              height="8"
              x="1074"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="924"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1074"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="1212.5"
              y="48.5"
              fill="#FFF"
              fillRule="nonzero"
              stroke="#CFDBE8"
              rx="4"
            />
            <rect
              width="106"
              height="8"
              x="1224"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1224"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="121"
              height="8"
              x="12"
              y="20"
              className="pulse"
              rx="1"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
