import React, { FC } from "react";
import { TalentPickData } from "@tvg/ts-types/TalentPicks";
import { RaceStatusType } from "@tvg/ts-types/Race";
import { Paragraph, Mtp, useColorTokens } from "@tvg/design-system";
import { Container, TrackNameWrapper } from "./styled-components";

interface MtpBodyProps {
  hidden?: boolean;
  qaLabel: string;
  talentPick: TalentPickData;
}

const MtpBody: FC<MtpBodyProps> = ({ hidden, qaLabel, talentPick }) => {
  const colorTokens = useColorTokens();

  const {
    mtp,
    raceStatus,
    race: raceNumber,
    trackName,
    racePostTime
  } = talentPick;

  return (
    <Container data-qa-label={qaLabel} hidden={hidden}>
      <Mtp
        mtp={+mtp}
        status={raceStatus as RaceStatusType["code"]}
        postTime={racePostTime}
        hasFullText
        isCollapsed
      />
      <TrackNameWrapper>
        <Paragraph
          qaLabel="mtp-track-name"
          whiteSpace="nowrap"
          color={colorTokens.content.subtle}
        >
          {trackName}
        </Paragraph>
      </TrackNameWrapper>
      <Paragraph qaLabel="mtp-race-number">{` - R${raceNumber}`}</Paragraph>
    </Container>
  );
};

export default MtpBody;
