import { breakpoints } from "@tvg/design-system";
import styled, { css } from "styled-components";

export const Container = styled.div<{ hasBottomDivider?: boolean }>`
  position: relative;
  display: flex;
  min-height: 67px;
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} ${theme.space["space-5"]}`};
  ${({ hasBottomDivider, theme }) =>
    hasBottomDivider &&
    css`
      &::after {
        content: "";
        height: 1px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80%;
        background-color: ${theme.colorTokens.border.subtle};
      }
    `}

  @media screen and ${breakpoints.tablet.max.sm} {
    padding-left: 8px;
  }
`;

export const RunnerJockeyTrainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${({ theme }) => theme.space["space-4"]};
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
`;

export const JockeyTrainerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media screen and ${breakpoints.tablet.max.sm} {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const JockeyTrainer = styled.span<{ hideEllipsis?: boolean }>`
  display: inline-block;
  color: ${({ theme }) => theme.colorTokens.content.subtle};
  margin: 2px 0;
  white-space: nowrap;
  ${({ hideEllipsis }) =>
    !hideEllipsis &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    `}
`;

export const OddsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;
