import React, { Fragment, PureComponent } from "react";
import { Routes, Route } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";

import { connect } from "react-redux";

import { attempt, get, isEmpty, bindAll } from "lodash";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import PromosWidgetComponent from "@tvg/promos-widget";
import PromoWidgetEvents from "@tvg/gtm/src/modules/PromoWidgetCalendar";
import tvgConf from "@tvg/conf";

import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import HomepageDesktopContainer from "@tvg/atomic-ui/_templates/HomepageDesktopContainer";
import SeoFooter from "@tvg/atomic-ui/_static/SeoFooter";

import {
  failFeaturesRequest,
  successFeaturesRequest,
  successMessagesRequest,
  failMessagesRequest
} from "../actions/capi";

export class SeoContent extends PureComponent {
  static defaultProps = {
    hasRequestedLogin: false
  };

  constructor(props) {
    super(props);
    bindAll(this, ["getFeatures", "getMessages"]);

    this.tvg = tvgConf();
  }

  componentDidMount() {
    if (!isEmpty(this.props.features)) {
      const featureToggles = {};
      let featureOverrides = this.getFeaturesOverrides() || {};
      const hasFeaturesOverrides = !!this.getFeaturesOverrides();

      this.props.features.forEach((toggle) => {
        let { enabled } = toggle;
        if (typeof window !== "undefined") {
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }
        }

        featureToggles[toggle.name] = enabled;
      });

      if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
        this.setFeaturesOverrides(featureOverrides);
      }
      this.props.dispatch(successFeaturesRequest(featureToggles));
    } else {
      this.getFeatures();
    }

    PromoWidgetEvents();

    mediatorClassic.subscribe("UPDATE_ROUTER", () => {
      const pathWithParameters = window.location.href.replace(
        window.location.origin,
        ""
      );
      if (this.props.location.pathname !== pathWithParameters) {
        this.props.history.replace(pathWithParameters);
      }
    });
  }

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    const messageNamespaces = ["Homepage"];

    return (
      typeof window !== "undefined" &&
      this.tvg
        .getMessages(messageNamespaces)
        .then((response) =>
          this.props.dispatch(successMessagesRequest(response))
        )
        .catch((err) => this.props.dispatch(failMessagesRequest(err)))
    );
  };

  tvg;

  featureToggles;

  renderSEOContainer = (shouldRenderSEO, seoProps) =>
    shouldRenderSEO && <HomepageDesktopContainer {...seoProps} />;

  render() {
    let SEOMessage;
    let SEOTitle;

    if (this.props.showNewHomepageSEO) {
      let shouldRenderSEO = false;
      let hasStackedCards = false;

      // Use this to render different seo content on different routes
      switch (this.props.location.pathname) {
        case "/":
        case "/home":
        case "/hmp/seo":
        case "/hmp/seo/":
        case "/hmp/seo/home":
        case "/hmp/seo/opted-in":
          SEOMessage = "seoContentHome";
          SEOTitle = "seoContentHomeTitle";
          shouldRenderSEO = false;
          break;
        case "/races":
        case "/hmp/seo/races":
          SEOMessage = "seoContentRaces";
          SEOTitle = "seoContentRacesTitle";
          shouldRenderSEO = true;
          break;
        case "/racetracks":
        case "/hmp/seo/racetracks":
          SEOMessage = "seoContentTrackList";
          SEOTitle = "seoContentTrackListTitle";
          shouldRenderSEO = true;
          break;
        case "/live":
        case "/hmp/seo/live":
          SEOMessage = "seoContentLive";
          SEOTitle = "seoContentLiveTitle";
          shouldRenderSEO = true;
          break;
        case "/results":
        case "/hmp/seo/results":
          SEOMessage = "seoContentResults";
          SEOTitle = "seoContentResultsTitle";
          shouldRenderSEO = true;
          break;
        case "/handicapping/talent-picks":
        case "/hmp/seo/handicapping/talent-picks":
          SEOMessage = "seoContentTalentPicks";
          SEOTitle = "seoContentTalentPicksTitle";
          shouldRenderSEO = true;
          break;
        case "/handicapping/race-replays":
        case "/hmp/seo/handicapping/race-replays":
          SEOMessage = "seoContentReplays";
          SEOTitle = "seoContentReplaysTitle";
          shouldRenderSEO = true;
          break;
        case "/handicapping/free-pp":
        case "/hmp/seo/handicapping/free-pp":
          SEOMessage = "seoContentPastPerformances";
          SEOTitle = "seoContentPastPerformancesTitle";
          shouldRenderSEO = true;
          break;
        default:
          shouldRenderSEO = false;
      }

      if (
        this.props.location.pathname.includes("/racetracks") &&
        !this.props.location.search.includes("race")
      ) {
        SEOMessage = "seoContentTrackList";
        SEOTitle = "seoContentTrackListTitle";
        shouldRenderSEO = true;
        hasStackedCards = this.props.enableSeoRaceTracks;
      }

      const seoContent = (
        <SeoFooter
          message={get(this.props.messages, SEOMessage, "")}
          title={get(this.props.messages, SEOTitle, "")}
        />
      );
      const promoWidget = (
        <PromosWidgetComponent
          hasRequestedLogin={this.props.hasRequestedLogin}
          hasLargeWidth={hasStackedCards}
          device="desktop"
          isWidget
        />
      );

      const seoProps = {
        leftContent: seoContent,
        rightContent: promoWidget,
        hasStackedCards
      };

      // Set routes were seo contents should rendered
      return (
        <Fragment>
          <Routes>
            <Route
              exact
              path="/"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/login"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/dev-login"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/home"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/races"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/results"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/racetracks"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/racetracks/:trackAbbr/:trackName"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/live"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/handicapping/talent-picks"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/handicapping/race-replays"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              exact
              path="/handicapping/free-pp"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
            <Route
              path="/hmp/seo"
              element={this.renderSEOContainer(shouldRenderSEO, seoProps)}
            />
          </Routes>
        </Fragment>
      );
    }

    return <Fragment />;
  }
}

export default connect(
  (store) => ({
    user: store.userData,
    hasRequestedLogin: get(store, "userData.hasRequested", false),
    featureToggles: get(store, "capi.featureToggles", {}),
    useDynamicSorting: get(
      store,
      "capi.featureToggles.useDynamicSorting",
      false
    ),
    showNewHomepageSEO: get(
      store,
      "capi.featureToggles.showNewHomepageSEO",
      false
    ),
    enableSeoRaceTracks: get(
      store,
      "capi.featureToggles.enableSeoRaceTracks",
      false
    ),
    features: get(store, "capi.features", []),
    messages: store.capi.messages,
    isBeta: store.app.isBeta
  }),
  (dispatch) => ({ dispatch })
)(withRouter(SeoContent));
