import React, { PureComponent, Fragment } from "react";

import { noop } from "lodash";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { arrowBack, arrowForward, arrowDown } from "../../_static/Icons/icons";
import DefaultButton from "../../_atom/Buttons/default";

import {
  PaginationWrapper,
  TopRacesButtonDefault,
  PagesWrapper,
  NextWrapper,
  WordWrapper,
  NavigationWrapper,
  PaginationBullet,
  SelectList,
  SelectorContainer,
  LabelsWrapper,
  PageSelected,
  SelectIcon
} from "./styled-components";

export default class Pagination extends PureComponent {
  static defaultProps = {
    pageSelected: 1,
    totalPages: 1,
    onBackPage: noop,
    onForwardPage: noop,
    onSelectPage: undefined,
    theme: "default",
    className: "",
    qaLabel: "pagination",
    isBackDisabled: false,
    isForwardDisabled: false,
    isLabelsShown: false
  };

  defaultPaginationRender = () => {
    const {
      theme,
      onBackPage,
      pageSelected,
      onSelectPage,
      onForwardPage,
      totalPages,
      isLabelsShown
    } = this.props;
    return (
      <PaginationWrapper
        theme={theme}
        className={this.props.className}
        data-qa-label={this.props.qaLabel}
      >
        <DefaultButton
          onClick={onBackPage}
          isDisabled={pageSelected === 1}
          qaLabel="previous-page-button"
        >
          {isLabelsShown ? (
            <NavigationWrapper isHidden={pageSelected === 1}>
              <Icon icon={arrowBack} />
              <WordWrapper>Previous</WordWrapper>
            </NavigationWrapper>
          ) : (
            <Icon icon={arrowBack} />
          )}
        </DefaultButton>
        <PagesWrapper>
          {onSelectPage ? (
            this.renderButtons()
          ) : (
            <Fragment>
              <strong>{pageSelected}</strong>&nbsp;of&nbsp;
              <strong>{totalPages}</strong>
            </Fragment>
          )}
        </PagesWrapper>
        <DefaultButton
          onClick={onForwardPage}
          isDisabled={pageSelected === totalPages}
          qaLabel="next-page-button"
        >
          {isLabelsShown ? (
            <NavigationWrapper
              isHidden={pageSelected === totalPages}
              alignRight
            >
              <WordWrapper>Next</WordWrapper>
              <Icon icon={arrowForward} />
            </NavigationWrapper>
          ) : (
            <Icon icon={arrowForward} />
          )}
        </DefaultButton>
      </PaginationWrapper>
    );
  };

  topRacesPaginationRender = () => (
    <PaginationWrapper
      theme={this.props.theme}
      className={this.props.className}
      data-qa-label={this.props.qaLabel}
    >
      <TopRacesButtonDefault
        onClick={this.props.onBackPage}
        isDisabled={this.props.isBackDisabled}
        size={16}
        qaLabel="pagination-previousButton"
      >
        <Icon icon={arrowBack} />
      </TopRacesButtonDefault>
      <TopRacesButtonDefault
        onClick={this.props.onForwardPage}
        isDisabled={this.props.isForwardDisabled}
        size={16}
        qaLabel="pagination-forwardButton"
      >
        <Icon icon={arrowForward} />
      </TopRacesButtonDefault>
    </PaginationWrapper>
  );

  tutorialsPaginationRender = () => {
    const { theme, onBackPage, pageSelected, onForwardPage, totalPages } =
      this.props;
    return (
      <PaginationWrapper
        theme={theme}
        className={this.props.className}
        data-qa-label={this.props.qaLabel}
      >
        <DefaultButton
          onClick={onBackPage}
          isDisabled={pageSelected === 1}
          qaLabel="pagination-previousButton"
        >
          <Icon icon={arrowBack} />
        </DefaultButton>
        {pageSelected !== totalPages && (
          <Fragment>
            <PagesWrapper>{this.renderButtons()}</PagesWrapper>
            <DefaultButton
              onClick={onForwardPage}
              isDisabled={pageSelected === totalPages}
              qaLabel="pagination-nextButton"
            >
              <NextWrapper>
                <WordWrapper>NEXT</WordWrapper>
                <Icon icon={arrowForward} />
              </NextWrapper>
            </DefaultButton>
          </Fragment>
        )}
      </PaginationWrapper>
    );
  };

  renderButtons = () => {
    const { theme, totalPages, pageSelected, onSelectPage } = this.props;

    if (theme === "tutorials") {
      const buttonsArray = [];
      for (let i = 1; i <= totalPages; i += 1) {
        buttonsArray.push(
          <PaginationBullet key={`wbp-${i}`} active={pageSelected === i} />
        );
      }

      return <Fragment>{buttonsArray}</Fragment>;
    }

    return (
      <SelectorContainer>
        <LabelsWrapper>
          <PageSelected data-qa-label="pageSelected">
            {pageSelected}
          </PageSelected>
          &nbsp;{`of ${totalPages}`}
        </LabelsWrapper>
        <SelectList
          onChange={(event) => {
            if (onSelectPage) onSelectPage(+event.target.value);
          }}
          value={pageSelected}
        >
          <option value="select-page" disabled>
            Select Page
          </option>
          {Array.from(Array(totalPages), (_, i) => i + 1).map((page) => (
            <option key={`page-${page}`} value={page}>
              {page}
            </option>
          ))}
        </SelectList>
        <SelectIcon icon={arrowDown} color={buildColor("grey", "900")} />
      </SelectorContainer>
    );
  };

  render() {
    const { theme } = this.props;
    let returnRender;

    switch (theme) {
      case "tutorials":
        returnRender = this.tutorialsPaginationRender();
        break;
      case "topRaces":
        returnRender = this.topRacesPaginationRender();
        break;
      default:
        returnRender = this.defaultPaginationRender();
        break;
    }
    return returnRender;
  }
}
