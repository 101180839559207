import { gql } from "@apollo/client";

export const TALENT = gql`
  fragment Talent on TalentPickType {
    talent {
      talentId
      name
      pictureUrl
      phrases
      pickCount
    }
  }
`;

export default TALENT;
