import { Probable, RaceOdds, RaceStatusType } from "@tvg/ts-types/Race";

export enum DerbyEventStatus {
  PRE = "preEvent",
  DERBY_DAY = "eventDay",
  POST = "default"
}

export interface IDerbyInfoProps {
  track: ITrack;
}

export interface ITrack {
  id: string;
  races: IRace[];
}

export interface IRunner {
  horseName: string;
  runnerId: string;
  scratched: boolean;
}

export interface IBettingInterest {
  biNumber: number;
  saddleColor: string;
  numberColor: string;
  favorite: boolean;
  runners: IRunner[];
  currentOdds: RaceOdds;
}

export interface IInterestComputedOdds extends IBettingInterest {
  computedOdd: number;
}

export interface IRace {
  id: string;
  tvgRaceId: number;
  raceNumber: string;
  numRunners: string;
  raceDate: string;
  status: RaceStatusType;
  mtp: number;
  trackName: string;
  description: string;
  probables: Probable[];
  bettingInterests: IBettingInterest[];
  postTime: string;
}

export interface HomepageEventBannerText {
  label: string;
  size?: number;
}

export interface ContentParagraph {
  type: "paragraph";
  content?: Array<ContentText>;
}

export interface ContentHeading {
  type: "heading";
  attrs: {
    level: number;
  };
  content: Array<ContentText>;
}

export interface ContentText {
  type: "text";
  text: string;
  marks?: Array<TextMarks>;
}

export interface TextMarks {
  type: "textStyle";
  attrs: {
    color: string;
  };
}

export type RichText = ContentParagraph | ContentHeading;

export enum BannerContext {
  DESKTOP = "desk",
  MOBILE_SA = "mobile",
  X_SELL = "xSell",
  HOME_PAGE = "homePage",
  PROGRAM_PAGE = "programPage"
}

export interface HeaderBanner {
  context: Array<BannerContext>;
  background_color: string;
  background_image: {
    filename: string;
    alt: string;
  };
  cta_buttons: Array<{
    button_type: string;
    label: string;
    link_url: {
      url: string;
    };
  }>;
  dynamic_text?: {
    type: "doc";
    content: Array<RichText>;
  };
}
