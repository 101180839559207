import React from "react";
import styled, { keyframes } from "styled-components";
import { buildColor } from "@tvg/design-system";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }
  
  50% {
    fill: ${buildColor("blue", "100")};
  }
  
  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

const MaskContainer = styled.div`
  padding: 8px 0;
`;

const CardContainer = styled.div`
  background: ${buildColor("grey", "000")};
  padding: 0 12px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const SVG = styled.svg`
  rect {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const TrackPageContentMask = () => {
  const rectColor = buildColor("blue", "000");
  return (
    <MaskContainer data-qa-label="trackPageContentMask">
      <CardContainer>
        <SVG width="100%" height="236" fill={buildColor("grey", "000")}>
          <rect y="40" width="248" height="16" rx="1" fill={rectColor} />
          <rect y="104" width="100%" height="116" rx="1" fill={rectColor} />
          <rect y="64" width="154" height="16" rx="1" fill={rectColor} />
          <rect y="16" width="53" height="16" rx="1" fill={rectColor} />
        </SVG>
      </CardContainer>
    </MaskContainer>
  );
};

export default TrackPageContentMask;
