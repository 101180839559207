import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("PROMO_WIDGET:CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.promoName"),
      module: "Promo widget",
      tag: undefined,
      menu: undefined,
      sport: "Horse Racing",
      destinationUrl: get(data, "payload.destinationUrl") // e.g ‘https://www.tvg.com/race-track’
    });
  });
};
