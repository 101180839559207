import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const WinnerLink = styled(Link)<{
  isDesktop: boolean;
  isModal: boolean;
  hasUniqueWinner: boolean;
}>`
  position: relative;
  text-decoration: none;
  ${({ isDesktop, isModal, hasUniqueWinner }) =>
    isDesktop || isModal || hasUniqueWinner
      ? css`
          width: 100%;
        `
      : css`
          flex: 1;
          min-width: 291px;
        `};
`;

export const TopSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const BottomSection = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.space["space-5"]};
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const RaceDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
`;
