import { gql } from "@apollo/client";
import PastTracksFragment from "../fragments/PastTracks";
import TrackDatesFragment from "../fragments/TrackDates";

export const PAST_TRACKS_QUERY = gql`
  query getPastTracks(
    $wagerProfile: String!
    $filterBy: PastTrackListFilter
    $allTracks: Boolean!
    $date: String
    $byDate: Boolean!
    $trackCode: String
    $byCode: Boolean!
    $withDates: Boolean!
  ) {
    allPastTracks: pastTracks(profile: $wagerProfile, filter: $filterBy)
      @include(if: $allTracks) {
      ...pastTracksFragment
    }
    pastTracksByDate: pastTracks(
      profile: $wagerProfile
      filter: $filterBy
      date: $date
    ) @include(if: $byDate) {
      ...pastTracksFragment
    }
    pastTrackByTrackCode: pastTracks(
      profile: $wagerProfile
      filter: $filterBy
      trackCode: $trackCode
    ) {
      ...pastTracksFragment @include(if: $byCode)
      ...trackDatesFragment @include(if: $withDates)
    }
  }
  ${PastTracksFragment.entry}
  ${TrackDatesFragment.entry}
`;
