import React, { PureComponent } from "react";
import { noop } from "lodash";

import ButtonLink from "../../_atom/Buttons/buttonLink";
import buildColor from "../../_static/ColorPalette";
import {
  video,
  medal,
  promo,
  talent,
  tracks,
  contests,
  question,
  store,
  bets,
  cup
} from "../../_static/Icons/icons";

import {
  NonRouterLink as Link,
  LinkIcon,
  RouterLinkContent
} from "./styled-components";

const IconMapping = {
  video,
  reward: medal,
  promotion: promo,
  picks: talent,
  track: tracks,
  tutorial: question,
  contest: contests,
  store,
  results: cup,
  mybets: bets
};

export default class QuickLink extends PureComponent {
  static defaultProps = {
    id: 0,
    label: "",
    icon: null,
    URL: "",
    imageURL: "",
    backgroundImageURL: "",
    backgroundColorName: "",
    backgroundColorCode: "",
    tag: null,
    newTab: false,
    isVerticalLayout: false,
    onClick: noop,
    isExternal: false,
    isTVG4: false,
    device: "mobile"
  };

  renderIcon = () => {
    const { icon } = this.props;
    const svg = icon && IconMapping[icon];

    return svg ? (
      <LinkIcon
        qaLabel="quickLinkIcon"
        icon={svg}
        color={buildColor("white", "100")}
        isVerticalLayout={this.props.isVerticalLayout}
      />
    ) : null;
  };

  render() {
    const { tag, label, url, newTab } = this.props;
    const processedTag =
      this.props.isVerticalLayout && label.length > 14 ? null : tag;
    return this.props.isTVG4 || this.props.isExternal ? (
      <Link
        data-qa-label="quickLink"
        tag={processedTag}
        href={url}
        {...(newTab ? { target: "_blank" } : {})}
        onClick={(event) => this.props.onClick && this.props.onClick(event)}
      >
        {this.renderIcon()}
        {label}
      </Link>
    ) : (
      <ButtonLink
        qaLabel="quickLink"
        url={url}
        type="non_styled"
        size="autoSized"
        onClick={(event) => this.props.onClick && this.props.onClick(event)}
      >
        <RouterLinkContent
          expanded={false}
          tag={processedTag}
          data-qa-label="quickLinkContent"
        >
          {this.renderIcon()}
          {label}
        </RouterLinkContent>
      </ButtonLink>
    );
  }
}
