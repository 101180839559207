import React, { PureComponent } from "react";
import { noop } from "lodash";

import ScrollWrapper from "../../_molecule/ScrollWrapper";
import QuickLink from "../../_molecule/QuickLink";
import { Container, List, Item, DesktopWrapper } from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import QuickLinkExpended from "../../_molecule/QuickLink/quickLinkExpanded";

export default class QuickLinks extends PureComponent {
  static defaultProps = {
    quickLinks: [],
    isVerticalLayout: false,
    onLinkClick: noop,
    isInApp: false,
    hasNoQuickLinks: false,
    device: "mobile",
    isExpanded: false
  };

  handleQuickLinkClick = (link, index, event) => {
    this.props.onLinkClick(link, index, event);
    if ((this.props.isVerticalLayout || this.props.isInApp) && link.onClick) {
      link.onClick(link, event);
    }
  };

  renderList = () => (
    <List
      data-qa-label="quickLinkList"
      vertical={this.props.isVerticalLayout}
      isExpanded={this.props.isExpanded}
      device={this.props.device}
    >
      {this.props.quickLinks.map((link, index) => (
        <Item
          key={`link-${link.label.replace(" ", "-").toLowerCase()}`}
          data-qa-label={`quickLink_${link.label
            .replace(" ", "-")
            .toLowerCase()}`}
          isExpanded={this.props.isExpanded}
        >
          {!this.props.isExpanded ? (
            <QuickLink
              {...link}
              isExternal={link.isExternal}
              isTVG4={link.isTVG4}
              isVerticalLayout={this.props.isVerticalLayout}
              onClick={(event) => this.handleQuickLinkClick(link, index, event)}
            />
          ) : (
            <QuickLinkExpended
              {...link}
              index={index}
              isExternal={link.isExternal}
              isTVG4={link.isTVG4}
              isVerticalLayout={this.props.isVerticalLayout}
              onClick={(event) => this.handleQuickLinkClick(link, index, event)}
              device={this.props.device}
            />
          )}
        </Item>
      ))}
    </List>
  );

  render() {
    const {
      quickLinks,
      device,
      isVerticalLayout,
      hasNoQuickLinks,
      isExpanded
    } = this.props;
    return !isVerticalLayout ? (
      quickLinks && quickLinks.length > 0 && (
        <Container
          isExpanded={isExpanded}
          vertical={isVerticalLayout}
          device={device}
          data-qa-label="quickLinksContainer"
        >
          <ScrollWrapper
            isFullWidth={isExpanded}
            fadeFrom="rgba(30, 75, 121, 0) 0%"
            fadeTo={`${buildColor("blue", "700")} 100%`}
            showFaders={!isExpanded}
            qaLabel="quickLinksScrollOverlay"
          >
            {this.renderList()}
          </ScrollWrapper>
        </Container>
      )
    ) : (
      <DesktopWrapper
        hasNoQuickLinks={hasNoQuickLinks}
        data-qa-label="quickLinksContainer"
      >
        <Container vertical={isVerticalLayout}>{this.renderList()}</Container>
      </DesktopWrapper>
    );
  }
}
