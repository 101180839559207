import React, { Component } from "react";
import { get, noop, throttle } from "lodash";

import ExpandableRow from "@tvg/atomic-ui/_templates/ExpandableRow";
import TrackLineHeader from "@tvg/atomic-ui/_molecule/TrackLineHeader";

import RaceList from "../track-races";
import TracksEnum from "../../TracksType/types";

const getTrackComponent = (
  track,
  isFavorite,
  handleFavoriteChange,
  useIsPromoTagShownFlag
) => (
  <TrackLineHeader
    qaLabel={`trackLineHeader-${track.code}`}
    trackCode={track.code}
    trackName={track.name || track.shortName}
    isFavorite={isFavorite}
    isGreyhound={track.isGreyhound}
    hasPromo={track.hasPromo}
    hasResultedState={track.isFinished}
    handleFavoriteChange={handleFavoriteChange}
    isPromoTagShown={track.isPromoTagShown}
    useIsPromoTagShownFlag={useIsPromoTagShownFlag}
  />
);

const didTrackOpenStateChange = (
  oldOpenTracks,
  newOpenTracks,
  oldIsFavorite,
  newIsFavorite,
  trackCode
) =>
  oldOpenTracks.indexOf(trackCode) > -1 !==
    newOpenTracks.indexOf(trackCode) > -1 || oldIsFavorite !== newIsFavorite;

export default class ExpandableTrack extends Component {
  static defaultProps = {
    track: {
      code: "",
      name: "",
      shortName: "",
      labels: [],
      isFavorite: false,
      hasPromo: false,
      hasResultedState: false
    },
    storedFavoriteTracks: [],
    isFavorite: 0,
    device: "mobile",
    trackDropdownIsOpen: noop,
    openTracks: [],
    trackType: TracksEnum.TOP_TRACKS,
    idPrefix: "",
    shouldUpdate: false,
    accountId: "-1",
    rdaClient: null,
    handleFavoriteChange: noop,
    isLogged: false,
    isLastTrack: false,
    useIsPromoTagShownFlag: false
  };

  localHandleFavoriteChange = throttle(
    () => {
      const isFavorite = !this.state.isFavorite;
      if (this.props.isLogged) {
        this.setState({
          isFavorite
        });
      }
      this.props
        .handleFavoriteChange(isFavorite, get(this.props, "track.code", ""))
        .catch(() => {
          this.setState({
            isFavorite: !isFavorite
          });
        });
    },
    1500,
    { trailing: false }
  );

  constructor(props) {
    super(props);
    this.state = {
      isFavorite: get(this.props, "track.isFavorite", false)
    };
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    didTrackOpenStateChange(
      get(this.props, "openTracks", []),
      get(nextProps, "openTracks", []),
      this.props.isFavorite,
      nextProps.isFavorite,
      get(this.props, "track.code", "")
    ) ||
    get(this.props, "isLogged", false) !== get(nextProps, "isLogged", false) ||
    get(this.props, "track.isPromoTagShown", false) !==
      get(nextProps, "track.isPromoTagShown", false) ||
    this.state.isFavorite !== get(nextState, "isFavorite", false);

  componentDidUpdate(prevProps) {
    if (prevProps.isFavorite !== this.props.isFavorite) {
      this.updateFavoriteState(this.props.isFavorite);
    }
  }

  updateFavoriteState = (isFavorite) => {
    this.setState({
      isFavorite: !!isFavorite
    });
  };

  render() {
    return (
      <ExpandableRow
        trackCode={get(this.props, "track.code", "")}
        forceHeight
        onExpand={(isOpen) => {
          const tracktype = get(this.props, "trackType", "");
          return this.props.onTrackClickHandler(
            get(this.props, "track.name", ""),
            get(this.props, "track.code", ""),
            get(this.props, "track.labels", []),
            `${tracktype[0]}${tracktype
              .slice(1)
              .replace("_", " ")
              .toLowerCase()}`,
            isOpen,
            get(this.props, "track.isGreyhound", false)
          );
        }}
        header={getTrackComponent(
          get(this, "props.track"),
          this.state.isFavorite,
          this.localHandleFavoriteChange,
          this.props.useIsPromoTagShownFlag
        )}
        isOpen={this.props.trackDropdownIsOpen(
          get(this.props, "track.code", "")
        )}
      >
        <RaceList
          isDisplayingRaces={this.props.trackDropdownIsOpen(
            get(this.props, "track.code", "")
          )}
          trackType={this.props.trackType}
          device={this.props.device}
          idPrefix={this.props.idPrefix}
          trackCode={get(this.props, "track.code", "")}
          trackName={get(this.props, "track.name", "")}
          isFavorite={get(this.state, "isFavorite", false)}
          isGreyhoundsTrack={get(this.props, "track.isGreyhound", "")}
          shouldUpdate={this.props.trackDropdownIsOpen(
            get(this.props, "track.code", "")
          )}
          accountId={this.props.accountId}
          rdaClient={this.props.rdaClient}
          isLogged={this.props.isLogged}
          isOpen={this.props.trackDropdownIsOpen(
            get(this.props, "track.code", "")
          )}
        />
      </ExpandableRow>
    );
  }
}
