import { gql } from "@apollo/client";

const PastRacesFragment = {
  entry: gql`
    fragment pastRacesFragment on PastRace {
      id
      number
      description
      purse
      date
      postTime
      track {
        code
        name
      }
      surface {
        code
        name
      }
      distance {
        value
        code
        name
      }
      type {
        id
        code
        name
      }
      raceClass {
        code
        name
      }
      video {
        replayFileName
      }
    }
  `
};

export default PastRacesFragment;
