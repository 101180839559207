import { get, sortBy } from "lodash";

export default (raceList = []) => {
  let raceToScroll = 0;
  if (raceList.length) {
    const allRacesOver = raceList.every(
      (race) => race.status.code === "RO" || race.status.code === "SK"
    );
    const raceListLimit = raceList.length - 1;

    if (allRacesOver) {
      raceToScroll = raceList[raceListLimit].number;
    } else {
      const sortedFilteredRaces = sortBy(raceList, (race) => race.mtp).filter(
        (race) => race.status.code === "IC" || race.status.code === "O"
      );
      raceToScroll = get(sortedFilteredRaces, "[0].number", 0);
    }
  }

  return +raceToScroll;
};
