import { gql } from "@apollo/client";

const PastRaceResultsFragment = {
  entry: gql`
    fragment resultsFragment on PastRace {
      results {
        runners {
          biNumber
          betAmount
          runnerNumber
          finishPosition
          runnerName
          winPayoff
          placePayoff
          showPayoff
          favorite
        }
        payoffs {
          wagerAmount
          selections {
            payoutAmount
            selection
          }
          wagerType {
            code
            name
          }
        }
      }
    }
  `
};

export default PastRaceResultsFragment;
