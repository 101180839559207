import { gql } from "@apollo/client";

const TrackFragment = {
  entry: gql`
    fragment Track on Track {
      id
      featured
      name
      shortName
      code
      numberOfRaces
      isGreyhound
      isFinished
      hasAboveTheLinePromo(product: $product, brand: $brand)
      raceTypes
      location {
        country
        state
      }
    }
  `
};

export default TrackFragment;
