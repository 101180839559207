import { get } from "lodash";

import { getPortByBrand } from "@tvg/utils/generalUtils";

const getProps = (result) => ({
  tracks: get(result, "data.tracks"),
  isLoading: result.data.loading
});

export default {
  skip: (props) => !get(props, "isOpen") || !props.enablePicksTracksTabAlerts,
  options: (props) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result) => ({ ...getProps(result) })
};
