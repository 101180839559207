import { get } from "lodash";
import { DefaultRootState } from "react-redux";
import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { defaultNoRacesContent } from "./defaultValues";

export const getEnablePopularRaces = (store: DefaultRootState) =>
  get(store, "capi.featureToggles.showPopularRacesDesk", false);

export const getPopularRacesNoRacesContent = (store: DefaultRootState) =>
  parseJSONCapiMessage(
    store,
    "capi.messages.popularRacesNoRacesContent",
    defaultNoRacesContent
  );
