import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import DefaultButton from "../../_atom/Buttons/default";
import { fontMedium } from "../../_static/Typography";

const handleIfIsTvg5 = (isTvg5) => {
  return isTvg5 ? "100%" : "311px";
};

export const CardWrapper = styled.article`
  background-color: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  ${(props, isTvg5) =>
    props.isHorizontalLayout &&
    css`
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      width: ${({ isDesktop }) =>
        isDesktop ? handleIfIsTvg5(isTvg5) : "290px"};
      width: 100%;
      height: 302px;
    `};
`;

export const SelectionsWrapper = styled.div`
  position: relative;
  padding: 12px 12px 4px;
  border-top: 1px solid ${buildColor("blue", "100")};
  flex: 1 1 auto;

  & > .talentPickCard > div {
    padding-left: 0;
  }
`;

export const TogglesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${buildColor("blue", "100")};
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
`;

export const ActionsWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  background-color: ${buildColor("white", "100")};
  padding: 0 12px 12px;
  border-radius: 4px;

  & > button {
    padding: 0 16px;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:nth-child(2n) {
      font-size: 14px;
      flex-grow: 1;
    }
  }
`;

export const SeeAllSelections = styled(DefaultButton)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  font-family: ${fontMedium};
  color: ${buildColor("blue_accent", "500")};
  background: rgba(255, 255, 255, 0.9);

  &:hover {
    text-decoration: underline;
  }
`;
