import styled from "styled-components";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.section`
  width: 100%;
  background-color: ${buildColor("white", "100")};
  margin-bottom: 0;
  border-radius: 0;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isDesktop ? "17px 12px 18px" : "15px 12px 0")};
`;

export const ContainerTitle = styled.span`
  display: flex;
  align-items: center;
  height: 19px;
  flex-grow: 1;
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
`;

export const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
