import React, { FC, useEffect, useState } from "react";
import { Icon } from "../../../src/components/icon";
import { ButtonsContainer, NavButton } from "./styled-components";
import { NavigationButtonProps } from "./types";

const NavigationButton: FC<NavigationButtonProps> = ({
  qaLabel = "navigationBtn",
  containerRef,
  shouldRefresh = false,
  onLeftClick,
  onRightClick
}) => {
  const [isLeftDisabled, setIsLeftDisabled] = useState(!!containerRef);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const scrollContainer = containerRef?.current;

  useEffect(() => {
    const checkScrollPosition = () => {
      if (scrollContainer) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
        setIsLeftDisabled(scrollLeft === 0);
        setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", checkScrollPosition);
      checkScrollPosition();
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [scrollContainer, shouldRefresh]);

  return (
    <ButtonsContainer data-qa-label={`${qaLabel}-container`}>
      <NavButton
        onClick={onLeftClick}
        data-qa-label="left-button"
        disabled={isLeftDisabled}
      >
        <Icon name="arrowLeft" size="s" />
      </NavButton>
      <NavButton
        onClick={onRightClick}
        data-qa-label="right-button"
        disabled={isRightDisabled}
      >
        <Icon name="arrowRight" size="s" />
      </NavButton>
    </ButtonsContainer>
  );
};

export default NavigationButton;
