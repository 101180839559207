import styled, { css } from "styled-components";

import type { Device } from "@tvg/ts-types/Device";

export const Container = styled.section<{ device: Device }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ theme, device }) =>
    device === "desktop"
      ? css`
          padding-bottom: ${theme.space["space-3"]};
          border-radius: ${theme.radii.s};
          background: ${theme.colorTokens.background.surface};
        `
      : css`
          padding: 0 ${theme.space["space-2"]} ${theme.space["space-2"]};
          background: ${theme.colorTokens.background.surface};
        `};
`;
