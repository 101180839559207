import React, { MouseEvent } from "react";

import { RaceStatusEnum } from "@tvg/ts-types/Race";
import {
  Icon,
  Paragraph,
  Mtp,
  useTheme,
  useColorTokens,
  ColorTokens
} from "@tvg/design-system";
import type { PreviousWinnerProps } from "../../types";
import { prepareRaceUrl, raceDetailsFormat, setBetType } from "./utils";
import AmountValue from "../AmountValue";
import {
  WinnerLink,
  HeaderContainer,
  TopSection,
  BottomSection,
  RaceDetailsContainer
} from "./styled-components";

const PreviousWinner = ({
  qaLabel = "previousWinner",
  winner,
  isDesktop,
  isModal,
  hasUniqueWinner,
  onSelection
}: PreviousWinnerProps) => {
  const theme = useTheme();
  const { content } = useColorTokens() as ColorTokens;
  const raceUrl = prepareRaceUrl(winner.currentRace);

  return (
    <WinnerLink
      isDesktop={isDesktop}
      isModal={isModal}
      hasUniqueWinner={hasUniqueWinner}
      data-qa-label={`${qaLabel}-raceId-${winner.currentRace.raceId}`}
      to={raceUrl}
      onClick={(event: MouseEvent) => {
        onSelection(winner, raceUrl, event);
      }}
    >
      <TopSection>
        <HeaderContainer>
          <Paragraph
            qaLabel={`${qaLabel}-entityName`}
            fontFamily="bold"
            fontSize="m"
          >
            {winner.entityName}
          </Paragraph>
          <AmountValue amount={winner.wager.winAmount} />
        </HeaderContainer>
        <Paragraph
          qaLabel={`${qaLabel}-details`}
          fontFamily="condensedRegular"
          fontSize="xs"
          color={content.subtle}
        >
          <>
            {setBetType(winner.wager.betType)}
            <Paragraph
              qaLabel={`${qaLabel}-details-separator`}
              role="separator"
              fontFamily="condensedRegular"
              fontSize="xs"
              color={content.disabled}
            >
              {` | `}
            </Paragraph>
            {raceDetailsFormat(winner.pastRace)}
          </>
        </Paragraph>
      </TopSection>
      <BottomSection theme={theme}>
        <RaceDetailsContainer>
          <Mtp
            qaLabel={`${qaLabel}-mtp`}
            isCollapsed
            mtp={winner.currentRace.mtp}
            postTime={winner.currentRace.postTime}
            status={RaceStatusEnum.OPEN}
            fontWeight="medium"
            hasFullText={winner.currentRace.mtp >= 60}
            whiteSpace="nowrap"
          />
          <Paragraph
            qaLabel={`${qaLabel}-trackName`}
            fontFamily="medium"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            ml="space-3"
          >
            {winner.currentRace.trackName}
          </Paragraph>
          <Paragraph
            qaLabel={`${qaLabel}-raceNumber`}
            fontFamily="medium"
            whiteSpace="nowrap"
            ml="space-3"
          >
            {`R${winner.currentRace.raceNumber}`}
          </Paragraph>
        </RaceDetailsContainer>
        <Icon
          qaLabel={`${qaLabel}-chevronRight`}
          name="chevronRight"
          lineColor={content.strong}
          size="s"
          ml="space-3"
        />
      </BottomSection>
    </WinnerLink>
  );
};

export default PreviousWinner;
