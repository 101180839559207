import styled, { css } from "styled-components";
import { fontCondensedNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const TagWrapper = styled.span`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: ${fontCondensedNormal};
  ${(props) =>
    props.labelDarker
      ? css`
          margin-bottom: 4px;
          color: ${buildColor("grey", "800")};
        `
      : css`
          margin-top: 4px;
          margin-bottom: 4px;
          color: ${buildColor("grey", "800")};
        `}
  ${(props) =>
    props.isScratched
      ? css`
          opacity: 0.5;
        `
      : css`
          opacity: 1;
        `}

  & svg {
    margin-right: 4px;
    fill: ${buildColor("grey", "800")};
  }
`;

export default TagWrapper;
