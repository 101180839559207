import React, { PureComponent } from "react";
import createTextHighlightComponent from "./styled-components";
import buildColor from "../../_static/ColorPalette";

export const TYPES = {
  primary: {
    bgColor: buildColor("blue_accent", "500"),
    textColor: buildColor("white", "100")
  },
  secondary: {
    bgColor: buildColor("blue", "100"),
    textColor: buildColor("grey", "900")
  },
  bet: {
    bgColor: buildColor("green", "600"),
    textColor: buildColor("white", "100")
  },
  marketing: {
    bgColor: buildColor("yellow", "500"),
    textColor: buildColor("grey", "900")
  },
  pending: {
    bgColor: buildColor("grey", "100"),
    textColor: buildColor("grey", "800")
  }
};

export const getType = (type) => TYPES[type];

export const buildTextHighlighted = createTextHighlightComponent;

export default class TextHighlighted extends PureComponent {
  static defaultProps = {
    children: null,
    fontSize: 11,
    type: "primary",
    uppercase: true,
    bold: false,
    className: "",
    qaLabel: ""
  };

  render() {
    const ExtendedText = createTextHighlightComponent(
      getType(this.props.type),
      this.props.fontSize,
      this.props.bold,
      this.props.uppercase,
      this.props.qaLabel
    );

    return (
      <ExtendedText className={this.props.className}>
        {this.props.children}
      </ExtendedText>
    );
  }
}
