import { ColorTokens, Paragraph, useColorTokens } from "@tvg/design-system";
import { formatPurse } from "@tvg/formatter/currency";
import React from "react";
import { Props } from "./types";
import { QA_LABEL } from "../../qaLabels";

const AmountValue = ({ amount }: Props) => {
  const { content } = useColorTokens() as ColorTokens;

  return (
    <Paragraph
      qaLabel={QA_LABEL.body.amount}
      color={content.positive}
      fontSize="xl"
      fontFamily="medium"
    >
      {formatPurse(amount)}
    </Paragraph>
  );
};

export default AmountValue;
