import React, {
  PropsWithChildren,
  memo,
  useEffect,
  useRef,
  useState,
  useCallback
} from "react";
import { Icon } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Container, Content, Summary } from "./styled-components";
import { Props } from "./types";
import {
  COLLAPSIBLE,
  CONTAINER,
  CONTENT,
  ICON,
  SUMMARY
} from "../../utils/constants";

const Collapsible = ({
  children,
  summary,
  isOpen = false,
  onClick,
  qaLabel = ""
}: PropsWithChildren<Props>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | "auto">(0);
  const [minHeight, setMinHeight] = useState(48);

  const updateHeight = useCallback((isCurrentlyOpen: boolean) => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(isCurrentlyOpen ? contentHeight : 0);
    }
  }, []);

  useEffect(() => {
    let resizeObserver: ResizeObserver;

    if (isOpen && contentRef.current) {
      resizeObserver = new ResizeObserver(() => updateHeight(isOpen));
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [isOpen]);

  useEffect(() => {
    updateHeight(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (summaryRef?.current) {
      setMinHeight(summaryRef.current.clientHeight);
    }
  }, []);

  return (
    <Container
      isOpen={isOpen}
      contentHeight={height}
      minHeight={minHeight}
      ref={containerRef}
      data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, CONTAINER])}
    >
      <Summary
        onClick={onClick}
        ref={summaryRef}
        data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, SUMMARY])}
      >
        {summary}
        <Icon
          qaLabel={buildQaLabel([qaLabel, COLLAPSIBLE, SUMMARY, ICON])}
          size="m"
          name={isOpen ? "chevronUp" : "chevronDown"}
          paddingX="space-3"
          paddingY="space-1"
          lineColor="content.subtle"
        />
      </Summary>
      <Content
        ref={contentRef}
        data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, CONTENT])}
        isOpen={isOpen}
      >
        {children}
      </Content>
    </Container>
  );
};

export default memo(Collapsible);
