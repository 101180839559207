import React, { FC, memo } from "react";
import { StyledHeader } from "./styled-components";
import type { HeaderProps } from "./types";
import { getColor } from "../utils";

export const Header: FC<HeaderProps> = memo(
  ({
    children,
    fontFamily = "medium",
    textOverflow = "clip",
    whiteSpace = "normal",
    color = "--fd-colors-content-default",
    tag = "h1",
    qaLabel = "header",
    ...rest
  }) => {
    const selectedColor = getColor(color);

    return (
      <StyledHeader
        role="heading"
        fontFamily={fontFamily}
        color={selectedColor}
        tag={tag}
        textOverflow={textOverflow}
        whiteSpace={whiteSpace}
        data-qa-label={qaLabel}
        {...rest}
      >
        {children}
      </StyledHeader>
    );
  }
);

export type { HeaderProps };
