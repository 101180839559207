import React, { Component, Fragment } from "react";
import { noop, isEqual, isString } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import formatSequencial from "@tvg/formatter/numeric";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { trash, promosolid } from "../../_static/Icons/icons";
import RunnerNumberGroup from "../RunnerNumberGroup";
import Button from "../../_atom/Buttons/button";

import {
  BetDetailsContainer,
  BetDetails,
  TrashButton,
  TrashContainer,
  SelectionsContainer,
  BetSummaryContainer,
  BetAmount,
  TicketAmount,
  BetLabel,
  RepeatableLabel,
  RepeatButton,
  PromoOptedInWrapper,
  PromoOptedInLabel
} from "./styled-components";

export const getListRunnerNumberGroup = (
  selectionsList = [],
  raceTypeCode = "T",
  isKey = false,
  isLeg = false,
  raceNumber = 1,
  favoriteRunners = [],
  showAllSelections = true
) => {
  const multipleColumns = selectionsList.length > 1;
  return (
    <Fragment>
      {(!showAllSelections && selectionsList.length > 5
        ? selectionsList.slice(0, 5)
        : selectionsList
      ).map((selection, index) => {
        if (isKey && index > 1) {
          return false;
        }
        const favoritesColumn = isLeg ? index : 0;
        const favorites = favoriteRunners[favoritesColumn] || [];
        const favoritesAsStrings = favorites.every((fav) => isString(fav));
        const normalizedFavs = favoritesAsStrings
          ? favorites
          : favorites.map((fav) => `${fav}`);

        let key = false;
        let label = "";
        if (multipleColumns) {
          key = index === 0 && isKey;
          if (isKey && index !== 0) {
            label = "W/";
          } else {
            label = !isLeg
              ? formatSequencial(index + 1)
              : `R${raceNumber + index}`;
          }
        }
        return (
          <RunnerNumberGroup
            key={`runner-group${label}`}
            runners={selection}
            raceTypeCode={raceTypeCode}
            isKey={key}
            label={label}
            favoriteRunners={normalizedFavs}
          />
        );
      })}
    </Fragment>
  );
};

class BetSummary extends Component {
  static defaultProps = {
    onDelete: noop,
    onRepeatClick: noop,
    isCancelable: false,
    betTotal: 0,
    wagerAmount: 0,
    selectionTypeCode: "",
    selections: [],
    favoriteRunners: [],
    raceTypeCode: "T",
    isKey: false,
    isLeg: false,
    isVertical: true,
    isLost: false,
    isRepeatable: false,
    isPromoQualifying: false,
    repetitionsNr: 1,
    isCancelled: false,
    isRefunded: false,
    totalWinAmount: null,
    className: "",
    raceNumber: "1",
    layout: "full",
    hasRepeaterEnabled: false,
    hideRepeatButton: false,
    hasPromosOptedIn: false,
    showAllSelections: true
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.selections.length !== nextProps.selections.length ||
      this.props.repetitionsNr !== nextProps.repetitionsNr ||
      !isEqual(this.props, nextProps)
    );
  }

  renderBetDetails() {
    return (
      <BetDetails>
        <div>
          <BetAmount>
            <span data-qa-label="betSummary-betAmount-amount">
              {formatCurrency(this.props.wagerAmount || 0)}
            </span>
            <span data-qa-label="betSummary-betAmount-betType">
              {this.props.selectionTypeCode}
            </span>
            {this.props.repetitionsNr > 1 && (
              <RepeatableLabel data-qa-label="betSummary-betAmount-repetitionsNr">
                [x{this.props.repetitionsNr}]
              </RepeatableLabel>
            )}
          </BetAmount>
          <TicketAmount>
            <span data-qa-label="betSummary-betAmount-betTotal">
              {this.props.repetitionsNr > 1
                ? formatCurrency(this.props.betTotal * this.props.repetitionsNr)
                : formatCurrency(this.props.betTotal || 0)}
            </span>
            <span>Ticket</span>
          </TicketAmount>
        </div>
        {this.props.hasPromosOptedIn && this.props.isPromoQualifying && (
          <PromoOptedInWrapper>
            <Icon icon={promosolid} color={buildColor("yellow", "500")} />
            <PromoOptedInLabel>Opted-In</PromoOptedInLabel>
          </PromoOptedInWrapper>
        )}
      </BetDetails>
    );
  }

  renderTrashButton() {
    return (
      <TrashButton
        onClick={this.props.onDelete}
        qaLabel="betSummary-cancelBetBtn"
      >
        <TrashContainer>
          <Icon size={16} icon={trash} color={buildColor("grey", "900")} />
        </TrashContainer>
      </TrashButton>
    );
  }

  renderSelections() {
    return getListRunnerNumberGroup(
      this.props.selections,
      this.props.raceTypeCode,
      this.props.isKey,
      this.props.isLeg,
      +this.props.raceNumber,
      this.props.favoriteRunners,
      this.props.showAllSelections
    );
  }

  renderFullBetSummary() {
    return (
      <BetSummaryContainer
        data-qa-label="betSummary"
        className={this.props.className}
      >
        <BetDetailsContainer
          isRepeatable={
            this.props.isRepeatable &&
            this.props.hasRepeaterEnabled &&
            !this.props.hideRepeatButton
          }
          isCancelable={this.props.isCancelable}
        >
          {this.renderBetDetails()}
          {this.props.isRepeatable &&
            this.props.hasRepeaterEnabled &&
            !this.props.hideRepeatButton &&
            !this.props.isCancelled &&
            !this.props.isLost &&
            !this.props.isRefunded &&
            !this.props.totalWinAmount && (
              <RepeatButton>
                <Button
                  type="tertiary"
                  qaLabel="betSummary-repeatLink"
                  onClick={this.props.onRepeatClick}
                >
                  Repeat
                </Button>
              </RepeatButton>
            )}
          {this.props.isCancelable && this.renderTrashButton()}
          {this.props.isCancelled && (
            <BetLabel
              bgColor={buildColor("white", "100")}
              color={buildColor("grey", "900")}
              data-qa-label="betSummary-cancelledLabel"
            >
              Cancelled
            </BetLabel>
          )}
          {this.props.isRefunded && (
            <BetLabel
              bgColor={buildColor("white", "100")}
              color={buildColor("grey", "900")}
              data-qa-label="betSummary-refundedLabel"
            >
              Refunded
            </BetLabel>
          )}
          {this.props.isLost && (
            <BetLabel
              bgColor={buildColor("blue", "100")}
              color={buildColor("grey", "900")}
              data-qa-label="betSummary-lostLabel"
            >
              Lost
            </BetLabel>
          )}

          {this.props.totalWinAmount && (
            <BetLabel
              bgColor={buildColor("green", "600")}
              color={buildColor("white", "100")}
              data-qa-label="betSummary-winlabel"
            >
              Won {formatCurrency(this.props.totalWinAmount)}
            </BetLabel>
          )}
        </BetDetailsContainer>
        <SelectionsContainer
          data-qa-label="runnerSelections"
          isVertical={this.props.isVertical}
          layout={this.props.layout}
          isMyBets={!this.props.isRepeatable && !this.props.hasRepeaterEnabled}
        >
          {this.renderSelections()}
        </SelectionsContainer>
      </BetSummaryContainer>
    );
  }

  renderSimpleBetSummary() {
    return (
      <div className={`simpleBetSummaryWrapper ${this.props.className}`}>
        {this.renderBetDetails()}
        <SelectionsContainer
          data-qa-label="runnerSelections"
          isVertical={this.props.isVertical}
          layout={this.props.layout}
        >
          {this.renderSelections()}
        </SelectionsContainer>
      </div>
    );
  }

  render() {
    switch (this.props.layout) {
      case "simple":
        return this.renderSimpleBetSummary();

      case "full":
      default:
        return this.renderFullBetSummary();
    }
  }
}

export default BetSummary;
