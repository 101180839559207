import { gql } from "@apollo/client";

export const UPCOMING_RACE_RDA = gql`
  fragment UpcomingRaceRDA on Race {
    id
    raceId: id
    tvgRaceId
    mtp
    postTime
    raceNumber: number
    trackName
    trackCode
    raceStatus: status {
      code
    }
    location {
      country
    }
    isGreyhound
    talentPicks {
      id
    }
    video {
      onTvg
      onTvg2
    }
  }
`;

export default UPCOMING_RACE_RDA;
