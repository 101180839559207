import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("blue", "000")};
  }
  
  50% {
    fill: ${color("blue", "100")};
  }
  
  100% {
    fill: ${color("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 113px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class AccountTransactionsListMask extends PureComponent {
  render() {
    return (
      <div data-qa-label="accountTransactionsListMask">
        <SVG>
          <g>
            <rect
              width="100%"
              height="112"
              transform="translate(0 1)"
              fill={color("white", "100")}
            />
            <path
              d="M20 18.6C20 18.6 20 16.2 17 16.2M17 16.2C14 16.2 14 18.6 14 18.6C14 19.4 14 20.2 17 21C20 21.8 20 22.6 20 23.4C20 23.4 20 25.8 17 25.8M17 16.2V14M17 25.8C14 25.8 14 23.4 14 23.4M17 25.8V28M26.5 20.75L24 23.25L21.5 20.75M24 18V23.1953"
              stroke={color("grey", "900")}
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <rect
              className="pulse"
              x="40"
              y="13"
              width="80"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="306"
              y="13"
              width="57"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="313"
              y="33"
              width="50"
              height="12"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="320"
              y="53"
              width="43"
              height="12"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="40"
              y="89"
              width="115"
              height="12"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="12"
              y="13"
              width="16"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="40"
              y="33"
              width="100"
              height="12"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="40"
              y="53"
              width="38"
              height="12"
              rx="2"
              fill={color("blue", "000")}
            />
          </g>
        </SVG>
      </div>
    );
  }
}
