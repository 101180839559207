import { gql } from "@apollo/client";

export const GET_USER_OPTIN_PROMOS = gql`
  query GetUserOptedInPromos($accountNumber: Int!) {
    userOptInPromotions(accountId: $accountNumber) {
      costCategoryGroup
      eligibilityStatusName
      optInBeginDate
      optInDate
      optInEndDate
      promoActiveStatus
      promotionCodes
      promotionDescription
      promotionName
    }
  }
`;

export const GET_USER_PROMOS = gql`
  query GetUserPromos(
    $accountNumber: Int!
    $overrideContext: Boolean
    $promotionType: PromotionType
  ) {
    userPromotions(
      accountId: $accountNumber
      overrideContext: $overrideContext
      promotionType: $promotionType
    ) {
      promotions {
        city
        costCategoryPriority
        country
        description
        endDate
        id
        isAboveTheLine
        isPromoTagShown
        name
        optedIn
        path
        promotionTypePriority
        referralCode
        startDate
        state
        title
        trackName
      }
      eligiblePromotions {
        categoryGroupName
        description
        eligibilityStatusName
        externalSystemCode
        lastPayoutDate
        name
        optInBeginDate
        optInDate
        optInEndDate
        promotionCodes
        segmentName
        status
        totalPayoutAmount
        totalPayoutPoints
        rootId
      }
    }
  }
`;
