import { combineReducers } from "redux";
import quickLinksReducer from "@tvg/quick-links/redux-manager";

import homeReducer, {
  initialState as initialHomeState
} from "@tvg/home/src/store/reducers";

import topRacesReducer, {
  initialState as initialTopRacesState
} from "@tvg/top-races/src/reducers";

import tracksBuilderReducer, {
  initialState as initialTracksBuilderState
} from "@tvg/tracks-builder/src/reducers";

import { initialState as initialQuickLinksContentState } from "@tvg/quick-links/redux-manager/reducers/content";

import { initialState as initialQuickLinksRenderingState } from "@tvg/quick-links/redux-manager/reducers/rendering";

import promosOnboardingReducer, {
  initialState as initialPromosOnboardingState
} from "@tvg/sh-lib-promos-onboarding/redux/reducers";

import capiReducer, {
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  initialState as initialUserState
} from "./App/userDataReducer";

import appReducer, { initialState as initialAppState } from "./App/appReducer";

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  app: initialAppState,
  home: initialHomeState,
  quickLinks: {
    content: initialQuickLinksContentState,
    rendering: initialQuickLinksRenderingState
  },
  topRaces: initialTopRacesState,
  topTracks: initialTracksBuilderState,
  promosOnboarding: initialPromosOnboardingState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  app: appReducer,
  home: homeReducer,
  quickLinks: quickLinksReducer,
  topRaces: topRacesReducer,
  topTracks: tracksBuilderReducer,
  promosOnboarding: promosOnboardingReducer
});
