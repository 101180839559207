import React, { Component } from "react";
import { isEqual, get, noop, flowRight as compose } from "lodash";
import { buildBetSlipUrl } from "@tvg/formatter/url";

import tvgConf from "@tvg/conf";
import TopRaces from "@tvg/atomic-ui/_templates/TopRaces";
import { TopRacesMask } from "@tvg/atomic-ui/_static/Masks";
import mediator from "@tvg/mediator";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import withRouter from "@tvg/utils/withCustomRouter";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { events as AlchemerEvents } from "@urp/alchemer";
import { getAccountNumber } from "@urp/store-selectors";
import TopRacesUpdate from "./actions";
import TopRacesQuery from "./graphql/queries/TopRaces";
import TopRacesRDAQuery from "./graphql/queries/TopRacesRDA";
import ApolloOptions from "./graphql/options.graph";

export class TopRacesComponent extends Component {
  static defaultProps = {
    races: [],
    isLoading: true,
    device: "mobile",
    wagerProfile: "PORT-Generic",
    optedInPromos: {},
    isLogged: false,
    shouldUpdate: false,
    rdaClient: noop,
    accountId: "",
    useRDAService: false,
    title: "Top Races",
    refetch: noop,
    isEndOfFetch: false,
    isPoller: true,
    isOpeningApp: false,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      activeRaceId: "",
      isEnd: false,
      hasFetched: false,
      showLastCard: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const hasFetched =
      state.hasFetched || (!props.isLoading && !state.hasFetched);

    if (props.races.length && state.activeRaceId !== "") {
      if (!state.isEnd) {
        const actualIndex = props.races.findIndex(
          (el) => el.raceId === state.activeRaceId
        );
        if (actualIndex !== -1) {
          return {
            ...state,
            hasFetched,
            activeRaceId: get(props, `races[${actualIndex}].raceId`, 0),
            activeIndex: actualIndex
          };
        }
      } else {
        return {
          ...state,
          hasFetched,
          activeIndex: get(props, "races.length")
        };
      }
    }
    return {
      activeIndex: state.activeIndex,
      activeRaceId: get(props, `races[${state.activeIndex}].raceId`, ""),
      hasFetched
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const loadingChanged = this.props.isLoading !== nextProps.isLoading;
    const activeIndexChanged = this.state.activeIndex !== nextState.activeIndex;
    const wagerProfileChanged =
      this.props.wagerProfile !== nextProps.wagerProfile;
    const optedInPromosChanged = !isEqual(
      this.props.optedInPromos,
      nextProps.optedInPromos
    );
    const racesChanged = !isEqual(nextProps.races, this.props.races);

    return (
      loadingChanged ||
      activeIndexChanged ||
      wagerProfileChanged ||
      optedInPromosChanged ||
      racesChanged
    );
  }

  componentWillUnmount() {
    this.props.dispatch(TopRacesUpdate([]));
  }

  onCardClick = (race, url, section) => {
    const newUrl = tvgConf().buildUrl({ path: url });

    const tag = this.getTagDescription(race);

    AlchemerEvents.selectPopularRace();

    mediator.base.dispatch({
      type: "HEADER_GO_TO_RACE",
      payload: {
        section,
        tag,
        url: newUrl,
        race
      }
    });

    const { history } = this.props;
    if (isTvg5()) {
      const raceUrl = newUrl.split("/").splice(3).join("/");
      history.push(raceUrl);
    }

    if (this.props.isOpeningApp && typeof window !== "undefined") {
      window.location.assign("https://racing.fanduel.com");
    }

    if (this.props.device === "desktop") {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: url }
      });
    }
  };

  onLastCardClick = () => {
    const { history } = this.props;
    if (isTvg5()) {
      history.push("/races");
    } else {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/races" }
      });
    }
  };

  onRunnerSelection = (race, runner, url, event) => {
    if (event && this.props.device === "desktop" && !isTvg5()) {
      event.preventDefault();
    }
    const tag = this.getTagDescription(race);
    const newUrl = tvgConf().buildUrl({
      path: `${url}&selectedRunner=${runner}`
    });

    mediator.base.dispatch({
      type: "TOP_RACES:SELECT_RUNNER",
      payload: {
        race,
        tag,
        url: newUrl,
        runner
      }
    });

    return (
      this.props.device === "desktop" &&
      !isTvg5() &&
      this.navigationOnDesktop(url, runner, race)
    );
  };

  onHandleTransition = (activeIndex) => {
    if (activeIndex > this.state.activeIndex) {
      mediator.base.dispatch({
        type: "TOP_RACES_SEE_NEXT_RACES"
      });
    } else if (activeIndex < this.state.activeIndex) {
      mediator.base.dispatch({
        type: "TOP_RACES_SEE_PREVIOUS_RACES"
      });
    }

    this.setState(
      {
        activeIndex,
        activeRaceId: get(this.props, `races[${activeIndex}].raceId`, 0),
        isEnd:
          get(this.props, "races.length", 1) === activeIndex &&
          get(this.props, "races.length", 1) > 0
      },
      () => {
        if (
          !get(this.props, "isEndOfFetch", false) &&
          get(this.props, "races.length", 1) - 5 <= activeIndex
        ) {
          this.props.refetch({
            wagerProfile: get(this.props, "wagerProfile") || "PORT-Generic",
            accountId: +get(this.props, "accountId"),
            isHighlighted: true,
            ...tvgConf().graphContext(),
            results: +get(this.props, "races.length") + 9,
            isRefetch: true
          });
        }
      }
    );
  };

  getTagDescription = (race) => {
    let promo;
    if (
      get(race, "promos[0].isAboveTheLine", false) ||
      get(race, "userPromotions.length", 0)
    ) {
      promo = get(race, "userPromotions[0].optedIn", false)
        ? "promo optin"
        : "Promo";
    } else {
      promo = "No promo";
    }

    const hasBets = +get(race, "betsCount", 0) > 0 ? "Bets" : "No bets";

    const talentPicks = race.talentPicks.length ? "Picks" : "No picks";

    return `${promo} - ${hasBets} - ${talentPicks}`;
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (!isEqual(this.props.isLogged, prevProps.isLogged)) {
      this.setState({
        showLastCard: false,
        isEnd: false,
        activeIndex: 0,
        activeRaceId: get(this.props, `races[0].raceId`, "")
      });
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      (!isEqual(prevProps.races, this.props.races) && !this.props.isLoading) ||
      (isEqual(prevProps.races, this.props.races) &&
        !this.props.isLoading &&
        this.props.isLoading !== prevProps.isLoading)
    ) {
      if (
        prevProps.races.length + 9 > this.props.races.length &&
        !this.props.isPoller
      ) {
        // eslint-disable-next-line
        this.setState({ showLastCard: true });
      }
    }
  }

  navigateToBetSlip = (race, runner, url) => {
    if (!isTvg5()) {
      if (race) {
        const newUrl = buildBetSlipUrl(
          race.trackCode,
          race.raceNumber || "",
          runner,
          race.isGreyhound
        );
        mediator.base.dispatch({
          type: "TVG4_NAVIGATION",
          payload: { route: url }
        });
        window.open(newUrl, "", "width=535,height=778");
      }
    } else {
      const { history } = this.props;
      history.push(`${url}&selectedRunner=${runner}&wt=WN`);
    }
  };

  navigationOnDesktop = (url, runner, race) => {
    if (this.props.isLogged) {
      this.navigateToBetSlip(race, runner, url);
    } else {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              this.navigateToBetSlip(race, runner, url);
            }
          },
          triggerAction: "top_races_select_runner"
        }
      });
    }
  };

  render() {
    let racesToRender;
    switch (this.props.device) {
      case "mobile":
        racesToRender = 2;
        break;
      case "tablet":
        racesToRender = 5;
        break;
      default:
        racesToRender = get(this.props, "races.length", 50);
    }

    return (this.state.hasFetched || !!this.props.races.length) &&
      this.props.shouldUpdate ? (
      <TopRaces
        onRunnerSelection={this.onRunnerSelection}
        optedInPromos={this.props.optedInPromos}
        onHandleTransition={this.onHandleTransition}
        races={this.props.races}
        isDesktop={this.props.device === "desktop"}
        onCardClick={this.onCardClick}
        activeIndex={this.state.activeIndex}
        racesToRender={racesToRender}
        onLastCardClick={this.onLastCardClick}
        title={this.props.title}
        isLoading={this.props.isLoading}
        showLastCard={this.state.showLastCard}
        isMTPNewRules={this.props.isMTPNewRules}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
    ) : (
      <TopRacesMask device={this.props.device} />
    );
  }
}

export const skipTopRacesQuery = ({
  isAccountCompliant,
  shouldUpdate,
  useRDAService,
  accountId
}) => !shouldUpdate || (useRDAService && isAccountCompliant && accountId);

export const skipTopRacesRDAQuery = ({
  isAccountCompliant,
  shouldUpdate,
  useRDAService,
  accountId
}) =>
  !shouldUpdate || (useRDAService ? !isAccountCompliant || !accountId : true);

export default withRouter(
  connect(
    (store) => ({
      wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
      isLogged: get(store, "userData.logged"),
      accountId: getAccountNumber(store),
      useRDAService: get(store, "capi.featureToggles.useRDAService", false),
      isMTPNewRules: get(store, "capi.featureToggles.MTPColoursRules", false),
      useIsPromoTagShownFlag: get(
        store,
        "capi.featureToggles.useIsPromoTagShownFlag",
        false
      ),
      isAccountCompliant: isAccountCompliantSelector(store)
    }),
    (dispatch) => ({ dispatch })
  )(
    compose(
      graphql(TopRacesQuery, {
        ...ApolloOptions,
        skip: skipTopRacesQuery
      }),
      graphql(TopRacesRDAQuery, {
        ...ApolloOptions,
        skip: skipTopRacesRDAQuery
      }),
      connect(
        (store) => ({
          optedInPromos: get(store, "userData.optedInPromos", {})
        }),
        () => ({})
      )
    )(TopRacesComponent)
  )
);
