import React from "react";
import { useSelector } from "react-redux";

import buildRaceUrl from "@tvg/formatter/url";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { getOptedInPromos } from "@urp/store-selectors";
import { Carousel } from "@tvg/design-system/web";
import type { Race } from "@tvg/ts-types/Race";
import useTopRacesCarousel from "./hooks/useTopRacesCarousel";
import { sendAnalyticsEvt } from "./utils";
import { RaceCard } from "./components/RaceCard";
import { TopRacesMask } from "./components/TopRacesMask";
import { NoRacesAvailable } from "./components/NoRacesAvailable";
import { ScrollableContainer, Element, Container } from "./styled-components";
import { getEnablePopularRaces } from "./redux/selectors";

const SECTION_TITLE = "Today’s Popular Races";

export const TopRaces = ({
  qaLabel = "topRaces",
  title
}: {
  qaLabel?: string;
  title?: string;
}) => {
  const isPopularRacesEnabled = useSelector(getEnablePopularRaces);
  const optedInPromos = useSelector(getOptedInPromos);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  const {
    races,
    handleScroll,
    racesWithSilks,
    loading,
    scrollContainerRef,
    renderedCardCount
  } = useTopRacesCarousel();

  if (!isMobile && !isPopularRacesEnabled) {
    return null;
  }

  if (loading && !races?.length) {
    return (
      <ScrollableContainer data-qa-label={qaLabel}>
        {[...Array(renderedCardCount)].map((_, idx) => (
          <Element key={`card-empty-${idx.toString()}`}>
            <TopRacesMask />
          </Element>
        ))}
      </ScrollableContainer>
    );
  }

  const renderNoRacesAvailable = () => (
    <NoRacesAvailable
      qaLabel={`${qaLabel}-noRacesAvailable`}
      {...(!isMobile && { sectionTitle: SECTION_TITLE })}
    />
  );

  const renderItems = () =>
    races.map((race: Race, idx: number) => {
      const runnersWithSilks = racesWithSilks?.length
        ? racesWithSilks[idx] || []
        : [];
      const raceUrl = buildRaceUrl(
        race.track.code,
        race.track.name,
        race.raceNumber
      );
      return idx < renderedCardCount ? (
        <Element key={race.id}>
          <RaceCard
            qaLabel={`${qaLabel}-raceCard-${idx}`}
            race={race}
            to={raceUrl}
            runnersWithSilks={runnersWithSilks}
            onClick={(section: string) =>
              sendAnalyticsEvt(race, raceUrl, section, optedInPromos)
            }
          />
        </Element>
      ) : (
        <Element key={race.id}>
          <TopRacesMask />
        </Element>
      );
    });

  return (
    <>
      {isMobile ? (
        <ScrollableContainer ref={scrollContainerRef} data-qa-label={qaLabel}>
          {races.length ? renderItems() : renderNoRacesAvailable()}
        </ScrollableContainer>
      ) : (
        <Container data-qa-label="top-races-section">
          {races.length ? (
            <Carousel
              title={title || SECTION_TITLE}
              qaLabel="carousel-top-races"
              onScroll={handleScroll}
              items={renderItems()}
            />
          ) : (
            renderNoRacesAvailable()
          )}
        </Container>
      )}
    </>
  );
};
