export default {
  desktop: [
    { id: 1, name: "PROMO_WIDGET", description: "Promotions Calendar" },
    { id: 2, name: "SEO_FOOTER", description: "Horse Racing Betting" }
  ],
  tablet: [
    { id: 1, name: "PROMO_WIDGET", description: "Promotions Calendar" },
    { id: 2, name: "SEO_FOOTER", description: "Horse Racing Betting" }
  ],
  mobile: [
    { id: 1, name: "PROMO_WIDGET", description: "Promotions Calendar" },
    { id: 2, name: "SEO_FOOTER", description: "Horse Racing Betting" }
  ]
};
