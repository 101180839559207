import { gql } from "@apollo/client";
import RaceFragment from "../fragments/Races";

export const GET_TODAYS_RACES = gql`
  query getTodayRaces(
    $wagerProfile: String!
    $product: String
    $brand: String
    $sortBy: RaceListSort
    $filterBy: RaceListFilter
  ) {
    raceDate
    races(filter: $filterBy, profile: $wagerProfile, sort: $sortBy) {
      ...Race
    }
  }
  ${RaceFragment.entry}
`;
