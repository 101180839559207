import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${({ theme }) => theme.space["space-3"]};
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`;
