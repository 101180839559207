const storeQuickLinks = (links) => ({
  type: "STORE_QUICK_LINKS",
  payload: links
});

const storeQuickLinksData = (data) => ({
  type: "STORE_QUICK_LINKS_DATA",
  payload: data
});

export { storeQuickLinks, storeQuickLinksData };
