import React from "react";
import styled from "styled-components";

export const generatePaths = (icon) =>
  icon.map((shape, index) => (
    <path
      key={`path-${index.toString()}`}
      fill={shape.fill}
      fillRule={shape.fillRule}
      stroke={shape.strokeColor}
      strokeWidth={shape.strokeWidth}
      d={shape.path}
    />
  ));

const colorize = (colorList, icon) => {
  const localIcon = icon.map((shape) => ({ ...shape }));
  colorList.forEach((color, index) => {
    if (color && localIcon[index]) {
      localIcon[index].fill = color;
    }
  });
  return localIcon;
};

const IconSVG = styled.svg.attrs(({ size, qaLabel, viewBoxSize }) => ({
  width: size,
  height: size,
  viewBox: `0 0 ${viewBoxSize} ${viewBoxSize}`,
  "data-qa-label": qaLabel
}))`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  fill: ${(props) =>
    props.color !== "inherit" && props.colorList.length === 0 && props.color};
`;

const Icon = (props) => (
  <IconSVG
    size={props.size}
    qaLabel={props.qaLabel}
    className={props.className}
    colorList={props.colorList}
    color={props.color}
    viewBoxSize={props.viewBoxSize}
  >
    {generatePaths(
      props.colorList.length > 0
        ? colorize(props.colorList, props.icon.shapes)
        : props.icon.shapes
    )}
  </IconSVG>
);

Icon.defaultProps = {
  size: 16,
  icon: null,
  color: "inherit",
  className: "",
  qaLabel: null,
  colorList: [],
  viewBoxSize: 1024
};

export default Icon;
