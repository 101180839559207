import styled from "styled-components";

export const Body = styled.div`
  padding: ${({ theme }) => theme.space["space-4"]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space["space-4"]};
`;

export const Description = styled.article`
  font-family: ${({ theme }) => theme.fonts.condensedRegular};
  text-align: justify;
  color: ${({ theme }) => theme.colorTokens.content.subtle};
`;
