import { gql } from "@apollo/client";
import { UPCOMING_RACE } from "../fragments/UpcomingRace";

export const UPCOMING_RACES = gql`
  query UpcomingRaces(
    $product: String
    $brand: String
    $device: String
    $pagination: Pagination
    $trackRacePagination: Pagination
    $wagerProfile: String
    $racesFilter: RaceListFilter
    $racesSort: RaceListSort
    $trackFilters: TrackListFilter
  ) {
    upcomingRaces: tracks(filter: $trackFilters, profile: $wagerProfile) {
      races(
        sort: $racesSort
        filter: $racesFilter
        page: $trackRacePagination
      ) {
        ...UpcomingRace
      }
    }
    extraRaces: races(
      filter: $racesFilter
      sort: $racesSort
      page: $pagination
      profile: $wagerProfile
    ) {
      ...UpcomingRace
    }
  }
  ${UPCOMING_RACE}
`;

export default UPCOMING_RACES;
