import React, { memo, useState } from "react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Header, useColorTokens } from "@tvg/design-system/web";
import tvgConf from "@tvg/conf";

import { Container, VideosRow } from "./styled-components";
import { sendSelectedEvent } from "../../utils";
import type { Playlist } from "../../types";
import VideoThumbnail from "../VideoThumbnail";
import VideoPlayerModal from "../VideoPlayerModal";
import { BASE, CONTAINER } from "../../constants";
import { Props } from "./types";

const isDesktop = tvgConf().device === "desktop";

const VideoPlaylist = ({ title, qaLabel, playlist }: Props) => {
  const { content } = useColorTokens();

  const [selectedItem, setSelectedItem] = useState<Playlist | null>(null);

  const onClose = () => {
    setSelectedItem(null);
  };

  const handleVideoClick = (item: Playlist) => {
    sendSelectedEvent(item.description.title, item.description.category);
    setSelectedItem(item);
  };

  if (!playlist.length) return <></>;

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, BASE, CONTAINER])}>
      {title && (
        <Header
          tag="h1"
          padding="space-4"
          fontFamily="bold"
          fontSize={isDesktop ? 18 : 16}
          color={content.strong}
        >
          <>{title}</>
        </Header>
      )}
      <VideosRow>
        {playlist.map((item, index) => (
          <VideoThumbnail
            key={`${item.description.title}-${index.toString()}`}
            item={item}
            onClick={() => handleVideoClick(item)}
            qaLabel={buildQaLabel([qaLabel, BASE])}
          />
        ))}
      </VideosRow>
      <VideoPlayerModal
        item={selectedItem}
        onClose={onClose}
        qaLabel={buildQaLabel([qaLabel, BASE])}
      />
    </Container>
  );
};

export default memo(VideoPlaylist);
