import { gql } from "@apollo/client";
import { UPCOMING_RACE_RDA } from "../fragments/Race";

export const UPCOMING_RACES_RDA = gql`
  query UpcomingRacesRDA(
    $wagerProfile: String
    $product: String
    $brand: String
    $device: String
    $pagination: Pagination!
    $accountId: Int!
    $racesFilter: RaceListFilter
    $trackFilters: TrackListFilter
    $racesSort: RaceListSort
    $wagerHistoryFilter: WagerHistoryFilter
  ) {
    upcomingRaces(
      highlighted: { product: $product, brand: $brand, device: $device }
      page: $pagination
      profile: $wagerProfile
      accountId: $accountId
      extraRacesFilter: $racesFilter
      extraRacesSort: $racesSort
      trackRaceFilter: $racesFilter
      trackFilter: $trackFilters
    ) {
      ...UpcomingRaceRDA
      highlighted {
        description
        style
      }
      betsCount: wagerHistory(filter: $wagerHistoryFilter) {
        count
      }
      userPromotions {
        isAboveTheLine
        optedIn
        isPromoTagShown
      }
    }
  }
  ${UPCOMING_RACE_RDA}
`;

export default UPCOMING_RACES_RDA;
