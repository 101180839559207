import { includes } from "lodash";
import mediator from "@tvg/mediator";
import TvgConfig from "@tvg/conf";

export const isMobile = (product) =>
  includes(
    [
      "ios2",
      "androidwrapper",
      "iosnative",
      "androidnative",
      "tvgandroid",
      "fdrios",
      "fdrandroid",
      "fdrandroidgps"
    ],
    product
  );

export const isAndroid = (product) =>
  includes(
    ["androidwrapper", "fdrandroid", "tvgandroid", "fdrandroidgps"],
    product
  );

/* eslint-disable consistent-return */
export const openExternalLink = (link, callback) => {
  if (isMobile(TvgConfig().product)) {
    mediator.ios.dispatch({
      type: "OPEN_EXTERNAL_APP",
      payload: { openExternalApp: link }
    });
  } else if (window) {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const tab = window.open(link, "_blank");
    if (tab) {
      tab.focus();
    }

    if (callback) {
      callback(tab);
    }
  }
};

export const isSafari = (allBrowsers = false) => {
  if (
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.userAgent
  ) {
    const ua = window.navigator.userAgent;
    const iOS =
      (!!ua.match(/iPad/i) || !ua.match(/iPhone/i)) && !!ua.match(/WebKit/i);

    return (
      iOS &&
      !!ua.match(/Safari/i) &&
      (allBrowsers || !ua.match(/CriOS/i)) &&
      (allBrowsers || !ua.match(/Chrome/i))
    );
  }
  return false;
};

export default isMobile;
