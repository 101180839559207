export default class Poller {
  pollerInstance;

  constructor() {
    this.pollerInstance = null;
  }

  refresh(callBack, interval) {
    // Make initial request
    if (typeof callBack === "function") {
      callBack();
    }
    // set poller
    this.pollerInstance = setInterval(callBack, interval);
  }

  stop() {
    if (this.pollerInstance) {
      clearInterval(this.pollerInstance);
      this.pollerInstance = null;
    }
    return this.pollerInstance;
  }

  start(callBack, interval) {
    if (this.pollerInstance) {
      clearInterval(this.pollerInstance);
    }
    this.refresh(callBack, interval);
  }

  isRunning() {
    return !!this.pollerInstance;
  }
}
