import React, { PureComponent } from "react";
import { Collapse } from "react-collapse";
import { bindAll, noop } from "lodash";

import Icon from "../../_static/Icons/index";

import buildColor from "../../_static/ColorPalette";
import { arrowDown } from "../../_static/Icons/icons";
import {
  RowContainer,
  Row,
  ExpandableHeader,
  IconContainer,
  ClickableArea,
  RowLineBreak
} from "./styled-components";

export default class ExpandableRow extends PureComponent {
  static defaultProps = {
    header: null,
    children: null,
    isExpandable: true,
    isOpen: false,
    onExpand: noop,
    className: "",
    hasLineBreak: false,
    forceHeight: false,
    trackCode: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };
    bindAll(this, "onExpand");
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.props.isOpen
    ) {
      this.setState({
        isOpen: nextProps.isOpen
      });
    }
  }

  onExpand() {
    if (this.props.isExpandable) {
      this.props.onExpand(this.state.isOpen);
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen
      }));
    }
  }

  render() {
    return (
      <RowContainer forceHeight={this.props.forceHeight}>
        <Row
          className={this.props.className}
          data-qa-label={`expandableRow-collapser${this.props.trackCode}`}
        >
          <ExpandableHeader onTouchStart={noop}>
            <ClickableArea onClick={this.onExpand}>
              {this.props.header}
            </ClickableArea>
            {this.props.isExpandable && (
              <IconContainer isOpen={this.state.isOpen}>
                <Icon
                  icon={arrowDown}
                  size={16}
                  color={buildColor("grey", "600")}
                  qaLabel="expandableRow-arrow-icon"
                />
              </IconContainer>
            )}
          </ExpandableHeader>
          {this.props.isExpandable && (
            <Collapse
              isOpened={this.state.isOpen}
              springConfig={{ stiffness: 200, damping: 25 }}
            >
              {this.props.children}
            </Collapse>
          )}
        </Row>
        {this.props.hasLineBreak && <RowLineBreak />}
      </RowContainer>
    );
  }
}
