import styled, { css } from "styled-components";
import { buildColor, breakpoints } from "@tvg/design-system";
import { Props } from "./types";

export const Container = styled.div<
  {
    hasSilk?: boolean;
  } & Pick<Props, "useUpdatedSilk">
>`
  display: flex;
  width: ${({ hasSilk, useUpdatedSilk }) => {
    if (useUpdatedSilk) return "40px";
    return hasSilk ? "59px" : "32px";
  }};

  @media screen and ${breakpoints.tablet.min.sm} {
    align-items: center;
  }

  ${({ useUpdatedSilk }) =>
    useUpdatedSilk &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        overflow: hidden;
        position: relative;
      }
    `}
`;

export const SaddleContainer = styled.div<{
  useUpdatedSilk: boolean;
  isSilkVisible: boolean;
}>`
  background-color: ${buildColor("white", "900")};
  display: inline-flex;
  flex-shrink: 0;
  z-index: 1;

  @media screen and ${breakpoints.tablet.max.sm} {
    & > div {
      height: 100%;
      border-radius: 0 4px 4px 0;
      ${({ useUpdatedSilk }) =>
        useUpdatedSilk &&
        css`
          justify-content: center;
          align-items: flex-start;
          padding: 4px;
          z-index: 0;
          position: absolute;
        `}

      ${({ isSilkVisible }) =>
        isSilkVisible &&
        ` p {
          position: absolute;
          top: 2px;
          right: 14px;
      }`}
    }
  }
`;

export const SilkImage = styled.img<Pick<Props, "useUpdatedSilk">>`
  display: flex;
  height: 42px;
  width: 42px;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  ${({ useUpdatedSilk }) =>
    useUpdatedSilk &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        height: 53px;
        width: 55px;
      }
    `}
`;

export const SilkContainer = styled.div<
  {
    isScratched?: boolean;
  } & Pick<Props, "useUpdatedSilk">
>`
  transform: translateX(-16px);
  height: 42px;
  width: 42px;
  ${({ isScratched }) =>
    isScratched &&
    css`
      opacity: 0.4;
    `}
  ${({ useUpdatedSilk }) =>
    useUpdatedSilk &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 40%;
        position: absolute;
        z-index: 1;
      }
    `}
`;

export const SilkBorderContainer = styled.div<Pick<Props, "useUpdatedSilk">>`
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 2;
  ${({ useUpdatedSilk }) =>
    useUpdatedSilk &&
    css`
      svg {
        height: 55px;
        width: 56px;
      }
    `}
`;
