import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import { TalentPickGraphData } from "@tvg/ts-types/Race";
import { get } from "lodash";
import { UseTalentPicksProps } from "../types";
import ApolloOptions, {
  getTalentPicksWithVisualSelections,
  getUniqueTalents
} from "../graphql/options.graph";
import { GET_GRAPH_TALENT_PICKS } from "../graphql/queries/talentPicksQuery";
import useTalentPicksSubscription from "./useTalentPicksSubscription";

const useTalentPicks = ({
  skip,
  trackCode,
  raceNumber,
  wagerProfile,
  races
}: UseTalentPicksProps) => {
  const { loading, subscribeToMore, data } = useQuery(
    GET_GRAPH_TALENT_PICKS,
    ApolloOptions.options({
      skip,
      trackCode,
      raceNumber,
      wagerProfile
    })
  );

  const talentPicksList: Array<TalentPickGraphData> = get(
    data,
    "talentPicksList",
    []
  );

  const talentPicks = useMemo(() => {
    const talentPicksWithVisualSelections = getTalentPicksWithVisualSelections(
      talentPicksList,
      races
    );

    return {
      talentPicks: talentPicksWithVisualSelections,
      talentsList: getUniqueTalents(talentPicksWithVisualSelections)
    };
  }, [JSON.stringify(talentPicksList), races]);

  useTalentPicksSubscription(subscribeToMore, wagerProfile);

  return useMemo(
    () => ({
      loading,
      talentPicks
    }),
    [loading, talentPicks]
  );
};

export default useTalentPicks;
