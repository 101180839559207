import styled, { css } from "styled-components";
import Icon from "../../_static/Icons";
import { generateTransition, cubic } from "../../_static/Transitions";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const StarIcon = styled(Icon)`
  position: absolute;
  ${({ isBetsStandalone }) =>
    isBetsStandalone
      ? css`
          top: -5px;
          left: -5px;
        `
      : css`
          top: -4px;
          right: -2px;
        `}
`;

export const SelectIcon = styled(Icon)`
  transform: ${(props) =>
    props.isRotated ? "rotate(180deg)" : "rotate(0deg)"};
  will-change: transform;
  transition: ${generateTransition(cubic, "transform")};
`;

export const WinnerIconWrapper = styled.div`
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  background: ${buildColor("green", "500")};
  border-radius: 50%;
  border: 1px solid ${buildColor("green", "000")};
  z-index: 2;
`;

export const WinnerIcon = styled(Icon)`
  position: absolute;
  top: 1px;
  left: 1px;
`;

const sharedScratchedStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
`;

export const RunnerNumberAtom = styled.span.attrs({
  "data-qa-label": "runnerNumber"
})`
  font-size: 12px;
  color: ${(props) => props.numberColor};
  position: relative;
  text-shadow: ${(props) => props.textShadow};
  background: ${(props) => props.gradient};
  border: ${(props) => props.border};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.saddleColor};
  flex-direction: ${(props) => props.flexDirection};
  font-family: ${fontMedium};
  border-radius: 1px;

  ${({
    isBetsStandalone,
    isActiveMultiRaceLegOver,
    isWinnerBI,
    isRaceOver,
    isScratched,
    isBetsDetailedView,
    isAllRunners
  }) =>
    (isBetsStandalone && isActiveMultiRaceLegOver && !isWinnerBI) ||
    (isBetsStandalone && !isRaceOver && isScratched && !isBetsDetailedView) ||
    (isBetsStandalone && isAllRunners && isActiveMultiRaceLegOver)
      ? css`
          opacity: 0.2;
        `
      : css`
          opacity: 1;
        `}

  ${({ isBetsStandalone, isWinnerBI, isUnknownWinnerStatus }) =>
    isBetsStandalone
      ? css`
          height: 20px;
          min-width: 20px;
          padding: 0 2px;
          ${(isWinnerBI || isUnknownWinnerStatus) && "z-index: 2;"}
        `
      : css`
          width: 100%;
          height: 100%;
          min-width: 16px;
          min-height: 16px;
          font-weight: bold;
        `}
  
  ${({
    isBetsStandalone,
    isScratched,
    isBetsDetailedView,
    isRaceOver,
    isUnknownWinnerStatus
  }) =>
    !isUnknownWinnerStatus &&
    isBetsStandalone &&
    isScratched &&
    !isBetsDetailedView &&
    isRaceOver &&
    css`
      &::before {
        content: "";
        ${sharedScratchedStyles};
        background-color: ${buildColor("white", "40")};
        z-index: 2;
      }
    `}
`;

export const ScratchedBoxIconContainer = styled.div`
  ${sharedScratchedStyles};
  z-index: 1;
`;
