import { gql } from "@apollo/client";

export const TOP_TRACKS = gql`
  fragment TopTracks on Track {
    id
    code
    shortName
    isFavorite
    favorite {
      id
    }
    name
    featured
    hasAboveTheLinePromo
    numberOfRaces
    isGreyhound
    isFinished
    hasPromo
    isPromoTagShown
  }

  query getTopTracksRDA(
    $accountId: Int!
    $wagerProfile: String!
    $product: String
    $brand: String
  ) {
    topTracks(
      accountId: $accountId
      profile: $wagerProfile
      product: $product
      brand: $brand
    ) {
      ...TopTracks
    }
  }
`;

export default TOP_TRACKS;
