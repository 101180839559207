import { get } from "lodash";

import type { Race } from "@tvg/ts-types/Race";
import mediator from "@tvg/mediator";
import { events as AlchemerEvents } from "@urp/alchemer";
import { doesSilkLoads } from "@tvg/sh-utils/raceUtils";
import {
  getRacePromos,
  isUserOptedInPromo
} from "@urp/lib-racetracks/src/utils/races";
import type { UserOptedInPromos } from "@tvg/ts-types/User";

export const buildQaLabel = (labels: string[]) =>
  labels.filter(Boolean).join("-");

export const getShouldShowSilks = async (
  races: Array<Race>
): Promise<Array<Array<boolean>>> => {
  const shouldShowSilks = await Promise.all(
    races.map(async (race) => {
      const bettingInterests = get(race, "bettingInterests", []);
      if (bettingInterests.length) {
        return doesSilkLoads(bettingInterests);
      }
      return [false];
    })
  );

  return shouldShowSilks;
};

export const sendAnalyticsEvt = (
  race: Race,
  url: string,
  section: string,
  optedInPromos: UserOptedInPromos
) => {
  const availableRacePromos = getRacePromos(race);
  const isOptedIn = isUserOptedInPromo(optedInPromos, availableRacePromos);
  AlchemerEvents.selectPopularRace();
  mediator.base.dispatch({
    type: "RACE_CELL_CLICKED",
    payload: {
      section,
      linkUrl: url,
      race,
      trackName: race.track.name,
      module: `Top races - ${section}`,
      isFDTV: race.video.onTvg || race.video.onTvg2,
      raceNumber: race.raceNumber,
      promoOptedIn: isOptedIn ? "true" : "false",
      trackCountry: race.track.location?.country
    }
  });
};

export const getCardIndex = (scrollContainer: HTMLDivElement | null) => {
  if (!scrollContainer || scrollContainer.children?.length === 0) return 0;

  const cardElements = Array.from(scrollContainer.children) as HTMLElement[];
  const cardWidth = cardElements.length > 0 ? cardElements[0].offsetWidth : 0;
  const { clientWidth: viewportWidth, scrollLeft } = scrollContainer;

  /* this returns the cadsElement length when scroll had reached the end of the scroll
  which is when the number of card shown are the requested at that moment. */
  const newVisibleCardIndex = Math.min(
    Math.floor((scrollLeft + viewportWidth) / cardWidth),
    cardElements.length - 1
  );

  return newVisibleCardIndex;
};
