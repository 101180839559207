export const getUserData = (userData) => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged) => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (accountNumber) => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});

export const setUserPromos = (allPromos) => ({
  type: "USER_PROMOS_SET",
  payload: allPromos
});

export const failUserPromos = (err) => ({
  type: "USER_PROMOS_FAIL",
  payload: err,
  error: true
});

export const successUserPromos = (userPromos) => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});
