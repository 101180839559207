import styled, { css } from "styled-components";

import { fontBold, fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const linkContainerCSS = `display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${fontMedium};
  color: ${buildColor("blue_accent", "500")};
  text-decoration: none;
  font-size: 12px;
  padding: 8px;
  `;

export const Container = styled.section`
  background: ${buildColor("white", "100")};
  width: 100%;
  position: relative;
  ${(props) =>
    props.isDesktop &&
    css`
      border-radius: 4px;
      padding-bottom: 8px;
    `}
`;

export const Title = styled.h1`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  margin: 0;
  ${({ biggerTitle }) =>
    biggerTitle
      ? css`
          height: 21px;
          font-size: 18px;
        `
      : css`
          height: 19px;
          font-size: 16px;
        `};
`;

export const UpperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  height: 48px;
  ${(props) =>
    props.isDesktop &&
    props.hasBiggestWinner &&
    css`
      height: 56px;
    `}
`;

export const WinnersList = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  flex-flow: row nowrap;
  list-style: none;
  ${(props) =>
    !props.hasBiggestWinner &&
    css`
      padding: 0;
    `};
  ${(props) =>
    props.isDesktop
      ? css`
          flex-flow: column;
          padding: 0;
        `
      : css`
          padding-bottom: 12px;

          &::before,
          &::after {
            content: "";
            display: block;
            min-width: 12px;
            height: 12px;
          }
        `};
  ${(props) =>
    props.isModal &&
    css`
      padding-bottom: 0;

      &::before,
      &::after {
        display: none;
      }
    `};
  ${(props) =>
    props.device !== "mobile" &&
    props.isModal &&
    css`
      width: 414px;
    `};
`;

export const Winner = styled.li`
  position: relative;
  height: 192px;
  min-width: 292px;
  border-radius: 4px;
  background-color: ${buildColor("blue_accent", "000")};
  border: 1px solid ${buildColor("blue", "100")};
  margin-left: 8px;
  padding: 11px;
  overflow: hidden;
  ${(props) =>
    props.isDesktop
      ? css`
          background-color: ${buildColor("white", "100")};
          border: none;
          width: 100%;
          margin-left: 0;
          padding: 12px;

          &:first-child {
            border: none;
          }
          &:last-child > a > div:last-child {
            border-bottom: none;
          }
        `
      : css`
          &:first-child {
            margin-left: 0;
          }
        `};
  ${(props) =>
    props.hasBiggestWinner && !props.isDesktop
      ? css`
          height: 192px;
        `
      : css`
          height: 136px;
        `};
  ${(props) =>
    props.isBiggestWinner &&
    props.isDesktop &&
    css`
      width: calc(100% - 16px);
      margin: 0 8px;
    `};
  ${(props) =>
    !props.isBiggestWinner &&
    props.isDesktop &&
    css`
      height: 133px;
      border-radius: 0;
    `};
  ${(props) =>
    props.hasBiggestWinner &&
    props.isDesktop &&
    css`
      &:nth-child(2) {
        border: none;
      }
    `};
  ${(props) =>
    props.isBiggestWinner &&
    css`
      background-color: ${buildColor("blue", "900")};
      border: none;
      padding: 0;
      height: 192px;

      &:hover {
        background-color: ${buildColor("blue", "800")};
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          transparent 0%,
          ${buildColor("blue", "900")} 100%
        );
        z-index: 90;
        opacity: 1;
      }

      &:hover::after {
        background: linear-gradient(
          transparent 0%,
          ${buildColor("blue", "800")} 100%
        );
      }
    `};
  ${(props) =>
    props.isBiggestWinner &&
    props.uniqueWinner &&
    css`
      width: 100%;
    `};
  ${(props) =>
    props.isBiggestWinner &&
    props.uniqueWinner &&
    props.isDesktop &&
    css`
      width: calc(100% - 16px);
      margin: 0 8px 8px;
    `};
  ${(props) =>
    props.isBiggestWinner &&
    props.isDesktop &&
    !props.uniqueWinner &&
    css`
      margin-bottom: 8px;
    `};
  ${(props) =>
    !props.isBiggestWinner &&
    css`
      &:hover {
        background-color: ${buildColor("blue_accent", "100")};
      }
    `}
  & > a {
    text-decoration: none;
  }
`;

export const TopSection = styled.div`
  position: relative;
  overflow: hidden;
  ${(props) =>
    props.isDesktop &&
    css`
      padding: 0;
    `};

  & > div {
    width: 100%;
    position: relative;
    z-index: 100;
  }

  & > svg {
    transform: scale(1.7) translateX(5%) translateY(20%);
    opacity: 0.7;
    ${({ device, uniqueWinner }) =>
      device === "tablet" &&
      uniqueWinner &&
      css`
        transform: scale(3.4) translateX(31%) translateY(35%);
      `}

    & > g > path {
      display: none;
    }
  }
`;

export const CatchPhrase = styled.div`
  font-family: ${fontMedium};
  font-weight: 500;
  color: ${buildColor("blue", "100")};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  margin-top: 16px;
  text-align: center;
`;

export const RunnerName = styled.div`
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 2px;
  ${(props) =>
    props.isBiggestWinner &&
    css`
      color: ${buildColor("white", "100")};
      font-family: ${fontBold};
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      height: 28px;
      overflow: hidden;
      text-transform: none;
      text-align: center;
    `};
`;

export const WinValue = styled.div`
  color: ${buildColor("green", "500")};
  font-family: ${fontMedium};
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 0;
  ${(props) =>
    props.isBiggestWinner &&
    css`
      font-family: ${fontBold};
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin-top: 2px;
      text-align: center;
    `};
`;

export const BetTrackDate = styled.div`
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  ${(props) =>
    props.isBiggestWinner &&
    css`
      color: ${buildColor("blue", "100")};
      line-height: 14px;
      text-align: center;
      margin: 2px 0 17px;
    `};
`;

export const BottomSection = styled.div`
  position: relative;
  z-index: 100;
  border-top: 1px solid ${buildColor("white", "20")};
  margin: 0 12px;
  padding-top: 8px;
  height: 56px;
  ${(props) =>
    !props.isBiggestWinner &&
    !props.isDesktop &&
    css`
      border-color: ${buildColor("blue", "000")};
      margin: 10px 0 0;
    `};
  ${(props) =>
    props.hasBiggestWinner &&
    !props.isBiggestWinner &&
    css`
      margin-top: 65px;
    `};
  ${(props) =>
    props.isDesktop &&
    !props.isBiggestWinner &&
    css`
      border-top: none;
      border-bottom: 1px solid ${buildColor("blue", "100")};
      padding: 18px 0 8px;
      margin: 0;
      height: auto;
    `};
`;

export const TrackName = styled.div`
  font-family: ${fontMedium};
  font-weight: 500;
  color: ${buildColor("white", "100")};
  font-size: 14px;
  line-height: 16px;
  margin-top: 2px;
  text-align: left;
  ${(props) =>
    !props.isBiggestWinner &&
    css`
      color: ${buildColor("grey", "900")};
    `};
`;

export const ArrowIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  ${(props) =>
    props.isDesktop &&
    !props.isBiggestWinner &&
    css`
      top: calc(50% - 3px);
    `}
`;

export const LinkContainer = styled.div`
  ${linkContainerCSS}
`;

export const LinkContainerTVG4 = styled.div`
  ${linkContainerCSS}
  padding-right: 0;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const LastElement = styled.div`
  background: ${buildColor("white", "100")};
  min-height: 136px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 0 0 20px;

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const LastElementContainer = styled.li`
  margin-right: -4px;
  min-width: 122px;
  padding-left: 8px;
  height: auto;
  ${(props) =>
    !props.isDesktop &&
    css`
      height: 192px;
    `};
  ${(props) =>
    !props.hasBiggestWinner &&
    css`
      height: 136px;
    `};
`;
