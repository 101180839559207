import React from "react";
import { get } from "lodash";
import tvgConf from "@tvg/conf";
import QuickLinksPattern from "../../_static/Patterns";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import QuickLink from ".";
import Icon from "../../_static/Icons";
import color from "../../_static/ColorPalette";
import { arrowRight } from "../../_static/Icons/icons";

import {
  ExpandedLink,
  RouterLinkContent,
  ExpandedInnerWrapper,
  LabelWrapper,
  ExpandedImg,
  ImageWrapper,
  LabelTextWrapper
} from "./styled-components";

export default class QuickLinkExpended extends QuickLink {
  renderImage = (imageURL, zIndex) => {
    const bucketUrl = `${tvgConf().config("google").gcs.url}/${
      tvgConf().config("google").gcs.tvgStatic
    }`;
    const imagePath = imageURL.startsWith("/") ? imageURL : `/${imageURL}`;
    const imageSrc = `${bucketUrl}${imagePath}`;

    return (
      <ExpandedImg
        src={imageSrc}
        zIndex={zIndex}
        data-qa-label="quickLinkExpandedImg"
      />
    );
  };

  renderImages = () => {
    const { imageURL, patternColor } = this.props;
    return (
      <ImageWrapper
        device={this.props.device}
        backgroundColor={get(this.props, "backgroundColor", "")}
      >
        {imageURL && this.renderImage(imageURL, 100)}
        <QuickLinksPattern
          mainColor={patternColor}
          qaLabel={`quickLinkSvg_${get(this.props, "index", 0)}`}
          index={get(this.props, "index", 0)}
        />
      </ImageWrapper>
    );
  };

  processedTag = () => {
    const { tag, label } = this.props;
    return this.props.isVerticalLayout && label.length > 14 ? null : tag;
  };

  renderLink = () => {
    const { label, url, newTab, backgroundColor, device } = this.props;
    const processedTag = this.processedTag();

    return (
      <ExpandedInnerWrapper backgroundColor={backgroundColor} device={device}>
        <ExpandedLink
          data-qa-label="quickLinkExpanded"
          tag={processedTag}
          href={url}
          {...(newTab ? { target: "_blank" } : {})}
          onClick={(event) => this.props.onClick && this.props.onClick(event)}
        >
          <RouterLinkContent
            tag={processedTag}
            expanded
            data-qa-label="quickLinkContent"
          >
            {this.renderImages()}
            <LabelWrapper device={device}>
              <LabelTextWrapper
                device={device}
                data-qa-label="quickLinkLabelText"
              >
                {label}
              </LabelTextWrapper>
              {device === "desktop" && (
                <Icon
                  icon={arrowRight}
                  color={color("white", "100")}
                  size={16}
                />
              )}
            </LabelWrapper>
          </RouterLinkContent>
        </ExpandedLink>
      </ExpandedInnerWrapper>
    );
  };

  renderButton = () => {
    const { label, url, backgroundColor, device } = this.props;
    const processedTag = this.processedTag();
    const [pathname, searchAndHash] = (url || "").split("?");
    const [search, hash] = (searchAndHash || "").split("#");

    return (
      <ExpandedInnerWrapper backgroundColor={backgroundColor} device={device}>
        <ButtonLink
          qaLabel="quickLinkExpanded"
          url={{
            pathname: pathname.replace("#", ""),
            search: search ? `?${search}` : "",
            hash: hash ? `#${hash}` : "",
            state: { fromQuickLink: true }
          }}
          type="non_styled"
          size="autoSized"
          onClick={(event) => this.props.onClick && this.props.onClick(event)}
        >
          <RouterLinkContent
            tag={processedTag}
            expanded
            data-qa-label="quickLinkContent"
          >
            {this.renderImages()}
            <LabelWrapper device={device}>
              <LabelTextWrapper device={device}>{label}</LabelTextWrapper>
              {device === "desktop" && (
                <Icon
                  icon={arrowRight}
                  color={color("white", "100")}
                  size={16}
                />
              )}
            </LabelWrapper>
          </RouterLinkContent>
        </ButtonLink>
      </ExpandedInnerWrapper>
    );
  };

  render() {
    return this.props.isTVG4 || this.props.isExternal
      ? this.renderLink()
      : this.renderButton();
  }
}
