import tvgConf from "@tvg/conf";

export enum STORYBLOK_CONTENT_PATH {
  PROMO_ONBOARDING = "promo-onboarding",
  HOMEPAGE_CONFIG = "homepage",
  EDUCATIONAL_HUB = "educational-hub",
  BETA_PROGRAM = "beta-program"
}

export const STORYBLOK_URL = "https://api.storyblok.com/v1/cdn/stories";
export const getStoryblokSpaceUrl = (contentPath: STORYBLOK_CONTENT_PATH) =>
  `https://api.storyblok.com/v1/cdn/spaces/${contentPath}`;

export const {
  storyblokToken: STORYBLOK_TOKEN,
  storyblokVersion: STORYBLOK_VERSION
} = tvgConf().config();
