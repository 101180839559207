import React from "react";
import { Error, Info, Promo, Success, Warning } from "./illustrations";
import type { DialogMessageProps } from "./types";
import {
  StyledViewContainer,
  StyledButtonContainer
} from "./styled-components";
import { Paragraph } from "../Typography";

const variantMap = {
  info: <Info />,
  warning: <Warning />,
  success: <Success />,
  error: <Error />,
  promo: <Promo />
};

export const DialogMessage = ({
  title,
  description,
  illustration,
  variant,
  qaLabel = "dialog-message",
  children,
  ...rest
}: DialogMessageProps) => (
  <StyledViewContainer data-qa-label={qaLabel} {...rest}>
    {variant ? variantMap[variant] : illustration}
    <Paragraph
      fontWeight="700"
      color="--fd-colors-content-strong"
      data-qa-label="dialog-message-title"
      fontSize="18px"
      lineHeight="125%"
      marginTop="12px"
      marginBottom="0"
      fontFamily="bold"
    >
      {title}
    </Paragraph>
    <Paragraph
      color="--fd-colors-content-default"
      textAlign="center"
      marginTop="8px"
      marginBottom="0"
      qaLabel="description"
      fontFamily="regular"
      lineHeight="125%"
    >
      {description}
    </Paragraph>
    <StyledButtonContainer>{children}</StyledButtonContainer>
  </StyledViewContainer>
);

export { type DialogMessageProps };
