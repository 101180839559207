import React, { Fragment } from "react";
import formatSequencial from "@tvg/formatter/numeric";
import BetSelection from "../../../betSelections";
import { Paragraph } from "../../../typography";
import { CustomBetSelectionsViewProps } from "../types";
import Icon from "../../../icon";
import { PositionsGrid, Content } from "./styled-components";

export const CustomBetSelectionsView: React.FC<
  CustomBetSelectionsViewProps
> = ({
  completeSelection,
  numWagerableRunners,
  type,
  isKey,
  isBox,
  isWheel,
  isLeg,
  isExoticBet,
  currentRaceNumber,
  scratches,
  breed
}) => {
  const renderKeyOrKeyBox = (index: number) => {
    const isKeyBox = isKey && isBox;
    const defaultText = isKeyBox ? "Box" : "w/";

    return (
      <Paragraph qaLabel={`selection-label-key${isKeyBox ? "-box" : ""}`}>
        {index === 0 ? <Icon name="key" size="s" qaLabel="key" /> : defaultText}
      </Paragraph>
    );
  };

  const renderKeyBoxOrKeyOrBox = (index: number) => {
    if (isKey) {
      return renderKeyOrKeyBox(index);
    }

    return <Paragraph qaLabel="selection-label-box">Box</Paragraph>;
  };

  const renderExoticOrLegBet = (index: number) =>
    isExoticBet ? (
      <Paragraph qaLabel={`selection-label-${formatSequencial(index + 1)}`}>
        {formatSequencial(index + 1)}
      </Paragraph>
    ) : (
      <Content>
        <Paragraph qaLabel={`selection-label-${formatSequencial(index + 1)}`}>
          {`L${index + 1} - R${Number(currentRaceNumber) + index}`}
        </Paragraph>
      </Content>
    );

  const renderWheel = (index: number) => (
    <Paragraph qaLabel="selection-label-wheel">
      {index === 0 ? <></> : "w/"}
    </Paragraph>
  );

  return completeSelection?.length ? (
    <PositionsGrid isLeg={isLeg}>
      {completeSelection.map((selection, index) => (
        <Fragment key={`${type}-${index.toString()}`}>
          {(isKey || isBox) && renderKeyBoxOrKeyOrBox(index)}
          {(isExoticBet || isLeg) && renderExoticOrLegBet(index)}
          {isWheel && renderWheel(index)}
          <BetSelection
            scratches={scratches?.[index]}
            numWagerableRunners={
              isLeg ? numWagerableRunners?.[index] : numWagerableRunners?.[0]
            }
            selections={selection}
            type={type}
            showBetType={false}
            breed={breed}
          />
        </Fragment>
      ))}
    </PositionsGrid>
  ) : null;
};
