import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import buildRaceUrl from "@tvg/formatter/url";
import { RaceInfo, RaceStatusTypeCode } from "@tvg/ts-types/Race";
import { getTodayDate } from "@urp/store-selectors";
import { BannerContext, HeaderBanner } from "@urp/derby-banner/src/types";
import { getEventType } from "@urp/derby-banner/src/utils";
import { getDerbyBannerConfigByStoryblok } from "../utils/homepageConfig";
import {
  setDerbyBanner,
  setHomepageEventStatus,
  setHomepageDerbyRace
} from "../store/actions";
import { DERBY_QUERY } from "../graphql/query/DerbyQuery";
import ApolloOptions from "../graphql/options.graph";
import {
  getEventDate,
  getRaceNumber,
  getTrackAbbr,
  geDerbyBanner,
  getDerbyRace,
  getEventName
} from "../store/selectors";
import { HomepageConfigType } from "../types";

export const useDerbyBannerConfig = ({
  context
}: {
  context: BannerContext;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const todayDate = useSelector(getTodayDate);
  const eventDate = useSelector(getEventDate);
  const derbyTitle = useSelector(getEventName);
  const trackAbbr = useSelector(getTrackAbbr);
  const raceNumber = useSelector(getRaceNumber);
  const derbyBanner = useSelector(geDerbyBanner);
  const cachedDerbyRace = useSelector(getDerbyRace);

  useEffect(() => {
    if (!derbyBanner) {
      getDerbyBannerConfigByStoryblok(
        tvgConf().config().storyblokVersion,
        tvgConf().config().storyblokToken
      ).then((newDerbyBanner) => {
        dispatch(setDerbyBanner(newDerbyBanner));
      });
    }
  }, [derbyBanner]);

  const cachedDerbyRaceStatus = get(cachedDerbyRace, "status.code");
  const currentType = getEventType(
    todayDate,
    eventDate
  ) as unknown as HomepageConfigType;

  const { data, loading } = useQuery<RaceInfo>(
    DERBY_QUERY,
    ApolloOptions({
      trackAbbr,
      eventStatus: currentType,
      raceNumber
    })
  );

  const race: RaceInfo = get(data, "track.races[0]", null);
  const raceStatus: RaceStatusTypeCode = get(race, "status.code");
  const eventStatus =
    (cachedDerbyRaceStatus || raceStatus) === "RO" ||
    (loading && !cachedDerbyRaceStatus)
      ? HomepageConfigType.DEFAULT
      : currentType;

  useEffect(() => {
    dispatch(setHomepageEventStatus(eventStatus));
    dispatch(setHomepageDerbyRace(race));
  }, [eventStatus, raceStatus, race]);

  const bannerCfg = (
    get(derbyBanner, `${eventStatus}`, []) as Array<HeaderBanner>
  ).find((cfg) => cfg.context.includes(context));

  const onBannerCtaClick = (linkText: string, url: string) => {
    const bannerName = get(derbyTitle, "label", "");
    const linkUrl =
      url.includes("#derbyRace") && trackAbbr
        ? buildRaceUrl(
            trackAbbr,
            get(race, "trackName", ""),
            get(race, "raceNumber", "")
          )
        : url;

    mediator.base.dispatch({
      type: "DERBY_HEADER_CLICK",
      payload: {
        linkUrl,
        bannerName,
        linkText
      }
    });
    navigate(linkUrl);
  };

  return {
    loading,
    trackAbbr,
    raceNumber,
    derbyRace: race,
    eventDate,
    eventStatus,
    bannerCfg,
    onBannerCtaClick
  };
};
