import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

export default styled.div.attrs((props) => ({
  id: props.id,
  "data-qa-label": "trackList-races"
}))`
  width: 100%;
  overflow: scroll hidden;
  padding: 0 8px 16px;
  white-space: nowrap;
  height: 63px;
  ${(props) =>
    props.hasNewLayout &&
    css`
      display: flex;
    `};

  & > a:not(:first-child) {
    margin-left: 8px;
  }

  & > a:last-child {
    margin-right: 8px;
  }

  & .emptyStateRacesContainer {
    background-color: ${buildColor("white", "100")};
    display: flex;
    align-items: center;
    height: 63px;
    white-space: normal;

    & > div {
      align-items: center;
    }
  }
`;
