import tvgConf from "@tvg/conf";
import { PreviousWinnersWRCResponse } from "@tvg/ts-types/PreviousWinners";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const wrcService: string = "service.wrc";

const fetchPreviousWinners = (accountId: string) =>
  requester()({
    method: "GET",
    url: `${getProtocol()}${tvgConf().config(
      wrcService
    )}/users/${accountId}/wager-recommendations/stable-entities`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  }) as unknown as Promise<PreviousWinnersWRCResponse>;

const WRC_SERVICE = {
  fetchPreviousWinners
};

export default WRC_SERVICE;
