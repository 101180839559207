export const PREVIOUS_WINNERS_POLLER_INTERVAL = 60000;
export const RACES_POLLER_INTERVAL = 60000;

export const MAX_WINNERS_HIGHLIGHT = 4;

export const BIGGEST_WINNERS_INDEX = 0;
export const OTHER_WINNERS_INDEX = 1;
export const ALL_WINNERS_INDEX = 2;

export const CATCH_PHRASE_DEFAULT = "Your previous winner is running again!";
export const MODAL_TITLE_DEFAULT = "Previous Winners Running Today";
