type ToggleOpenTrackRow = {
  type: "TOGGLE_TRACK_ROW";
  payload: {
    openTracks: Array<string>;
  };
};

type ToggleOnlyFavorites = {
  type: "TOGGLE_ONLY_FAVORITES";
  payload: {
    onlyFavorites: boolean;
  };
};

type ToggleReplayVideo = {
  type: "SET_CURRENT_REPLAY_VIDEO" | "REMOVE_CURRENT_REPLAY_VIDEO";
  payload: {
    currentReplay: string | null;
  };
};

export type Actions =
  | ToggleOpenTrackRow
  | ToggleOnlyFavorites
  | ToggleReplayVideo;

export const toggleOpenTrackRow = (
  openTracks: Array<string>
): ToggleOpenTrackRow => ({
  type: "TOGGLE_TRACK_ROW",
  payload: { openTracks }
});

export const toggleOnlyFavorites = (
  onlyFavorites: boolean
): ToggleOnlyFavorites => ({
  type: "TOGGLE_ONLY_FAVORITES",
  payload: { onlyFavorites }
});

export const setCurrentReplayVideo = (
  currentReplay: string | null
): ToggleReplayVideo => ({
  type: "SET_CURRENT_REPLAY_VIDEO",
  payload: { currentReplay }
});
