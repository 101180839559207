import { gql } from "@apollo/client";

export const TOP_RACES_BETTING_INTERESTS = gql`
  fragment TopRacesBettingInterests on BettingInterest {
    currentOdds {
      numerator
      denominator
    }
    morningLineOdds {
      numerator
      denominator
    }
    runners {
      horseName
      runnerId
      scratched
      masterPickNumber: raceNumber
      trainer
      jockey
      timeform {
        silkUrl
        silkUrlSvg
      }
    }
    isFavorite: favorite
    biNumber
  }
`;
