import type {
  OptInPromotion,
  Promo,
  UserEligiblePromotion
} from "@tvg/ts-types/Promos";

export enum ActionType {
  SET_PROMOS = "SET_USER_PROMOTIONS",
  SET_ELIGIBLE_PROMOS = "SET_USER_ELIGIBLE_PROMOTIONS",
  SET_OPTIN = "SET_USER_OPTED_IN_PROMOTIONS"
}

export interface Action<P> {
  type: ActionType;
  payload: P;
}

export const setUserPromotions = (promotions: Promo[]): Action<Promo[]> => ({
  type: ActionType.SET_PROMOS,
  payload: promotions
});

export const setUserEligiblePromotions = (
  eligiblePromotions: UserEligiblePromotion[]
): Action<UserEligiblePromotion[]> => ({
  type: ActionType.SET_ELIGIBLE_PROMOS,
  payload: eligiblePromotions
});

export const setUserOptedinPromotions = (
  optinPromos: OptInPromotion[]
): Action<OptInPromotion[]> => ({
  type: ActionType.SET_OPTIN,
  payload: optinPromos
});
