import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }
  
  50% {
    opacity: 0.5
  }
  
  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const FiltersLoading = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="312" height="32" fill="none">
    <rect y="10" width="150" height="12" rx="2" fill="#E9EFF5" />
    <rect x="162" y="10" width="150" height="12" rx="2" fill="#E9EFF5" />
  </SVG>
);

export default FiltersLoading;
