export interface Promo {
  id: number;
  title: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string | null;
  optedIn: boolean;
  path: string;
  trackName?: string | null;
  state?: string | null;
  country?: string | null;
  city?: string | null;
  promoTypePriority?: number;
  costCategoryPriority?: number;
  isPromoTagShown: boolean;
}

export interface PromosWidgetPROResponseData {
  contexts: Array<string>;
  descriptions: string;
  id: number;
  name: string;
  promotions: Array<Promo>;
}

export interface PromosWidgetPROResponse {
  status: number;
  statusText: string;
  headers?: string;
  config?: string;
  data: Array<PromosWidgetPROResponseData>;
}

export interface OptInPromotion {
  costCategoryGroup: string;
  eligibilityStatusName: string;
  optInBeginDate: Date;
  optInDate: Date;
  optInEndDate: Date;
  promoActiveStatus: string;
  promotionCodes: string;
  promotionDescription: string;
  promotionName: string;
}

export interface UserEligiblePromotion {
  categoryGroupName: string;
  description: string;
  eligibilityStatusName: string;
  externalSystemCode: string;
  lastPayoutDate: string;
  name: string;
  optInBeginDate: string;
  optInDate: string;
  optInEndDate: string;
  promotionCodes: string[];
  segmentName: string;
  status: string;
  totalPayoutAmount: number;
  totalPayoutPoints: number;
  rootId: number;
}

export enum PromotionTypeEnum {
  RACE = "RACE",
  ALL = "ALL"
}
