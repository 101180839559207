import React from "react";
import { useTheme } from "@tvg/design-system";
import { SVG } from "./styled-components";

const LoadingMask = ({ isDesktop }: { isDesktop: boolean }) => {
  const theme = useTheme();
  const containerFill = theme.colors.white[900];
  const rectFill = theme.colors.blue["000"];
  const rectStroke = theme.colors.grey[500];

  return isDesktop ? (
    <SVG
      data-qa-label="previousWinners-loading-mask"
      viewBox="0 0 325 362"
      theme={theme}
      isDesktop
    >
      <rect width="325" height="362" rx="4" fill={containerFill} />
      <rect width="325" height="44" fill={containerFill} />
      <rect
        x="12"
        y="12"
        width="118"
        height="20"
        rx="1"
        fill={rectFill}
        className="pulse"
      />
      <rect
        x="261"
        y="12"
        width="52"
        height="20"
        rx="1"
        fill={rectFill}
        className="pulse"
      />
      <g fill="none" fillRule="evenodd">
        <mask id="path-4-inside-1_31208_26298" fill={containerFill}>
          <path d="M0 44H325V146H0V44Z" />
        </mask>
        <path d="M0 44H325V146H0V44Z" fill={containerFill} />
        <rect
          x="13.3562"
          y="80"
          width="115.753"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13.3562"
          y="60"
          width="57.8767"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="253"
          y="60"
          width="60"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13"
          y="118"
          width="300"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <path
          d="M325 145H0V147H325V145Z"
          fill="#B0B7BF"
          mask="url(#path-4-inside-1_31208_26298)"
        />
        <mask id="path-10-inside-2_31208_26298" fill={containerFill}>
          <path d="M0 146H325V248H0V146Z" />
        </mask>
        <path d="M0 146H325V248H0V146Z" fill={containerFill} />
        <rect
          x="13.3562"
          y="182"
          width="115.753"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13.3562"
          y="162"
          width="57.8767"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="253"
          y="162"
          width="60"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13"
          y="220"
          width="300"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <path
          d="M325 247H0V249H325V247Z"
          fill="#B0B7BF"
          mask="url(#path-10-inside-2_31208_26298)"
        />
        <rect
          width="325"
          height="102"
          transform="translate(0 248)"
          fill={containerFill}
        />
        <rect
          x="13.3562"
          y="284"
          width="115.753"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13.3562"
          y="264"
          width="57.8767"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="253"
          y="264"
          width="60"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="13"
          y="322"
          width="300"
          height="12"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
      </g>
      <defs>
        <clipPath id="clip0_31208_26298">
          <rect
            width="325"
            height="306"
            fill={containerFill}
            transform="translate(0 44)"
          />
        </clipPath>
      </defs>
    </SVG>
  ) : (
    <SVG
      data-qa-label="previousWinners-loading-mask"
      theme={theme}
      isDesktop={false}
      height="158"
    >
      <g fill="none" fillRule="evenodd">
        <mask id="path-1-inside-1_31208_26237" fill={containerFill}>
          <path d="M0 0H375V158H0V0Z" />
        </mask>
        <path d="M0 0H375V158H0V0Z" fill={containerFill} />
        <rect width="100%" height="44" fill={containerFill} />
        <rect
          x="12"
          y="12"
          width="118"
          height="20"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <rect
          x="311"
          y="12"
          width="52"
          height="20"
          rx="1"
          fill={rectFill}
          className="pulse"
        />
        <g fill="none" fillRule="evenodd">
          <rect
            x="12.5"
            y="44.5"
            width="100%"
            height="101"
            rx="3.5"
            fill={containerFill}
          />
          <rect
            x="25.3562"
            y="80"
            width="115.753"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="25.3562"
            y="60"
            width="57.8767"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="261"
            y="60"
            width="60"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="25"
            y="118"
            width="296"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="12.5"
            y="44.5"
            width="324"
            height="101"
            rx="3.5"
            stroke={rectStroke}
          />
          <rect
            x="349.5"
            y="44.5"
            width="324"
            height="101"
            rx="3.5"
            fill={containerFill}
          />
          <rect
            x="362.356"
            y="80"
            width="115.753"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="362.356"
            y="60"
            width="57.8767"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="362"
            y="118"
            width="296"
            height="12"
            rx="1"
            fill={rectFill}
            className="pulse"
          />
          <rect
            x="349.5"
            y="44.5"
            width="324"
            height="101"
            rx="3.5"
            stroke={rectStroke}
          />
        </g>
      </g>
      <path
        d="M0 1H375V-1H0V1ZM375 157H0V159H375V157Z"
        fill="#CFD6DB"
        mask="url(#path-1-inside-1_31208_26237)"
      />
      <defs>
        <clipPath id="clip0_31208_26237">
          <path d="M0 0H375V158H0V0Z" fill={containerFill} />
        </clipPath>
        <clipPath id="clip1_31208_26237">
          <rect
            width="386"
            height="114"
            fill={containerFill}
            transform="translate(0 44)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
};

export default LoadingMask;
