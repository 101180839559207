import React, { PureComponent } from "react";
import { get } from "lodash";

import SimpleInlineInfo from "@tvg/ipp/src/components/SimpleInlineInfo";
import RunnerFlags from "@tvg/ipp/src/components/RunnerFlags";
import InlineInfo from "../../_atom/InlineInfo";
import Icon from "../../_static/Icons";
import { star } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import TrackMasterPickTag from "../TrackMasterPickTag";
import {
  BioWrapper,
  BioTitle,
  BioDescription,
  Results,
  ResultsDistance,
  ResultsOdds,
  FlagsAndPick
} from "./styled-components";

export default class RunnerBio extends PureComponent {
  static defaultProps = {
    raceTypeCode: "T",
    runnerName: "",
    handicappingData: [],
    isScratched: false,
    firstHandicapBold: false,
    showFavorite: false,
    isFavorite: false,
    runnerMasterPickNumber: -1,
    isRunnerTrackMasterPick: false,
    isRunnerNumberFirePick: false,
    resultsData: null,
    hasJockeyChanges: false,
    handicappingSortMode: "saddlecloth",
    hasTrackMasterInfo: true,
    trackDataSource: "",
    runnerData: null,
    showFlags: true,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    }
  };

  renderOdds = () => {
    const odds = get(this.props.resultsData, "odds");

    if (!!odds && !!odds.numerator) {
      return `${odds.numerator}${
        odds.denominator ? `/${odds.denominator}` : ""
      }`;
    }

    return "n/a";
  };

  renderRunnerFlags = () => {
    const flagsObg = get(this.props.runnerData, "timeform.flags", {});
    const hasFlags = Object.keys(flagsObg).some((flag) => flagsObg[flag]);
    return (
      hasFlags &&
      this.props.showFlags && (
        <RunnerFlags
          flags={flagsObg}
          maxFlags={2}
          handicappingRunnerFlags={this.props.handicappingRunnerFlags}
        />
      )
    );
  };

  render() {
    const flagsObg = get(this.props.runnerData, "timeform.flags", {});
    const hasFlags = Object.keys(flagsObg).some((flag) => flagsObg[flag]);
    const hasPicksOrFlags =
      (hasFlags && this.props.showFlags) || this.props.hasTrackMasterInfo;

    return (
      <BioWrapper>
        {(this.props.isRunnerTrackMasterPick ||
          this.props.isRunnerNumberFirePick) && (
          <TrackMasterPickTag
            pickNumber={this.props.runnerMasterPickNumber}
            labelDarker={this.props.isRunnerTrackMasterPick}
            isScratched={this.props.isScratched}
            trackDataSource={this.props.trackDataSource}
            isNumberFirePick={this.props.isRunnerNumberFirePick}
          />
        )}
        <BioTitle
          runnerName={this.props.runnerName}
          isScratched={this.props.isScratched}
        >
          {this.props.showFavorite && (
            <Icon
              icon={star}
              size={14}
              color={
                !this.props.isFavorite
                  ? buildColor("grey", "100")
                  : buildColor("yellow", "500")
              }
            />
          )}
          {this.props.runnerName}
        </BioTitle>
        {!!this.props.resultsData && (
          <Results>
            <ResultsDistance>
              {this.props.resultsData.accBeatenDistance}
            </ResultsDistance>
            <ResultsOdds>{this.renderOdds()}</ResultsOdds>
          </Results>
        )}
        <BioDescription isScratched={this.props.isScratched}>
          {this.props.handicappingData.length > 0 &&
            (this.props.hasTrackMasterInfo ? (
              <InlineInfo
                isFirstBold={this.props.firstHandicapBold}
                info={this.props.handicappingData}
                hasJockeyChanges={this.props.hasJockeyChanges}
                isScratched={this.props.isScratched}
                showHandicappingSortMode={this.props.raceTypeCode !== "G"}
                handicappingSortMode={this.props.handicappingSortMode}
              />
            ) : (
              <SimpleInlineInfo info={this.props.handicappingData} />
            ))}
        </BioDescription>
        {hasPicksOrFlags && (
          <FlagsAndPick>
            {!this.props.isRunnerTrackMasterPick &&
              !this.props.isRunnerNumberFirePick &&
              this.props.hasTrackMasterInfo && (
                <TrackMasterPickTag
                  pickNumber={this.props.runnerMasterPickNumber}
                  labelDarker={this.props.isRunnerTrackMasterPick}
                  trackDataSource={this.props.trackDataSource}
                />
              )}
            {hasFlags && this.props.showFlags && (
              <RunnerFlags
                flags={flagsObg}
                maxFlags={2}
                handicappingRunnerFlags={this.props.handicappingRunnerFlags}
              />
            )}
          </FlagsAndPick>
        )}
      </BioWrapper>
    );
  }
}
