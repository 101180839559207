/**
 * HACK TO ALLOW SCROLLING ON NON IOS BECAUSE IOS NEEDS
 * SCROLLING=NO IN ORDER TO NOT FIT IFRAMES AUTOMAGICALLY (WTF?!)
 * https://salomvary.com/iframe-resize-ios-safari.html
 */
const setIframeScrolling = () => {
  const iOS =
    !!typeof navigator === "object" &&
    navigator.platform &&
    /iPad|iPhone|iPod/.test(navigator.platform);
  const iframe = document.getElementById("iframe");

  if (!iOS && iframe) {
    iframe.setAttribute("scrolling", "yes");
  }
};

export default setIframeScrolling;
