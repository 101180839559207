import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.isDesktop ? "452px" : "192px")};
`;

const pulseAnimation = keyframes`
   0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: ${(props) => (props.isDesktop ? "452px" : "192px")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class PreviousWinnersMask extends PureComponent {
  static defaultProps = {
    isDesktop: false,
    device: "mobile"
  };

  render() {
    return (
      <SVGContainer
        data-qa-label="previousWinnersMask"
        isDesktop={this.props.isDesktop}
        device={this.props.device}
      >
        <SVG isDesktop={this.props.isDesktop}>
          {this.props.isDesktop ? (
            <g fill="none" fillRule="evenodd" transform="translate(-1011 -456)">
              <rect width="1440" height="1192" fill={color("blue", "900")} />
              <g transform="translate(324 56)">
                <path
                  fill={color("blue", "000")}
                  d="M4,0 L1020,0 C1022.20914,-8.49901461e-16 1024,1.790861 1024,4 L1024,2045 L0,2045 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z"
                />
                <rect
                  width="325"
                  height="452"
                  x="687"
                  y="400"
                  fill={color("white", "100")}
                  rx="4"
                />
                <g transform="translate(687 400)">
                  <g transform="translate(0 312)">
                    <rect
                      width="325"
                      height="132"
                      fill={color("white", "100")}
                      fillRule="nonzero"
                    />
                    <rect
                      className="pulse"
                      width="104"
                      height="11.821"
                      x="12"
                      y="35.463"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="152"
                      height="7.881"
                      x="12"
                      y="55.164"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="52"
                      height="11.821"
                      x="12"
                      y="15.761"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="60"
                      height="7.881"
                      x="12"
                      y="94.567"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="140"
                      height="11.821"
                      x="12"
                      y="110.328"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                  </g>
                  <g transform="translate(0 180)">
                    <rect
                      width="325"
                      height="132"
                      fill={color("white", "100")}
                      fillRule="nonzero"
                    />
                    <rect
                      className="pulse"
                      width="301"
                      height="1"
                      x="12"
                      y="131"
                      fill={color("blue", "100")}
                    />
                    <rect
                      className="pulse"
                      width="104"
                      height="11.821"
                      x="12"
                      y="35.463"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="152"
                      height="7.881"
                      x="12"
                      y="55.164"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="52"
                      height="11.821"
                      x="12"
                      y="15.761"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="60"
                      height="7.881"
                      x="12"
                      y="94.567"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="140"
                      height="11.821"
                      x="12"
                      y="110.328"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                  </g>
                  <g transform="translate(0 48)">
                    <rect
                      width="325"
                      height="132"
                      fill={color("white", "100")}
                      fillRule="nonzero"
                    />
                    <rect
                      className="pulse"
                      width="301"
                      height="1"
                      x="12"
                      y="131"
                      fill={color("blue", "100")}
                    />
                    <rect
                      className="pulse"
                      width="104"
                      height="11.821"
                      x="12"
                      y="35.463"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="152"
                      height="7.881"
                      x="12"
                      y="55.164"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="52"
                      height="11.821"
                      x="12"
                      y="15.761"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="60"
                      height="7.881"
                      x="12"
                      y="94.567"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                    <rect
                      className="pulse"
                      width="140"
                      height="11.821"
                      x="12"
                      y="110.328"
                      fill={color("blue", "000")}
                      rx="1"
                    />
                  </g>
                  <rect
                    className="pulse"
                    width="144"
                    height="16"
                    x="12"
                    y="20"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect width="325" height="48" />
                  <rect
                    className="pulse"
                    width="71"
                    height="12"
                    x="242"
                    y="22"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                </g>
              </g>
            </g>
          ) : (
            <g fill="none" fillRule="evenodd" transform="translate(0 -104)">
              <rect width="1251" height="768" fill={color("blue", "000")} />
              <g transform="translate(0 104)">
                <rect
                  width="1216"
                  height="194"
                  fill={color("white", "100")}
                  fillRule="nonzero"
                />
                <g transform="translate(12 46)">
                  <rect
                    width="291"
                    height="135"
                    x=".5"
                    y=".5"
                    fill={color("white", "100")}
                    fillRule="nonzero"
                    stroke={color("blue", "000")}
                    rx="4"
                  />
                  <rect
                    className="pulse"
                    width="104"
                    height="12"
                    x="12"
                    y="36"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="152"
                    height="8"
                    x="12"
                    y="56"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="52"
                    height="12"
                    x="12"
                    y="16"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="60"
                    height="8"
                    x="12"
                    y="96"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="140"
                    height="12"
                    x="12"
                    y="112"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                </g>
                <g transform="translate(312 46)">
                  <rect
                    width="291"
                    height="135"
                    x=".5"
                    y=".5"
                    fill={color("white", "100")}
                    fillRule="nonzero"
                    stroke={color("blue", "000")}
                    rx="4"
                  />
                  <rect
                    className="pulse"
                    width="104"
                    height="12"
                    x="12"
                    y="36"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="152"
                    height="8"
                    x="12"
                    y="56"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="52"
                    height="12"
                    x="12"
                    y="16"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="60"
                    height="8"
                    x="12"
                    y="96"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="140"
                    height="12"
                    x="12"
                    y="112"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                </g>
                <g transform="translate(612 46)">
                  <rect
                    width="291"
                    height="135"
                    x=".5"
                    y=".5"
                    fill={color("white", "100")}
                    fillRule="nonzero"
                    stroke={color("blue", "000")}
                    rx="4"
                  />
                  <rect
                    className="pulse"
                    width="104"
                    height="12"
                    x="12"
                    y="36"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="152"
                    height="8"
                    x="12"
                    y="56"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="52"
                    height="12"
                    x="12"
                    y="16"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="60"
                    height="8"
                    x="12"
                    y="96"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="140"
                    height="12"
                    x="12"
                    y="112"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                </g>
                <g transform="translate(912 46)">
                  <rect
                    width="291"
                    height="135"
                    x=".5"
                    y=".5"
                    fill={color("white", "100")}
                    fillRule="nonzero"
                    stroke={color("blue", "000")}
                    rx="4"
                  />
                  <rect
                    className="pulse"
                    width="104"
                    height="12"
                    x="12"
                    y="36"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="152"
                    height="8"
                    x="12"
                    y="56"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="52"
                    height="12"
                    x="12"
                    y="16"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="60"
                    height="8"
                    x="12"
                    y="96"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                  <rect
                    className="pulse"
                    width="140"
                    height="12"
                    x="12"
                    y="112"
                    fill={color("blue", "000")}
                    rx="1"
                  />
                </g>
                <rect
                  className="pulse"
                  width="240"
                  height="8"
                  x="12"
                  y="20"
                  fill={color("blue", "000")}
                  rx="1"
                />
              </g>
            </g>
          )}
        </SVG>
      </SVGContainer>
    );
  }
}
