export default {
  error: {
    title: "Uh Oh!",
    description:
      "There seems to be a problem loading in the __componentName__ that are running today.",
    altDescription: "There seems to be a problem loading today's tracks."
  },
  favorite: {
    title: "No favorites added",
    description: "Tap the star on the left of any track to add a favorite",
    altDescription: ""
  },
  noraces: {
    title: "No __componentName__ available",
    description:
      "__componentName__ should become available in the next minutes.",
    altDescription: ""
  },
  noresults: {
    title: "No results",
    description: "Your filters have hidden your __componentName__",
    altDescription: ""
  }
};
