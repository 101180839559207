import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }
  
  50% {
    opacity: 0.5
  }
  
  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const CardsLoading = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="100%" height="282" fill="none">
    <g clipPath="url(#clip0_11707_627917)">
      <g
        clipPath="url(#clip1_11707_627917)"
        filter="url(#filter0_i_11707_627917)"
      >
        <rect width="349.333" height="64" fill="#FAFCFF" />
        <circle cx="34" cy="32" r="22" fill="#E9EFF5" />
        <rect x="64" y="16.5" width="144" height="12" rx="1" fill="#E9EFF5" />
        <rect x="64" y="37.5" width="144" height="12" rx="1" fill="#E9EFF5" />
        <rect
          x="267.333"
          y="16.5"
          width="70"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect
          x="267.333"
          y="37.5"
          width="70"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
      </g>
      <rect
        width="349.333"
        height="150"
        transform="translate(0 64)"
        fill="white"
      />
      <rect
        width="349.333"
        height="68"
        transform="translate(0 214)"
        fill="white"
      />
      <rect x="12" y="226" width="156.667" height="44" rx="2" fill="#E9EFF5" />
      <rect
        x="180.667"
        y="226"
        width="156.667"
        height="44"
        rx="2"
        fill="#E9EFF5"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="348.333"
      height="281"
      rx="3.5"
      stroke="#D6E3F0"
    />
    <g clipPath="url(#clip2_11707_627917)">
      <g
        clipPath="url(#clip3_11707_627917)"
        filter="url(#filter1_i_11707_627917)"
      >
        <rect
          width="349.333"
          height="64"
          transform="translate(361.333)"
          fill="#FAFCFF"
        />
        <circle cx="395.333" cy="32" r="22" fill="#E9EFF5" />
        <rect
          x="425.333"
          y="16.5"
          width="144"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect
          x="425.333"
          y="37.5"
          width="144"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect
          x="628.667"
          y="16.5"
          width="70"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect
          x="628.667"
          y="37.5"
          width="70"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
      </g>
      <rect
        width="349.333"
        height="150"
        transform="translate(361.333 64)"
        fill="white"
      />
      <rect
        width="349.333"
        height="68"
        transform="translate(361.333 214)"
        fill="white"
      />
      <rect
        x="373.333"
        y="226"
        width="156.667"
        height="44"
        rx="2"
        fill="#E9EFF5"
      />
      <rect x="542" y="226" width="156.667" height="44" rx="2" fill="#E9EFF5" />
    </g>
    <rect
      x="361.833"
      y="0.5"
      width="348.333"
      height="281"
      rx="3.5"
      stroke="#D6E3F0"
    />
    <g clipPath="url(#clip4_11707_627917)">
      <g
        clipPath="url(#clip5_11707_627917)"
        filter="url(#filter2_i_11707_627917)"
      >
        <rect
          width="349.333"
          height="64"
          transform="translate(722.667)"
          fill="#FAFCFF"
        />
        <circle cx="756.667" cy="32" r="22" fill="#E9EFF5" />
        <rect
          x="786.667"
          y="16.5"
          width="144"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect
          x="786.667"
          y="37.5"
          width="144"
          height="12"
          rx="1"
          fill="#E9EFF5"
        />
        <rect x="990" y="16.5" width="70" height="12" rx="1" fill="#E9EFF5" />
        <rect x="990" y="37.5" width="70" height="12" rx="1" fill="#E9EFF5" />
      </g>
      <rect
        width="349.333"
        height="150"
        transform="translate(722.667 64)"
        fill="white"
      />
      <rect
        width="349.333"
        height="68"
        transform="translate(722.667 214)"
        fill="white"
      />
      <rect
        x="734.667"
        y="226"
        width="156.667"
        height="44"
        rx="2"
        fill="#E9EFF5"
      />
      <rect
        x="903.333"
        y="226"
        width="156.667"
        height="44"
        rx="2"
        fill="#E9EFF5"
      />
    </g>
    <rect
      x="723.167"
      y="0.5"
      width="348.333"
      height="281"
      rx="3.5"
      stroke="#D6E3F0"
    />
    <defs>
      <filter
        id="filter0_i_11707_627917"
        x="0"
        y="0"
        width="349.333"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_11707_627917"
        />
      </filter>
      <filter
        id="filter1_i_11707_627917"
        x="361.333"
        y="0"
        width="349.333"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_11707_627917"
        />
      </filter>
      <filter
        id="filter2_i_11707_627917"
        x="722.667"
        y="0"
        width="349.333"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_11707_627917"
        />
      </filter>
      <clipPath id="clip0_11707_627917">
        <rect width="349.333" height="282" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_11707_627917">
        <rect width="349.333" height="64" fill="white" />
      </clipPath>
      <clipPath id="clip2_11707_627917">
        <rect x="361.333" width="349.333" height="282" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip3_11707_627917">
        <rect
          width="349.333"
          height="64"
          fill="white"
          transform="translate(361.333)"
        />
      </clipPath>
      <clipPath id="clip4_11707_627917">
        <rect x="722.667" width="349.333" height="282" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip5_11707_627917">
        <rect
          width="349.333"
          height="64"
          fill="white"
          transform="translate(722.667)"
        />
      </clipPath>
    </defs>
  </SVG>
);

export default CardsLoading;
