import React, { PureComponent, Fragment } from "react";
import { get, noop } from "lodash";

import { isTvg5 } from "@tvg/utils/generalUtils";
import TalentPicksCard from "@urp/talent-picks/src/components/TalentPicksCard";
import { TalentPicksPageMsgs } from "@urp/talent-picks/src/types";
import MessageBox from "../../_molecule/MessageBox";
import LiveButtonsGroup from "../../_organism/LiveButtonsGroup";
import TalentPickCard from "../../_organism/TalentPickCard";
import TalentPickFilter from "../../_organism/TalentPickFilter";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Icon from "../../_static/Icons";
import { video } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import RedirectCard from "../../_molecule/RedirectCard";
import {
  TalentPicksContainer,
  NewButton,
  MessageContainer,
  WatchLiveButtonWrapper,
  WatchLiveLabel,
  PicksWrapper,
  Title,
  TalentPickWrapper,
  TitleContainer,
  RedirectLink,
  RedirectLinkDesktop
} from "./styled-components";
import TalentPickCardMask from "../../_static/Masks/talent-pick-card";
import MagnetScrollArea from "../MagnetScrollArea";

export default class TalentPicksList extends PureComponent {
  static defaultProps = {
    talentPickList: [],
    isRaceList: false,
    hideOnPickWager: false,
    buttons: [],
    onPublishToggle: null,
    onNewPick: null,
    context: "mep",
    tvg1Link: null,
    tvg2Link: null,
    imageBaseURL: "//www.tvg.com",
    showButtonsCallBack: () => true,
    enableLiveButtons: false,
    onProgramPage: false,
    onHomePage: false,
    talentsFilter: [],
    onFilterClick: noop,
    device: "mobile",
    activeFilters: [],
    picksAlertsToggle: false,
    enableTalentFilter: false,
    filterEmptyStateMessage: "",
    hasAlerts: false,
    onSeeAllSelections: null,
    isLoading: false,
    title: "Talent Picks",
    onRedirectTalentPage: noop,
    onClickHeader: noop,
    onHandleTransition: noop,
    activeIndex: 0,
    onModalDesktop: false,
    talentPicksOnHomepageToggle: false,
    talentPicksPageMsgs: {},
    triggerCTAClickedAmplitudeEvent: noop
  };

  talentPicksRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      context: typeof this.props.onNewPick === "function" ? "tool" : "app",
      talentPicksWidth: 0
    };
  }

  componentDidMount() {
    this.handleResize();
    if (window !== "undefined") {
      window.addEventListener("resize", this.handleResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (this.talentPicksRef.current) {
      this.setState({
        talentPicksWidth: this.talentPicksRef.current.clientWidth / 2
      });
    }
  };

  createTalentPickCards = () => {
    const insideTvg5 = isTvg5();
    const bettingBtn = this.props.buttons.find((button) =>
      button.type.includes("bet")
    );

    return this.props.talentPickList.map((talentPick) =>
      !this.props.onHomePage ? (
        <TalentPickCard
          key={`talent-pick-${get(talentPick, "id", "tp")}`}
          talentPick={talentPick}
          isRacePick={this.props.isRaceList}
          buttons={this.props.buttons}
          onPublishToggle={this.props.onPublishToggle}
          imageBaseURL={this.props.imageBaseURL}
          showButtonsCallBack={this.props.showButtonsCallBack}
          isDesktop={this.props.device === "desktop"}
          onSeeAllSelections={this.props.onSeeAllSelections}
          isHorizontalLayout={this.props.onHomePage}
          onClickHeader={this.props.onClickHeader}
        />
      ) : (
        <TalentPickWrapper
          key={`talentPick-${talentPick.id}`}
          isDesktop={this.props.device === "desktop"}
          talentPickWrapperWidth={this.state.talentPicksWidth}
          isTvg5={insideTvg5}
        >
          {insideTvg5 && this.props.talentPicksOnHomepageToggle ? (
            <TalentPicksCard
              talentPick={talentPick}
              onHomePage={this.props.onHomePage}
              bettingBtn={bettingBtn}
              showAddToBetslipButton
            />
          ) : (
            <TalentPickCard
              key={`talent-pick-${get(talentPick, "id", "tp")}`}
              talentPick={talentPick}
              isRacePick={this.props.isRaceList}
              buttons={this.props.buttons}
              onPublishToggle={this.props.onPublishToggle}
              imageBaseURL={this.props.imageBaseURL}
              showButtonsCallBack={this.props.showButtonsCallBack}
              isDesktop={this.props.device === "desktop"}
              onSeeAllSelections={this.props.onSeeAllSelections}
              isHorizontalLayout={this.props.onHomePage}
              onClickHeader={this.props.onClickHeader}
            />
          )}
        </TalentPickWrapper>
      )
    );
  };

  createRedirectCard = () => (
    <TalentPickWrapper
      key="talentPick-lastCard"
      isLastCard
      isDesktop={this.props.device === "desktop"}
      talentPickWrapperWidth={this.state.talentPicksWidth}
      isTvg5={isTvg5()}
    >
      <RedirectCard
        isDesktop={this.props.device === "desktop"}
        text="see all picks"
        url="/handicapping/talent-picks"
        onClick={(event, url) => {
          if (this.props.device === "desktop") {
            event.preventDefault();
          }
          this.props.onRedirectTalentPage(url);
          if (
            this.props.triggerCTAClickedAmplitudeEvent &&
            typeof this.props.triggerCTAClickedAmplitudeEvent === "function"
          ) {
            this.props.triggerCTAClickedAmplitudeEvent();
          }
        }}
        qaLabel="seeAllCard"
      />
    </TalentPickWrapper>
  );

  renderPicks(renderFilter) {
    const emptyMessages = {
      app: "No TVG picks are currently available",
      tool: "No picks are currently available. Start inspiring your fans!",
      hidden: "Talent Picks are only offered in the 1st leg of this wager"
    };

    const endOfListMessage = `You reached the end of the talent list.\nWatch FDTV now from your device for today's racing brought to you by TVG's team of expert analysts.`;

    const hasBottomMessageBox =
      this.props.enableLiveButtons &&
      (this.props.tvg1Link || this.props.tvg2Link);
    const emptyStateTitle = this.props.tvg1Link
      ? "No TVG Picks available."
      : "";
    emptyMessages.app = this.props.enableLiveButtons
      ? emptyStateTitle
      : emptyMessages.app;

    const liveMessage = hasBottomMessageBox
      ? "Watch FDTV now from your device for today's racing brought to you by TVG's team of expert analysts."
      : "No TVG picks are currently available.";
    const EmptyStateComponent =
      this.props.enableLiveButtons && !this.props.onProgramPage ? (
        <Fragment>
          <MessageContainer isFilterEnabled={renderFilter}>
            <MessageBox
              title={
                this.props.hideOnPickWager
                  ? emptyMessages.hidden
                  : emptyMessages[this.state.context]
              }
              type="info"
              message={liveMessage}
              hasIcon
              isVertical
            />
            <LiveButtonsGroup
              className="liveButtonsWrapper"
              tvg1Link={this.props.tvg1Link}
              tvg2Link={this.props.tvg2Link}
            />
          </MessageContainer>
        </Fragment>
      ) : (
        <MessageBox
          type="info"
          message={
            this.props.hideOnPickWager
              ? emptyMessages.hidden
              : emptyMessages[this.state.context]
          }
          hasIcon
          isVertical
        />
      );

    return this.props.talentPickList.length && !this.props.hideOnPickWager ? (
      <Fragment>
        {this.createTalentPickCards()}
        {hasBottomMessageBox &&
          !this.props.onProgramPage &&
          !this.props.onHomePage && (
            <MessageBox
              className="talentPicksWatchLiveBox"
              type="infoWhiteBackground"
              message={endOfListMessage}
              hasIcon
              hasBorderBottom
              hasBorderTop
              hasMarginTop
              hasMarginBottom
            >
              <WatchLiveButtonWrapper>
                {this.props.tvg1Link && (
                  <ButtonLink
                    url={get(this.props, "tvg1Link")}
                    type="secondary"
                    qaLabel="tvg1-link-button"
                  >
                    <Icon
                      icon={video}
                      size={16}
                      color={buildColor("grey", "900")}
                      qaLabel="tvg1-link-button-icon"
                    />
                    <WatchLiveLabel>Watch FanDuel TV</WatchLiveLabel>
                  </ButtonLink>
                )}
                {this.props.tvg2Link && (
                  <ButtonLink
                    url={get(this.props, "tvg2Link")}
                    type="secondary"
                    className="tvg2Button"
                    qaLabel="tvg2-link-button"
                  >
                    <Icon
                      icon={video}
                      size={16}
                      color={buildColor("grey", "900")}
                      qaLabel="tvg2-link-button-icon"
                    />
                    <WatchLiveLabel>Watch FanDuel Racing</WatchLiveLabel>
                  </ButtonLink>
                )}
              </WatchLiveButtonWrapper>
            </MessageBox>
          )}
      </Fragment>
    ) : (
      EmptyStateComponent
    );
  }

  render() {
    const isEmpty =
      !this.props.talentPickList.length || this.props.hideOnPickWager;
    const onTool = this.state.context === "tool";
    const renderFilter =
      (this.props.hasAlerts && this.props.picksAlertsToggle) ||
      (this.props.enableTalentFilter && this.props.talentsFilter.length > 0);
    return this.props.isLoading ? (
      <TalentPickCardMask isDesktop={this.props.device === "desktop"} />
    ) : (
      <TalentPicksContainer
        ref={this.talentPicksRef}
        isFilterEnabled={this.props.enableTalentFilter}
        hideOnPickWager={this.props.hideOnPickWager}
        hasNewButton={onTool}
        data-qa-label="talentPicks"
        isOnProgramPage={this.props.onProgramPage}
        isOnTool={onTool}
        isEmpty={isEmpty}
        isHorizontalLayout={this.props.onHomePage}
        isDesktop={this.props.device === "desktop"}
      >
        {!this.props.onProgramPage &&
          !this.props.onHomePage &&
          renderFilter && (
            <TalentPickFilter
              enableTalentFilter={this.props.enableTalentFilter}
              onClick={this.props.onFilterClick}
              imageBaseURL={this.props.imageBaseURL}
              hasAlerts={this.props.hasAlerts}
              talents={this.props.talentsFilter}
              activeFilters={this.props.activeFilters}
              device={this.props.device}
              picksAlertsToggle={this.props.picksAlertsToggle}
              filterEmptyStateMessage={this.props.filterEmptyStateMessage}
            />
          )}
        {this.props.onHomePage && (
          <TitleContainer isDesktop={this.props.device === "desktop"}>
            <Title
              data-qa-label="title"
              isDesktop={this.props.device === "desktop"}
            >
              {this.props.onHomePage
                ? this.props.talentPicksPageMsgs?.title
                : this.props.title}
            </Title>
            {this.props.device !== "desktop" || isTvg5() ? (
              <RedirectLink
                to="/handicapping/talent-picks"
                onClick={this.props.triggerCTAClickedAmplitudeEvent}
              >
                See all
              </RedirectLink>
            ) : (
              <RedirectLinkDesktop
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onRedirectTalentPage("/handicapping/talent-picks");
                }}
              >
                See all
              </RedirectLinkDesktop>
            )}
          </TitleContainer>
        )}

        {!this.props.onHomePage ? (
          <PicksWrapper
            isHorizontalLayout={this.props.onHomePage}
            isEmpty={isEmpty}
            isOnProgramPage={this.props.onProgramPage}
            isOnTool={onTool}
            isOnHomePage={!!this.props.onHomePage}
            onModalDesktop={this.props.onModalDesktop}
          >
            {this.renderPicks(renderFilter)}
          </PicksWrapper>
        ) : (
          <MagnetScrollArea
            hasPagination={this.props.device === "desktop"}
            onHandleTransition={this.props.onHandleTransition}
            activeIndex={this.props.activeIndex}
            isLoading={this.props.isLoading}
            cardsPerPage={2}
            contextSA="talentpicks"
          >
            {this.createTalentPickCards()}
            {this.createRedirectCard()}
          </MagnetScrollArea>
        )}

        {onTool && (
          <NewButton qaLabel="new-pick-button" onClick={this.props.onNewPick}>
            NEW PICK
          </NewButton>
        )}
      </TalentPicksContainer>
    );
  }
}
