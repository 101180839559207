import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("grey", "100")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const card = (
  <g>
    <rect fill={color("white", "100")} y="7" height="250" width="100%" />
    <rect fill={color("grey", "000")} y="7" height="49" width="100%" />
    <path
      fill={color("grey", "100")}
      d="m62.80412,50.51804c-3.22666,-1.39511 -11.27739,-3.25367 -16.37013,-4.92763c-0.39738,-0.13051 -0.47097,-0.99773 -0.47097,-2.79131c0,-1.92066 0.21735,-3.27955 0.68892,-4.27614c0.63489,-1.35883 1.66824,-4.2629 1.66824,-6.3009c0.76083,-0.88791 2.36979,-2.05238 3.0403,-5.38597c0.57964,-2.93566 -0.26681,-4.00469 -0.64752,-5.00128c-0.03682,-0.10867 -0.09146,-0.21735 -0.10927,-0.32608c-0.1449,-0.67051 0.05464,-4.25827 0.56238,-7.03007c0.34389,-1.90285 -0.09086,-5.94323 -2.71946,-9.29577c-1.64982,-2.11966 -4.43999,-4.89429 -12.12679,-5.07313c-0.11042,0.00518 -0.21163,0.01209 -0.31975,0.01781c-0.10867,-0.00577 -0.20989,-0.01264 -0.3203,-0.01781c-7.68674,0.17884 -10.47696,2.95347 -12.12679,5.07313c-2.628,3.3526 -3.06335,7.39292 -2.71886,9.29577c0.5072,2.77175 0.70674,6.35957 0.56183,7.03007c-0.01781,0.10867 -0.07245,0.21735 -0.10867,0.32608c-0.38071,0.99659 -1.22777,2.06562 -0.64752,5.00128c0.67051,3.33359 2.27953,4.49811 3.0403,5.38597c0,2.038 1.03336,4.94201 1.66764,6.3009c0.47212,0.99659 0.68892,2.35541 0.68892,4.27614c0,1.79358 -0.07305,2.66079 -0.47097,2.79131c-5.09214,1.67401 -13.14287,3.53258 -16.37013,4.92763c-1.37321,0.77172 -1.94828,2.49688 -1.94828,5.37218l57.50557,0c0,-2.8753 -0.57508,-4.60047 -1.94889,-5.37218l0.00022,0z"
    />
    <rect
      className="pulse"
      width="120"
      height="8"
      x="72"
      y="20"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect width="100%" height="1" y="7" fill={color("grey", "100")} />
    <rect width="100%" height="1" y="56" fill={color("grey", "100")} />
    <rect
      className="pulse"
      width="250"
      height="8"
      x="8"
      y="77"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect width="100%" height="1" y="105" fill={color("grey", "100")} />
    <rect
      className="pulse"
      width="50"
      height="8"
      x="8"
      y="130"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect
      className="pulse"
      width="85"
      height="8"
      x="66"
      y="130"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect
      className="pulse"
      width="16"
      height="16"
      x="8"
      y="160"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect
      className="pulse"
      width="16"
      height="16"
      x="25"
      y="160"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect
      className="pulse"
      width="16"
      height="16"
      x="42"
      y="160"
      rx="1"
      ry="1"
      fill={color("blue", "700")}
    />
    <rect fill={color("grey", "000")} y="200" height="56" width="100%" />
    <rect width="100%" height="1" y="200" fill={color("grey", "100")} />
    <rect width="100%" height="1" y="256" fill={color("grey", "100")} />
  </g>
);

export default class TalentPicksMask extends PureComponent {
  render() {
    return (
      <div data-qa-label="talentPicksMasc" style={{ paddingTop: "8px" }}>
        <SVG>
          <rect fill={color("white", "100")} height="100%" width="100%" />
          <svg>{card}</svg>
          <svg y="272">{card}</svg>
          <svg y="544">{card}</svg>
        </SVG>
      </div>
    );
  }
}
