import React, { memo } from "react";
import { IconButton, secondaryOverride } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Props } from "./types";
import { Container } from "./styled-components";
import {
  CONTAINER,
  FAVORITE,
  ICON,
  NON_FAVORITE,
  SUMMARY
} from "../../utils/constants";

const FavoriteIcon = ({
  isFavorite,
  toggleFavorite,
  trackName = "",
  qaLabel = "",
  className
}: Props) => (
  <Container
    className={className}
    isFavorite={isFavorite}
    data-qa-label={buildQaLabel([qaLabel, SUMMARY, ICON, CONTAINER, trackName])}
  >
    <IconButton
      qaLabel={buildQaLabel([
        qaLabel,
        SUMMARY,
        ICON,
        trackName,
        isFavorite ? FAVORITE : NON_FAVORITE
      ])}
      onClick={toggleFavorite}
      size="s"
      iconName="star"
      overrideColors={{
        ...secondaryOverride,
        default: "--fd-colors-component-button-transparent-background-base",
        hover: "--fd-colors-core-black-tint-005",
        active: "--fd-colors-component-button-transparent-background-base",
        iconHoverColor: isFavorite
          ? "--fd-colors-core-yellow-l-3"
          : "--fd-colors-content-strong",
        iconColor: isFavorite
          ? "--fd-colors-core-yellow-l-3"
          : "--fd-colors-content-strong",
        iconActiveColor: ""
      }}
    />
  </Container>
);

export default memo(FavoriteIcon);
