export const initialState = {
  openTracks: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_TRACK": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    default: {
      return state;
    }
  }
}
