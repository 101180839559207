import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const QuickLinkStyle = css`
  position: relative;

  img {
    height: 160px;
    width: 292px;
    display: flex;
    margin: ${({ theme }) => `0 ${theme.space["space-1"]}`};
    border: none;
    object-fit: cover;
    box-shadow: ${({ theme }) => theme.elevations.low};
    border-radius: 4px;
  }
`;

export const Container = styled.section`
  width: 100%;
`;

export const LinksWrapper = styled.ul`
  display: flex;
  padding: ${({ theme }) => `${theme.space["space-4"]} 0`};
  list-style-type: none;
  overflow-x: auto;

  & li {
    position: relative;
  }

  & li:first-child {
    padding-left: ${({ theme }) => theme.space["space-4"]};
  }

  & li:last-child {
    padding-right: ${({ theme }) => theme.space["space-4"]};
  }
`;

export const QuickLink = styled(Link)`
  ${QuickLinkStyle}
`;

export const ExternalQuickLink = styled.a`
  ${QuickLinkStyle}
`;

export const TagWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
`;
