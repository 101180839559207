import { useQuery } from "@apollo/client";
import { get } from "lodash";

import tvgConf from "@tvg/conf";
import type { RaceFilter } from "@tvg/ts-types/PreviousWinners";
import { GET_PREVIOUS_WINNERS_RACES } from "../../graphql/queries/PreviousWinnersQuery";
import { RACES_POLLER_INTERVAL } from "../../constants";

const conf = tvgConf();

export default function usePreviousWinnersRacesData(variables: {
  matchRaces: Array<RaceFilter> | undefined;
  wagerProfile: string;
}) {
  const { data, loading, refetch } = useQuery(GET_PREVIOUS_WINNERS_RACES, {
    skip: !variables.matchRaces?.length,
    pollInterval: RACES_POLLER_INTERVAL,
    fetchPolicy: "cache-and-network",
    ssr: false,
    variables: {
      wagerProfile: get(variables, "wagerProfile", "PORT-Generic"),
      matchRaces: get(variables, "matchRaces", []),
      isOpen: true,
      isGreyhounds: false,
      ...conf.graphContext()
    }
  });

  return { races: get(data, "races", []), loading, refetch };
}
