import { gql } from "@apollo/client";

const SearchByTrainerFragment = {
  entry: gql`
    fragment searchByTrainer on RaceEntities {
      trainers {
        name
        dob
        entityRunnerId
      }
    }
  `
};

export default SearchByTrainerFragment;
