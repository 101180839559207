import React, { PureComponent, Fragment } from "react";
import { noop } from "lodash";

import MessageBox from "../../_molecule/MessageBox";
import Button from "../../_atom/Buttons/button";
import TabGroup from "../../_molecule/TabGroup";
import AlertLine from "../../_molecule/AlertLine";
import ProcessingOverlay from "../../_static/ProcessingOverlay";

import {
  AlertsContainer,
  ButtonContainer,
  AlertsList
} from "./styled-components";

export default class PicksAlerts extends PureComponent {
  static defaultProps = {
    talentsList: [],
    tracksList: [],
    enablePicksTracksTabAlerts: false,
    isLoading: false,
    selectedTab: "talent",
    onConfirm: noop,
    onCancel: noop,
    handleTabClick: noop,
    talentAlertsInfoMessage:
      "Get notified when your favorite talent has a new pick available to bet",
    trackAlertsInfoMessage:
      "Get notified when TVG picks are submitted on your favorite tracks"
  };

  renderTabs = () => {
    const tabs = [
      {
        title: "talent alerts",
        isActive: this.props.selectedTab === "talent",
        onClick: () => this.props.handleTabClick("talent")
      },
      {
        title: "track alerts",
        isActive: this.props.selectedTab === "track",
        onClick: () => this.props.handleTabClick("track")
      }
    ];
    return <TabGroup tabs={tabs} />;
  };

  renderInfoMessage = () => (
    <MessageBox
      type="info"
      message={
        this.props.selectedTab === "talent"
          ? this.props.talentAlertsInfoMessage
          : this.props.trackAlertsInfoMessage
      }
    />
  );

  renderTalentsAlerts = () =>
    this.props.talentsList.map((talentAlert) => (
      <li key={talentAlert.id}>
        <AlertLine
          qaLabel={`talentAlert-${talentAlert.talentName}`}
          pictureUrl={talentAlert.pictureUrl}
          title={talentAlert.talentName}
          isActive={talentAlert.isActive}
          onToggleClick={talentAlert.onClick}
        />
      </li>
    ));

  renderTracksAlerts = () =>
    this.props.tracksList.map((trackAlert) => (
      <li key={trackAlert.id}>
        <AlertLine
          qaLabel={`trackAlert-${trackAlert.trackName}`}
          title={trackAlert.trackName}
          isActive={trackAlert.isActive}
          onToggleClick={trackAlert.onClick}
        />
      </li>
    ));

  renderBottomButtons = () => (
    <ButtonContainer>
      <Button
        qaLabel="alerts-cancel"
        type="tertiary"
        size="big"
        onClick={this.props.onCancel}
      >
        cancel
      </Button>
      <Button size="big" qaLabel="alerts-save" onClick={this.props.onConfirm}>
        confirm alerts
      </Button>
    </ButtonContainer>
  );

  render() {
    return (
      <Fragment>
        {this.props.isLoading && <ProcessingOverlay customTop={48} />}
        <AlertsContainer>
          {this.props.enablePicksTracksTabAlerts && this.renderTabs()}
          {this.renderInfoMessage()}
          <AlertsList>
            {this.props.selectedTab === "talent"
              ? this.renderTalentsAlerts()
              : this.renderTracksAlerts()}
          </AlertsList>
          {this.renderBottomButtons()}
        </AlertsContainer>
      </Fragment>
    );
  }
}
