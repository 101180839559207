import { gql } from "@apollo/client";

export const GET_TOP_POOLS = gql`
  query getTopPools(
    $wagerProfile: String!
    $brand: String!
    $product: String!
    $device: String!
  ) {
    carryOverPools(
      profile: $wagerProfile
      filter: { raceStatus: ["O", "IC"] }
    ) {
      track: trackName
      trackCode
      amount
      wagerType {
        id
        code
        name
      }
      race {
        id
        raceNumber: number
        postTime
        mtp
        isGreyhound
        highlighted(product: $product, device: $device, brand: $brand) {
          description
          style
          pinnedOrder
        }
        location {
          country
        }
        promos {
          isAboveTheLine
        }
        video {
          onTvg
          onTvg2
        }
      }
      poolType
    }
  }
`;
