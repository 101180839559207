import React, { Component } from "react";
import { noop, isEqual, indexOf, toString, get } from "lodash";

import { doesSilkLoads } from "@tvg/utils/handicappingUtils";

import RunnerLine from "../../_molecule/RunnerLine";

const getRunnerSelections = (biNumber, selections) =>
  selections.map((column) => indexOf(column, toString(biNumber)) >= 0);

const getFavoriteSelections = (biNumber, leg) =>
  leg.indexOf(`${biNumber}`) >= 0;

export default class RunnerList extends Component {
  static defaultProps = {
    runners: [],
    leg: 0,
    isLeg: false,
    isWagerable: true,
    onRunnerSelect: noop,
    raceTypeCode: "T",
    selections: [[]],
    numColumns: 1,
    isKey: false,
    firstHandicapBold: false,
    isFavoriteSelectionActive: false,
    favoriteSelections: [[]],
    expandedRunner: "",
    expandLine: noop,
    expandInfo: noop,
    inLinePastPerformance: false,
    isTrackMasterPick: false,
    isNumberFirePick: false,
    isRacingAndSports: false,
    handicappingSortMode: "saddlecloth",
    redirectToRaceCard: false,
    raceUrl: "/",
    hasTrackMasterInfo: true,
    onRunnerSelection: noop,
    race: null,
    hasHover: false,
    showRunnerLineActiveState: true,
    trackDataSource: "",
    currentRace: {},
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    scrollRunnerPP: false,
    scrollOffset: 0
  };

  state = {
    doesSilkLoads: true
  };

  componentDidMount() {
    this.setDoesSilkLoads();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.doesSilkLoads !== nextState.doesSilkLoads ||
      !isEqual(nextProps, this.props)
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.currentRace, prevProps.currentRace)) {
      this.setDoesSilkLoads();
    }
  }

  setDoesSilkLoads() {
    doesSilkLoads(this.props.runners).then((silkLoads) => {
      if (this.state.doesSilkLoads !== silkLoads) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          doesSilkLoads: silkLoads
        });
      }
    });
  }

  renderRunners() {
    return (
      this.props.runners &&
      this.props.runners.map((bi) =>
        bi.runners.map((runner) => (
          <RunnerLine
            race={this.props.race}
            runners={this.props.runners}
            key={`runner-${runner.runnerId}`}
            selections={getRunnerSelections(bi.biNumber, this.props.selections)}
            runnerData={runner}
            isFavorite={bi.isFavorite}
            biNumber={bi.biNumber}
            leg={this.props.leg}
            raceTypeCode={this.props.raceTypeCode}
            currentOddsNumerator={get(bi, "currentOdds.numerator")}
            currentOddsDenominator={get(bi, "currentOdds.denominator")}
            morningLineOddsNumerator={get(bi, "morningLineOdds.numerator")}
            morningLineOddsDenominator={get(bi, "morningLineOdds.denominator")}
            isWagerable={this.props.isWagerable}
            onRunnerSelect={this.props.onRunnerSelect}
            numColumns={this.props.numColumns}
            isKey={this.props.isKey}
            isLeg={this.props.isLeg}
            expandedRunner={this.props.expandedRunner}
            expandLine={this.props.expandLine}
            expandInfo={this.props.expandInfo}
            firstHandicapBold={this.props.firstHandicapBold}
            isFavoriteSelectionActive={this.props.isFavoriteSelectionActive}
            onRunnerSelection={this.props.onRunnerSelection}
            favoriteSelection={
              this.props.isFavoriteSelectionActive &&
              getFavoriteSelections(
                bi.biNumber,
                this.props.favoriteSelections[this.props.leg]
              )
            }
            inLinePastPerformance={this.props.inLinePastPerformance}
            isRunnerTrackMasterPick={this.props.isTrackMasterPick}
            isRunnerNumberFirePick={this.props.isNumberFirePick}
            isRunnerRacingAndSports={this.props.isRacingAndSports}
            handicappingSortMode={this.props.handicappingSortMode}
            redirectToRaceCard={this.props.redirectToRaceCard}
            raceUrl={this.props.raceUrl}
            hasTrackMasterInfo={this.props.hasTrackMasterInfo}
            hasHover={this.props.hasHover}
            showActiveState={this.props.showRunnerLineActiveState}
            trackDataSource={this.props.trackDataSource}
            currentRace={this.props.currentRace}
            handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            scrollRunnerPP={this.props.scrollRunnerPP}
            scrollOffset={this.props.scrollOffset}
            doesSilkLoads={this.state.doesSilkLoads}
          />
        ))
      )
    );
  }

  render() {
    return !!this.props.runners.length && <tbody>{this.renderRunners()}</tbody>;
  }
}
