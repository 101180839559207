import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const SVG = styled.svg`
  width: auto;
  height: 320px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class TopRacesMask extends PureComponent {
  render() {
    return (
      <SVG>
        <g fill="none" fillRule="evenodd">
          <rect
            width="291"
            height="319"
            x=".5"
            y=".5"
            fill="#FFF"
            fillRule="nonzero"
            stroke="#E6E9EF"
            rx="4"
          />
          <rect
            width="70"
            height="8"
            x="210"
            y="296"
            fill="#E6E9EF"
            fillRule="nonzero"
            rx="1"
          />
          <rect
            width="60"
            height="8"
            x="12"
            y="296"
            fill="#E6E9EF"
            fillRule="nonzero"
            rx="1"
          />
          <g fill="#E6E9EF" transform="translate(0 112)">
            <path d="M24 55h268v1H24z" />
            <rect
              width="100"
              height="8"
              x="72"
              y="17"
              fillRule="nonzero"
              rx="1"
            />
            <rect
              width="132"
              height="8"
              x="72"
              y="34"
              fillRule="nonzero"
              rx="1"
            />
            <path d="M0 0h24v55H0z" />
          </g>
          <g fill="#E6E9EF" transform="translate(0 168)">
            <path d="M24 55h268v1H24z" />
            <rect
              width="52"
              height="8"
              x="72"
              y="17"
              fillRule="nonzero"
              rx="1"
            />
            <rect
              width="124"
              height="8"
              x="72"
              y="34"
              fillRule="nonzero"
              rx="1"
            />
            <path d="M0 0h24v55H0z" />
          </g>
          <g fill="#E6E9EF" transform="translate(0 224)">
            <path d="M0 55h292v1H0z" />
            <rect
              width="84"
              height="8"
              x="72"
              y="17"
              fillRule="nonzero"
              rx="1"
            />
            <rect
              width="156"
              height="8"
              x="72"
              y="34"
              fillRule="nonzero"
              rx="1"
            />
            <path d="M0 0h24v56H0z" />
          </g>
          <path
            fill="#FFF"
            fillRule="nonzero"
            stroke="#E6E9EF"
            d="M.5 111.5h291V4A3.5 3.5 0 0 0 288 .5H4A3.5 3.5 0 0 0 .5 4v107.5z"
          />
          <rect
            width="64"
            height="24"
            x="11"
            y="76"
            fill="#E6E9EF"
            fillRule="nonzero"
            rx="2"
          />
          <rect
            width="98"
            height="8"
            x="12"
            y="34"
            fill="#E6E9EF"
            fillRule="nonzero"
            rx="1"
          />
          <rect
            width="20"
            height="8"
            x="12"
            y="16"
            fill="#E6E9EF"
            fillRule="nonzero"
            rx="1"
          />
        </g>
      </SVG>
    );
  }
}
