import gql from "graphql-tag";

const VideoFragment = {
  entry: gql`
    fragment Video on Race {
      video {
        liveStreaming
        streams
        onTvg
        onTvg2
        hasReplay
      }
    }
  `
};

export default VideoFragment;
