import { gql } from "@apollo/client";
import { TOP_RACE } from "../fragments/Race";
import { TOP_RACES_BETTING_INTERESTS } from "../fragments/BettingInterests";

export const TOP_RACES_RDA = gql`
  query topRacesRDA(
    $wagerProfile: String!
    $brand: String!
    $product: String!
    $device: String!
    $accountId: Int!
    $results: Int!
  ) {
    races: topRaces(
      page: { current: 0, results: $results }
      accountId: $accountId
      highlighted: { product: $product, device: $device, brand: $brand }
      profile: $wagerProfile
    ) {
      ...TopRace
      userPromotions {
        optedIn
        isPromoTagShown
      }
      highlighted {
        description
        style
        pinnedOrder
      }
      betsCount: wagerHistory(filter: { betStatus: ACTIVE }) {
        count
      }
      bettingInterests {
        ...TopRacesBettingInterests
      }
    }
  }
  ${TOP_RACES_BETTING_INTERESTS}
  ${TOP_RACE}
`;

export default TOP_RACES_RDA;
