import styled, { css } from "styled-components";

export const Container = styled.section<{ isDesktop: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  ${({ isDesktop, theme }) =>
    isDesktop
      ? css`
          border-radius: ${theme.radii.s};
          padding-bottom: ${theme.space["space-3"]};
        `
      : css`
          margin-bottom: ${theme.space["space-3"]};
          border-bottom: 1px solid ${theme.colorTokens.border.default};
        `}
`;

export const UpperSection = styled.div<{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  ${({ theme, isDesktop }) => css`
    padding: ${isDesktop ? theme.space["space-4"] : theme.space["space-5"]};
  `};
`;

export const LinkContainerDesk = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.medium};
    font-size: ${theme.fontSizes.s};
    color: ${theme.colorTokens.component.button.buttonLink.content.base};
    padding: ${theme.space["space-3"]};
  `};
  padding-right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
