import React from "react";

import { Header, Paragraph } from "@tvg/design-system/web";
import { HeaderLevel } from "@tvg/design-system/web/components/Typography/Header/types";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import {
  RichTextParserProps,
  Variables,
  ContentText,
  TextMarks
} from "./types";
import { TAG_FONT_SIZE } from "./constants";

const processText = (
  key: string,
  text: string,
  marks: Array<TextMarks> = [],
  variables?: Variables
) => {
  const htmlTxt = replaceCAPIVariables(text, variables);

  return (
    <span
      key={key}
      data-qa-label={key}
      style={{
        ...marks.reduce((acc, mark) => ({ ...acc, ...mark.attrs }), {})
      }}
    >
      {htmlTxt}
    </span>
  );
};

const processHeader = (
  key: string,
  level: number,
  content: Array<ContentText>,
  variables?: Variables
) => (
  <Header
    key={key}
    qaLabel={key}
    tag={`h${level}` as HeaderLevel}
    fontSize={TAG_FONT_SIZE[`h${level}`] || TAG_FONT_SIZE.h3}
    fontFamily="bold"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
  >
    <>
      {content.map((contentText, index) =>
        processText(
          `text-${index}`,
          contentText.text,
          contentText.marks,
          variables
        )
      )}
    </>
  </Header>
);

const processParagraph = (
  key: string,
  content: Array<ContentText> | undefined,
  variables?: Variables
) => (
  <Paragraph
    key={key}
    qaLabel={key}
    whiteSpace="nowrap"
    textOverflow="ellipsis"
  >
    <>
      {(content || []).map((contentText, index) =>
        processText(
          `text-${index}`,
          contentText.text,
          contentText.marks,
          variables
        )
      )}
    </>
  </Paragraph>
);

export const RichTextParser = ({ content, variables }: RichTextParserProps) => (
  <>
    {(content || []).map((textLine, index) =>
      textLine.type === "heading"
        ? processHeader(
            `header-${index}`,
            textLine.attrs.level,
            textLine.content,
            variables
          )
        : processParagraph(`paragraph-${index}`, textLine.content, variables)
    )}
  </>
);
