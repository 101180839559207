import gql from "graphql-tag";

const RaceFragment = {
  entry: gql`
    fragment TopRace on Race {
      runnerNumber: numWagerableRunners
      raceNumber: number
      mtp
      raceId: id
      tvgRaceId
      trackName
      trackCode
      postTime
      status {
        code
      }
      type {
        code
      }
    }
  `
};

export default RaceFragment;
