import React from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  margin: auto;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const DesktopWalletHeader = () => (
  <SVG
    width="1440"
    height="104"
    viewBox="0 0 1440 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd)">
      <path d="M0 2H1440V106H0V2Z" fill="white" />
    </g>
    <rect
      x="109"
      y="24"
      width="180"
      height="16"
      fill="#E9EFF5"
      className="pulse"
    />
    <rect
      x="109"
      y="68"
      width="294"
      height="16"
      fill="#E9EFF5"
      className="pulse"
    />
    <defs>
      <filter
        id="filter0_dd"
        x="-4"
        y="0"
        width="1448"
        height="112"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0666667 0 0 0 0 0.168627 0 0 0 0 0.266667 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </SVG>
);

export default DesktopWalletHeader;
