import { defaultColors, type OverrideColorsProps } from "./types";

export const computeColors = (
  overrideColors: OverrideColorsProps | undefined,
  mode: "light" | "dark"
) =>
  overrideColors
    ? {
        ...defaultColors[mode as keyof typeof defaultColors],
        ...overrideColors
      }
    : defaultColors[mode as keyof typeof defaultColors];
