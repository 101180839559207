import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { buildText } from "../Text";
import { fontMedium, fontNormal } from "../../_static/Typography";

export const InfoContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  word-wrap: break-word;
  align-items: center;
  font-family: ${({ isFirstFontMedium }) =>
    isFirstFontMedium ? fontMedium : fontNormal};

  span {
    font-family: ${fontNormal};
  }

  span:first-child {
    font-family: ${({ isFirstFontMedium }) =>
      isFirstFontMedium ? fontMedium : fontNormal};
  }
`;

export const InlineInfoWrapper = styled.div`
  ${(props) =>
    props.showInfoInline &&
    css`
      display: inline-flex;
    `}
`;

export const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  line-height: 1.3;

  & > svg {
    margin-right: 2px;
  }
`;

export const Description = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    capitalize: true
  })
)`
  ${({ showInfoInline }) =>
    showInfoInline
      ? css`
          margin-right: 5px;
        `
      : css`
          display: block;
        `};
  font-weight: ${({ isDescriptionBold }) =>
    isDescriptionBold ? "bold" : "normal"};
  text-align: left;
  padding-bottom: ${({ showInfoInline }) => (showInfoInline ? "0" : "2px")};
  font-family: ${fontMedium};
`;

export const OptionItem = styled(
  buildText({
    tag: "span",
    color: buildColor("grey", "800")
  })
)`
  ${({ showInfoInline }) =>
    showInfoInline
      ? css`
          font-size: 14px;
          color: ${buildColor("grey", "700")};
        `
      : css`
          font-size: 12px;
        `};
  position: relative;

  &:not(:last-child) {
    margin-right: 4px;
    padding-right: 5px;

    &::after {
      position: absolute;
      right: 0;
      top: 1px;
      bottom: 1px;
      content: "";
      border-right: 1px solid ${buildColor("grey", "300")};
    }
  }
`;

export const SortActive = styled.strong`
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  background-color: ${buildColor("grey", "200")};
  color: ${buildColor("grey", "900")};
  padding: 1px 2px;
  border-radius: 1px;
  ${({ hasArrow }) =>
    hasArrow &&
    css`
      padding: 1px 13px 1px 2px;
    `}

  & > svg {
    transform: translate(1px, 0);
    position: absolute;
    top: calc(50% - 6px);
    right: 2px;
  }
`;
