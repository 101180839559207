import { gql } from "@apollo/client";
import { BETTING_INTERESTS } from "../fragments/BettingInterests";

export const GET_TALENT_PICKS_RACES = gql`
  query getTalentPicksRaces($tracksCode: [String], $wagerProfile: String) {
    talentPicksRaces: tracks(
      profile: $wagerProfile
      filter: { code: $tracksCode }
    ) {
      id
      races {
        id
        tvgRaceId
        number
        trackCode
        numRunners
        numWagerableRunners
        track {
          id
        }
        ...BettingInterests
      }
    }
  }
  ${BETTING_INTERESTS}
`;

export default GET_TALENT_PICKS_RACES;
