import React, { PureComponent } from "react";

import { noop, isEmpty } from "lodash";
import buildRaceUrl from "@tvg/formatter/url";

import Icon from "../../_static/Icons";
import TopRace from "../../_organism/TopRace";
import MagnetScrollArea from "../MagnetScrollArea";
import TopRaceMask from "../../_static/Masks/topRace";
import {
  Container,
  Title,
  UpperSection,
  LinkContainer,
  IconContainer,
  LastElement,
  TopRaceOutterContainer,
  LastElementContainer,
  LinkContainerTVG4
} from "./styled-components";
import { arrowForward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

export default class TopRaces extends PureComponent {
  static defaultProps = {
    races: [],
    isDesktop: false,
    onRedirectToRace: noop,
    onHandleTransition: noop,
    onCardClick: noop,
    onRunnerSelection: noop,
    optedInPromos: {},
    activeIndex: 0,
    racesToRender: 0,
    onLastCardClick: noop,
    title: "Top Races",
    isLoading: false,
    showLastCard: false,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  renderRaces = () =>
    this.props.races.map((race, index) => (
      <TopRaceOutterContainer
        isDesktop={this.props.isDesktop}
        key={`topRace-${race.raceId}`}
        data-qa-label={`topRace-${race.raceId}`}
      >
        {this.props.activeIndex >= index - this.props.racesToRender &&
        this.props.activeIndex <= index + this.props.racesToRender ? (
          <TopRace
            onRunnerSelection={this.props.onRunnerSelection}
            optedInPromos={this.props.optedInPromos}
            onRedirectToRace={this.props.onRedirectToRace}
            key={`topRace-${race.raceId}`}
            race={race}
            isLastRace={false}
            raceCardUrl={buildRaceUrl(
              race.trackCode,
              race.trackName,
              +race.raceNumber,
              race.isGreyhound && this.props.isDesktop
            )}
            onCardClick={this.props.onCardClick}
            isDesktop={this.props.isDesktop}
            data-qa-label={`topRaceContainer-${race.raceId}`}
            isMTPNewRules={this.props.isMTPNewRules}
            useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
          />
        ) : (
          <TopRaceMask
            key={`topRaceMask-${race.raceId}`}
            data-qa-label={`topRaceContainer-${race.raceId}`}
          />
        )}
      </TopRaceOutterContainer>
    ));

  renderLastElement = () => (
    <LastElementContainer
      isDesktop={this.props.isDesktop}
      key="TopRacesLastElement"
      isFirstElement={!this.props.races.length}
      data-qa-label="topRaceLastElement"
    >
      <LastElement show={this.props.showLastCard || isEmpty(this.props.races)}>
        {!this.props.isDesktop ? (
          <LinkContainer to="/tracks" data-qa-label="topRaceLastElement-Link">
            <IconContainer data-qa-label="topRaceLastElement-IconContainer">
              <Icon
                icon={arrowForward}
                color={buildColor("blue_accent", "500")}
                size={16}
                qaLabel="topRaceLastElement-Icon"
              />
            </IconContainer>
            SEE ALL RACES
          </LinkContainer>
        ) : (
          <LinkContainerTVG4
            onClick={() => this.props.onLastCardClick()}
            to="/tracks"
            data-qa-label="topRaceLastElement-Desktop-Link"
          >
            <IconContainer>
              <Icon
                icon={arrowForward}
                color={buildColor("blue_accent", "500")}
                size={16}
              />
            </IconContainer>
            SEE ALL RACES
          </LinkContainerTVG4>
        )}
      </LastElement>
    </LastElementContainer>
  );

  render() {
    return (
      <Container data-qa-label="topRacesContainer">
        <UpperSection isDesktop={this.props.isDesktop}>
          <Title
            biggerTitle={this.props.isDesktop}
            data-qa-label="topRacesTitle"
          >
            {this.props.title}
          </Title>
        </UpperSection>
        <MagnetScrollArea
          hasPagination={this.props.isDesktop}
          onHandleTransition={this.props.onHandleTransition}
          activeIndex={this.props.activeIndex}
          isLoading={this.props.isLoading}
        >
          {this.renderRaces()}
          {this.renderLastElement()}
        </MagnetScrollArea>
      </Container>
    );
  }
}
