import { gql } from "@apollo/client";

export const GET_PREVIOUS_WINNERS_RACES = gql`
  query getPreviousWinnersRaces(
    $wagerProfile: String
    $isOpen: Boolean
    $isGreyhound: Boolean
    $matchRaces: [MatchRacesFilter]
  ) {
    races(
      profile: $wagerProfile
      sort: { byMTP: ASC }
      filter: {
        isOpen: $isOpen
        isGreyhound: $isGreyhound
        matchRaces: $matchRaces
      }
    ) {
      id
      tvgRaceId
      mtp
      postTime
    }
  }
`;

export default GET_PREVIOUS_WINNERS_RACES;
