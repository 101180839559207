import { gql } from "@apollo/client";

export const TOP_RACE = gql`
  fragment TopRace on Race {
    id
    tvgRaceId
    country: location {
      country
    }
    runnerNumber: numWagerableRunners
    raceNumber: number
    isGreyhound
    mtp
    raceId: id
    trackName
    trackCode
    postTime
    type {
      code
    }
    talentPicks {
      id
    }
    video {
      onTvg
      onTvg2
    }
  }
`;

export default TOP_RACE;
