import { gql } from "@apollo/client";

const PastTracksFragment = {
  entry: gql`
    fragment pastTracksFragment on PastTrack {
      code
      name
      location {
        city
        state
        country
      }
      imageName
      imageLink
    }
  `
};

export default PastTracksFragment;
