let xDown = null;
let timeStamp = null;

export const handleTouchStart = (evt) => {
  xDown = evt.touches[0].clientX;
};

export const handleTouchMove = (evt) => {
  if (timeStamp && Date.now() - timeStamp < 1000) {
    return 0;
  }

  timeStamp = Date.now();
  return xDown - evt.touches[0].clientX;
};

export const evaluateReachingEdge = (e) => {
  const scrollableElement = e.currentTarget;
  let edgeHasBeenReached = false;
  if (scrollableElement) {
    const { scrollLeft, scrollWidth, clientWidth } = scrollableElement;
    edgeHasBeenReached =
      (scrollLeft === 0 && xDown - e.touches[0].clientX < 0) ||
      (scrollLeft + clientWidth === scrollWidth &&
        xDown - e.touches[0].clientX > 0);
  }

  return edgeHasBeenReached;
};
