import React, { PureComponent } from "react";
import { noop } from "lodash";

import ReactIdSwiper from "react-id-swiper";
import ContainerLimits from "./styled-components";

export default class ScrollArea extends PureComponent {
  static defaultProps = {
    setSwiper: noop,
    hasPagination: false,
    cardsPerPage: 3,
    contextSA: "topraces",
    isFreeScroll: false
  };

  computeParams = () => {
    if (!this.props.hasPagination)
      return {
        slidesPerView: "auto",
        watchSlidesVisibility: true,
        pagination: {
          hide: true
        },
        shouldSwiperUpdate: true,
        autoHeight: true,
        freeMode: this.props.isFreeScroll
      };

    return {
      slidesPerView: this.props.cardsPerPage,
      spaceBetween: this.props.contextSA !== "talentpicks" ? 11 : 16,
      watchSlidesVisibility: true,
      pagination: {
        hide: true
      },
      shouldSwiperUpdate: true
    };
  };

  // this is a hack, we need to access the swiper component inside the swiper id component,
  //  but isn't ready when we receive the ref
  setSwiperRef = (node) =>
    setTimeout(
      () => node && node.swiper && this.props.setSwiper(node.swiper),
      100
    );

  render() {
    const params = this.computeParams();
    return (
      <ContainerLimits
        isDesktop={this.props.hasPagination}
        data-qa-label="top-races-scrollAreaContainer"
        contextSA={this.props.contextSA}
      >
        <div>
          <ReactIdSwiper {...params} ref={this.setSwiperRef}>
            {this.props.children}
          </ReactIdSwiper>
        </div>
      </ContainerLimits>
    );
  }
}
