import React, { Component } from "react";
import { noop, get } from "lodash";

import { buildRaceUrl } from "@tvg/formatter";

import {
  handleTouchMove,
  handleTouchStart,
  evaluateReachingEdge
} from "@tvg/utils/swipeEventHandlers";
import { getHasPromo } from "@tvg/utils/PromoUtils";
import {
  DesktopWrapper,
  PaginationWrapper,
  RaceList
} from "./styled-components";
import Icon from "../../_static/Icons";
import { arrowBack, arrowForward } from "../../_static/Icons/icons";
import TrackRaceLink from "../../_molecule/TrackRaceLink";
import DefaultButton from "../../_atom/Buttons/default";

const bestPromo = ({ isLogged, promos }) => {
  let belowTheLinePromo;
  let aboveTheLinePromo;
  promos.forEach((promo) => {
    if (!promo.isAboveTheLine && isLogged && !belowTheLinePromo) {
      belowTheLinePromo = promo;
    }

    if (promo.isAboveTheLine && !aboveTheLinePromo) {
      aboveTheLinePromo = promo;
    }
  });
  return belowTheLinePromo || aboveTheLinePromo;
};

export const findMissingScrollAmount = (currentScrollAmount) => {
  const stringValue = currentScrollAmount.toString();
  return +stringValue.substring(stringValue.length - 2, stringValue.length);
};

export default class TrackLine extends Component {
  static defaultProps = {
    device: "mobile",
    idPrefix: "",
    trackRaces: [],
    qaLabel: "",
    isGreyhound: false,
    trackCode: "",
    trackName: "",
    isFavorite: false,
    isLogged: false,
    sendGTMScrollEvent: noop,
    onRaceSelect: noop,
    isMTPNewRules: false,
    isSEORacetracks: false,
    useIsPromoTagShownFlag: false
  };

  constructor(props) {
    super(props);
    this.SCROLL_AMOUNT = 100;
    this.shallDisplayNavigationArrows = true;
    this.state = {
      disableLeftScroll: true,
      disableRightScroll: true
    };
    this.scrollTimeout = null;
  }

  componentDidMount() {
    const tempRaceList = document.getElementById(
      `${this.props.idPrefix}_racesContainer-${this.props.trackCode}`
    );
    if (tempRaceList !== null) {
      const navigationStatus = {
        disableLeftScroll: tempRaceList.scrollLeft === 0,
        disableRightScroll:
          tempRaceList.offsetWidth + tempRaceList.scrollLeft >=
          tempRaceList.scrollWidth
      };
      /* eslint-disable */ this.setState({
        ...navigationStatus
      }); /* eslint-enable */
      this.shallDisplayNavigationArrows = !navigationStatus.disableRightScroll;
      if (
        this.shallDisplayNavigationArrows &&
        this.props.device === "desktop"
      ) {
        tempRaceList.addEventListener("scroll", this.removeEventAfterScroll);
      }
    }
  }

  scrollTimeout;

  // hack to detect scroll move on beginnig
  removeEventAfterScroll = (event) => {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      this.scrollRaceList(0);
      event.target.removeEventListener("scroll", this.removeEventAfterScroll);
    }, 50);
  };

  raceList;

  shallDisplayNavigationArrows;

  SCROLL_AMOUNT;

  scrollRaceList = (amount) => {
    const disableLeftScroll = this.raceList.scrollLeft + amount <= 0;
    this.setState({
      disableLeftScroll,
      disableRightScroll:
        this.raceList.offsetWidth + this.raceList.scrollLeft + amount >=
        this.raceList.scrollWidth
    });
    if (amount !== 0) {
      this.raceList.scroll({
        left: this.raceList.scrollLeft + amount,
        behavior: "smooth"
      });
    }
  };

  scrollForward = () => {
    this.props.sendGTMScrollEvent(false);
    this.scrollRaceList(this.SCROLL_AMOUNT);
  };

  scrollBack = () => {
    const amountToScroll = this.state.disableRightScroll
      ? -findMissingScrollAmount(this.raceList.scrollLeft)
      : -this.SCROLL_AMOUNT;
    this.props.sendGTMScrollEvent(true);
    this.scrollRaceList(amountToScroll);
  };

  handleSwipeGTMEvents = (e) => {
    let swipeDirection = handleTouchMove(e);
    const isLivingOnTheEdge = evaluateReachingEdge(e);
    swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;

    if (swipeDirection !== 0) {
      this.props.sendGTMScrollEvent(swipeDirection < 0);
    }
  };

  renderTrackRaceLinks = (isDesktop) =>
    this.props.trackRaces.map((trackRace) => {
      const userPromotions = get(trackRace, "userPromotions", []);
      let aboveTheLine = null;
      let belowTheLine = null;

      userPromotions.forEach((promo) => {
        if (!promo.isAboveTheLine && !belowTheLine) {
          belowTheLine = promo;
        }

        if (promo.isAboveTheLine && !aboveTheLine) {
          aboveTheLine = promo;
        }
      });

      const tracksPromos = get(trackRace, "promos", []);
      const promo = bestPromo({
        isLogged: this.props.isLogged,
        promos: tracksPromos
      });
      const userPromo = belowTheLine || aboveTheLine;

      const hasPromo = get(promo, "isAboveTheLine", false) || !!userPromo;

      const isPromoTagShown =
        !!get(userPromo, "isPromoTagShown", false) ||
        !!get(promo, "isPromoTagShown", false);

      const shouldShowPromo = getHasPromo(
        this.props.useIsPromoTagShownFlag,
        hasPromo,
        isPromoTagShown
      );

      const isOptedIn =
        get(userPromo, "optedIn", false) || get(promo, "optedIn", false);
      let promoState = "no promo";

      if (isOptedIn || shouldShowPromo) {
        promoState = isOptedIn ? "promo optin" : "promo";
      }

      const programPageURL = buildRaceUrl(
        this.props.trackCode,
        this.props.trackName,
        trackRace.number,
        this.props.device === "desktop" && this.props.isGreyhound
      );

      const id =
        this.props.idPrefix && this.props.trackCode && trackRace.number
          ? `${this.props.idPrefix}_raceElement_${this.props.trackCode}_${trackRace.number}`
          : "";
      return (
        <li
          id={id}
          key={`trackRaceLink_${trackRace.number}`}
          data-qa-label={`trackRace${trackRace.number}Item`}
        >
          <TrackRaceLink
            onClickCallback={(event) => {
              if (isDesktop) {
                event.preventDefault();
              }
              this.props.onRaceSelect(
                programPageURL,
                this.props.trackName,
                trackRace,
                promoState,
                this.props.isFavorite,
                this.props.isGreyhound
              );
            }}
            raceNumber={trackRace.number}
            raceTime={trackRace.postTime}
            hasPromo={isOptedIn || shouldShowPromo}
            optedIn={isOptedIn}
            qaLabel="trackRace"
            mtp={trackRace.mtp}
            status={trackRace.raceStatus}
            to={programPageURL}
            isMTPNewRules={this.props.isMTPNewRules}
            isSEORacetracks={this.props.isSEORacetracks}
          />
        </li>
      );
    });

  render() {
    return this.props.device === "desktop" ? (
      <DesktopWrapper data-qa-label={`${this.props.qaLabel}Container`}>
        {this.shallDisplayNavigationArrows && (
          <PaginationWrapper>
            <DefaultButton
              isDisabled={this.state.disableLeftScroll}
              onClick={() => this.scrollBack()}
              qaLabel={`${this.props.qaLabel}BackButton`}
            >
              <Icon icon={arrowBack} />
            </DefaultButton>
          </PaginationWrapper>
        )}
        <RaceList
          data-qa-label={`${this.props.qaLabel}RaceList`}
          ref={(raceList) => {
            if (raceList) {
              this.raceList = raceList;
            }
          }}
          device={this.props.device}
          id={`${this.props.idPrefix}_racesContainer-${this.props.trackCode}`}
          hasLineBreak={this.props.isSEORacetracks}
          hasNavigation={
            this.props.device === "desktop" && this.shallDisplayNavigationArrows
          }
        >
          {this.renderTrackRaceLinks(true)}
        </RaceList>
        {this.shallDisplayNavigationArrows && (
          <PaginationWrapper isRight>
            <DefaultButton
              isDisabled={this.state.disableRightScroll}
              onClick={() => this.scrollForward()}
              qaLabel={`${this.props.qaLabel}ForwardButton`}
            >
              <Icon icon={arrowForward} />
            </DefaultButton>
          </PaginationWrapper>
        )}
      </DesktopWrapper>
    ) : (
      <RaceList
        id={`${this.props.idPrefix}_racesContainer-${this.props.trackCode}`}
        hasLineBreak={
          this.props.isSEORacetracks && this.props.device === "tablet"
        }
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchMove={(e) => this.handleSwipeGTMEvents(e)}
        data-qa-label={`${this.props.qaLabel}RaceList`}
      >
        {this.renderTrackRaceLinks(false)}
      </RaceList>
    );
  }
}
