import { gql } from "@apollo/client";
import TrackFragment from "../fragments/Tracks";

export const GET_TODAYS_TRACKS = gql`
  query getTracks(
    $wagerProfile: String
    $sortByName: TrackListSort
    $trackFilters: TrackListFilter
    $product: String
    $brand: String
  ) {
    tracks(profile: $wagerProfile, sort: $sortByName, filter: $trackFilters) {
      ...Track
    }
  }
  ${TrackFragment.entry}
`;
