import { gql } from "@apollo/client";

export const TalentPickWagerFragment = {
  entry: gql`
    fragment WagerType on TalentPickType {
      wagerType {
        type {
          id
          code
        }
        legCount
        isBox
        isKey
        isWheel
      }
    }
  `
};
