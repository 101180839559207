export const secondaryDarkOverride = {
  default: "--fd-colors-component-button-transparent-background-hover",
  hover: "--fd-colors-core-white-tint-020",
  active: "--fd-colors-core-white-tint-030",
  disabled: "--fd-colors-component-button-transparent-background-disabled",
  iconColor: "--fd-colors-component-button-transparent-content-base",
  iconHoverColor: "--fd-colors-component-button-transparent-content-hover",
  iconActiveColor: "--fd-colors-component-button-transparent-content-active",
  iconDisabledColor: "--fd-colors-component-button-transparent-content-disabled"
};

export const bettingOverride = {
  default: "--fd-colors-component-button-primary-background-base",
  hover: "--fd-colors-component-button-primary-background-hover",
  active: "--fd-colors-component-button-primary-background-active",
  disabled: "--fd-colors-component-button-primary-background-disabled",
  iconColor: "--fd-colors-component-button-primary-content-base",
  iconHoverColor: "--fd-colors-component-button-primary-content-hover",
  iconActiveColor: "--fd-colors-component-button-primary-content-active",
  iconDisabledColor: "--fd-colors-component-button-primary-content-disabled"
};

export const secondaryOverride = {
  default: "--fd-colors-content-on-dark",
  hover: "--fd-colors-core-white-tint-010",
  active: "--fd-colors-component-button-tertiary-background-hover",
  disabled: "--fd-colors-component-button-tertiary-background-disabled",
  iconColor: "--fd-colors-content-strong",
  iconHoverColor: "--fd-colors-content-strong",
  iconActiveColor: "--fd-colors-content-strong",
  iconDisabledColor: "--fd-colors-component-button-tertiary-content-disabled"
};

export const tertiaryOverride = {
  default: "--fd-colors-component-button-buttonLink-background-base",
  hover: "--fd-colors-component-button-tertiary-background-hover",
  active: "--fd-colors-component-button-buttonLink-background-active",
  disabled: "--fd-colors-component-button-buttonLink-background-disabled",
  iconColor: "--fd-colors-component-button-tertiary-content-base",
  iconHoverColor: "--fd-colors-component-button-tertiary-content-hover",
  iconActiveColor: "--fd-colors-component-button-tertiary-content-active",
  iconDisabledColor: "--fd-colors-component-button-tertiary-content-disabled"
};

export const primaryOverride = {
  default: "--fd-colors-component-button-secondary-background-base",
  hover: "--fd-colors-component-button-secondary-background-hover",
  active: "--fd-colors-component-button-secondary-background-active",
  disabled: "--fd-colors-component-button-secondary-background-disabled",
  iconColor: "--fd-colors-component-button-secondary-content-base",
  iconHoverColor: "--fd-colors-component-button-secondary-content-hover",
  iconActiveColor: "--fd-colors-component-button-secondary-content-active",
  iconDisabledColor: "--fd-colors-component-button-secondary-content-disabled"
};
