import React, { PureComponent } from "react";
import Label from "./styled-components";

export default class SequentialLabel extends PureComponent {
  static defaultProps = {
    label: ""
  };

  processLabel = () => {
    const isLeg = this.props.label.charAt(0) === "R";
    const number = !isLeg ? this.props.label.slice(0, -2) : this.props.label;
    const letters = !isLeg ? this.props.label.slice(-2).toLowerCase() : "";
    return {
      number,
      letters
    };
  };

  render() {
    const { number, letters } = this.processLabel();
    return (
      <Label>
        {number}
        {letters && <sup>{letters}</sup>}
      </Label>
    );
  }
}
