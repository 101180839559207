import React, { PureComponent } from "react";
import { noop } from "lodash";

import { StyledLink, StyledRef } from "./styled-components";
import { getSize, getType } from "./styles";

export default class ButtonLink extends PureComponent {
  static defaultProps = {
    className: "",
    children: null,
    url: "",
    size: "default",
    type: "primary",
    tag: "link",
    isDisabled: false,
    hasRoundedCorners: true,
    isStretched: false,
    qaLabel: "",
    onClick: noop,
    hasShadow: false
  };

  render() {
    const sizeSpecs = getSize(this.props.size);
    const typeSpecs = getType(this.props.type);
    const componentProps = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {}),
      ...{
        size: this.props.size,
        type: this.props.type,
        className: this.props.className
      },
      ...(this.props.tag === "link"
        ? {
            to: this.props.url,
            state:
              typeof this.props.url !== "string"
                ? this.props.url.state
                : undefined
          }
        : { href: this.props.url, as: this.props.tag }),
      onClick: this.props.onClick,
      $sizeSpecs: sizeSpecs,
      $typeSpecs: typeSpecs,
      tag: this.props.tag,
      $isStretched: this.props.isStretched,
      $isDisabled: this.props.isDisabled,
      $hasRoundedCorners: this.props.hasRoundedCorners,
      $hasShadow: this.props.hasShadow
    };

    return this.props.tag === "link" ? (
      <StyledLink {...componentProps}>{this.props.children}</StyledLink>
    ) : (
      <StyledRef {...componentProps}>{this.props.children}</StyledRef>
    );
  }
}
