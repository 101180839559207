import styled, { keyframes } from "styled-components";
import { buildColor } from "@tvg/design-system";
import { IsDesktopProps } from "../../types";

export const SVGContainer = styled.div<IsDesktopProps>`
  width: 100%;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? "12px" : "0")};
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("grey", "000")};
  }
  
  50% {
    fill: ${buildColor("blue", "000")};
  }
  
  100% {
    fill: ${buildColor("grey", "000")};
   }
`;

export const SVG = styled.svg<IsDesktopProps>`
  display: block;
  width: 100%;
  height: ${({ isDesktop }) => (isDesktop ? "440px" : "172px")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${buildColor("blue", "100")};
    stroke-width: 1;
  }
`;
