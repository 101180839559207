import React, { memo } from "react";

import { getTrackName } from "@urp/lib-racetracks/src/utils/races";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Paragraph } from "@tvg/design-system";
import { Tag } from "@tvg/design-system/web";
import { Container, Row } from "./styled-components";
import { Props } from "./types";
import { buildQaLabel } from "../../utils";
import { CONTAINER, TEXT, TRACK } from "../../utils/constants";

const TrackNameDisplay = ({ race, qaLabel = "" }: Props) => {
  const highlighted = useColorTokens("content.onDark");
  const titleColor = useColorTokens("content.default");
  const subtitleColor = useColorTokens("content.subtle");
  const hasDescription = !!race.highlighted?.description;

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, TRACK, CONTAINER])}>
      <Row>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, TRACK, TEXT, race.track.name])}
          color={hasDescription ? highlighted : titleColor}
          fontFamily="medium"
        >
          {getTrackName(race.track.name)}
        </Paragraph>
        {hasDescription && (
          <Tag
            qaLabel={buildQaLabel([qaLabel, TRACK])}
            label={race.highlighted?.description || ""}
            size="small"
            variant="favorite"
            hideIcon
          />
        )}
      </Row>
      <Paragraph
        qaLabel={buildQaLabel([qaLabel, TRACK, TEXT, race.raceNumber])}
        color={hasDescription ? highlighted : subtitleColor}
        fontFamily="regular"
      >
        <>Race {race.raceNumber}</>
      </Paragraph>
    </Container>
  );
};

export default memo(TrackNameDisplay);
