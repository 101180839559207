import styled from "styled-components";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const TrackInfo = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 12px;
`;

export const FavoriteIconWrapper = styled.button`
  width: 16px;
  height: 24px;
  padding: 0;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

export const IconsWrapper = styled.span`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 8px;
  }
`;

export const TrackName = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: ${buildColor("grey", "900")};
  font-family: ${fontMedium};
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  min-width: 0;
`;

export const TextEllipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ResultedTag = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  letter-spacing: 0;
`;
