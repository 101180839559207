import { noop } from "lodash";
import TopTracks from "@tvg/atomic-ui/_templates/TopTracks";

import GraphQuery from "../graphql/queries/TopTracks";
import RDAQuery from "../graphql/queries/TopTracksRDA";
import GraphOptions from "../graphql/top-tracks-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

export const skipQuery = ({ isAccountCompliant, shouldUpdate }) =>
  isAccountCompliant || !shouldUpdate;

export const skipRDAQuery = ({ isAccountCompliant, shouldUpdate, accountId }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const topTracks = {
  template: TopTracks,
  query: GraphQuery,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: false,
  onlyFetchResultedTracks: false,
  renderTracksLetters: false,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps
};

export default topTracks;
