import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import buildColor from "../ColorPalette";
import ProgramPageMask from "./program-page";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "100")};
  }
  
  50% {
    fill: ${buildColor("blue", "200")};
  }
  
  100% {
    fill: ${buildColor("blue", "100")};
  }
`;

const MaskContainer = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${buildColor("blue", "200")};
    stroke-width: 1;
  }
`;

const TabletVideoContainer = styled.div`
  flex-grow: 1;
  min-width: 320px;
  max-width: 60%;
  border-right: 8px solid transparent;
`;

const TabletProgramPageContainer = styled.div`
  min-width: 320px;
  max-width: 40%;
  flex-grow: 1;
  border-left: 8px solid transparent;
`;

export default class ProgramPageTabletMask extends PureComponent {
  render() {
    return (
      <MaskContainer data-qa-label="ProgramPageTabletMask">
        <TabletVideoContainer>
          <SVG>
            <rect
              fill={buildColor("blue", "000")}
              x="0"
              y="0"
              width="100%"
              height="317"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="325"
              width="100%"
              height="1"
            />
            <rect
              fill={buildColor("blue", "000")}
              x="0"
              y="326"
              width="100%"
              height="40"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="366"
              width="100%"
              height="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="341"
              width="63"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("white", "100")}
              x="0"
              y="367"
              width="100%"
              height="132"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="383"
              width="56"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="96"
              y="383"
              width="56"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="184"
              y="383"
              width="56"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="272"
              y="383"
              width="56"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="407"
              width="100%"
              height="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="424"
              width="320"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="440"
              width="284"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="499"
              width="100%"
              height="1"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="507"
              width="100%"
              height="1"
            />
            <rect
              fill={buildColor("blue", "000")}
              x="0"
              y="508"
              width="100%"
              height="40"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="548"
              width="100%"
              height="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="524"
              width="63"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("white", "100")}
              x="0"
              y="549"
              width="100%"
              height="84"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="565"
              width="23"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="63"
              y="565"
              width="120"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="325"
              y="565"
              width="23"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="589"
              width="325"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="605"
              width="290"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="632"
              width="100%"
              height="1"
            />
            <rect
              fill={buildColor("white", "100")}
              x="0"
              y="633"
              width="100%"
              height="84"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="649"
              width="23"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="63"
              y="649"
              width="120"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="325"
              y="649"
              width="23"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="669"
              width="325"
              height="8"
              rx="1"
            />
            <rect
              className="pulse"
              fill={buildColor("blue", "100")}
              x="8"
              y="687"
              width="290"
              height="8"
              rx="1"
            />
            <rect
              fill={buildColor("blue", "200")}
              x="0"
              y="716"
              width="100%"
              height="1"
            />
          </SVG>
        </TabletVideoContainer>
        <TabletProgramPageContainer>
          <ProgramPageMask />
        </TabletProgramPageContainer>
      </MaskContainer>
    );
  }
}
