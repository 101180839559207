import { get, noop } from "lodash";

import tvgConf from "@tvg/conf";
import { getPortByBrand } from "@tvg/utils/generalUtils";

const LABELS = {
  BET: "bet",
  RES: "res",
  PROMO: "promo"
};

const POLL_INTERVAL = 60000;

const labelCreator = (track) => {
  let labels = [];

  if (track.hasAboveTheLinePromo) {
    labels.push({
      text: LABELS.PROMO,
      type: "secondary"
    });
  }

  if (track.isFinished) {
    labels = [];
    labels.push({
      text: LABELS.RES,
      type: "secondary"
    });
  }

  return labels;
};

// TODO: Import CombinedHeaderTracksActions
const tracksAZDataFeeder = (tracks = [], hasError = false) => {
  const finalStruct = {
    tracks: [],
    isLoading: true,
    hasError,
    refetch: noop
  };

  finalStruct.tracks = tracks.map((track) => ({
    name: track.name,
    shortName: track.shortName,
    code: track.code,
    isFavorite: track.isFavorite,
    hasPromo: track.hasAboveTheLinePromo || track.hasPromo,
    isFinished: track.isFinished,
    favorite: track.favorite,
    isGreyhound: track.isGreyhound,
    labels: labelCreator(track),
    isOpen: false
  }));

  return finalStruct;
};

const INCLUDE_GREYHOUNDS = true;

const buildQueryVariables = (wagerProfile = "PORT-Generic") => {
  const queryVariables = {
    wagerProfile,
    sortByName: { byName: "ASC" },
    trackFilters: {
      allTrackClasses: INCLUDE_GREYHOUNDS,
      isFavorite: true,
      raceStatus: ["RO", "MC", "C"]
    },
    product: tvgConf().product,
    brand: tvgConf().brand
  };

  return queryVariables;
};

const QUERY_VARIABLES = {
  wagerProfile: "PORT-Generic",
  accountId: ""
};

const loggedInOptions = (props) => {
  const builtVariables = buildQueryVariables(props.wagerProfile);
  return {
    ...QUERY_VARIABLES,
    ...builtVariables,
    amountOfFavoriteTracks: props.amountOfFavoriteTracks,
    accountId: +get(props, "accountId", "-1"),
    wagerProfile: get(props, "wagerProfile", getPortByBrand())
  };
};

export default {
  skip: (props) => !get(props, "shouldUpdate"),
  options: (props) => {
    const variables = {
      ...(props.isLogged ? loggedInOptions(props) : {})
    };
    return {
      client:
        props.isAccountCompliant && !!get(props, "accountId", "")
          ? props.rdaClient
          : null,
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result) => {
    let hasError = false;

    if (get(result, "data.error", undefined) !== undefined) {
      hasError = true;
    }
    return Object.assign(
      tracksAZDataFeeder(get(result, "data.tracks", []), hasError),
      {
        isLoading: result.data.loading,
        refetch: result.data.refetch
      }
    );
  }
};
