import { get } from "lodash";

import { Race } from "@tvg/ts-types/Race";
import { HomepageEventBannerText } from "@urp/derby-banner/src/types";
import { HomepageHeaderBanner } from "../types";

export const getTrackAbbr = (store: unknown): string | undefined =>
  get(store, "home.eventInfo.trackAbbr");

export const getRaceNumber = (store: unknown): number | undefined =>
  get(store, "home.eventInfo.raceNumber");

export const getEventDate = (store: unknown): Date | null =>
  new Date(get(store, "home.eventInfo.eventDate", null));

export const geDerbyBanner = (store: unknown): HomepageHeaderBanner =>
  get(store, "home.derbyBanner", undefined);

export const getDerbyRace = (store: unknown): Race | undefined =>
  get(store, "home.derbyRace");

export const getEventName = (store: unknown): HomepageEventBannerText =>
  get(store, "home.eventInfo.eventName", {
    label: "",
    size: 0
  });
