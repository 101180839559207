import { combineReducers } from "redux";

import tracksReducer, {
  State as TracksState,
  initialState as tracksInitialState
} from "./tracks";
import racesReducer, {
  State as RacesState,
  initialState as racesInitialState
} from "./races";
import userFavoritesReducer, {
  State as UserFavoritesState,
  initialState as userFavoritesInitialState
} from "./userFavoritesReducer";
import raceTracksFilterReducer, {
  RaceTracksFilterState,
  initialState as RaceTracksFilterInitialState
} from "./raceTracksFilter";
import raceTracksSearchReducer, {
  RaceTracksSearchState,
  initialState as RaceTracksSearchInitialState
} from "./raceTracksSearch";
import tabsReducer, {
  State as TabsState,
  initialState as TabsInitialState
} from "./tabs";

export type State = {
  tracks: TracksState;
  races: RacesState;
  userFavorites: UserFavoritesState;
  filter: RaceTracksFilterState;
  search: RaceTracksSearchState;
  tabs: TabsState;
};

export const initialState = {
  tracks: tracksInitialState,
  races: racesInitialState,
  userFavorites: userFavoritesInitialState,
  filter: RaceTracksFilterInitialState,
  search: RaceTracksSearchInitialState,
  tabs: TabsInitialState
};

export default combineReducers({
  tracks: tracksReducer,
  races: racesReducer,
  userFavorites: userFavoritesReducer,
  filter: raceTracksFilterReducer,
  search: raceTracksSearchReducer,
  tabs: tabsReducer
});
