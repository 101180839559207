import tvgConf from "@tvg/conf";
import { QueryHookOptions } from "@apollo/client";
import { ApolloOptionsProps } from "../types";
import { POLL_INTERVAL } from "../constants";

const conf = tvgConf();

export default ({
  shouldUpdate = false,
  wagerProfile,
  ...apolloOptions
}: ApolloOptionsProps): QueryHookOptions => {
  const variables = {
    wagerProfile,
    ...conf.graphContext()
  };

  return {
    skip: !shouldUpdate || !wagerProfile,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "cache-and-network",
    ssr: false,
    variables,
    ...apolloOptions
  };
};
