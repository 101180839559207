import { gql } from "@apollo/client";

export const DERBY_QUERY = gql`
  query DerbyQuery(
    $trackAbbr: String
    $wagerProfile: String
    $raceNumber: Int
  ) {
    track(profile: $wagerProfile, code: $trackAbbr) {
      id
      races(pagesByField: { raceNumber: $raceNumber, results: 1 }) {
        id
        tvgRaceId
        raceNumber: number
        numRunners
        raceDate
        mtp
        trackName
        postTime
        description
        status {
          code
        }
        probables {
          amount
          minWagerAmount
          wagerType {
            id
            code
            name
            description
          }
          betCombos {
            runner1
            runner2
            payout
          }
        }
        bettingInterests {
          biNumber
          saddleColor
          numberColor
          favorite
          runners {
            horseName
            runnerId
            scratched
          }
          currentOdds {
            numerator
            denominator
          }
        }
      }
    }
  }
`;
