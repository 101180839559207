import { generateQaLabel } from "./utils";

const BASE_QA_LABEL = "top-pools";
const CONTAINER = "container";
const HEADER = "header";
const BODY = "body";
const DESKTOP_VIEW = "desktop";
const TITLE = "title";
const MOBILE_VIEW = "mobile";
const ICON = "icon";
const RACE = "race";
const NAME = "name";
const NUM = "number";
const MTP = "mtp";
const AMOUNT = "amount";
const WAGER_TYPE = "wagerType";
const MASK = "loading-mask";
const POOL = "pool";
const LIST = "list";
const HIGHLIGHT = "highlight";
const UPCOMING = "upcoming";

export const QA_LABEL = {
  loading: generateQaLabel([BASE_QA_LABEL, MASK]),
  container: generateQaLabel([BASE_QA_LABEL, CONTAINER]),
  header: {
    container: generateQaLabel([BASE_QA_LABEL, HEADER, CONTAINER]),
    title: generateQaLabel([BASE_QA_LABEL, HEADER, TITLE])
  },
  body: {
    container: generateQaLabel([BASE_QA_LABEL, BODY, CONTAINER]),
    mtp: generateQaLabel([BASE_QA_LABEL, MTP]),
    amount: generateQaLabel([BASE_QA_LABEL, AMOUNT]),
    wagerType: generateQaLabel([BASE_QA_LABEL, WAGER_TYPE]),
    race: {
      name: generateQaLabel([BASE_QA_LABEL, MOBILE_VIEW, RACE, NAME]),
      number: generateQaLabel([BASE_QA_LABEL, MOBILE_VIEW, RACE, NUM]),
      value: generateQaLabel([BASE_QA_LABEL, MOBILE_VIEW, RACE])
    },
    desktop: {
      highlightTitle: generateQaLabel([
        BASE_QA_LABEL,
        DESKTOP_VIEW,
        HIGHLIGHT,
        TITLE
      ]),
      upcomingTitle: generateQaLabel([
        BASE_QA_LABEL,
        DESKTOP_VIEW,
        UPCOMING,
        TITLE
      ]),
      pool: generateQaLabel([BASE_QA_LABEL, DESKTOP_VIEW, POOL]),
      list: generateQaLabel([BASE_QA_LABEL, DESKTOP_VIEW, LIST, POOL]),
      highlightingPool: generateQaLabel([
        BASE_QA_LABEL,
        DESKTOP_VIEW,
        HIGHLIGHT,
        POOL
      ]),
      upcomingPool: generateQaLabel([
        BASE_QA_LABEL,
        DESKTOP_VIEW,
        UPCOMING,
        POOL
      ])
    },
    mobile: {
      pool: generateQaLabel([BASE_QA_LABEL, MOBILE_VIEW, POOL]),
      icon: generateQaLabel([BASE_QA_LABEL, MOBILE_VIEW, ICON])
    }
  }
};
