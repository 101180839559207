import styled from "styled-components";
import { breakpoints, Theme } from "@tvg/design-system";

export const ScrollableContainer = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  width: 100%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.space["space-4"]};
  gap: ${({ theme }) => theme.space["space-3"]};
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding-left: ${({ theme }) => theme.space["space-3"]};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.background.layer};
`;

export const SectionItems = styled.div`
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  margin: 0 ${({ theme }) => theme.space["space-5"]};
  padding-bottom: ${({ theme }) => theme.space["space-3"]};
  gap: ${({ theme }: { theme: Theme }) => theme.space["space-3"]};

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colorTokens.content.subtle3};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space["space-5"]};
`;

export const Element = styled.div`
  scroll-snap-align: start;

  @media screen and ${breakpoints.tablet.max.sm} {
    &:first-of-type {
      padding-left: ${({ theme }) => theme.space["space-5"]};
    }

    &:last-of-type {
      padding-right: ${({ theme }) => theme.space["space-5"]};
    }
  }
`;
