import React from "react";

export const Info = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
    <path
      d="M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      fill="var(--fd-colors-system-info-background-subtle)"
    />
    <path
      d="M30 45.5V26.125M30 20.3125V14.5M58.095 30.0108C58.095 45.5272 45.5165 58.1058 30 58.1058C14.4836 58.1058 1.90504 45.5272 1.90504 30.0108C1.90504 14.4944 14.4836 1.91583 30 1.91583C45.5165 1.91583 58.095 14.4944 58.095 30.0108Z"
      stroke="var(--fd-colors-system-info-content-accent)"
      strokeWidth="3"
    />
  </svg>
);
