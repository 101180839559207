export const selectRaceFilter = (filterSelected) => ({
  type: "RACE_FILTER_SELECTED",
  payload: { filterSelected }
});

export const selectRegionFilter = (filterSelected) => ({
  type: "REGION_FILTER_SELECTED",
  payload: { filterSelected }
});

export const setRaceFiltersFromPreferences = (filters) => ({
  type: "SET_RACE_FILTERS",
  payload: { filters }
});

export const setRegionFiltersFromPreferences = (filters) => ({
  type: "SET_REGION_FILTERS",
  payload: { filters }
});
