import React, { PureComponent } from "react";

import { noop, get } from "lodash";
import buildColor from "../../_static/ColorPalette";
import {
  refresh,
  funnel,
  starOutline,
  info,
  error as errorIcon,
  tracks
} from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";
import Button from "../../_atom/Buttons/button";

import {
  InfoWrapper,
  InfoLogoWrapper,
  IconExclamationContainer,
  InfoContainer,
  InfoTitle,
  InfoMessage,
  ButtonContainer
} from "./styled-components";
import defaultEmptyMessages from "./defaultEmptyMessages";

const LOGO_SIZE_M = 40;
const LOGO_SIZE_L = 60;
const MAIN_ICON_SIZE_M = 18;
const MAIN_ICON_SIZE_L = 24;
const EXCLAMATION_CONTAINER_SIZE_M = 12;
const EXCLAMATION_CONTAINER_SIZE_L = 16;

export default class EmptyStateMessages extends PureComponent {
  static defaultProps = {
    messageType: "info",
    device: "mobile",
    full: false,
    hasIconOverlay: true,
    mainIcon: null,
    overlayIcon: null,
    componentName: "",
    onClearFilters: noop,
    emptyMessages: {}
  };

  static getEmptyMessage(emptyMessages, messageType) {
    return (
      get(emptyMessages, `${messageType}`, defaultEmptyMessages[messageType]) ||
      defaultEmptyMessages.noresults
    );
  }

  processMessageType = () => {
    const emptyMessage = EmptyStateMessages.getEmptyMessage(
      this.props.emptyMessages,
      this.props.messageType
    );

    let messageTypeConfig;
    switch (this.props.messageType) {
      case "error":
        messageTypeConfig = {
          description: this.props.componentName
            ? emptyMessage.description.replace(
                "__componentName__",
                `${
                  this.props.componentName === "favorite"
                    ? "favorite tracks"
                    : this.props.componentName
                }`
              )
            : emptyMessage.altDescription,
          mainIcon: refresh,
          overlayIcon: errorIcon,
          logoSize: this.props.full ? LOGO_SIZE_L : LOGO_SIZE_M,
          mainIconSize: this.props.full ? MAIN_ICON_SIZE_L : MAIN_ICON_SIZE_M,
          exclamationContainer: this.props.full
            ? EXCLAMATION_CONTAINER_SIZE_L
            : EXCLAMATION_CONTAINER_SIZE_M,
          buttonText: "Refresh"
        };
        break;
      case "favorite":
        messageTypeConfig = {
          mainIcon: starOutline
        };
        break;
      case "noraces":
        messageTypeConfig = {
          title: emptyMessage.title.replace(
            "__componentName__",
            `${this.props.componentName === "favorite" ? "favorite" : ""} ${
              this.props.componentName === "upcoming races" ? "races" : "tracks"
            }`
          ),
          description: emptyMessage.description.replace(
            "__componentName__",
            `${
              this.props.componentName === "favorite"
                ? "Favorite races"
                : "Races"
            }`
          ),
          mainIcon:
            this.props.componentName === "favorite" ? starOutline : tracks
        };
        break;
      default:
        messageTypeConfig = {
          description: emptyMessage.description.replace(
            "__componentName__",
            `${this.props.componentName} ${
              this.props.componentName !== "upcoming races" ? "tracks" : ""
            }`
          ),
          mainIcon: funnel,
          buttonText: "Clear Filters"
        };
    }

    return {
      mainIcon: this.props.mainIcon,
      overlayIcon: this.props.overlayIcon || info,
      full: this.props.full,
      ...emptyMessage,
      ...messageTypeConfig
    };
  };

  handleButtonClick = () => {
    const messages = this.processMessageType();
    if (messages.buttonText === "Refresh") {
      location.reload();
    } else if (messages.buttonText === "Clear Filters") {
      this.props.onClearFilters();
    }
  };

  render() {
    const messages = this.processMessageType();

    return (
      <InfoWrapper full={messages.full}>
        <InfoLogoWrapper
          full={messages.full}
          size={messages.logoSize || LOGO_SIZE_M}
        >
          <Icon
            icon={messages.mainIcon}
            size={messages.mainIconSize || MAIN_ICON_SIZE_M}
            qaLabel="InfoIcon"
            color={messages.mainIconColor || buildColor("grey", "800")}
          />
          {this.props.hasIconOverlay && (
            <IconExclamationContainer>
              <Icon
                icon={messages.overlayIcon}
                size={
                  messages.exclamationContainer || EXCLAMATION_CONTAINER_SIZE_M
                }
                qaLabel="WarningIcon"
                color={
                  messages.overlayIcon === errorIcon
                    ? buildColor("red", "500")
                    : buildColor("blue_accent", "500")
                }
              />
            </IconExclamationContainer>
          )}
        </InfoLogoWrapper>
        <InfoContainer full={messages.full}>
          <InfoTitle full={messages.full}>{messages.title}</InfoTitle>
          <InfoMessage full={messages.full}>{messages.description}</InfoMessage>
        </InfoContainer>
        {messages.buttonText && (
          <ButtonContainer>
            <Button isStretched onClick={this.handleButtonClick}>
              {messages.buttonText}
            </Button>
          </ButtonContainer>
        )}
      </InfoWrapper>
    );
  }
}
