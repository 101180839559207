import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Poller from "@tvg/poller";
import type {
  PreviousWinnersWRCResponse,
  PreviousWinnerWRCResponse,
  PreviousWinnerWRCResponseLists
} from "@tvg/ts-types/PreviousWinners";
import wrc from "@tvg/api/wrc";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { getCatchPhrase, getModalTitle } from "../../redux/selectors";
import { PREVIOUS_WINNERS_POLLER_INTERVAL } from "../../constants";

export default function usePreviousWinnersState() {
  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const catchPhrase = useSelector(getCatchPhrase);
  const modalTitle = useSelector(getModalTitle);

  const [isLoading, setIsLoading] = useState(false);
  const [matchRaces, setMatchRaces] = useState<
    Array<{ trackCode: string; number: string }>
  >([]);
  const [winners, setWinners] = useState<PreviousWinnerWRCResponseLists>([
    [],
    [],
    []
  ]);

  const buildMatchRaces = (previousWinners: Array<PreviousWinnerWRCResponse>) =>
    previousWinners.map((winner) => ({
      trackCode: winner.currentRace.trackCode,
      number: winner.currentRace.raceNumber.toString()
    }));

  const fetchPreviousWinners = () => {
    wrc
      .fetchPreviousWinners(accountNumber)
      .then((resp: PreviousWinnersWRCResponse) => {
        setIsLoading(false);
        setWinners(resp.data.recommendations);
        setMatchRaces(buildMatchRaces(resp.data.recommendations[2]));
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    const poller = new Poller();
    if (isLogged && accountNumber && typeof window !== "undefined") {
      setIsLoading(true);
      poller.start(fetchPreviousWinners, PREVIOUS_WINNERS_POLLER_INTERVAL);
    }
    return () => {
      poller.stop();
      setWinners([[], [], []]);
    };
  }, [isLogged, accountNumber]);

  return {
    isLogged,
    accountNumber,
    catchPhrase,
    modalTitle,
    winners,
    isLoading,
    matchRaces,
    fetchPreviousWinners
  };
}
