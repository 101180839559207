import { BetStateType } from "@tvg/desktop-bet/src/types";
import { BetTicketState } from "@tvg/ts-types/Bet";
import { TabKey } from "@tvg/ts-types/Generic";
import { RaceBet, RaceWagerType } from "@tvg/ts-types/Race";
import { TalentPickData } from "@tvg/ts-types/TalentPicks";

// Declare existing actions
type SetBetConfirmationAction = {
  type: "SET_BET_CONFIRMATION";
  payload: boolean;
};

type SetShowRepeatBetAction = {
  type: "SET_SHOW_REPEAT_BET";
  payload: boolean;
};

type SetBetTicketStateAction = {
  type: "SET_BET_TICKET_STATE";
  payload: BetTicketState;
};

type SetBetRepeatNumberStateAction = {
  type: "SET_BET_REPEAT_NUMBER";
  payload: number;
};

type ClearBetRepeatNumberStateAction = {
  type: "SET_BET_REPEAT_NUMBER";
};

type SetRaceBets = {
  type: "SET_RACE_BETS";
  payload: Array<RaceBet>;
};

type SetBetAmountAction = {
  type: "SET_BET_AMOUNT";
  payload: number;
};

type SetPickAction = {
  type: "SET_PICK";
  payload: {
    value?: TalentPickData | null;
    triggerOrigin?: "programpage" | "pickspage";
  };
};

type SetApplyPickAction = {
  type: "SET_APPLY_PICK";
  payload: boolean;
};

type SetEditButtonAction = {
  type: "SET_PICK_EDIT_BUTTON";
  payload: boolean;
};

type SetOpenConfirmationModal = {
  type: "SET_OPEN_CONFIRMATION_MODAL";
  payload: boolean;
};

type NoMandatoryTalentIdSelected = {
  type: "NO_TALENT_ID_SELECTED_WARNING_VISIBLE";
  payload: boolean;
};

type SetScratchedSelectionAction = {
  type: "SET_SELECTION_SCRATCHED";
  payload: string[][];
};

type SetBetTypeAction = {
  type: "SET_BET_TYPE";
  payload?: RaceWagerType | null | undefined;
};

type SetWagerTypesAction = {
  type: "SET_WAGER_TYPES";
  payload?: RaceWagerType[];
};

type SetStartBettingAction = {
  type: "SET_START_BETTING";
  payload: boolean;
};

type SetBetStateAction = {
  type: "SET_BET_STATE";
  payload: BetStateType;
};

type SetPickLegAction = {
  type: "SET_PICK_LEG";
  payload: number;
};

type SetResultsOfficialsTab = {
  type: "SET_RESULTS_OFFICIALS_TAB";
  payload: TabKey;
};

type SetSelectionRequestAction = {
  type: "SET_BET_SELECTIONS";
  payload: string[][];
};

type SetSelectionMatrixRequestAction = {
  type: "SET_SELECTIONS_MATRIX";
  payload: { selections: string[][]; favoriteSelections: string[][] };
};

type ResetSelectionAction = { type: "RESET_BET_SELECTIONS" };

type ResetBettingAction = { type: "RESET_BETTING" };

type SetFavoriteSelectionRequestAction = {
  type: "SET_FAVORITE_SELECTIONS";
  payload: string[][];
};

export type Actions =
  | NoMandatoryTalentIdSelected
  | SetSelectionRequestAction
  | SetSelectionMatrixRequestAction
  | ResetSelectionAction
  | SetStartBettingAction
  | SetBetConfirmationAction
  | SetBetStateAction
  | SetBetTypeAction
  | SetWagerTypesAction
  | SetBetAmountAction
  | SetPickLegAction
  | SetPickAction
  | SetOpenConfirmationModal
  | SetResultsOfficialsTab
  | SetRaceBets
  | SetScratchedSelectionAction
  | ResetBettingAction
  | SetFavoriteSelectionRequestAction
  | SetEditButtonAction
  | SetApplyPickAction
  | SetBetTicketStateAction
  | SetBetRepeatNumberStateAction
  | ClearBetRepeatNumberStateAction
  | SetShowRepeatBetAction;

export const resetSelection = (): ResetSelectionAction => ({
  type: "RESET_BET_SELECTIONS"
});

export const resetBetting = (): ResetBettingAction => ({
  type: "RESET_BETTING"
});

export const setShowRepeatBet = (status: boolean): SetShowRepeatBetAction => ({
  type: "SET_SHOW_REPEAT_BET",
  payload: status
});

export const noMandatoryTalentIdSelected = (
  status: boolean
): NoMandatoryTalentIdSelected => ({
  type: "NO_TALENT_ID_SELECTED_WARNING_VISIBLE",
  payload: status
});

export const setSelectionRequest = (
  selections: string[][]
): SetSelectionRequestAction => ({
  type: "SET_BET_SELECTIONS",
  payload: selections
});

export const setSelectionMatrixRequest = (selections: {
  selections: string[][];
  favoriteSelections: string[][];
}): SetSelectionMatrixRequestAction => ({
  type: "SET_SELECTIONS_MATRIX",
  payload: selections
});

export const setFavoriteSelectionRequest = (
  selections: string[][]
): SetFavoriteSelectionRequestAction => ({
  type: "SET_FAVORITE_SELECTIONS",
  payload: selections
});

export const setStartBetting = (
  value: boolean = true
): SetStartBettingAction => ({
  type: "SET_START_BETTING",
  payload: value
});

export const setBetConfirmation = (
  value: boolean
): SetBetConfirmationAction => ({
  type: "SET_BET_CONFIRMATION",
  payload: value
});

export const setBetTicketState = (
  value: BetTicketState
): SetBetTicketStateAction => ({
  type: "SET_BET_TICKET_STATE",
  payload: value
});

export const setBetRepeatNumber = (
  value: number
): SetBetRepeatNumberStateAction => ({
  type: "SET_BET_REPEAT_NUMBER",
  payload: value
});

export const clearBetRepeatNumber = (): ClearBetRepeatNumberStateAction => ({
  type: "SET_BET_REPEAT_NUMBER"
});

export const setBetAmount = (value: number): SetBetAmountAction => ({
  type: "SET_BET_AMOUNT",
  payload: value
});

export const setPickAction = (
  value?: TalentPickData | null,
  triggerOrigin?: "programpage" | "pickspage"
): SetPickAction => ({
  type: "SET_PICK",
  payload: {
    value,
    triggerOrigin
  }
});

export const setApplyPickAction = (value: boolean): SetApplyPickAction => ({
  type: "SET_APPLY_PICK",
  payload: value
});

export const setPickEditButtonAction = (
  value: boolean
): SetEditButtonAction => ({
  type: "SET_PICK_EDIT_BUTTON",
  payload: value
});

export const setSelectionScratched = (
  value: string[][]
): SetScratchedSelectionAction => ({
  type: "SET_SELECTION_SCRATCHED",
  payload: value
});

export const setBetType = (
  value?: RaceWagerType | null | undefined
): SetBetTypeAction => ({
  type: "SET_BET_TYPE",
  payload: value
});

export const setWagerTypes = (
  value?: RaceWagerType[]
): SetWagerTypesAction => ({
  type: "SET_WAGER_TYPES",
  payload: value
});

export const setBetState = (value: BetStateType): SetBetStateAction => ({
  type: "SET_BET_STATE",
  payload: value
});

export const setPickLeg = (value: number): SetPickLegAction => ({
  type: "SET_PICK_LEG",
  payload: value
});

export const setRaceBets = (value: Array<RaceBet>): SetRaceBets => ({
  type: "SET_RACE_BETS",
  payload: value
});

export const setResultsOfficialsTab = (
  value: TabKey
): SetResultsOfficialsTab => ({
  type: "SET_RESULTS_OFFICIALS_TAB",
  payload: value
});
