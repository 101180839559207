import { parseJSONCapiMessage } from "@fdr/utils/parseJSONCapiMessage";
import { get } from "lodash";
import { DefaultRootState } from "react-redux";
import { defaultPlaylistSettings, defaultTitle } from "./defaultValues";
import type { PlaylistSettingsMessage } from "../types";

export const getDefaultPlaylistSettings = (
  store: DefaultRootState
): PlaylistSettingsMessage =>
  parseJSONCapiMessage(
    store,
    "capi.messages.homePlaylistSettings",
    defaultPlaylistSettings
  );

export const getHeaderTitle = (store: DefaultRootState): string =>
  get(store, "capi.messages.tipsAndPicksTitle", defaultTitle);

export const getProgramPageVideoPlaylistSettings = (
  store: DefaultRootState
): PlaylistSettingsMessage =>
  parseJSONCapiMessage(
    store,
    "capi.messages.programPagePlaylistSettings",
    defaultPlaylistSettings
  );
