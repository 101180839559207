import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import {
  TrackFilterValueEnum,
  RegionFiltersEnum
} from "@urp/lib-racetracks/src/types";
import { FilterItem } from "../../types";

export const COMP_WIDTH_PLUS_ERROR_MARGIN = 137;

export const RACE_TYPE_OPTIONS: FilterItem<RaceTypeCodeEnum>[] = [
  {
    label: "Tbred",
    value: RaceTypeCodeEnum.THOROUGHBRED
  },
  {
    label: "Harness",
    value: RaceTypeCodeEnum.HARNESS
  },
  {
    label: "QH",
    value: RaceTypeCodeEnum.QTR_HORSE
  }
];

export const REGION_OPTIONS: FilterItem<RegionFiltersEnum>[] = [
  {
    label: "USA",
    value: RegionFiltersEnum.US
  },
  {
    label: "INT",
    value: RegionFiltersEnum.International
  }
];

export const TRACKS_OPTIONS: FilterItem<TrackFilterValueEnum>[] = [
  {
    label: "Featured",
    value: TrackFilterValueEnum.FEATURED
  },
  {
    label: "Favorites",
    value: TrackFilterValueEnum.FAVORITE
  }
];

export const BETTING_FEATURE_OPTIONS = [
  {
    label: "Top Pools",
    value: "topPools"
  },
  {
    label: "Promos",
    value: "promos"
  },
  {
    label: "FDTV",
    value: "fdtv"
  }
];

export const DISTANCE_OPTIONS = [
  {
    label: "less than 7f",
    value: "X-7"
  },
  {
    label: "7f-8f",
    value: "7-8"
  },
  {
    label: "more than 8f",
    value: "8-X"
  }
];
