import tvgConf from "@tvg/conf";

import { getPortByBrand } from "@tvg/utils/generalUtils";
import { get, some } from "lodash";

const conf = tvgConf();

const POLL_INTERVAL = 60000;

const getDecimalOddsValue = ({ numerator, denominator }) => {
  let decimal = denominator && numerator ? numerator / denominator : numerator;
  if (!decimal || isNaN(decimal)) {
    decimal = Number.MAX_VALUE;
  }
  return decimal;
};

const getLowestOdd = (arr) => {
  let lowestOdd = Number.MAX_VALUE;
  let myOdd = Number.MAX_VALUE;

  try {
    for (let i = 0; i < arr.length; i += 1) {
      if (some(arr[i].runners, (runner) => runner.scratched === false)) {
        myOdd = arr[i].currentOdds
          ? getDecimalOddsValue(arr[i].currentOdds)
          : myOdd;
        if (myOdd < lowestOdd) {
          lowestOdd = myOdd;
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return lowestOdd;
};

const isFavorite = (currentOdds, lowestOdd) => {
  const odd = currentOdds ? getDecimalOddsValue(currentOdds) : 99;
  return odd === lowestOdd && odd !== 99;
};

const isFavoriteBIScratched = (bettingInterests) => {
  for (let i = 0; i < bettingInterests.length; i += 1) {
    if (bettingInterests[i].favorite || bettingInterests[i].isFavorite) {
      const { runners } = bettingInterests[i];

      for (let j = 0; j < runners.length; j += 1) {
        if (!runners[j].scratched) {
          return false;
        }
      }
    }
  }
  return true;
};

const getRunners = (bettingInterest) =>
  bettingInterest.runners
    .map((runner) => ({
      ...runner,
      handicapping: [runner.jockey, runner.trainer]
    }))
    .filter((runner) => get(runner, "scratched", true) === false);

const getCoupledEntries = (bettingInterest, addBettingInterests = []) => {
  const runnersDecoupled = [];
  bettingInterest.runners.forEach((runner) =>
    runnersDecoupled.push({ ...bettingInterest, runners: [runner] })
  );

  return addBettingInterests.concat(runnersDecoupled.splice(1, 1));
};

const buildRaces = (races) =>
  (races || []).map((race) => {
    let addBettingInterests = [];
    let lowestOdd = Number.MIN_VALUE;
    const useLowestOddFavorite = isFavoriteBIScratched(race.bettingInterests);

    if (useLowestOddFavorite) {
      lowestOdd = getLowestOdd(race.bettingInterests);
    }

    const sortedPromos = [...(get(race, "promos") || [])].sort(
      (a, b) => +b.isAboveTheLine - +a.isAboveTheLine
    );

    return {
      ...race,
      country: get(race, "country.country"),
      betsCount: get(race, "betsCount.count"),
      promos: sortedPromos,
      bettingInterests: (race.bettingInterests || [])
        .map((bettingInterest) => {
          if (bettingInterest.runners.length > 1) {
            addBettingInterests = getCoupledEntries(
              bettingInterest,
              addBettingInterests
            );
          }
          return {
            ...bettingInterest,
            isFavorite: useLowestOddFavorite
              ? isFavorite(bettingInterest.currentOdds, lowestOdd)
              : bettingInterest.isFavorite,
            runners: [bettingInterest.runners[0]]
          };
        })
        .concat(addBettingInterests)
        .map((bettingInterest) => {
          const runners = getRunners(bettingInterest);
          return { ...bettingInterest, runners };
        })
        .filter((bettingInterest) => bettingInterest.runners.length)
        .sort(
          (bettingInterest, nextBettingInterest) =>
            getDecimalOddsValue(
              bettingInterest?.currentOdds || { numerator: 99, denominator: 0 }
            ) -
            getDecimalOddsValue(
              nextBettingInterest?.currentOdds || {
                numerator: 99,
                denominator: 0
              }
            )
        )
        .slice(0, 3)
    };
  });

export default {
  options: (props) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      accountId: +get(props, "accountId"),
      isHighlighted: true,
      ...conf.graphContext(),
      results: +get(props, "races.length") || 9,
      isRefetch: get(props, "isRefetch", false),
      isAccountCompliant: get(props, "isAccountCompliant", false)
    };

    return {
      client:
        props.isAccountCompliant && props.useRDAService
          ? get(props, "rdaClient")
          : null,
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result) => ({
    races: buildRaces(get(result, "data.races", [])),
    isEndOfFetch:
      get(result, "data.races.length", 0) ===
        get(result, "data.previousData.races.length") &&
      result.data.variables.isRefetch,
    isLoading: result.data.loading,
    refetch: (variables) => result.data.refetch(variables),
    isPoller: get(result, "data.races.length") === result.data.variables.results
  })
};
