import {
  PreviousWinnerPastRace,
  PreviousWinnerCurrentRace
} from "@tvg/ts-types/PreviousWinners";
import { formatDateWithTextDayFirst } from "@tvg/formatter/dates";
import { BetTypeCodesReversedEnum } from "@tvg/design-system";
import buildRaceUrl from "@tvg/formatter/url";

export const setBetType = (betType: string) => {
  const betTypeExtensive =
    betType.trim() as keyof typeof BetTypeCodesReversedEnum;
  return BetTypeCodesReversedEnum[betTypeExtensive]
    ? `${BetTypeCodesReversedEnum[betTypeExtensive].toUpperCase()} BET`
    : "";
};

export const prepareRaceUrl = (race: PreviousWinnerCurrentRace) => {
  const url = buildRaceUrl(
    race.trackCode,
    race.trackName,
    +race.raceNumber,
    false
  ).concat("&wt=WN");

  return race.bettingInterestNumber
    ? `${url}&selectedRunner=${race.bettingInterestNumber}`
    : url;
};

export const raceDetailsFormat = (race: PreviousWinnerPastRace) =>
  `${race.trackCode} R${race.raceNumber}, ${formatDateWithTextDayFirst(
    race.raceDate
  )}`;
