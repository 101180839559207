import { gql } from "@apollo/client";
import { TalentPickRaceFragment } from "../fragments/BettingInterestsFragment";

export const GET_GRAPH_TALENT_PICKS_RACES = gql`
  query getTalentPicksRaces($tracksCode: [String], $wagerProfile: String) {
    talentPicksRaces: tracks(
      profile: $wagerProfile
      filter: { code: $tracksCode }
    ) {
      id
      races {
        id
        number
        track {
          id
          code
        }
        numRunners
        numWagerableRunners
        ...BettingInterests
      }
    }
  }

  ${TalentPickRaceFragment.entry}
`;
