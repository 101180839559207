import {
  BorderRadiusProps,
  ColorProps,
  FlexDirectionProps,
  HeightProps,
  PaddingProps,
  borderRadius,
  color,
  flexDirection,
  height,
  padding
} from "styled-system";
import styled from "styled-components";

export const Wrapper = styled.section<BorderRadiusProps & ColorProps>`
  width: 100%;
  ${borderRadius};
  ${color};
`;

export const Header = styled.div<HeightProps>`
  ${height};
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

export const ListContainer = styled.ul<
  FlexDirectionProps & PaddingProps & { gap: number }
>`
  ${flexDirection};
  ${padding};
  gap: ${({ gap }) => gap}px;
  width: 100%;
  display: flex;
  overflow: auto;
`;
