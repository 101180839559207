import React, { PureComponent } from "react";
import styled from "styled-components";

const SVG = styled.svg`
  z-index: 80;
  position: absolute;
  transform: translateX(-11%) translateY(-2%);
`;

export default class QuickLinksMask extends PureComponent {
  static defaultProps = {
    mainColor: "",
    qaLabel: "",
    index: 0
  };

  render() {
    const { mainColor, qaLabel, index } = this.props;

    return (
      <SVG data-qa-label={qaLabel}>
        <defs>
          <linearGradient
            x1="0%"
            y1="50.006%"
            x2="100%"
            y2="50.006%"
            id={`a${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="49.981%"
            x2="100%"
            y2="49.981%"
            id={`b${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="50.006%"
            x2="100%"
            y2="50.006%"
            id={`c${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={`d${index}`}>
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="50.006%"
            x2="100%"
            y2="50.006%"
            id={`e${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="49.993%"
            x2="100%"
            y2="49.993%"
            id={`f${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="49.981%"
            x2="100%"
            y2="49.981%"
            id={`g${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={`h${index}`}>
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={`i${index}`}>
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id={`j${index}`}>
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="49.993%"
            x2="100%"
            y2="49.993%"
            id={`k${index}`}
          >
            <stop stopColor={mainColor} stopOpacity="0" offset="0%" />
            <stop stopColor={mainColor} offset="100%" />
          </linearGradient>
        </defs>
        <g
          id="Quick-Links-V2"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Quick-Links-Overview-SVG"
            transform="translate(-100.000000, -748.000000)"
            fillRule="nonzero"
          >
            <g id="svg-yellow" transform="translate(100.000000, 748.000000)">
              <g id="Group" opacity="0.7">
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="140.32 61.62 133.44 65.57 124.47 56.61 131.36 52.66"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="40.76 100.25 33.88 104.21 24.92 95.24 31.8 91.29"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  points="221.12 54.66 214.24 58.62 205.27 49.65 212.16 45.7"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.4"
                  points="141.47 82.1 138.22 83.98 128.51 74.27 131.77 72.4"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.3"
                  points="145.02 85.65 141.76 87.52 139.15 84.91 142.41 83.04"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.4"
                  points="184.47 80.38 181.21 82.25 176.97 78.01 180.23 76.14"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.2"
                  points="116.24 95.88 112.98 97.75 100.42 85.18 103.68 83.31"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  points="61.66 66.72 58.4 68.59 45.84 56.03 49.1 54.16"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="39.47 8.6 44.54 5.68 48.18 9.32 43.11 12.23"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="44.19 13.31 49.26 10.4 51.08 12.22 46 15.13"
                />
                <polygon
                  id="Path"
                  fill={`url(#a${index})`}
                  opacity="0.5"
                  points="47.51 16.64 52.59 13.72 127.27 88.42 122.2 91.33"
                />
                <polygon
                  id="Path"
                  fill={`url(#b${index})`}
                  opacity="0.4"
                  points="27.29 67.61 28.2 67.09 53.79 92.68 52.88 93.2"
                />
                <polygon
                  id="Path"
                  fill={`url(#c${index})`}
                  opacity="0.4"
                  points="14.11 7.96 15.02 7.44 102.66 95.07 101.75 95.59"
                />
                <polygon
                  id="Path"
                  fill={`url(#d${index})`}
                  opacity="0.4"
                  points="147.24 10.42 148.15 9.9 229.3 91.06 228.4 91.58"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.3"
                  points="198.6 85.82 203.67 82.91 218.48 97.72 213.41 100.63"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.3"
                  points="64.45 95.92 69.53 93.01 84.33 107.82 79.26 110.73"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.3"
                  points="91.82 21.11 96.89 18.19 111.7 33 106.63 35.91"
                />
                <g
                  opacity="0.5"
                  transform="translate(76.000000, 58.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.96 3.76 0.85 3.58 5.93 0.66 6.03 0.85" />
                  <polygon points="1.53 4.33 1.42 4.15 6.5 1.23 6.6 1.42" />
                  <polygon points="2.1 4.9 1.99 4.72 7.06 1.8 7.17 1.99" />
                  <polygon points="2.67 5.47 2.56 5.29 7.64 2.37 7.74 2.55" />
                  <polygon points="3.24 6.04 3.13 5.86 8.2 2.94 8.31 3.13" />
                  <polygon points="3.81 6.61 3.7 6.42 8.77 3.51 8.88 3.7" />
                  <polygon points="4.38 7.18 4.27 7 9.34 4.08 9.45 4.26" />
                  <polygon points="4.94 7.75 4.84 7.56 9.91 4.65 10.02 4.83" />
                  <polygon points="5.51 8.32 5.41 8.13 10.48 5.22 10.59 5.4" />
                  <polygon points="6.08 8.89 5.98 8.7 11.05 5.79 11.16 5.97" />
                  <polygon points="6.65 9.45 6.55 9.27 11.62 6.36 11.73 6.54" />
                  <polygon points="7.22 10.03 7.12 9.84 12.19 6.93 12.3 7.11" />
                  <polygon points="7.79 10.59 7.69 10.41 12.76 7.5 12.87 7.68" />
                  <polygon points="8.36 11.16 8.26 10.98 13.33 8.07 13.44 8.25" />
                  <polygon points="8.93 11.73 8.83 11.55 13.9 8.64 14 8.82" />
                  <polygon points="9.5 12.3 9.4 12.12 14.47 9.21 14.57 9.39" />
                  <polygon points="10.07 12.87 9.97 12.69 15.04 9.78 15.14 9.96" />
                  <polygon points="10.64 13.44 10.53 13.26 15.61 10.35 15.71 10.53" />
                  <polygon points="11.21 14.01 11.11 13.83 16.18 10.92 16.28 11.1" />
                  <polygon points="11.78 14.58 11.67 14.4 16.75 11.49 16.85 11.67" />
                  <polygon points="12.35 15.15 12.24 14.97 17.32 12.06 17.42 12.24" />
                  <polygon points="12.92 15.72 12.81 15.54 17.89 12.63 17.99 12.81" />
                  <polygon points="13.49 16.29 13.38 16.11 18.46 13.19 18.56 13.38" />
                  <polygon points="14.06 16.86 13.95 16.68 19.03 13.77 19.13 13.95" />
                  <polygon points="14.63 17.43 14.52 17.25 19.6 14.33 19.7 14.52" />
                  <polygon points="15.2 18 15.09 17.82 20.17 14.9 20.27 15.09" />
                  <polygon points="15.77 18.57 15.66 18.39 20.73 15.47 20.84 15.66" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(139.000000, 22.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="1.03 10.04 0.93 9.86 17.11 0.57 17.21 0.75" />
                  <polygon points="2.35 11.36 2.24 11.17 18.42 1.88 18.53 2.07" />
                  <polygon points="3.66 12.67 3.55 12.49 19.73 3.2 19.84 3.38" />
                  <polygon points="4.97 13.98 4.87 13.8 21.04 4.51 21.15 4.69" />
                  <polygon points="6.28 15.29 6.18 15.11 22.36 5.82 22.46 6" />
                  <polygon points="7.59 16.6 7.49 16.42 23.67 7.13 23.77 7.32" />
                  <polygon points="8.91 17.91 8.8 17.73 24.98 8.45 25.09 8.63" />
                  <polygon points="10.22 19.23 10.11 19.05 26.29 9.76 26.4 9.94" />
                  <polygon points="11.53 20.54 11.43 20.36 27.6 11.07 27.71 11.25" />
                  <polygon points="12.84 21.85 12.74 21.67 28.92 12.38 29.02 12.56" />
                  <polygon points="14.15 23.16 14.05 22.98 30.23 13.69 30.33 13.87" />
                  <polygon points="15.47 24.48 15.36 24.29 31.54 15 31.65 15.19" />
                  <polygon points="16.78 25.79 16.67 25.6 32.85 16.32 32.96 16.5" />
                  <polygon points="18.09 27.1 17.99 26.92 34.16 17.63 34.27 17.81" />
                  <polygon points="19.4 28.41 19.3 28.23 35.48 18.94 35.58 19.12" />
                  <polygon points="20.71 29.72 20.61 29.54 36.79 20.25 36.89 20.43" />
                  <polygon points="22.03 31.03 21.92 30.85 38.1 21.56 38.21 21.75" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(162.000000, 87.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.81 10.12 0.7 9.94 16.88 0.65 16.99 0.83" />
                  <polygon points="2.12 11.43 2.01 11.25 18.19 1.96 18.3 2.14" />
                  <polygon points="3.43 12.74 3.33 12.56 19.5 3.27 19.61 3.45" />
                  <polygon points="4.74 14.06 4.64 13.87 20.82 4.58 20.92 4.77" />
                  <polygon points="6.05 15.37 5.95 15.18 22.13 5.89 22.23 6.08" />
                  <polygon points="7.37 16.68 7.26 16.5 23.44 7.21 23.54 7.39" />
                  <polygon points="8.68 17.99 8.57 17.81 24.75 8.52 24.86 8.7" />
                  <polygon points="9.99 19.3 9.88 19.12 26.06 9.83 26.17 10.01" />
                  <polygon points="11.3 20.61 11.2 20.43 27.38 11.14 27.48 11.33" />
                  <polygon points="12.61 21.93 12.51 21.74 28.69 12.45 28.79 12.64" />
                  <polygon points="13.93 23.24 13.82 23.06 30 13.77 30.1 13.95" />
                  <polygon points="15.24 24.55 15.13 24.37 31.31 15.08 31.42 15.26" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(0.000000, 29.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.1 10.08 3.26849658e-13 9.9 16.18 0.61 16.28 0.79" />
                  <polygon points="1.42 11.39 1.31 11.21 17.49 1.92 17.6 2.11" />
                  <polygon points="2.73 12.7 2.62 12.52 18.8 3.23 18.91 3.42" />
                  <polygon points="4.04 14.02 3.94 13.84 20.11 4.55 20.22 4.73" />
                  <polygon points="5.35 15.33 5.25 15.15 21.43 5.86 21.53 6.04" />
                  <polygon points="6.67 16.64 6.56 16.46 22.74 7.17 22.84 7.35" />
                  <polygon points="7.98 17.95 7.87 17.77 24.05 8.48 24.16 8.66" />
                  <polygon points="9.29 19.27 9.18 19.08 25.36 9.79 25.47 9.98" />
                  <polygon points="10.6 20.58 10.5 20.4 26.68 11.1 26.78 11.29" />
                  <polygon points="11.91 21.89 11.81 21.71 27.99 12.42 28.09 12.6" />
                  <polygon points="13.22 23.2 13.12 23.02 29.3 13.73 29.4 13.91" />
                  <polygon points="14.54 24.51 14.43 24.33 30.61 15.04 30.72 15.22" />
                  <polygon points="15.85 25.82 15.74 25.64 31.92 16.35 32.03 16.54" />
                  <polygon points="17.16 27.14 17.05 26.95 33.23 17.67 33.34 17.85" />
                  <polygon points="18.47 28.45 18.37 28.27 34.55 18.98 34.65 19.16" />
                  <polygon points="19.78 29.76 19.68 29.58 35.86 20.29 35.96 20.47" />
                  <polygon points="21.1 31.07 20.99 30.89 37.17 21.6 37.27 21.78" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(142.000000, 86.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="1.03 2.56 0.93 2.38 4.19 0.5 4.29 0.69" />
                  <polygon points="1.51 3.04 1.41 2.86 4.67 0.99 4.77 1.17" />
                  <polygon points="2 3.52 1.89 3.34 5.15 1.47 5.26 1.65" />
                  <polygon points="2.48 4 2.37 3.82 5.63 1.95 5.74 2.13" />
                  <polygon points="2.96 4.48 2.85 4.3 6.11 2.43 6.22 2.61" />
                  <polygon points="3.44 4.97 3.34 4.78 6.59 2.91 6.7 3.09" />
                  <polygon points="3.92 5.45 3.82 5.27 7.08 3.39 7.18 3.58" />
                  <polygon points="4.4 5.93 4.3 5.75 7.56 3.88 7.66 4.06" />
                  <polygon points="4.88 6.41 4.78 6.23 8.04 4.36 8.15 4.54" />
                  <polygon points="5.37 6.89 5.26 6.71 8.52 4.84 8.63 5.02" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(34.000000, 0.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.52 3.68 0.41 3.5 5.49 0.59 5.59 0.77" />
                  <polygon points="1.45 4.61 1.35 4.43 6.42 1.52 6.52 1.7" />
                  <polygon points="2.38 5.55 2.28 5.36 7.35 2.45 7.45 2.63" />
                  <polygon points="3.31 6.48 3.21 6.29 8.28 3.38 8.39 3.56" />
                  <polygon points="4.24 7.41 4.14 7.23 9.21 4.31 9.32 4.5" />
                </g>
                <g
                  opacity="0.5"
                  transform="translate(224.000000, 63.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.8 3.11 0.69 2.93 5.76 0.02 5.87 0.2" />
                  <polygon points="1.73 4.04 1.62 3.86 6.69 0.95 6.8 1.13" />
                  <polygon points="2.66 4.97 2.55 4.79 7.63 1.88 7.73 2.06" />
                  <polygon points="3.59 5.91 3.48 5.72 8.56 2.81 8.66 2.99" />
                  <polygon points="4.52 6.84 4.41 6.65 9.49 3.74 9.59 3.92" />
                  <polygon points="5.45 7.77 5.35 7.58 10.42 4.67 10.52 4.85" />
                  <polygon points="6.38 8.7 6.28 8.52 11.35 5.6 11.46 5.78" />
                  <polygon points="7.31 9.63 7.21 9.45 12.28 6.53 12.39 6.72" />
                  <polygon points="8.24 10.56 8.14 10.38 13.21 7.47 13.32 7.65" />
                  <polygon points="9.18 11.49 9.07 11.31 14.14 8.4 14.25 8.58" />
                  <polygon points="10.11 12.42 10 12.24 15.07 9.33 15.18 9.51" />
                  <polygon points="11.04 13.35 10.93 13.17 16.01 10.26 16.11 10.44" />
                  <polygon points="11.97 14.28 11.86 14.1 16.94 11.19 17.04 11.37" />
                  <polygon points="12.9 15.22 12.79 15.03 17.87 12.12 17.97 12.3" />
                </g>
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="218.84 60.42 223.91 57.51 227.55 61.14 222.47 64.06"
                />
                <g
                  opacity="0.5"
                  transform="translate(126.000000, 92.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.41 3.57 0.3 3.39 5.38 0.48 5.48 0.66" />
                  <polygon points="1.34 4.5 1.23 4.32 6.31 1.41 6.41 1.59" />
                  <polygon points="2.27 5.44 2.17 5.25 7.24 2.34 7.34 2.52" />
                  <polygon points="3.2 6.37 3.1 6.18 8.17 3.27 8.28 3.45" />
                  <polygon points="4.13 7.3 4.03 7.11 9.1 4.2 9.21 4.38" />
                  <polygon points="5.06 8.23 4.96 8.05 10.03 5.13 10.14 5.31" />
                  <polygon points="6 9.16 5.89 8.98 10.96 6.06 11.07 6.25" />
                  <polygon points="6.93 10.09 6.82 9.91 11.9 7 12 7.18" />
                  <polygon points="7.86 11.02 7.75 10.84 12.82 7.93 12.93 8.11" />
                  <polygon points="8.79 11.95 8.68 11.77 13.76 8.86 13.86 9.04" />
                  <polygon points="9.72 12.88 9.62 12.7 14.69 9.79 14.79 9.97" />
                  <polygon points="10.65 13.81 10.55 13.63 15.62 10.72 15.72 10.9" />
                  <polygon points="11.58 14.75 11.48 14.56 16.55 11.65 16.66 11.83" />
                  <polygon points="12.51 15.68 12.41 15.5 17.48 12.58 17.59 12.76" />
                  <polygon points="13.44 16.61 13.34 16.43 18.41 13.51 18.52 13.69" />
                  <polygon points="14.38 17.54 14.27 17.36 19.34 14.44 19.45 14.63" />
                  <polygon points="15.31 18.47 15.2 18.29 20.28 15.38 20.38 15.56" />
                </g>
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="226.79 18.46 231.87 15.55 235.5 19.19 230.43 22.1"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="231.51 23.18 236.58 20.27 238.4 22.09 233.33 25"
                />
                <g
                  opacity="0.4"
                  transform="translate(180.000000, 0.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.47 3.35 0.37 3.18 5.36 0.31 5.46 0.49" />
                  <polygon points="1.38 4.27 1.28 4.09 6.27 1.23 6.38 1.41" />
                  <polygon points="2.3 5.19 2.2 5.01 7.19 2.14 7.29 2.32" />
                  <polygon points="3.22 6.11 3.11 5.93 8.11 3.06 8.21 3.24" />
                  <polygon points="4.13 7.02 4.03 6.84 9.02 3.98 9.13 4.15" />
                  <polygon points="5.05 7.94 4.95 7.76 9.94 4.89 10.04 5.07" />
                  <polygon points="5.97 8.85 5.86 8.67 10.85 5.81 10.96 5.99" />
                  <polygon points="6.88 9.77 6.78 9.59 11.77 6.72 11.87 6.9" />
                </g>
                <g
                  opacity="0.4"
                  transform="translate(210.000000, 0.000000)"
                  fill={mainColor}
                  id="Path"
                >
                  <polygon points="0.97 3.08 0.87 2.9 5.86 0.04 5.97 0.22" />
                  <polygon points="1.89 4 1.79 3.82 6.78 0.95 6.88 1.13" />
                  <polygon points="2.81 4.92 2.7 4.74 7.7 1.87 7.8 2.05" />
                  <polygon points="3.72 5.83 3.62 5.66 8.61 2.79 8.72 2.97" />
                  <polygon points="4.64 6.75 4.54 6.57 9.53 3.7 9.63 3.88" />
                  <polygon points="5.56 7.67 5.45 7.49 10.44 4.62 10.55 4.8" />
                  <polygon points="6.47 8.58 6.37 8.4 11.36 5.54 11.47 5.72" />
                  <polygon points="7.39 9.5 7.28 9.32 12.28 6.45 12.38 6.63" />
                  <polygon points="8.31 10.41 8.2 10.24 13.19 7.37 13.3 7.55" />
                  <polygon points="9.22 11.33 9.12 11.15 14.11 8.29 14.21 8.46" />
                  <polygon points="10.14 12.25 10.03 12.07 15.03 9.2 15.13 9.38" />
                  <polygon points="11.05 13.16 10.95 12.98 15.94 10.12 16.05 10.3" />
                  <polygon points="11.97 14.08 11.87 13.9 16.86 11.04 16.96 11.21" />
                  <polygon points="12.89 15 12.78 14.82 17.78 11.95 17.88 12.13" />
                  <polygon points="13.8 15.91 13.7 15.73 18.69 12.87 18.79 13.05" />
                  <polygon points="14.72 16.83 14.62 16.65 19.61 13.78 19.71 13.96" />
                </g>
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="15.68 74.9 12.42 76.77 8.18 72.52 11.44 70.65"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="26.38 85.77 23.12 87.64 13.41 77.93 16.67 76.06"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.4"
                  points="70.78 0.06 74.98 4.26 78.24 2.39 75.91 0.06"
                />
                <polygon
                  id="Path"
                  fill={`url(#e${index})`}
                  opacity="0.5"
                  points="91.26 0.06 83.27 0.06 170.48 87.27 175.55 84.36"
                />
                <polygon
                  id="Path"
                  fill={`url(#f${index})`}
                  opacity="0.4"
                  points="104.14 0.06 102.71 0.06 176.81 74.15 177.72 73.63"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="130.32 0.06 140.56 10.3 145.63 7.39 138.31 0.06"
                />
                <polygon
                  id="Path"
                  fill={`url(#g${index})`}
                  opacity="0.5"
                  points="166.86 0.06 158.87 0.06 184.66 25.85 189.73 22.93"
                />
                <polygon
                  id="Path"
                  fill={`url(#h${index})`}
                  opacity="0.4"
                  points="196.31 3.62376795e-13 192.69 3.62376795e-13 225.39 32.7 227.69 31.38"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.5"
                  points="150.92 112.06 147.61 108.75 142.53 111.66 142.94 112.06"
                />
                <polygon
                  id="Path"
                  fill={mainColor}
                  opacity="0.3"
                  points="114.56 112.06 106.89 104.39 103.63 106.27 109.43 112.06"
                />
                <polygon
                  id="Path"
                  fill={`url(#i${index})`}
                  points="116.83 0.06 113.21 0.06 138.09 24.94 140.39 23.62"
                />
              </g>
              <polygon
                id="Path"
                fill={mainColor}
                opacity="0.5"
                points="58.26 79.05 51.38 83 42.41 74.03 49.3 70.08"
              />
              <polygon
                id="Path"
                fill={mainColor}
                opacity="0.5"
                points="196.82 30.03 193.56 31.9 189.32 27.65 192.58 25.78"
              />
              <polygon
                id="Path"
                fill={mainColor}
                opacity="0.5"
                points="207.53 40.9 204.26 42.77 194.56 33.06 197.82 31.19"
              />
              <polygon
                id="Path"
                fill={`url(#j${index})`}
                points="18.91 0.06 15.3 0.06 51.66 36.42 53.95 35.1"
              />
              <polygon
                id="Path"
                fill={`url(#k${index})`}
                points="58.94 0.06 57.51 0.06 127.12 69.67 128.02 69.15"
              />
            </g>
          </g>
        </g>
      </SVG>
    );
  }
}
