import { gql } from "@apollo/client";

export const TRACK = gql`
  fragment Track on Track {
    id
    favorite {
      id
    }
    code
    shortName
    name
    featured
    hasAboveTheLinePromo
    numberOfRaces
    isGreyhound
    isFinished
    isFavorite
    hasPromo
    isPromoTagShown
  }

  query tracks(
    $accountId: Int!
    $wagerProfile: String
    $trackFilters: TrackListFilter
    $product: String
    $brand: String
  ) {
    tracks(
      accountId: $accountId
      profile: $wagerProfile
      trackSort: { byName: ASC }
      trackFilter: $trackFilters
      product: $product
      brand: $brand
    ) {
      ...Track
    }
  }
`;

export default TRACK;
