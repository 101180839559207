import type {
  SetHomepageOrder,
  SetDerbyBanner,
  SetHomepageEventStatusAction,
  SetHomepageDerbyRaceAction
} from "./actions";
import type { HomepageOrder } from "../types";

export type State = {
  homepageOrder: HomepageOrder | null;
};

export const initialState: State = {
  homepageOrder: null
};

export default (
  state: State = initialState,
  action:
    | SetHomepageOrder
    | SetDerbyBanner
    | SetHomepageEventStatusAction
    | SetHomepageDerbyRaceAction
) => {
  switch (action.type) {
    case "SET_HOMEPAGE_ORDER_ACTION":
      return {
        ...state,
        homepageOrder: action.payload
      };
    case "SET_DERBY_BANNER_ACTION":
      return {
        ...state,
        ...action.payload
      };
    case "SET_HOMEPAGE_EVENT_STATUS":
      return {
        ...state,
        currentConfig: action.payload
      };
    case "SET_HOMEPAGE_DERBY_RACE":
      return {
        ...state,
        derbyRace: action.payload
      };
    default: {
      return state;
    }
  }
};
