import { isNull, get, sortBy, flatMap } from "lodash";

export const TALENT_PICKS = "talentpicks";
export const TRACKMASTER_PICKS = "trackmasterpicks";
export const TIMEFORM_PICKS = "timeformpicks";
export const HANDICAPPING_STORE = "handicappingStore";
export const RACING_AND_SPORTS = "racingAndSports";

export const TRACK_DATA_SOURCE_USGSA = "USGSA";
export const TRACK_DATA_SOURCE_GSA = "GSA";
export const TRACK_DATA_SOURCE_SRW = "SRW";
export const TRACK_DATA_SOURCE_EQB = "EQB";
export const TRACK_DATA_SOURCE_RAS = "RAS";

const getOddsInfo = (bettingInterestRunnerInfo) => {
  const currentOddsNumerator =
    get(bettingInterestRunnerInfo, "currentOdds.numerator") || "";
  const currentOddsDenominator =
    get(bettingInterestRunnerInfo, "currentOdds.denominator") || "";
  const mlOddsNumerator =
    get(bettingInterestRunnerInfo, "morningLineOdds.numerator") || "";
  const mlOddsDenominator =
    get(bettingInterestRunnerInfo, "morningLineOdds.denominator") || "";
  const isMorningLine =
    currentOddsNumerator === "" && currentOddsDenominator === "";

  return {
    currentOddsNumerator: isMorningLine
      ? mlOddsNumerator
      : currentOddsNumerator,
    currentOddsDenominator: isMorningLine
      ? mlOddsDenominator
      : currentOddsDenominator,
    isMorningLine
  };
};

const hasInfo = (freePicks) =>
  !!freePicks && !isNull(freePicks.number) && !isNull(freePicks.info);

const getPickInfo = (bettingInterestData, picksType = TRACKMASTER_PICKS) => {
  const returnPicksInfo = [];
  bettingInterestData.runners.forEach((runner) => {
    const pickInfo =
      picksType === TIMEFORM_PICKS
        ? get(runner, "timeform.freePick")
        : get(runner, "handicapping.freePick");
    if (hasInfo(pickInfo)) {
      returnPicksInfo.push({
        runnerInfo: runner,
        pickInfo
      });
    }
  });
  return returnPicksInfo;
};

const hasPicksInfo = (bettingInterestData, picksType = TRACKMASTER_PICKS) =>
  bettingInterestData.runners &&
  bettingInterestData.runners.some((runner) =>
    hasInfo(
      picksType === TIMEFORM_PICKS
        ? get(runner, "timeform.freePick")
        : get(runner, "handicapping.freePick")
    )
  );

const processBettingInterests = (
  raceData = [],
  raceTypeCode = "T",
  picksType = TRACKMASTER_PICKS
) =>
  raceData
    .filter((bettingInterestData) =>
      hasPicksInfo(bettingInterestData, picksType)
    )
    .map((bettingInterestData) => {
      const runnerAndPicksInfoStruct =
        getPickInfo(bettingInterestData, picksType) || [];
      const runnerOddsInfo = getOddsInfo(bettingInterestData);

      return runnerAndPicksInfoStruct.map((runnerAndPicksInfo) => ({
        info: runnerAndPicksInfo.pickInfo.info,
        number: runnerAndPicksInfo.pickInfo.number,
        horseName: runnerAndPicksInfo.runnerInfo.horseName,
        runnerId: runnerAndPicksInfo.runnerInfo.runnerId,
        raceTypeCode,
        scratched: runnerAndPicksInfo.runnerInfo.scratched,
        isMorningLine: runnerOddsInfo.isMorningLine,
        currentOddsNumerator: runnerOddsInfo.currentOddsNumerator,
        currentOddsDenominator: runnerOddsInfo.currentOddsDenominator
      }));
    });

const prepDataForPicks = (
  bettingInsterestData,
  raceTypeCode,
  picksType = TRACKMASTER_PICKS
) =>
  sortBy(
    flatMap(
      processBettingInterests(bettingInsterestData, raceTypeCode, picksType)
    ),
    ["number"]
  );

export const filterPicksBettingInterests = (
  raceData,
  picksType = TRACKMASTER_PICKS
) => {
  const biWithPicks = [];

  if (!!raceData && raceData.length > 0) {
    raceData
      .filter((bettingInterestData) =>
        hasPicksInfo(bettingInterestData, picksType)
      )
      .forEach((bi) => {
        bi.runners.forEach((runner) => {
          const freePick =
            picksType === TIMEFORM_PICKS
              ? get(runner, "timeform.freePick")
              : get(runner, "handicapping.freePick");

          if (hasInfo(freePick)) {
            biWithPicks[get(freePick, "number", 0) - 1] = {
              biNumber: bi.biNumber,
              currentOdds: bi.currentOdds,
              morningLineOdds: bi.morningLineOdds,
              isFavorite: bi.isFavorite,
              runners: [
                {
                  horseName: get(runner, "horseName"),
                  runnerId: get(runner, "runnerId"),
                  scratched: get(runner, "scratched"),
                  masterPickNumber: get(freePick, "number"),
                  masterPickInfo: get(freePick, "info", "") || ""
                }
              ]
            };
          }
        });
      });
  }

  return biWithPicks;
};

export const getTrackDataSourceTitle = (trackDataFromTimeform, withPicks) => {
  let title = "Trackmaster";

  if (trackDataFromTimeform === TRACK_DATA_SOURCE_GSA) {
    title = "Timeform 1-2-3";
  } else if (trackDataFromTimeform === TRACK_DATA_SOURCE_SRW) {
    title = "Free";
  }

  return `${title}${withPicks ? " Picks" : ""}`;
};

export default prepDataForPicks;
