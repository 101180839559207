import { gql } from "@apollo/client";

export const TalentPickRaceFragment = {
  entry: gql`
    fragment BettingInterests on Race {
      bettingInterests {
        biNumber
        saddleColor
        numberColor
        runners {
          runnerId
          entityRunnerId
          scratched
        }
      }
    }
  `
};
