import { WatchQueryFetchPolicy } from "@apollo/client";
import { get } from "lodash";

import type {
  RaceCardBettingInterest,
  RaceOdds,
  RacePanelLink
} from "@tvg/ts-types/Race";
import type { WagerProfile } from "@tvg/ts-types/User";
import tvgConf from "@tvg/conf";
import { MAX_RESULTS, POLL_INTERVAL } from "../utils/constants";

interface ApolloProps {
  wagerProfile: WagerProfile;
  renderedCardCount?: number;
}

const conf = tvgConf();

export const getDecimalOddsValue = ({
  numerator,
  denominator
}: RaceOdds): number => {
  const decimal =
    denominator && numerator ? numerator / denominator : numerator;
  return !decimal || Number.isNaN(decimal) ? Number.MAX_VALUE : decimal;
};

export const getLowestOdd = (
  bettingInterests: RaceCardBettingInterest[]
): number => {
  let lowestOdd = Number.MAX_VALUE;

  try {
    bettingInterests.forEach((bettingInterest) => {
      if (bettingInterest.runners.some((runner) => !runner.scratched)) {
        const myOdd = bettingInterest.currentOdds
          ? getDecimalOddsValue(bettingInterest.currentOdds)
          : Number.MAX_VALUE;
        if (myOdd < lowestOdd) {
          lowestOdd = myOdd;
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
  return lowestOdd;
};

export const isFavorite = (
  currentOdds: RaceOdds,
  lowestOdd: number
): boolean => {
  const odd = currentOdds ? getDecimalOddsValue(currentOdds) : Number.MAX_VALUE;
  return odd === lowestOdd && odd !== 99;
};

export const isFavoriteBIScratched = (
  bettingInterests: RaceCardBettingInterest[]
): boolean =>
  bettingInterests.every((bettingInterest) =>
    bettingInterest.isFavorite
      ? bettingInterest.runners.every((runner) => runner.scratched)
      : true
  );

const getRunners = (bettingInterest: RaceCardBettingInterest) =>
  bettingInterest.runners.filter((runner) => !runner.scratched);

const getCoupledEntries = (
  bettingInterest: RaceCardBettingInterest,
  addBettingInterests: RaceCardBettingInterest[]
) => {
  const runnersDecoupled: RaceCardBettingInterest[] = [];
  bettingInterest.runners.forEach((runner) =>
    runnersDecoupled.push({ ...bettingInterest, runners: [runner] })
  );

  return addBettingInterests.concat(runnersDecoupled.splice(1, 1));
};

export const buildRaces = (races: RacePanelLink[]): RacePanelLink[] =>
  (races || []).map((race) => {
    let addBettingInterests: RaceCardBettingInterest[] = [];
    let lowestOdd = Number.MIN_VALUE;
    const useLowestOddFavorite = isFavoriteBIScratched(
      race.bettingInterests || []
    );

    if (useLowestOddFavorite) {
      lowestOdd = getLowestOdd(race.bettingInterests || []);
    }

    const sortedPromos = [...(race.promos || [])].sort(
      (a, b) => +b.isAboveTheLine - +a.isAboveTheLine
    );

    return {
      ...race,
      id: `${race.trackCode}-${race.raceNumber}`,
      track: {
        code: race.trackCode,
        name: race.trackName,
        location: { ...get(race, "track.trackLocation", {}) }
      },
      promos: sortedPromos,
      numRunners: race.runnerNumber,
      bettingInterests: (race.bettingInterests || [])
        .map((bettingInterest) => {
          if (bettingInterest.runners.length > 1) {
            addBettingInterests = getCoupledEntries(
              bettingInterest,
              addBettingInterests
            );
          }
          return {
            ...bettingInterest,
            isFavorite: useLowestOddFavorite
              ? isFavorite(bettingInterest.currentOdds, lowestOdd)
              : bettingInterest.isFavorite,
            runners: [bettingInterest.runners[0]]
          };
        })
        .concat(addBettingInterests)
        .map((bettingInterest) => {
          const runners = getRunners(bettingInterest);
          return { ...bettingInterest, runners };
        })
        .filter((bettingInterest) => bettingInterest.runners.length)
        .sort(
          (bettingInterest, nextBettingInterest) =>
            getDecimalOddsValue(
              bettingInterest?.currentOdds || { numerator: 99, denominator: 0 }
            ) -
            getDecimalOddsValue(
              nextBettingInterest?.currentOdds || {
                numerator: 99,
                denominator: 0
              }
            )
        )
        .slice(0, 3)
    };
  });

export default {
  options: (props: ApolloProps) => {
    const variables = {
      ...conf.graphContext(),
      wagerProfile: props.wagerProfile,
      results: props.renderedCardCount || MAX_RESULTS
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      ssr: false,
      variables
    };
  }
};
