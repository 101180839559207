import React from "react";
import ReactJWPlayer from "@jwplayer/jwplayer-react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import type { Props } from "./types";
import { captionOptions } from "./constants";
import { VIDEO } from "../../constants";
import { JWPlayerFDRSkin } from "./styled-components";

const defaultConfig = {
  autostart: "viewable",
  mute: true,
  captions: captionOptions,
  renderCaptionsNatively: false,
  allowFullscreen: true
};

const VideoPlayer = ({ item, qaLabel = "" }: Props) => (
  <>
    <JWPlayerFDRSkin />
    <ReactJWPlayer
      library={item.video.playerScript}
      playlist={[
        {
          image: item.video.thumbnail,
          file: item.video.src,
          tracks: item.video.tracks
        }
      ]}
      width="100%"
      height={item.video.height}
      config={defaultConfig}
      responsive
      aspectratio="16:9"
      data-qa-label={buildQaLabel([qaLabel, VIDEO])}
    />
  </>
);

export default VideoPlayer;
