import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import type { Race } from "@tvg/ts-types/Race";
import { getWagerProfile } from "@urp/store-selectors";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import { getShouldShowSilks, getCardIndex } from "../utils";
import {
  CARD_COUNT_DESKTOP,
  CARD_COUNT_MOBILE,
  CARD_COUNT_TABLET,
  MAX_RESULTS
} from "../utils/constants";
import { GET_TOP_RACES } from "../graphql/queries/TopRaces";
import ApolloOptions, { buildRaces } from "../graphql/options.graph";

const useTopRacesCarousel = () => {
  const wagerProfile = useSelector(getWagerProfile);
  const [races, setRaces] = useState<Array<Race>>([]);
  const [racesWithSilks, setRacesWithSilks] = useState<Array<Array<boolean>>>(
    []
  );
  const [topRacesLoading, setTopRacesLoading] = useState(true);
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const isDesktop = useMediaQuery(breakpoints.desktop.min.xl);
  // this value define when new races should be query to the BE
  const askRacesCount = isMobile ? 2 : 4;
  const cardCountInitial =
    (isMobile && CARD_COUNT_MOBILE) ||
    (isDesktop && CARD_COUNT_DESKTOP) ||
    CARD_COUNT_TABLET;
  const [renderedCardCount, setRenderedCardCount] = useState(cardCountInitial);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { data, loading } = useQuery(
    GET_TOP_RACES,
    ApolloOptions.options({
      wagerProfile,
      renderedCardCount:
        races.length > 0 && renderedCardCount > races.length - askRacesCount
          ? renderedCardCount
          : races.length
    })
  );

  useEffect(() => {
    if (data?.races?.length > 0) {
      // @ts-ignore
      const newRaces: Array<Race> = buildRaces(data.races);
      setRaces(newRaces);
      setTopRacesLoading(false);
      getShouldShowSilks(newRaces).then((shouldShowSilksResponse) => {
        setRacesWithSilks(shouldShowSilksResponse);
      });
    }
  }, [data?.races]);

  const handleScroll = (scrollContainer: HTMLDivElement) => {
    const newCardIndex = getCardIndex(scrollContainer);
    setRenderedCardCount((prevCount) =>
      newCardIndex > prevCount - askRacesCount
        ? prevCount + MAX_RESULTS
        : prevCount
    );
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", () =>
        handleScroll(scrollContainer)
      );
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", () =>
          handleScroll(scrollContainer)
        );
      }
    };
  }, [scrollContainerRef.current]);

  return {
    loading: loading || topRacesLoading,
    handleScroll,
    races,
    racesWithSilks,
    scrollContainerRef,
    renderedCardCount
  };
};

export default useTopRacesCarousel;
