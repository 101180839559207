import gql from "graphql-tag";

const TrackFragment = {
  entry: gql`
    fragment Track on Race {
      track {
        id
        trackLocation: location {
          country
          city
          state
        }
      }
    }
  `
};

export default TrackFragment;
