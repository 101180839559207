import React from "react";

const RacingAndSportsIcon = () => (
  <svg
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.3 119.87"
    data-qa-label="racingAndSportsIcon"
  >
    <path
      fill="#b1b235"
      d="M197.05,42.89c-.22-1.54-.66-2.64-1.54-3.74-.66-.88-1.54-1.76-2.64-2.2-1.98-1.1-4.4-1.54-7.26-1.54-3.08,0-5.5,.44-6.82,1.32s-2.2,1.98-2.2,3.74c0,1.54,.66,2.86,2.2,3.96s3.3,1.98,5.72,2.86c2.42,.88,5.06,1.54,7.92,2.2,2.86,.66,5.94,1.54,9.02,2.42s6.16,1.98,9.02,3.08c2.86,1.32,5.5,2.86,7.92,4.84,2.42,1.98,4.18,4.4,5.72,7.04,1.54,2.86,2.2,6.16,2.2,10.12,0,5.06-1.1,9.46-3.08,13.2-1.98,3.74-4.84,6.82-8.36,9.24-3.52,2.42-7.92,4.18-12.76,5.5-5.06,1.32-10.34,1.76-16.28,1.76s-10.78-.88-15.4-2.42c-4.62-1.54-8.58-3.74-11.88-6.38-3.3-2.64-5.94-5.72-7.7-9.24-1.98-3.52-2.86-7.26-3.08-11h27.05c.66,3.08,2.2,5.28,4.62,6.82,1.1,.66,2.42,1.32,3.74,1.54,1.54,.44,2.86,.44,4.18,.44,3.52,0,6.38-.44,8.58-1.54s3.08-2.64,3.08-4.84-1.32-3.74-3.74-5.06c-2.42-1.1-5.5-2.2-9.24-3.08-3.74-.88-7.7-1.98-12.1-3.08-4.4-1.1-8.36-2.64-12.1-4.62-3.74-1.98-6.82-4.62-9.24-8.14-2.42-3.3-3.74-7.7-3.74-13.42,0-4.62,.88-8.58,2.86-12.1s4.4-6.38,7.7-8.58c3.3-2.2,7.26-3.96,11.88-5.06s9.46-1.76,14.96-1.76c5.94,0,11.22,.66,15.62,2.2,4.4,1.54,8.14,3.3,11,5.94,3.08,2.42,5.28,5.28,7.04,8.8,1.76,3.3,2.86,6.82,3.3,10.56h-26.17v.22Z"
    />
    <g>
      <polygon
        fill="#b1b235"
        points="96.93 45.53 96.93 56.3 112.77 56.3 96.93 69.06 96.93 80.5 131.68 80.5 131.68 69.06 116.06 69.06 131.68 56.52 131.68 45.53 96.93 45.53"
      />
      <path
        fill="#b1b235"
        d="M96.93,86v18.25s.88,15.62,17.38,15.62,17.38-15.62,17.38-15.62v-18.25h-34.75Zm24.85,16.06s-.66,6.82-7.48,6.82-7.48-6.82-7.48-6.82v-4.62h14.96v4.62h0Z"
      />
      <path
        fill="#b1b235"
        d="M96.93,0V13.2l3.96,1.76v13.2l-3.96,1.98v12.32l34.75-14.52V15.62L96.93,0Zm12.76,24.41v-6.6l9.9,3.3-9.9,3.3Z"
      />
    </g>
    <path
      fill="#004693"
      d="M83.14,44.87c.44-18.69-18.91-24.19-27.71-24.19H13.64L0,106.23H23.53l5.28-31.23h7.48l13.2,31.23h26.17l-15.62-32.77s22.65-8.36,23.09-28.59Zm-28.37,10.34c-2.2,1.98-3.96,2.86-6.82,2.86H31.45l3.08-18.91h11.44c2.86,0,6.16-.66,8.8,1.1,2.42,1.54,3.52,4.62,3.52,7.26,0,3.3-1.32,5.94-3.52,7.7Z"
    />
  </svg>
);

export default RacingAndSportsIcon;
