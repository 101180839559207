import { RaceStatusEnum, RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { selectionsNbr } from "./selections";

export const emptyState = {
  title: "No FDTV picks available",
  description: "There are no picks available for today"
};

export const talentPicksPage = {
  title: "FDTV Picks",
  picksResulted: "Resulted Races",
  emptyState,
  trackFilter: {
    filterLabel: "Track",
    selectedLabel: "All Tracks",
    selectedValue: ""
  },
  expertFilter: {
    filterLabel: "Expert",
    selectedLabel: "All Experts",
    selectedValue: ""
  }
};

export const talentPicksNoData = {
  loading: false,
  talentPicks: [],
  talentPicksActive: [],
  talentPicksResulted: [],
  trackOptions: [
    {
      label: "All Tracks",
      value: ""
    }
  ],
  expertOptions: [
    {
      label: "All Experts",
      value: ""
    }
  ],
  trackFilter: {
    label: "All Tracks",
    value: ""
  },
  expertFilter: {
    label: "All Experts",
    value: ""
  }
};

const bettingInterests = [
  {
    biNumber: 1,
    saddleColor: "#BC0000",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "1",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 2,
    saddleColor: "#C3CDD4",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "2",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 3,
    saddleColor: "#253B84",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "3",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 4,
    saddleColor: "#C1A800",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "4",
        entityRunnerId: "1192456",
        scratched: true
      }
    ]
  },
  {
    biNumber: 5,
    saddleColor: "#1C5B1B",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "5",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 6,
    saddleColor: "#252525",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "6",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 7,
    saddleColor: "#B63D13",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "7",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 8,
    saddleColor: "#B27692",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "8",
        entityRunnerId: "1192456",
        scratched: true
      }
    ]
  },
  {
    biNumber: 9,
    saddleColor: "#13A19C",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "9",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 10,
    saddleColor: "#610F9A",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "10",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 11,
    saddleColor: "#6B6B6B",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "11",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 12,
    saddleColor: "#2F9A3B",
    numberColor: "#000000",
    runners: [
      {
        runnerId: "12",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 13,
    saddleColor: "#413733",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "13",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  },
  {
    biNumber: 14,
    saddleColor: "#790031",
    numberColor: "#FFFFFF",
    runners: [
      {
        runnerId: "14",
        entityRunnerId: "1192456",
        scratched: false
      }
    ]
  }
];

export const talentPicksData = {
  loading: false,
  talentPicksActive: [
    {
      id: "26520",
      selections: [["1"], ["2"]],
      favorites: [[], []],
      amount: "1.0",
      price: "1.0",
      wagerType: {
        id: 310,
        abbreviation: "DB",
        legCount: 2,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "XX6",
      trackName: "XX6 Test Track",
      race: 2,
      raceType: "T",
      raceStatus: "IC",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 6
      },
      published: true,
      racePostTime: "2023-03-15T13:00:00Z",
      mtp: 13,
      highlighted: null
    },
    {
      id: "26521",
      selections: [["1"], ["2"], ["3"]],
      favorites: [[], [], []],
      amount: "0.5",
      price: "0.5",
      wagerType: {
        id: 330,
        abbreviation: "P3",
        legCount: 3,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "XX1",
      trackName: "XX1 Test Track",
      race: 2,
      raceType: "T",
      raceStatus: "IC",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 6
      },
      published: true,
      racePostTime: "2023-03-15T13:00:00Z",
      mtp: 13,
      highlighted: null
    }
  ],
  talentPicksResulted: [
    {
      id: "26503",
      selections: [["4", "7"]],
      favorites: [],
      amount: "1.0",
      price: "6.0",
      wagerType: {
        id: 70,
        abbreviation: "WPS",
        legCount: 1,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "XX6",
      trackName: "XX6 Test Track",
      race: 11,
      raceType: "T",
      raceStatus: "RO",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 6
      },
      published: true,
      racePostTime: "2023-03-15T10:50:05Z",
      mtp: 0,
      highlighted: null
    }
  ],
  trackOptions: [
    {
      label: "All Tracks",
      value: ""
    },
    {
      value: "XX6",
      label: "XX6 Test Track"
    },
    {
      value: "XX1",
      label: "XX1 Test Track"
    },
    {
      value: "XX3",
      label: "XX3 Test Track"
    },
    {
      value: "XX5",
      label: "XX5 Test Track"
    }
  ],
  expertOptions: [
    {
      label: "All Experts",
      value: ""
    },
    {
      value: "39",
      label: "Joe"
    },
    {
      value: "55",
      label: "Pedro Cavalos"
    },
    {
      value: "46",
      label: "Bino"
    }
  ],
  trackFilter: {
    label: "All Tracks",
    value: ""
  },
  expertFilter: {
    label: "All Experts",
    value: ""
  }
};

export const talentPicksDataWithRaceOFF = {
  loading: false,
  talentPicksActive: [
    {
      id: "26520",
      selections: [["1"], ["2"]],
      favorites: [[], []],
      amount: "1.0",
      price: "1.0",
      wagerType: {
        id: 310,
        abbreviation: "DB",
        legCount: 2,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "XX6",
      trackName: "XX6 Test Track",
      race: 2,
      raceType: "T",
      raceStatus: "IC",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 6
      },
      published: true,
      racePostTime: "2023-03-15T13:00:00Z",
      mtp: 13,
      highlighted: null
    },
    {
      id: "26521",
      selections: [["1"], ["2"], ["3"]],
      favorites: [[], [], []],
      amount: "0.5",
      price: "0.5",
      wagerType: {
        id: 330,
        abbreviation: "P3",
        legCount: 3,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "XX1",
      trackName: "XX1 Test Track",
      race: 2,
      raceType: "T",
      raceStatus: "SK",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 6
      },
      published: true,
      racePostTime: "2023-03-15T13:00:00Z",
      mtp: 13,
      highlighted: null
    }
  ],
  talentPicksResulted: []
};

export const talentPicksQueryData = {
  talentPicksList: [
    {
      id: "26545",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13
      },

      race: {
        id: "XX6-15",
        number: "15",
        track: {
          code: "XX6",
          name: "XX6 Test Track"
        },
        mtp: 0,
        postTime: "2023-03-16T12:10:05Z",
        isGreyhound: false,
        status: {
          code: "RO"
        },
        type: {
          code: "T"
        },
        highlighted: null,
        numRunners: 10,
        numWagerableRunners: 10,
        bettingInterests
      },
      wagerType: {
        type: {
          id: 370,
          code: "P7"
        },
        legCount: 7,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      amount: "1.0",
      price: "1.0",
      selections: [
        [
          {
            order: 1
          }
        ],
        [
          {
            order: 2
          }
        ],
        [
          {
            order: 3
          }
        ],
        [
          {
            order: 4
          }
        ],
        [
          {
            order: 6
          }
        ],
        [
          {
            order: 7
          }
        ],
        [
          {
            order: 8
          }
        ]
      ],
      favorites: [[], [], [], [], [], [], []]
    },
    {
      id: "26549",
      talent: {
        talentId: "46",
        name: "Bino",
        pictureUrl:
          "static/image/fa823c40-2ea5-44b1-aa03-83f73d4b3f57_download.jpeg",
        phrases: ["Yoooo"],
        pickCount: 1
      },

      race: {
        id: "CAE-2",
        number: "2",
        track: {
          code: "CAE",
          name: "FR - Caen"
        },
        mtp: 0,
        postTime: "2023-03-16T12:25:00Z",
        isGreyhound: false,
        status: {
          code: "O"
        },
        type: {
          code: "H"
        },
        highlighted: null,
        numRunners: 9,
        numWagerableRunners: 9,
        bettingInterests
      },
      wagerType: {
        type: {
          id: 80,
          code: "QN"
        },
        legCount: 1,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      amount: "1.0",
      price: "1.0",
      selections: [
        [
          {
            order: 1
          },
          {
            order: 2
          }
        ]
      ],
      favorites: []
    },
    {
      id: "26547",
      talent: {
        talentId: "39",
        name: "Joe",
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13
      },

      race: {
        id: "XX6-16",
        number: "16",
        track: {
          code: "XX6",
          name: "XX6 Test Track"
        },
        mtp: 0,
        postTime: "2023-03-16T12:30:02Z",
        isGreyhound: false,
        status: {
          code: "RO"
        },
        type: {
          code: "T"
        },
        highlighted: null,
        numRunners: 8,
        numWagerableRunners: 8,
        bettingInterests
      },
      wagerType: {
        type: {
          id: 60,
          code: "PS"
        },
        legCount: 1,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      amount: "1.0",
      price: "6.0",
      selections: [
        [
          {
            order: 1
          },
          {
            order: 2
          },
          {
            order: 3
          }
        ]
      ],
      favorites: []
    },
    {
      id: "26550",
      talent: {
        talentId: "78",
        name: "Angelita Heaney",
        pictureUrl:
          "static/image/d29e560c-d9bf-47f4-b05b-5907bc2d5892_Screenshot 2019-12-05 at 17.27.22.png",
        phrases: ["YEAHHHHHHH"],
        pickCount: 4
      },

      race: {
        id: "ABU-1",
        number: "1",
        track: {
          code: "ABU",
          name: "Abu Dhabi"
        },
        mtp: 0,
        postTime: "2023-03-16T13:00:00Z",
        isGreyhound: false,
        status: {
          code: "O"
        },
        type: {
          code: "T"
        },
        highlighted: null,
        numRunners: 7,
        numWagerableRunners: 7,
        bettingInterests
      },
      wagerType: {
        type: {
          id: 110,
          code: "EX"
        },
        legCount: 1,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      amount: "2.0",
      price: "8.0",
      selections: [
        [
          {
            order: 1
          },
          {
            order: 2
          }
        ],
        [
          {
            order: 3
          },
          {
            order: 4
          }
        ]
      ],
      favorites: [
        [
          {
            order: 2
          }
        ]
      ]
    }
  ]
};

export const talentPicksDataProps = {
  talentPicks: [
    {
      amount: "1.0",
      favorites: [[], [], [], [], [], [], []],
      highlighted: null,
      id: "26545",
      mtp: 0,
      price: "1.0",
      published: true,
      raceId: "XX6-15",
      race: 15,
      racePostTime: "2023-03-16T12:10:05Z",
      raceStatus: "RO",
      raceType: "T",
      numRunnersAllRaces: ["10"],
      selections: [["1"], ["2"], ["3"], ["4"], ["6"], ["7"], ["8"]],
      talent: {
        name: "Joe",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13,
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        talentId: "39"
      },
      track: "XX6",
      trackName: "XX6 Test Track",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          }
        ],
        [
          {
            number: "2",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "3",
            numberColor: "#FFFFFF",
            saddleColor: "#253B84",
            scratched: false
          }
        ],
        [
          {
            number: "4",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "6",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "7",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "8",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "P7",
        id: 370,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 7
      }
    },
    {
      amount: "1.0",
      favorites: [],
      highlighted: null,
      id: "26549",
      mtp: 0,
      numRunnersAllRaces: ["9"],
      price: "1.0",
      published: true,
      race: 2,
      racePostTime: "2023-03-16T12:25:00Z",
      raceId: "CAE-2",
      raceStatus: "O",
      raceType: "H",
      selections: [["1", "2"]],
      talent: {
        name: "Bino",
        phrases: ["Yoooo"],
        pickCount: 1,
        pictureUrl:
          "static/image/fa823c40-2ea5-44b1-aa03-83f73d4b3f57_download.jpeg",
        talentId: "46"
      },
      track: "CAE",
      trackName: "FR - Caen",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "QN",
        id: 80,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    },
    {
      amount: "1.0",
      favorites: [],
      highlighted: null,
      id: "26547",
      mtp: 0,
      numRunnersAllRaces: ["8"],
      price: "6.0",
      published: true,
      race: 16,
      racePostTime: "2023-03-16T12:30:02Z",
      raceStatus: "RO",
      raceType: "T",
      selections: [["1", "2", "3"]],
      talent: {
        name: "Joe",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13,
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        talentId: "39"
      },
      raceId: "XX6-16",
      track: "XX6",
      trackName: "XX6 Test Track",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          },
          {
            number: "3",
            numberColor: "#FFFFFF",
            saddleColor: "#253B84",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "PS",
        id: 60,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    },
    {
      amount: "2.0",
      favorites: [["2"]],
      highlighted: null,
      id: "26550",
      mtp: 0,
      numRunnersAllRaces: ["7"],
      raceId: "ABU-1",
      price: "8.0",
      published: true,
      race: 1,
      racePostTime: "2023-03-16T13:00:00Z",
      raceStatus: "O",
      raceType: "T",
      selections: [
        ["1", "2"],
        ["3", "4"]
      ],
      talent: {
        name: "Angelita Heaney",
        phrases: ["YEAHHHHHHH"],
        pickCount: 4,
        pictureUrl:
          "static/image/d29e560c-d9bf-47f4-b05b-5907bc2d5892_Screenshot 2019-12-05 at 17.27.22.png",
        talentId: "78"
      },
      track: "ABU",
      trackName: "Abu Dhabi",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          }
        ],
        [
          {
            number: "3",
            numberColor: "#FFFFFF",
            saddleColor: "#253B84",
            scratched: false
          },
          {
            number: "4",
            numberColor: "#000000",
            saddleColor: "#C1A800",
            scratched: true
          }
        ]
      ],
      wagerType: {
        abbreviation: "EX",
        id: 110,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    }
  ],
  talentsList: [
    {
      name: "Joe",
      phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
      pickCount: 13,
      pictureUrl:
        "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
      talentId: "39"
    },
    {
      name: "Bino",
      phrases: ["Yoooo"],
      pickCount: 1,
      pictureUrl:
        "static/image/fa823c40-2ea5-44b1-aa03-83f73d4b3f57_download.jpeg",
      talentId: "46"
    },
    {
      name: "Angelita Heaney",
      phrases: ["YEAHHHHHHH"],
      pickCount: 4,
      pictureUrl:
        "static/image/d29e560c-d9bf-47f4-b05b-5907bc2d5892_Screenshot 2019-12-05 at 17.27.22.png",
      talentId: "78"
    }
  ]
};
export const talentPicksDataWithoutVisualsProps = {
  talentPicks: [
    {
      amount: "1.0",
      favorites: [[], [], [], [], [], [], []],
      highlighted: null,
      id: "26545",
      mtp: 0,
      price: "1.0",
      published: true,
      raceId: "XX6-15",
      race: 15,
      racePostTime: "2023-03-16T12:10:05Z",
      raceStatus: "RO",
      raceType: "T",
      numRunnersAllRaces: ["10"],
      selections: [["1"], ["2"], ["3"], ["4"], ["6"], ["7"], ["8"]],
      talent: {
        name: "Joe",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13,
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        talentId: "39"
      },
      track: "XX6",
      trackName: "XX6 Test Track",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          }
        ],
        [
          {
            number: "2",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "3",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "4",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "6",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "7",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ],
        [
          {
            number: "8",
            numberColor: "",
            saddleColor: "",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "P7",
        id: 370,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 7
      }
    },
    {
      amount: "1.0",
      favorites: [],
      highlighted: null,
      id: "26549",
      mtp: 0,
      numRunnersAllRaces: ["9"],
      price: "1.0",
      published: true,
      race: 2,
      racePostTime: "2023-03-16T12:25:00Z",
      raceId: "CAE-2",
      raceStatus: "O",
      raceType: "H",
      selections: [["1", "2"]],
      talent: {
        name: "Bino",
        phrases: ["Yoooo"],
        pickCount: 1,
        pictureUrl:
          "static/image/fa823c40-2ea5-44b1-aa03-83f73d4b3f57_download.jpeg",
        talentId: "46"
      },
      track: "CAE",
      trackName: "FR - Caen",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "QN",
        id: 80,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    },
    {
      amount: "1.0",
      favorites: [],
      highlighted: null,
      id: "26547",
      mtp: 0,
      numRunnersAllRaces: ["8"],
      price: "6.0",
      published: true,
      race: 16,
      racePostTime: "2023-03-16T12:30:02Z",
      raceStatus: "RO",
      raceType: "T",
      selections: [["1", "2", "3"]],
      talent: {
        name: "Joe",
        phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
        pickCount: 13,
        pictureUrl:
          "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
        talentId: "39"
      },
      raceId: "XX6-16",
      track: "XX6",
      trackName: "XX6 Test Track",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          },
          {
            number: "3",
            numberColor: "#FFFFFF",
            saddleColor: "#253B84",
            scratched: false
          }
        ]
      ],
      wagerType: {
        abbreviation: "PS",
        id: 60,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    },
    {
      amount: "2.0",
      favorites: [["2"]],
      highlighted: null,
      id: "26550",
      mtp: 0,
      numRunnersAllRaces: ["7"],
      raceId: "ABU-1",
      price: "8.0",
      published: true,
      race: 1,
      racePostTime: "2023-03-16T13:00:00Z",
      raceStatus: "O",
      raceType: "T",
      selections: [
        ["1", "2"],
        ["3", "4"]
      ],
      talent: {
        name: "Angelita Heaney",
        phrases: ["YEAHHHHHHH"],
        pickCount: 4,
        pictureUrl:
          "static/image/d29e560c-d9bf-47f4-b05b-5907bc2d5892_Screenshot 2019-12-05 at 17.27.22.png",
        talentId: "78"
      },
      track: "ABU",
      trackName: "Abu Dhabi",
      visualSelections: [
        [
          {
            number: "1",
            numberColor: "#FFFFFF",
            saddleColor: "#BC0000",
            scratched: false
          },
          {
            number: "2",
            numberColor: "#000000",
            saddleColor: "#C3CDD4",
            scratched: false
          }
        ],
        [
          {
            number: "3",
            numberColor: "#FFFFFF",
            saddleColor: "#253B84",
            scratched: false
          },
          {
            number: "4",
            numberColor: "#000000",
            saddleColor: "#C1A800",
            scratched: true
          }
        ]
      ],
      wagerType: {
        abbreviation: "EX",
        id: 110,
        isBox: false,
        isKey: false,
        isWheel: false,
        legCount: 1
      }
    }
  ],
  talentsList: [
    {
      name: "Joe",
      phrases: ["Joe know's stuff", "Joe know's stuff but not everything"],
      pickCount: 13,
      pictureUrl:
        "static/image/6cf76649-b148-4027-9aed-5d41d088117d_tvgpicks_placeholder.png",
      talentId: "39"
    },
    {
      name: "Bino",
      phrases: ["Yoooo"],
      pickCount: 1,
      pictureUrl:
        "static/image/fa823c40-2ea5-44b1-aa03-83f73d4b3f57_download.jpeg",
      talentId: "46"
    },
    {
      name: "Angelita Heaney",
      phrases: ["YEAHHHHHHH"],
      pickCount: 4,
      pictureUrl:
        "static/image/d29e560c-d9bf-47f4-b05b-5907bc2d5892_Screenshot 2019-12-05 at 17.27.22.png",
      talentId: "78"
    }
  ]
};

export const talentPick = {
  id: "1",
  amount: "10",
  price: "10",
  wagerType: {
    id: 1,
    abbreviation: WagerTypeCodesEnum.EXACTA,
    legCount: 1,
    isBox: false,
    isKey: false,
    isWheel: false
  },
  track: "AUB",
  trackName: "AUB - Ipswich",
  race: 1,
  raceType: RaceTypeCodeEnum.THOROUGHBRED,
  raceStatus: RaceStatusEnum.OPEN,
  talent: {
    talentId: "1",
    name: "Ken Rudulph",
    pictureUrl: "https://i.imgur.com/o9fpo46.png",
    popularity: undefined,
    tvgAccountId: undefined,
    phrases: undefined,
    isActive: undefined,
    pickCount: 1
  },
  published: false,
  racePostTime: "2022-05-10T10:20:00Z",
  mtp: 65,
  highlighted: null,
  selections: selectionsNbr,
  favorites: [],
  visualSelections: [
    [
      {
        number: "1",
        saddleColor: "",
        numberColor: "",
        scratched: false
      },
      {
        number: "1A",
        saddleColor: "",
        numberColor: "",
        scratched: false
      },
      {
        number: "4",
        saddleColor: "",
        numberColor: "",
        scratched: false
      }
    ],
    [
      {
        number: "1",
        saddleColor: "",
        numberColor: "",
        scratched: false
      },
      {
        number: "1A",
        saddleColor: "",
        numberColor: "",
        scratched: false
      }
    ]
  ]
};
