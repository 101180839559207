import React from "react";
import { Paragraph } from "@tvg/design-system";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Container, Row } from "./styled-components";
import type { Props } from "./types";
import { CONTAINER, DESCRIPTION, TEXT } from "../../constants";

const Description = ({ item, qaLabel = "" }: Props) => {
  const colors = useColorTokens();

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, DESCRIPTION, CONTAINER])}>
      <Paragraph
        qaLabel={buildQaLabel([qaLabel, DESCRIPTION, TEXT])}
        color={colors.content.strong}
        fontFamily="regular"
      >
        {item.description.title}
      </Paragraph>
      <Row>
        <Paragraph
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          qaLabel={buildQaLabel([qaLabel, DESCRIPTION, TEXT])}
          fontFamily="condensedRegular"
          textTransform="uppercase"
          fontSize={12}
          color={colors.content.subtle}
        >
          {item.description.category}
        </Paragraph>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, DESCRIPTION, TEXT])}
          fontFamily="condensedRegular"
          textTransform="uppercase"
          fontSize={12}
          color={colors.content.subtle}
        >
          {item.description.uploadedAt}
        </Paragraph>
      </Row>
    </Container>
  );
};

export default Description;
