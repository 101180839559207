import React from "react";
import QuickLinks from "@urp/quick-links";
import { TopRaces } from "@urp/popular-races";
import LegacyTopRaces from "@tvg/top-races";
import LegacyTopPools from "@tvg/top-pools";
import TopPools from "@urp/top-pools";
import { TalentPickList } from "@urp/talent-picks";
import TalentPicks from "@tvg/talent-picks";
import { isTvg5 } from "@tvg/utils/generalUtils";
import UpcomingRaces from "@tvg/upcoming-races";
import TopTracks from "@urp/top-tracks";
import { TopTracks as TracksBuilderTopTracks } from "@tvg/tracks-builder";
import PromosWidgetLegacy from "@tvg/promos-widget";
import PromosWidget from "@urp/promos-widget";
import { TipsAndPicks } from "@urp/tips-picks";
import PreviousWinnersLegacy from "@tvg/previous-winners";
import PreviousWinners from "@urp/previous-winners";
import SeoFooter from "@tvg/atomic-ui/_static/SeoFooter";
import PromosOnboarding from "@tvg/promos-onboarding";

const componentMap = {
  QUICK_LINKS: {
    type: "fixed",
    render: (props) => (
      <QuickLinks
        key="QUICK_LINKS"
        useQuickLinksExpanded={props.quickLinksExpanded}
        device={props.device}
        shouldUpdate={props.shouldUpdate}
      />
    )
  },
  PROMO_ONBOARDING: {
    type: "fixed",
    render: (props) => (
      <PromosOnboarding
        key="PROMO_ONBOARDING"
        isInternalComponent
        isHomePageSection
        componentName="progress"
        device={props.device}
        shouldUpdate={props.shouldUpdate}
      />
    )
  },
  TOP_RACES: {
    type: "full",
    render: (props, context) =>
      props.useTopRacesDeskDS ? (
        <TopRaces key="TOP_RACES" />
      ) : (
        <LegacyTopRaces
          key="TOP_RACES"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
          rdaClient={context.rdaClient}
        />
      )
  },
  UPCOMING_RACES: {
    type: "full",
    render: (props, context) => (
      <UpcomingRaces
        key="UPCOMING_RACES"
        title={props.title}
        device={props.device}
        shouldUpdate={props.shouldUpdate}
        rdaClient={context.rdaClient}
      />
    )
  },
  TIPS_PICKS: {
    type: "large",
    render: (props) =>
      props.useURPTipsAndPicks && <TipsAndPicks key="TIPS_PICKS" />
  },
  TOP_TRACKS: {
    type: "large",
    render: (props, context) =>
      props.useTopTracksDS ? (
        <TopTracks key="TOP_TRACKS" title={props.title} />
      ) : (
        <TracksBuilderTopTracks
          key="TOP_TRACKS"
          trackType="TOP_TRACKS"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
          rdaClient={context.rdaClient}
        />
      )
  },
  PROMO_WIDGET: {
    type: "small",
    render: (props) =>
      props.usePromosWidgetDS ? (
        <PromosWidget
          key="PROMO_WIDGET"
          isWidget
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
        />
      ) : (
        <PromosWidgetLegacy
          key="PROMO_WIDGET_LEGACY"
          isWidget
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
        />
      )
  },
  TOP_POOLS: {
    type: "small",
    render: (props) =>
      isTvg5() ? (
        <TopPools
          key="TOP_POOLS"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
        />
      ) : (
        <LegacyTopPools
          key="TOP_POOLS"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
        />
      )
  },
  TALENT_PICKS: {
    type: "large",
    render: (props) =>
      isTvg5() ? (
        <TalentPickList key="TALENT_PICKS" onHomePage />
      ) : (
        <TalentPicks
          key="TALENT_PICKS"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
          onHomePage
          onProgramPage={false}
        />
      )
  },
  PREVIOUS_WINNERS: {
    type: "small",
    render: (props) =>
      props.usePreviousWinnersDS ? (
        <PreviousWinners
          key="PREVIOUS_WINNERS"
          title={props.title}
          device={props.device}
        />
      ) : (
        <PreviousWinnersLegacy
          key="PREVIOUS_WINNERS"
          title={props.title}
          device={props.device}
          shouldUpdate={props.shouldUpdate}
        />
      )
  },
  SEO_FOOTER: {
    type: "large",
    render: (props) => (
      <SeoFooter
        key="SEO_FOOTER"
        title={props.title}
        device={props.device}
        shouldUpdate={props.shouldUpdate}
        message={props.message}
      />
    )
  },
  PROMO_BANNER_TOP: {
    type: "top",
    render: () => <div key="PROMO_BANNER_TOP" id="homepagePromoBanner_top" />
  },
  PROMO_BANNER_BOTTOM: {
    type: "bottom",
    render: () => (
      <div key="PROMO_BANNER_BOTTOM" id="homepagePromoBanner_bottom" />
    )
  },
  DEFAULT: {
    type: "default",
    render: () => <div key="default" />
  }
};

export default componentMap;
