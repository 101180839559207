import React, { FC, useEffect, useRef } from "react";
import { CarouselContainer, Header, ListItems } from "./styled-components";
import { CarouselProps } from "./types";
import { Paragraph } from "../../../src/components/typography";
import NavigationButton from "../NavigationButton";
import { usePreviousValue } from "./hooks";

export const Carousel: FC<CarouselProps> = ({
  items,
  numberVisibleCards = 3,
  qaLabel,
  onMount,
  onScroll,
  title = ""
}) => {
  const container = useRef<HTMLDivElement>(null);
  const prevItems = usePreviousValue(items);

  useEffect(() => {
    const scrollContainer = container.current;

    if (onMount && typeof onMount === "function" && scrollContainer) {
      onMount(scrollContainer);
    }
  }, [onMount]);

  useEffect(() => {
    const scrollContainer = container.current;

    if (onScroll && typeof onScroll === "function" && scrollContainer) {
      scrollContainer.addEventListener("scroll", (event) => {
        onScroll(scrollContainer);
        event.preventDefault();
        event.stopPropagation();
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", (event) => {
          if (onScroll) onScroll(scrollContainer);
          event.preventDefault();
          event.stopPropagation();
        });
      }
    };
  }, [onScroll, container.current]);

  useEffect(() => {
    const scrollContainer = container.current;

    if (scrollContainer && prevItems?.length === 0) {
      scrollContainer.scrollLeft = 0;
    }
  }, [items.length]);

  const onScrollRight = () => {
    if (container.current) {
      const cardWidth = (container.current.firstChild as HTMLElement)
        .offsetWidth;
      container.current.scrollLeft += cardWidth * numberVisibleCards;
    }
  };

  const onScrollLeft = () => {
    if (container.current) {
      const cardWidth = (container.current.firstChild as HTMLElement)
        .offsetWidth;
      container.current.scrollLeft -= cardWidth * numberVisibleCards;
    }
  };

  return (
    <CarouselContainer data-qa-label={`carousel-container-${qaLabel}`}>
      <Header>
        <Paragraph qaLabel="carousel-title" fontSize={18} fontFamily="bold">
          {title}
        </Paragraph>
        <NavigationButton
          containerRef={container}
          onLeftClick={onScrollLeft}
          onRightClick={onScrollRight}
          shouldRefresh={items.length !== container.current?.childNodes.length}
        />
      </Header>
      <ListItems data-qa-label={`carousel-items-${qaLabel}`} ref={container}>
        {items}
      </ListItems>
    </CarouselContainer>
  );
};
