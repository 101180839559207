import React from "react";
import moment from "moment";

import {
  MinutesText,
  RaceOff,
  RaceTime,
  Resulted,
  TextDescription
} from "./styled-components";

import { MtpProps } from "./types";
import { useQaLabel } from "../../hooks";
import { useColor } from "../../theming";

const Mtp: React.FC<MtpProps> = ({
  status,
  mtp,
  postTime,
  isCollapsed = false,
  hasFullText = false,
  qaLabel = "mtp",
  color = "blue.300",
  shouldUserColorTokens,
  ...rest
}) => {
  const testProps = useQaLabel(qaLabel);
  const defaultColor = shouldUserColorTokens
    ? (color as string)
    : useColor(color as string);

  if (status === "SK") {
    return (
      <RaceOff
        isCollapsed={isCollapsed}
        hasFullText={hasFullText}
        {...testProps}
        {...rest}
      >
        {isCollapsed && !hasFullText ? "OFF" : "Race Off"}
      </RaceOff>
    );
  }

  if (status === "RO") {
    return !isCollapsed || hasFullText ? (
      <Resulted
        isCollapsed={isCollapsed}
        hasFullText={hasFullText}
        defaultColor={defaultColor}
        {...testProps}
        {...rest}
      >
        Resulted
      </Resulted>
    ) : null;
  }

  const getMTPSufix = (collapsed: boolean, hasFText: boolean): string => {
    if (collapsed && hasFText) {
      return " MTP";
    }

    if (collapsed && !hasFText) {
      return "m";
    }

    return " ";
  };

  return mtp > 59 ? (
    <RaceTime
      hasFullText={hasFullText}
      isCollapsed={isCollapsed}
      defaultColor={defaultColor}
      {...testProps}
      {...rest}
    >
      {moment(postTime).format("hh:mm A")}
    </RaceTime>
  ) : (
    <MinutesText
      mtp={mtp}
      isCollapsed={isCollapsed}
      defaultColor={defaultColor}
      {...testProps}
      {...rest}
    >
      {mtp > 9 || isCollapsed ? mtp : `0${mtp}`}
      {getMTPSufix(isCollapsed, hasFullText)}
      {!isCollapsed && (
        <TextDescription
          mtp={mtp}
          defaultColor={defaultColor}
          data-qa-label="mtp-text"
        >
          {mtp === 1 ? "MINUTE" : "MINUTES"} TILL POST
        </TextDescription>
      )}
    </MinutesText>
  );
};

export default Mtp;
export { MtpProps, Mtp };
