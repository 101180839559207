import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const FavoriteTracksMask = ({
  qaLabel,
  isDesktop
}: {
  qaLabel: string;
  isDesktop: boolean;
}) =>
  isDesktop ? (
    <Container data-qa-label={qaLabel}>
      <SVG
        width="652"
        height="427"
        viewBox="0 0 652 427"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_35811_399539)">
          <rect
            width="651"
            height="426"
            transform="translate(0.25 0.406006)"
            fill="white"
          />
          <rect
            x="16.25"
            y="24.406"
            width="85.5684"
            height="16"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="602.223"
            y="26.406"
            width="33.0273"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <g clipPath="url(#clip1_35811_399539)">
            <mask id="path-4-inside-1_35811_399539" fill="white">
              <path d="M0.25 64.406H651.25V186.406H0.25V64.406Z" />
            </mask>
            <path d="M0.25 64.406H651.25V186.406H0.25V64.406Z" fill="white" />
            <rect
              width="651"
              height="32"
              transform="translate(0.25 64.406)"
              fill="white"
            />
            <g clipPath="url(#clip2_35811_399539)">
              <rect
                x="16.25"
                y="64.406"
                width="20"
                height="20"
                rx="10"
                fill="#E9EFF5"
                className="pulse"
              />
            </g>
            <rect
              x="44.25"
              y="68.406"
              width="81.8125"
              height="12"
              rx="1"
              fill="#E9EFF5"
              className="pulse"
            />
            <rect
              x="617.309"
              y="65.9892"
              width="17.9414"
              height="16.8336"
              rx="1"
              fill="#E9EFF5"
              className="pulse"
            />
            <rect
              width="375"
              height="74"
              transform="translate(0.25 96.406)"
              fill="white"
            />
            <g clipPath="url(#clip3_35811_399539)">
              <rect
                x="56.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="56.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="68.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="68.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="149.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="149.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="161.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="161.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="242.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="242.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="254.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="254.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="335.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="335.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="347.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="347.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="428.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="428.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="440.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="440.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="521.25"
                y="96.406"
                width="85"
                height="74"
                rx="4"
                fill="white"
              />
              <rect
                x="521.75"
                y="96.906"
                width="84"
                height="73"
                rx="3.5"
                stroke="#0A0A0A"
                strokeOpacity="0.05"
              />
              <rect
                x="533.25"
                y="108.406"
                width="28"
                height="12"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
              <rect
                x="533.25"
                y="124.406"
                width="60.686"
                height="16"
                rx="1"
                fill="#E9EFF5"
                className="pulse"
              />
            </g>
          </g>
          <path
            d="M651.25 185.406H0.25V187.406H651.25V185.406Z"
            fill="#EAF0F6"
            mask="url(#path-4-inside-1_35811_399539)"
          />
          <g clipPath="url(#clip4_35811_399539)">
            <rect
              x="17.25"
              y="96.406"
              width="36"
              height="74"
              rx="1"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <g clipPath="url(#clip5_35811_399539)">
            <rect
              x="600.25"
              y="96.406"
              width="36"
              height="74"
              rx="1"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <mask id="path-35-inside-2_35811_399539" fill="white">
            <path d="M0.25 186.406H651.25V234.406H0.25V186.406Z" />
          </mask>
          <path d="M0.25 186.406H651.25V234.406H0.25V186.406Z" fill="white" />
          <path
            d="M651.25 233.406H0.25V235.406H651.25V233.406Z"
            fill="#EAF0F6"
            mask="url(#path-35-inside-2_35811_399539)"
          />
          <g clipPath="url(#clip6_35811_399539)">
            <rect
              x="16.25"
              y="200.406"
              width="20"
              height="20"
              rx="10"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <rect
            x="44.25"
            y="204.406"
            width="81.8125"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="617.309"
            y="201.989"
            width="17.9414"
            height="16.8336"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <mask id="path-40-inside-3_35811_399539" fill="white">
            <path d="M0.25 234.406H651.25V282.406H0.25V234.406Z" />
          </mask>
          <path d="M0.25 234.406H651.25V282.406H0.25V234.406Z" fill="white" />
          <path
            d="M651.25 281.406H0.25V283.406H651.25V281.406Z"
            fill="#EAF0F6"
            mask="url(#path-40-inside-3_35811_399539)"
          />
          <g clipPath="url(#clip7_35811_399539)">
            <rect
              x="16.25"
              y="248.406"
              width="20"
              height="20"
              rx="10"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <rect
            x="44.25"
            y="252.406"
            width="81.8125"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="617.309"
            y="249.989"
            width="17.9414"
            height="16.8336"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <mask id="path-45-inside-4_35811_399539" fill="white">
            <path d="M0.25 282.406H651.25V330.406H0.25V282.406Z" />
          </mask>
          <path d="M0.25 282.406H651.25V330.406H0.25V282.406Z" fill="white" />
          <path
            d="M651.25 329.406H0.25V331.406H651.25V329.406Z"
            fill="#EAF0F6"
            mask="url(#path-45-inside-4_35811_399539)"
          />
          <g clipPath="url(#clip8_35811_399539)">
            <rect
              x="16.25"
              y="296.406"
              width="20"
              height="20"
              rx="10"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <rect
            x="44.25"
            y="300.406"
            width="81.8125"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="617.309"
            y="297.989"
            width="17.9414"
            height="16.8336"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <mask id="path-50-inside-5_35811_399539" fill="white">
            <path d="M0.25 330.406H651.25V378.406H0.25V330.406Z" />
          </mask>
          <path d="M0.25 330.406H651.25V378.406H0.25V330.406Z" fill="white" />
          <path
            d="M651.25 377.406H0.25V379.406H651.25V377.406Z"
            fill="#EAF0F6"
            mask="url(#path-50-inside-5_35811_399539)"
          />
          <g clipPath="url(#clip9_35811_399539)">
            <rect
              x="16.25"
              y="344.406"
              width="20"
              height="20"
              rx="10"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <rect
            x="44.25"
            y="348.406"
            width="81.8125"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="617.309"
            y="345.989"
            width="17.9414"
            height="16.8336"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <mask id="path-55-inside-6_35811_399539" fill="white">
            <path d="M0.25 378.406H651.25V426.406H0.25V378.406Z" />
          </mask>
          <path d="M0.25 378.406H651.25V426.406H0.25V378.406Z" fill="white" />
          <path
            d="M651.25 425.406H0.25V427.406H651.25V425.406Z"
            fill="#EAF0F6"
            mask="url(#path-55-inside-6_35811_399539)"
          />
          <g clipPath="url(#clip10_35811_399539)">
            <rect
              x="16.25"
              y="392.406"
              width="20"
              height="20"
              rx="10"
              fill="#E9EFF5"
              className="pulse"
            />
          </g>
          <rect
            x="44.25"
            y="396.406"
            width="81.8125"
            height="12"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
          <rect
            x="617.309"
            y="393.989"
            width="17.9414"
            height="16.8336"
            rx="1"
            fill="#E9EFF5"
            className="pulse"
          />
        </g>
        <defs>
          <clipPath id="clip0_35811_399539">
            <rect
              x="0.25"
              y="0.406006"
              width="651"
              height="426"
              rx="4"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_35811_399539">
            <path d="M0.25 64.406H651.25V186.406H0.25V64.406Z" fill="white" />
          </clipPath>
          <clipPath id="clip2_35811_399539">
            <rect
              x="16.25"
              y="64.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip3_35811_399539">
            <rect
              width="558"
              height="74"
              fill="white"
              transform="translate(56.25 96.406)"
            />
          </clipPath>
          <clipPath id="clip4_35811_399539">
            <rect
              x="17.25"
              y="96.406"
              width="36"
              height="74"
              rx="1"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip5_35811_399539">
            <rect
              x="600.25"
              y="96.406"
              width="36"
              height="74"
              rx="1"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip6_35811_399539">
            <rect
              x="16.25"
              y="200.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip7_35811_399539">
            <rect
              x="16.25"
              y="248.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip8_35811_399539">
            <rect
              x="16.25"
              y="296.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip9_35811_399539">
            <rect
              x="16.25"
              y="344.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip10_35811_399539">
            <rect
              x="16.25"
              y="392.406"
              width="20"
              height="20"
              rx="10"
              fill="white"
            />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  ) : (
    <Container data-qa-label={qaLabel}>
      <SVG
        data-qa-label="favoriteTracks-loadingMask"
        width="375"
        height="48"
        viewBox="0 0 375 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="375" height="48" fill="white" />
        <mask id="path-2-inside-1_31980_141052" fill="white">
          <path d="M0 48H375V96H0V48Z" />
        </mask>
        <path d="M0 48H375V96H0V48Z" fill="white" />
        <path
          d="M375 95H0V97H375V95Z"
          fill="#EAF0F6"
          mask="url(#path-2-inside-1_31980_141052)"
        />
        <rect
          className="pulse"
          x="16"
          y="16"
          width="16"
          height="16"
          rx="8"
          fill="#0A0A0A"
        />
        <rect
          className="pulse"
          x="40"
          y="16.5"
          width="166"
          height="15"
          rx="1"
          fill="#0A0A0A"
        />
        <rect
          className="pulse"
          x="353"
          y="18"
          width="14"
          height="12"
          rx="1"
          fill="#0A0A0A"
        />
      </SVG>
    </Container>
  );

export default FavoriteTracksMask;
