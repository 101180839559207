import styled from "styled-components";

export const Container = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  align-items: center;
  margin: ${({ theme }) => theme.space["space-4"]}
    ${({ theme }) => theme.space["space-4"]} 0;
`;

export const TrackNameWrapper = styled.div`
  max-width: 157px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${({ theme }) => theme.space["space-3"]};
`;
