import React, { FC } from "react";
import { get } from "lodash";
import { HandicapOptions } from "@tvg/ts-types/Race";
import { Icon } from "@tvg/design-system";

import {
  Description,
  OptionItem,
  InfoItems,
  InfoItem,
  InfoContainer,
  FavoriteTag,
  Separator,
  IconContainer
} from "./styled-components";

interface Props {
  /**
   * Description of the race
   */
  title?: string;
  /**
   * Inline data to displayed
   */
  info: Array<HandicapOptions | string>;
  /**
   * Handicap options keys to highlight (only available if info typeof HandicapOptions)
   */
  highlighted?: Array<string>;
  /**
   * Handicap options keys to mark has changed (only available if info typeof HandicapOptions)
   */
  changed?: Array<string>;
  /**
   * Handicap options keys with favorite tag  (only available if info typeof HandicapOptions)
   */
  favorite?: Array<string>;
  /**
   * Handicap info separator
   */
  separator?: string;
  /**
   * Style class name
   */
  className?: string;
  /*
    use updated icon to not break anywhere the icon
   */
  useUpdatedIcon?: boolean;
}

const SimpleInlineInfo: FC<Props> = ({
  title = "",
  info = [],
  highlighted = [],
  changed = [],
  favorite = [],
  separator = " |",
  className = "",
  useUpdatedIcon = false
}) => {
  const isInfoArrayOfStrings = () =>
    info.reduce(
      (result, infoItem) => result && typeof infoItem === "string",
      true
    );

  const renderInfoItems = () => (
    <InfoItems data-qa-label="handicapItems">
      {info.map((infoItem, infoIndex) => {
        const hasSeparator = infoIndex !== 0;

        if (typeof infoItem === "string")
          return hasSeparator ? `${separator} ${infoItem}` : infoItem;

        const infoItemKey = get(infoItem, "key", `keyDefault_${infoIndex}`);
        const hasChanges = changed && changed.includes(infoItemKey);
        return (
          <InfoItem
            key={infoItemKey}
            data-qa-label={`handicapItem-${infoItemKey}`}
            isHighlighted={highlighted && highlighted.includes(infoItemKey)}
            hasChanges={hasChanges}
          >
            {hasSeparator && <Separator>{`${separator} `}</Separator>}
            {hasChanges && (
              <IconContainer>
                <Icon
                  name="changes"
                  size="s"
                  backgroundColor={
                    useUpdatedIcon ? "orange.400" : "transparent"
                  }
                  lineColor={useUpdatedIcon ? "grey.900" : "orange.400"}
                />
              </IconContainer>
            )}
            {infoItem.value}
            {favorite && favorite.includes(infoItemKey) && (
              <FavoriteTag>FAV</FavoriteTag>
            )}
          </InfoItem>
        );
      })}
    </InfoItems>
  );

  return (
    <InfoContainer className={className}>
      {!!title && (
        <Description data-qa-label="handicapDescription">{title}</Description>
      )}
      {isInfoArrayOfStrings() ? (
        <OptionItem data-qa-label="handicapItem">
          {info.join(`${separator} `)}
        </OptionItem>
      ) : (
        renderInfoItems()
      )}
    </InfoContainer>
  );
};

export default SimpleInlineInfo;
