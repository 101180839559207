import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("blue", "000")};
  }
  
  50% {
    fill: ${color("blue", "100")};
  }
  
  100% {
    fill: ${color("blue", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 49px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class PaginationMask extends PureComponent {
  render() {
    return (
      <div data-qa-label="paginationMask">
        <SVG>
          <g>
            <rect
              width="100%"
              height="48"
              transform="translate(0 1)"
              fill={color("white", "100")}
            />
            <rect
              className="pulse"
              x="12"
              y="17"
              width="16"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              width="16"
              height="16"
              rx="2"
              transform="matrix(-1 0 0 1 363 17)"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="32"
              y="17"
              width="40"
              height="16"
              rx="2"
              fill={color("blue", "000")}
            />
            <rect
              width="40"
              height="16"
              rx="2"
              transform="matrix(-1 0 0 1 343 17)"
              fill={color("blue", "000")}
            />
            <rect
              className="pulse"
              x="142"
              y="9"
              width="92"
              height="32"
              rx="2"
              fill={color("blue", "000")}
            />
          </g>
        </SVG>
      </div>
    );
  }
}
