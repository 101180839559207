import { Store } from "@reduxjs/toolkit";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { get } from "lodash";

type UTCDate = [number, number, number];
const MONTH_INDEX = 1;

export const getUpcomingRacesInfo = (store: Store) =>
  parseCAPIMessage(store, "capi.messages.tracksUpcomingRaces", {
    title: "Upcoming Races",
    noUpcomingRaces: {
      title: "No Upcoming Races",
      hint: "Still no upcoming races availables"
    },
    noUpcomingRacesDesk: {
      title: "Upcoming Races",
      hint: "No upcoming races available with selected filters"
    },
    maxResults: 20
  });

export const getDateRace = (store: Store): string =>
  get(store, "mtpStatus.raceDate", "");

export const getMtpStatus = (store: Store) =>
  get(store, "mtpStatus.mtpStatus", []);

export const getTodayDate = (store: unknown): Date => {
  const currentLocalDate = new Date();
  const raceDate: UTCDate = get(store, "mtpStatus.raceDate", "")
    .split("-")
    .map((date: string, i: number) => {
      const parsedDate = +date;
      return MONTH_INDEX === i ? parsedDate - 1 : parsedDate;
    });
  const todayDate: UTCDate =
    raceDate.length === 3
      ? raceDate
      : [
          currentLocalDate.getUTCFullYear(),
          currentLocalDate.getUTCMonth(),
          currentLocalDate.getUTCDate()
        ];

  return new Date(Date.UTC(...todayDate, currentLocalDate.getHours()));
};
