import { isURP } from "@tvg/utils/generalUtils";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import { Store } from "@reduxjs/toolkit";
import { talentPicksPage } from "../__mocks__/talentPicksMocks";
import { TalentPicksPageMsgs } from "./types";

export const getTalentPicksPageMsgs = (store: Store): TalentPicksPageMsgs =>
  parseCAPIMessage(store, "capi.messages.TalentPicksPage", talentPicksPage);

export const getBlacklistedWagerTypes = (store: unknown) =>
  isURP
    ? parseCAPIMessage(store, "capi.messages.URP_FDRBlacklistedBets", undefined)
    : parseCAPIMessage(store, "capi.messages.FDRBlacklistedBets", undefined);

export const getTalentPicksSectionTitle = (store: Store) =>
  parseCAPIMessage(store, "capi.messages.TalentPicks_pgp", "FDTV Picks");

export const getTalentPicksToggleONPgP = (store: Store) =>
  parseCAPIMessage(store, "capi.featureToggles.talentPicksOnProgPage", false);
