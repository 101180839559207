import { noop } from "lodash";
import TracksAZTemplate from "@tvg/atomic-ui/_templates/TracksAZ";

import GraphQuery from "../graphql/queries/TracksAZQuery";
import RDAQuery from "../graphql/queries/TracksAZRDAQuery";
import GraphOptions from "../graphql/resulted-tracks-az-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

export const skipQuery = ({ isAccountCompliant, shouldUpdate }) =>
  isAccountCompliant || !shouldUpdate;

export const skipRDAQuery = ({ isAccountCompliant, shouldUpdate, accountId }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const tracksAZ = {
  template: TracksAZTemplate,
  query: GraphQuery,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: false,
  onlyFetchResultedTracks: true,
  renderTracksLetters: true,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps
};

export default tracksAZ;
