import { FilterOption, TalentFilter } from "./types";

export const CONTENT_FILTER_WIDTH = 145;

export const TRACK_FILTER_DEFAULT: FilterOption = {
  label: "All Tracks",
  value: ""
};
export const EXPERT_FILTER_DEFAULT: FilterOption = {
  label: "All Experts",
  value: ""
};

export const TALENT_FILTER_DEFAULT: TalentFilter = {
  label: "All",
  value: "",
  image: "",
  picksCount: 0
};
