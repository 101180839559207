import React from "react";
import { Tooltip } from "../Tooltip";
import { type IconButtonProps } from "./types";
import { StyledButton } from "./styled-components";
import { Icon } from "../Icon";
import { computeColors } from "./utils";
import {
  secondaryDarkOverride,
  tertiaryOverride,
  secondaryOverride,
  bettingOverride,
  primaryOverride
} from "./constants";

export const IconButton = ({
  size = "l",
  iconSize = "m",
  iconName = "arrowLeft",
  placementTooltip = "bottom-mid",
  textTooltip = "",
  qaLabel,
  isDisabled,
  onClick,
  mode = "light",
  overrideColors,
  hasBorder,
  ...rest
}: IconButtonProps) => {
  const colors = computeColors(overrideColors, mode);

  const ContainerIconButton = () => (
    <StyledButton
      size={size}
      textTooltip={textTooltip || undefined}
      disabled={isDisabled}
      onClick={(e) => {
        if (onClick) onClick(e);
        e.preventDefault();
        e.stopPropagation();
      }}
      data-qa-label={qaLabel}
      mode={mode}
      colors={colors}
      hasBorder={hasBorder}
      {...rest}
    >
      <Icon
        name={iconName}
        size={iconSize}
        lineColor="--fd-colors-content-strong"
        backgroundColor="transparent"
        qaLabel="button-icon"
      />
    </StyledButton>
  );

  return (
    <>
      {textTooltip ? (
        <Tooltip
          qaLabel="tooltip"
          content={textTooltip}
          placement={placementTooltip}
          isDarkMode={mode === "dark"}
          isDisabled={isDisabled}
        >
          <ContainerIconButton />
        </Tooltip>
      ) : (
        <ContainerIconButton />
      )}
    </>
  );
};

export {
  type IconButtonProps,
  secondaryDarkOverride,
  secondaryOverride,
  bettingOverride,
  tertiaryOverride,
  primaryOverride
};
export default IconButton;
