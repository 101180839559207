import React from "react";
import { Selections } from "./styled-components";
import { ShowSelectionsProps, SaddleSelectionsProps } from "./types";
import SaddleNumbers from "./saddleNumbers";
import { useQaLabel } from "../../hooks";

export const SaddleSelections = ({
  numWagerableRunners,
  selections,
  isDark,
  showBetType,
  scratches,
  wagerDetails,
  shouldShowRefundTag,
  breed = "thoroughbred",
  qaLabel = "saddle-selections-container",
  onHandicaping,
  hideWillPaysTag,
  enableMyBetsBehavior,
  showOdds,
  favoriteRunner,
  showReplacedRunners,
  raceStatus
}: ShowSelectionsProps) => {
  const viewTestProps = useQaLabel(qaLabel || "saddle-selections-container");

  return (
    <Selections {...viewTestProps}>
      <SaddleNumbers
        scratches={scratches}
        numWagerableRunners={numWagerableRunners}
        selections={
          selections?.length > 0
            ? selections.sort(
                (a, b) =>
                  parseInt(a.number.toString(), 10) -
                  parseInt(b.number.toString(), 10)
              )
            : []
        }
        isDark={isDark}
        showBetType={showBetType}
        breed={breed}
        wagerDetails={wagerDetails}
        onHandicaping={onHandicaping}
        shouldShowRefundTag={shouldShowRefundTag}
        hideWillPaysTag={hideWillPaysTag}
        enableMyBetsBehavior={enableMyBetsBehavior}
        showOdds={showOdds}
        favoriteRunner={favoriteRunner}
        showReplacedRunners={showReplacedRunners}
        raceStatus={raceStatus}
      />
    </Selections>
  );
};

export default SaddleSelections;
export type { SaddleSelectionsProps };
