import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const TalentPicksContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue["000"]};
`;

export const StyledHeader = styled.div<{ shouldHide: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue[100]};

  @media screen and ${breakpoints.tablet.max.sm} {
    ${({ shouldHide }) => shouldHide && "display: none"};
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.space["space-2"]};
    background-color: ${({ theme }) =>
      theme.colorTokens.background.promotional};
    border-bottom: none;

    & > h1 {
      font-size: ${({ theme }) => theme.fontSizes.l};
      color: ${({ theme }) => theme.colorTokens.content.onDark};
    }
  }
`;

export const ArrowWrapper = styled.div`
  display: none;
  @media screen and ${breakpoints.tablet.max.sm} {
    display: block;
    position: absolute;
    left: 0;
  }
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

export const FiltersContainer = styled.div<{
  shouldHide: boolean;
  isLoading: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${({ theme }) => css`
    background-color: ${theme.colors.blue_accent["000"]};
    box-shadow: inset 0 -1px 0 ${theme.colors.blue[100]};
    padding: ${theme.space["space-4"]};
    gap: ${theme.space["space-4"]};
  `}

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: 0;
    gap: 0;
    overflow-x: scroll;
    box-shadow: none;
    background-color: ${({ theme }) =>
      theme.colorTokens.background.promotional};
    ${({ shouldHide }) => shouldHide && "display: none"};
    ${({ theme, shouldHide, isLoading }) =>
      !shouldHide &&
      !isLoading &&
      css`
        &::after {
          content: "";
          width: 100%;
          height: 86px;
          right: 0;
          position: absolute;
          background: linear-gradient(
            270deg,
            ${theme.colorTokens.background.promotional} 0%,
            rgba(5, 40, 90, 0) 20%
          );
          pointer-events: none;
        }
      `}

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 294px;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  ${({ theme }) => css`
    background-color: ${theme.colors.blue["000"]};
    padding: ${theme.space["space-4"]} ${theme.space["space-4"]} 0;
  `}

  @media screen and ${breakpoints.tablet.max.sm} {
    ${({ theme }) => css`
      padding: ${theme.space["space-4"]} 0 0;
    `}
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  ${({ theme }) => css`
    padding: ${theme.space["space-4"]};
    margin: ${theme.space["space-4"]} 0;
  `}
`;

export const CardsContainer = styled.div`
  min-width: ${({ theme }) => `calc(280px + 2 * ${theme.space["space-4"]})`};
  display: flex;
  gap: ${({ theme }) => theme.space["space-4"]};

  @media screen and ${breakpoints.tablet.min.sm} {
    flex-grow: 1;
    flex-wrap: wrap;
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    width: 100%;
    align-items: center;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${({ theme }) => theme.space["space-4"]};
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: ${({ theme }) =>
      `0 ${theme.space["space-4"]} ${theme.space["space-4"]}`};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
