import type { Actions } from "../actions/tabs";
import { TABS } from "../../types";

export type State = {
  currentTab: TABS;
};

export const initialState = {
  currentTab: TABS.TODAY
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SET_CURRENT_TAB": {
      return {
        ...state,
        currentTab: action.payload.currentTab
      };
    }
    default: {
      return state;
    }
  }
}
