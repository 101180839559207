const colors = [
  {
    number: 0,
    numberColor: "#FFFFFF",
    saddleColor: "#C20E0E"
  },
  {
    number: 1,
    numberColor: "#FFFFFF",
    saddleColor: "#C20E0E"
  },
  {
    number: 2,
    numberColor: "#000000",
    saddleColor: "#BCC8C8"
  },
  {
    number: 3,
    numberColor: "#FFFFFF",
    saddleColor: "#3B4E8C"
  },
  {
    number: 4,
    numberColor: "#000000",
    saddleColor: "#D3B13C"
  },
  {
    number: 5,
    numberColor: "#FFFFFF",
    saddleColor: "#116410"
  },
  {
    number: 6,
    numberColor: "#FFFFFF",
    saddleColor: "#303030"
  },
  {
    number: 7,
    numberColor: "#000000",
    saddleColor: "#D65A2F"
  },
  {
    number: 8,
    numberColor: "#000000",
    saddleColor: "#E095AA"
  },
  {
    number: 9,
    numberColor: "#000000",
    saddleColor: "#2AAAA5"
  },
  {
    number: 10,
    numberColor: "#FFFFFF",
    saddleColor: "#713A98"
  },
  {
    number: 11,
    numberColor: "#FFFFFF",
    saddleColor: "#6B6B6B"
  },
  {
    number: 12,
    numberColor: "#000000",
    saddleColor: "#4EA958"
  },
  {
    number: 13,
    numberColor: "#FFFFFF",
    saddleColor: "#58514D"
  },
  {
    number: 14,
    numberColor: "#FFFFFF",
    saddleColor: "#94335A"
  },
  {
    number: 15,
    numberColor: "#FFFFFF",
    saddleColor: "#69522F"
  },
  {
    number: 16,
    numberColor: "#FFFFFF",
    saddleColor: "#4C6E9F"
  },
  {
    number: 17,
    numberColor: "#FFFFFF",
    saddleColor: "#373786"
  },
  {
    number: 18,
    numberColor: "#FFFFFF",
    saddleColor: "#285F23"
  },
  {
    number: 19,
    numberColor: "#FFFFFF",
    saddleColor: "#32496D"
  },
  {
    number: 20,
    numberColor: "#FFFFFF",
    saddleColor: "#671334"
  }
];

export default colors;
