import React from "react";
import { IconWithBackground } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import type { Props } from "./types";
import {
  Container,
  Duration,
  IconContainer,
  Thumbnail
} from "./styled-components";
import Description from "../Description";
import { CONTAINER, ICON, TEXT, THUMBNAIL } from "../../constants";

const VideoThumbnail = ({ item, onClick, qaLabel = "" }: Props) => (
  <Container
    onClick={onClick}
    data-qa-label={buildQaLabel([qaLabel, THUMBNAIL, CONTAINER])}
  >
    <Thumbnail
      source={item.video.thumbnail}
      data-qa-label={buildQaLabel([qaLabel, THUMBNAIL])}
    >
      <IconContainer>
        <IconWithBackground
          qaLabel={buildQaLabel([qaLabel, THUMBNAIL, ICON])}
          mainIcon={{
            name: "video",
            backgroundColor: "blue.700",
            lineColor: "white.700"
          }}
          mainCircleBackground="white.700"
        />
      </IconContainer>
      <Duration data-qa-label={buildQaLabel([qaLabel, THUMBNAIL, TEXT])}>
        {item.video.duration}
      </Duration>
    </Thumbnail>

    <Description item={item} qaLabel={buildQaLabel([qaLabel, THUMBNAIL])} />
  </Container>
);

export default VideoThumbnail;
