import { ColorTokens, Paragraph, useColorTokens } from "@tvg/design-system";
import formatCurrency from "@tvg/formatter/currency";
import React from "react";

export interface AmountValueProps {
  amount: number;
}

const AmountValue = ({ amount }: AmountValueProps) => {
  const { content } = useColorTokens() as ColorTokens;

  return (
    <Paragraph
      qaLabel="winner-amount"
      color={content.positive}
      fontSize="xl"
      fontFamily="medium"
    >
      {formatCurrency(amount)}
    </Paragraph>
  );
};

export default AmountValue;
