import { gql } from "@apollo/client";

export const UPCOMING_RACE = gql`
  fragment UpcomingRace on Race {
    raceId: id
    tvgRaceId
    mtp
    postTime
    raceNumber: number
    tvgRaceId
    raceDate
    trackName
    trackCode
    track {
      id
    }
    status {
      code
    }
    location {
      country
    }
    isGreyhound
    highlighted(product: $product, device: $device, brand: $brand) {
      description
      style
    }
    promos(product: $product, brand: $brand) {
      isAboveTheLine
      rootParentPromoID
      isPromoTagShown
    }
    talentPicks {
      id
    }
    video {
      onTvg
      onTvg2
    }
  }
`;

export default UPCOMING_RACE;
