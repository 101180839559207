import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy } from "lodash";

import type { Track } from "@tvg/ts-types/Track";
import { getIsLogged, getWagerProfile } from "@urp/store-selectors";
import { NO_TRACKS_FILTER } from "../constants";
import { findTrack, getOpenTracksRaces, isTrackOpen } from "../utils/races";
import { sendOpenAnalyticEvt } from "../utils/analytics";
import {
  getOpenTracks,
  getIsAccountCompliant,
  getFavoriteTracks,
  getTopTracksConfig
} from "../redux/selectors";
import { toggleOpenTrackRow as toggleOpenTrackRowAction } from "../redux/actions/tracks";
import useTodaysTracks from "./useTodaysTracks";
import useTodaysRaces from "./useTodaysRaces";

export default () => {
  const dispatch = useDispatch();
  const isUserLogged = useSelector(getIsLogged);
  const isAccountCompliant = useSelector(getIsAccountCompliant);
  const wagerProfile = useSelector(getWagerProfile);
  const favoriteTracks = useSelector(getFavoriteTracks);
  const openTracks = useSelector(getOpenTracks);
  const { maxResults } = useSelector(getTopTracksConfig);

  const [tracksRaces, setTracksRaces] = useState<Array<Track>>([]);

  // favorites
  const { loading: favoritesLoading, tracks: favoritesTracks } =
    useTodaysTracks({
      wagerProfile,
      tracksFilter:
        isUserLogged && isAccountCompliant && favoriteTracks?.length > 0
          ? favoriteTracks
          : NO_TRACKS_FILTER,
      maxResults
    });
  // featured tracks
  const { loading: featuredLoading, tracks: featuredTracks } = useTodaysTracks({
    wagerProfile,
    onlyFeaturedTracks: true,
    maxResults
  });

  const topTracksTotal = favoritesTracks.length + featuredTracks.length;
  const hasTopTracks = topTracksTotal > 0;

  const { loading: todaysRacesLoading, races: todaysRaces } = useTodaysRaces({
    wagerProfile,
    tracksFilter: openTracks
  });

  const isLoading = favoritesLoading && featuredLoading && todaysRacesLoading;
  const topTracksResult = hasTopTracks
    ? uniqBy([...favoritesTracks, ...featuredTracks], "code").slice(
        0,
        maxResults
      )
    : [];

  useEffect(() => {
    if (!isLoading) {
      const newOpenTracksRaces = getOpenTracksRaces(
        openTracks,
        topTracksResult,
        todaysRaces
      );

      setTracksRaces(newOpenTracksRaces);
    }
  }, [
    isLoading,
    JSON.stringify(topTracksResult),
    JSON.stringify(todaysRaces),
    JSON.stringify(openTracks),
    maxResults
  ]);

  const toggleOpenTracks = (trackCode: string): string[] =>
    isTrackOpen(openTracks, trackCode)
      ? openTracks.filter((openTrackCode) => openTrackCode !== trackCode)
      : [...openTracks, trackCode];

  const toggleOpenTrackRow = (trackCode: string) => {
    const newOpenTracks = toggleOpenTracks(trackCode);
    dispatch(toggleOpenTrackRowAction(newOpenTracks));
  };

  const onTrackClickHandler = useCallback(
    (trackCode: string) => {
      toggleOpenTrackRow(trackCode);
      const isOpen = isTrackOpen(openTracks, trackCode);
      const track = findTrack(topTracksResult, trackCode);
      sendOpenAnalyticEvt({
        trackName: track?.name || "",
        isOpen
      });
    },
    [JSON.stringify(openTracks), JSON.stringify(topTracksResult)]
  );

  return {
    loading: isLoading,
    tracksRaces,
    openTracks,
    onTrackClickHandler
  };
};
