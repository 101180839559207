import React, { PureComponent } from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";

import { connect } from "react-redux";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";

import { attempt, get, isEmpty, bindAll } from "lodash";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import TopRacesEvents from "@tvg/gtm/src/modules/TopRaces";
import PreviousWinnersEvents from "@tvg/gtm/src/modules/PreviousWinners";
import TopTracksEvents from "@tvg/gtm/src/modules/TopTracks";
import TracksEvents from "@tvg/gtm/src/modules/Tracks";
import TopPoolsEvents from "@tvg/gtm/src/modules/TopPools";
import TalenPicksEvents from "@tvg/gtm/src/modules/TalentPicks";
import tvgConf from "@tvg/conf";
import Home from "@tvg/home";
import ApolloContext from "@tvg/utils/apolloContext";
import { toggleOpenTrackRow } from "@tvg/tracks-builder/src/actions";

import calculateFeatureOverride from "@tvg/utils/featuresUtils";

import {
  getPromoOnboardingPollerTime,
  getPromosOnboardingToggle
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { initPromoOnboardingPoller } from "@tvg/sh-lib-promos-onboarding/utils/initPromoOnboardingPoller";
import { failFeaturesRequest, successFeaturesRequest } from "../actions/capi";
import { getUserData } from "../actions/user";

export class Main extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      promoEligible: null
    };

    bindAll(this, ["getFeatures"]);

    this.tvg = tvgConf();
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }

      mediatorClassic.subscribe("UPDATE_ROUTER", () => {
        const pathWithParameters = window.location.href.replace(
          window.location.origin,
          ""
        );

        if (this.props.location.pathname !== pathWithParameters) {
          this.props.history.replace(pathWithParameters);
        }
      });

      mediator.base.subscribe("TVG4_NAVIGATION", (data) => {
        mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
      });

      mediatorClassic.subscribe("NEW_FAVORITE_TRACKS", (data) => {
        if (data.from !== "home") {
          // update open tracks
          const openTracks = this.props.openTracks.slice();
          data.favoriteTracks.forEach((track) => {
            if (openTracks.indexOf(track) < 0) {
              openTracks.push(track);
            }
          });
          this.props.dispatch(toggleOpenTrackRow(openTracks));
        }
      });

      mediator.base.subscribe("UPDATE_SESSION_FAVORITE_TRACKS", (data) => {
        attempt(() => {
          const favorites = (
            sessionStorage.getItem("favoriteTracks") || ""
          ).split(",");
          const trackCode = get(data, "payload.trackCode", "");
          const favoriteId = get(data, "payload.favoriteId", null);

          if (get(data, "payload.isAddingFavorite", false)) {
            if (!favorites.includes(trackCode)) {
              favorites.push(trackCode);
            }
          } else {
            favorites.splice(favorites.indexOf(trackCode), 1);
          }

          const favoritesToStore = favorites.join(",");
          sessionStorage.setItem("favoriteTracks", favoritesToStore);

          // Send event to update favorites in LHN
          mediatorClassic.dispatch("NEW_FAVORITE_TRACKS", {
            favoriteTracks: favoritesToStore,
            from: "home"
          });

          // Send event to update favorites in TVG4
          mediatorClassic.dispatch("UPDATE_FAVORITE_TRACKS", {
            favorites,
            trackCode,
            favoriteId
          });
        });
      });

      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        (data) => {
          this.props.dispatch(getUserData(data));
        }
      );

      TopRacesEvents();
      TopTracksEvents();
      TopPoolsEvents();
      TalenPicksEvents();
      TracksEvents();
      PreviousWinnersEvents();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.promoOnboardingToggle) {
      // bind this context to setState to use on a regular function
      const setState = this.setState.bind(this);

      initPromoOnboardingPoller(
        {
          isLogged: get(this.props, "isLogged", false),
          accountNumber: get(this.props, "userData.user.accountNumber"),
          promoEligible: get(this.state, "promoEligible"),
          promoOnboardingPollerTime: get(
            this.props,
            "promoOnboardingPollerTime"
          )
        },
        {
          isLogged: get(prevProps, "isLogged", false),
          accountNumber: get(prevProps, "userData.user.accountNumber"),
          promoEligible: get(prevState, "promoEligible"),
          promoOnboardingPollerTime: get(prevState, "promoOnboardingPollerTime")
        },
        setState,
        this.props.dispatch
      );
    }

    if (prevProps.isLogged && !this.props.isLogged) {
      this.props.history.replace("/");
    }
  }

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err) => this.props.dispatch(failFeaturesRequest(err)));

  getHomepage = (seoContent) => (
    <ApolloContext.Consumer>
      {(value) => (
        <Home
          shouldUpdate={typeof window !== "undefined"}
          device="desktop"
          seoContent={seoContent}
          graphClients={value}
        />
      )}
    </ApolloContext.Consumer>
  );

  tvg;

  render() {
    const seoContent = get(this.props.messages, "seoContentHome", "");

    return (
      <main>
        <TvgSafeAreaProvider>
          <Routes>
            <Route path="/" element={this.getHomepage(seoContent)} />

            <Route path="/hmp" element={this.getHomepage(seoContent)} />

            <Route path="/login" element={this.getHomepage(seoContent)} />

            <Route path="/dev-login" element={this.getHomepage(seoContent)} />
            <React.Fragment>
              <Route
                path="/recover-email"
                element={this.getHomepage(seoContent)}
              />

              <Route
                path="/forgot-credentials"
                element={this.getHomepage(seoContent)}
              />
            </React.Fragment>
          </Routes>
        </TvgSafeAreaProvider>
      </main>
    );
  }
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged"),
    openTracks: get(store, "topTracks.openTracks", []),
    userData: get(store, "userData"),
    featureToggles: get(store, "capi.featureToggles"),
    features: get(store, "capi.features"),
    hasPromoBadge: get(store, "capi.featureToggles.promosDisplayBadge", false),
    hasPromosOptedIn: get(
      store,
      "capi.featureToggles.promosDisplayOptedIn",
      false
    ),
    messages: store.capi.messages,
    promoOnboardingToggle: getPromosOnboardingToggle(store),
    promoOnboardingPollerTime: getPromoOnboardingPollerTime(store)
  }),
  (dispatch) => ({ dispatch })
)(withRouter(Main));
