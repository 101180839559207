import type { RaceInfo } from "@tvg/ts-types/Race";
import type {
  HomepageBannerConfig,
  HomepageConfigType,
  HomepageOrder
} from "../types";

export type SetHomepageOrder = {
  type: "SET_HOMEPAGE_ORDER_ACTION";
  payload: HomepageOrder;
};

export type SetDerbyBanner = {
  type: "SET_DERBY_BANNER_ACTION";
  payload: HomepageBannerConfig;
};

export interface SetHomepageEventStatusAction {
  type: "SET_HOMEPAGE_EVENT_STATUS";
  payload: HomepageConfigType;
}

export interface SetHomepageDerbyRaceAction {
  type: "SET_HOMEPAGE_DERBY_RACE";
  payload: RaceInfo;
}

const setHomepageOrder = (value: HomepageOrder): SetHomepageOrder => ({
  type: "SET_HOMEPAGE_ORDER_ACTION",
  payload: value
});

export const setDerbyBanner = (
  value: HomepageBannerConfig
): SetDerbyBanner => ({
  type: "SET_DERBY_BANNER_ACTION",
  payload: value
});

export const setHomepageEventStatus = (
  value: HomepageConfigType
): SetHomepageEventStatusAction => ({
  type: "SET_HOMEPAGE_EVENT_STATUS",
  payload: value
});

export const setHomepageDerbyRace = (
  value: RaceInfo
): SetHomepageDerbyRaceAction => ({
  type: "SET_HOMEPAGE_DERBY_RACE",
  payload: value
});

export default setHomepageOrder;
