import { PastRaceResults } from "@tvg/ts-types/Race";
import { Actions } from "../actions/races";
import { ToggleHideResultsEnum } from "../../types";

export type State = {
  hideResults: ToggleHideResultsEnum;
  pastRacesCache: Record<string, PastRaceResults>;
};

export const initialState = {
  hideResults: ToggleHideResultsEnum.OFF,
  pastRacesCache: {}
};

export default function reducer(
  state: State = initialState,
  action: Actions = {} as Actions
): State {
  switch (action.type) {
    case "TOGGLE_HIDE_RESULTS": {
      return {
        ...state,
        hideResults: action.payload.hideResults
      };
    }
    case "ADD_PAST_RACE_CACHE": {
      return {
        ...state,
        pastRacesCache: {
          ...state.pastRacesCache,
          [action.payload.key]: action.payload.pastRace
        }
      };
    }
    default: {
      return state;
    }
  }
}
