export enum StoryblokVersion {
  PUBLISHED = "published",
  DRAFT = "draft"
}

export enum StoryblokEnvironment {
  QA = "QA",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION"
}

export type StoryblokDraft = boolean | StoryblokVersion;

export interface StoryblokStoryContent {
  uuid: string;
  component: string;
}

export interface StoryblokStoryTranslatedSlug {
  path: string;
  name: string;
  lang: string;
}

export type StoryblokStoryTagList = Array<string>;

export type StoryblokStoryMetaData = Record<string, string>;

export interface StoryblokStoryAlternative {
  id: number;
  name: string;
  slug: string;
  full_slug: string;
  is_folder: boolean;
  parent_id: number;
}

export interface StoryblokStory<T = unknown> {
  id: number;
  uuid: string;
  name: string;
  slug: string;
  full_slug: string;
  default_full_slug: string | null;
  created_at: string;
  published_at: string | null;
  first_published_at: string | null;
  sort_by_date: string | null;
  release_id: number | null;
  lang: string;
  content: StoryblokStoryContent & T;
  position: number;
  is_startpage: boolean;
  parent_id: number;
  group_id: string;
  translated_slugs: Array<StoryblokStoryTranslatedSlug>;
  tag_list: StoryblokStoryTagList;
  meta_data: StoryblokStoryMetaData | null;
  path: string | null;
  alternates: Array<StoryblokStoryAlternative>;
}

export interface StoryblokResponse {
  story: StoryblokStory;
}

export interface StoryblokLinkBase {
  cached_url: string;
  fieldtype: string;
  id: string;
  linktype: string;
  url: string;
}

export interface StoryblokLinkStory<T = unknown> extends StoryblokLinkBase {
  story: StoryblokStory<T>;
}

export enum StoryblokSlug {
  XSELL = "xsell/xsell",
  STANDALONE = "sa/sa"
}
