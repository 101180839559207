import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Card = styled.div`
  width: ${(props) => props.cardWidth}px;
  height: ${(props) => props.cardHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 292px;
`;

const linkStyle = css`
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: ${buildColor("blue_accent", "500")};
  font-size: 12px;
  font-family: ${fontMedium};
  font-weight: 500;
  padding: 12px;
`;

export const RedirectLink = styled(Link)`
  ${linkStyle}
`;

export const RedirectLinkTVG4 = styled.a`
  ${linkStyle}

  &:hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin: auto;
  margin-bottom: 20px;
  background: ${buildColor("white", "100")};
  border-radius: 50%;
  border: 1px solid ${buildColor("grey", "300")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
