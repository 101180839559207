import { gql } from "@apollo/client";

export const GET_TRACKS = gql`
  query getTracks($wagerProfile: String!) {
    tracks: pastTracks(profile: $wagerProfile) {
      id
      name
    }
  }
`;

export default GET_TRACKS;
