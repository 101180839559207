import { gql } from "@apollo/client";

export const BETTING_INTERESTS = gql`
  fragment BettingInterests on Race {
    bettingInterests {
      biNumber
      saddleColor
      numberColor
      runners {
        runnerId
        scratched
      }
    }
  }
`;
