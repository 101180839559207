import { gql } from "@apollo/client";
import RaceFragment from "../fragments/Races";

export const GET_TODAYS_RACES_UPDATE = gql`
  subscription todayRacesUpdate(
    $wagerProfile: String!
    $product: String
    $brand: String
  ) {
    raceChangesUpdate(profile: $wagerProfile) {
      ...Race
    }
  }
  ${RaceFragment.entry}
`;
