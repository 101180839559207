export const initialState = {
  areQuickLinksVisible: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "HIDE_QUICK_LINKS":
      return {
        areQuickLinksVisible: false
      };
    case "SHOW_QUICK_LINKS":
      return {
        areQuickLinksVisible: true
      };
    default:
      return state;
  }
}
