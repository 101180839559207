import { gql } from "@apollo/client";

export const TRACK = gql`
  fragment Track on Track {
    id
    code
    shortName
    name
    featured
    hasAboveTheLinePromo(product: $product, brand: $brand)
    numberOfRaces
    isGreyhound
    isFinished
    isPromoTagShown
  }

  query getTopTracks($wagerProfile: String, $product: String, $brand: String) {
    featureTracks: tracks(
      filter: { featured: true }
      profile: $wagerProfile
      sort: { byName: ASC }
    ) {
      ...Track
    }
  }
`;

export default TRACK;
