import React from "react";

import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { VisualSelection } from "@tvg/ts-types/Selections";
import BetUtils from "@tvg/utils/betSelection";
import formatCurrency from "@tvg/formatter/currency";
import { BetTypeCell } from "@tvg/design-system";

interface Props {
  wagerAmount: string;
  betTotal: string;
  selections: VisualSelection[][];
  wagerTypeCode: WagerTypeCodesEnum;
  wagerTypeName: string;
  isKey: boolean;
  isBox: boolean;
  isWheel: boolean;
  legCount: number;
  raceNumber: string;
  numWagerableRunners?: string[];
  onHandicaping?: boolean;
}

const BetSummary = ({
  wagerAmount,
  betTotal,
  selections,
  wagerTypeCode,
  wagerTypeName,
  isKey,
  isBox,
  legCount,
  numWagerableRunners,
  raceNumber,
  onHandicaping,
  isWheel
}: Props) => (
  <BetTypeCell
    m={1}
    showOnlySelections
    qaLabel="talent-picks-bet-type-cell"
    completeSelection={selections}
    selections={selections[0]}
    scratches={BetUtils.getSelectionsRunnerNbrScratched(selections)}
    approximatedPayout="0"
    betAmount={formatCurrency(+wagerAmount)}
    betTicket={formatCurrency(+betTotal)}
    type={wagerTypeCode}
    isKey={isKey}
    isBox={isBox}
    isWheel={isWheel}
    betStatus="NONE"
    betTypeName={wagerTypeName}
    isLeg={legCount > 1}
    numWagerableRunners={numWagerableRunners}
    raceNumber={raceNumber}
    onHandicaping={onHandicaping}
  />
);

export default BetSummary;
