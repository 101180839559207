import { STORYBLOK_VERSION } from "./constraints/config";
import { StoryblokDraft, StoryblokVersion } from "./types";

export const getStoryblokVersion = (
  draft?: StoryblokDraft
): StoryblokVersion => {
  if (typeof draft === "boolean") {
    return draft ? StoryblokVersion.DRAFT : StoryblokVersion.PUBLISHED;
  }

  return draft || STORYBLOK_VERSION;
};
