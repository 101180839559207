import React from "react";
import { useSelector } from "react-redux";
import { getActiveBets } from "@urp/lib-racetracks/src/redux/selectors";
import { get } from "lodash";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import {
  getRacePromos,
  isUserOptedInPromo
} from "@urp/lib-racetracks/src/utils/races";
import { getIsLogged, getOptedInPromos } from "@urp/store-selectors";
import { Container } from "./styled-components";
import { Props } from "./types";
import { renderOtherIcons, renderVideoIcons } from "./renderer";
import { buildQaLabel } from "../../utils";
import { CONTAINER, ICONS } from "../../utils/constants";

const RaceIcons = ({ race, qaLabel = "" }: Props) => {
  const { content } = useColorTokens();

  const optedInPromos = useSelector(getOptedInPromos);
  const isLogged = useSelector(getIsLogged);
  const activeBets = useSelector(getActiveBets);

  const hasDescription = !!race.highlighted?.description;

  const availablePromos = getRacePromos(race);
  const isOptedIn = isUserOptedInPromo(optedInPromos, availablePromos);
  const hasPromosAvailable = !!availablePromos.length;
  const hasActiveBets = get(activeBets, race?.tvgRaceId ?? "", 0);
  const lineColor = hasDescription ? content.onDark : content.subtle2;
  const brandColor = hasDescription ? "white" : "grey";

  const video = get(race, "video");
  const code = get(race, "status.code");

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, ICONS, CONTAINER])}>
      {renderOtherIcons(
        isLogged,
        isOptedIn,
        hasPromosAvailable,
        hasActiveBets,
        lineColor,
        qaLabel
      )}
      {renderVideoIcons(video, code, lineColor, brandColor, qaLabel)}
    </Container>
  );
};

export default RaceIcons;
