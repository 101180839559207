import styled from "styled-components";

import buildColor from "../../_static/ColorPalette";

export const AlertsContainer = styled.div`
  position: relative;
  background-color: ${buildColor("white", "100")};
`;

export const ButtonContainer = styled.div`
  background-color: ${buildColor("blue", "000")};
  border-top: 1px solid ${buildColor("grey", "300")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 8px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
  position: sticky;
  bottom: 0;
  box-shadow:
    0 -2px 9px 0 rgb(0 0 0 / 16%),
    0 0 1px 0 rgb(0 0 0 / 24%);
`;

export const AlertsList = styled.ul`
  margin: 0 8px;
  list-style-type: none;
  height: 100%;
  flex-grow: 1;

  > li:not(:last-child) {
    border-bottom: 1px solid ${buildColor("blue", "100")};
  }
`;
