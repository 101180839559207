import styled, { keyframes, css } from "styled-components";
import { buildColor } from "@tvg/design-system";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("grey", "000")};
  }
  
  50% {
    fill: ${buildColor("blue", "000")};
  }
  
  100% {
    fill: ${buildColor("grey", "000")};
   }
`;

export const SVG = styled.svg<{ isDesktop: boolean }>`
  display: block;
  width: 100%;
  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
  rect.stroke {
    stroke: ${({ theme }) => theme.colors.blue[100]};
    stroke-width: 1;
  }

  ${({ theme, isDesktop }) =>
    isDesktop &&
    css`
      margin-bottom: ${theme.space["space-4"]};
      border-radius: ${theme.radii.s};
    `}
`;
