import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { VisualSelection } from "@tvg/ts-types/Selections";
import { getRunnerColors } from "@tvg/design-system";

export const getVisualSelections = (
  pickSelections: Array<string> | Array<Array<string>>,
  raceType: RaceTypeCodeEnum
): Array<VisualSelection> | Array<Array<VisualSelection>> =>
  pickSelections.map((selection) =>
    Array.isArray(selection)
      ? (getVisualSelections(selection, raceType) as unknown as VisualSelection)
      : {
          number: selection,
          ...getRunnerColors(
            selection,
            raceType === RaceTypeCodeEnum.HARNESS ? "harness" : "thoroughbred"
          )
        }
  );

export const isProgramPage = (): boolean => {
  if (window !== undefined) {
    const { pathname } = window.location;
    return pathname.includes("/racetracks");
  }
  return false;
};
