import { get, omit } from "lodash";
import { FavoriteTrackWithId } from "@tvg/ts-types/User";
import { Actions } from "../actions/userFavorites";

export type State = {
  tracks: string[];
  tracksWithId: FavoriteTrackWithId;
  tracksLoaded: boolean;
};

export const initialState = {
  tracks: [],
  tracksLoaded: false,
  tracksWithId: {}
};

const userFavoritesReducer = (
  state: State = initialState,
  action: Actions
): State => {
  switch (action.type) {
    case "SET_USER_FAVORITE_TRACKS": {
      return {
        ...state,
        tracks: action.payload.favoriteTracks,
        tracksLoaded: true
      };
    }
    case "CLEAR_USER_FAVORITE_TRACKS": {
      return { ...initialState };
    }
    case "SET_FAVORITE_TRACK": {
      const trackCode = get(action, "payload.trackCode");
      const favoriteId = get(action, "payload.favoriteId");
      const hasTrackCodeInTracksWithId = Object.keys(
        get(state, "tracksWithId", {})
      ).includes(get(action, "payload.trackCode", ""));
      if (hasTrackCodeInTracksWithId) {
        return {
          ...state,
          tracks: [...state.tracks, action.payload.trackCode]
        };
      }

      return {
        ...state,
        tracks: [...state.tracks, action.payload.trackCode],
        tracksWithId: {
          ...state.tracksWithId,
          [trackCode]: favoriteId
        }
      };
    }
    case "REMOVE_FAVORITE_TRACK": {
      return {
        ...state,
        tracks: [...state.tracks.filter((t) => t !== action.payload.trackCode)],
        tracksWithId: omit(state.tracksWithId, action.payload.trackCode)
      };
    }
    case "SET_USER_FAVORITE_TRACKS_WITH_FAVORITE_ID": {
      return { ...state, tracksWithId: action.payload.favoriteTracksWithId };
    }
    default: {
      return state;
    }
  }
};

export default userFavoritesReducer;
