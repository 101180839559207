import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleElement = styled.h4`
  color: ${({ theme }) => theme.colorTokens.content.default};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

export const Separator = styled.div`
  &::before {
    margin: 12px -12px 16px 0;
    content: " ";
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.colorTokens.border.default};
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colorTokens.content.subtle};
  font-size: 14px;
  margin-top: 6px;

  & * {
    font-family: ${({ theme }) => theme.fonts.regular};
    line-height: 18px;
  }

  & ol,
  ul {
    margin-top: 8px;
  }

  & li {
    margin-left: 16px;
    list-style-type: disc;
  }
`;
