import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import buildColor from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "100")};
  }
  
  50% {
    fill: ${buildColor("blue", "200")};
  }
  
  100% {
    fill: ${buildColor("blue", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  background: ${buildColor("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${buildColor("blue", "200")};
    stroke-width: 1;
  }
`;

export default class RacesMask extends PureComponent {
  render() {
    return (
      <SVGContainer data-qa-label="racesMask">
        <SVG>
          <g fill="none" fillRule="evenodd">
            <g transform="translate(0 -48)">
              <rect
                className="stroke"
                width="91"
                height="47"
                x="12.5"
                y="48.5"
                rx="4"
              />
              <rect
                className="stroke"
                width="91"
                height="47"
                x="112.5"
                y="48.5"
                rx="4"
              />
              <rect
                className="pulse"
                width="15"
                height="8"
                x="20"
                y="77"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="pulse"
                width="15"
                height="8"
                x="120"
                y="77"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="pulse"
                width="35"
                height="8"
                x="20"
                y="59"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="pulse"
                width="51"
                height="8"
                x="120"
                y="59"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="stroke"
                width="91"
                height="47"
                x="212.5"
                y="48.5"
                rx="4"
              />
              <rect
                className="pulse"
                width="15"
                height="8"
                x="220"
                y="77"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="pulse"
                width="51"
                height="8"
                x="220"
                y="59"
                fill={buildColor("blue", "200")}
                rx="1"
              />
              <rect
                className="stroke"
                width="91"
                height="47"
                x="312.5"
                y="48.5"
                rx="4"
              />
            </g>
            <rect
              className="pulse"
              width="15"
              height="8"
              x="320"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="320"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="412.5"
              y=".5"
              rx="4"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="420"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="51"
              height="8"
              x="420"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="512.5"
              y=".5"
              rx="4"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="520"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="520"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="612.5"
              y=".5"
              rx="4"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="620"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="620"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="712.5"
              y=".5"
              rx="4"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="812.5"
              y=".5"
              rx="4"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="720"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="820"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="720"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="820"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="stroke"
              width="91"
              height="47"
              x="912.5"
              y=".5"
              rx="4"
            />
            <rect
              className="pulse"
              width="15"
              height="8"
              x="920"
              y="29"
              fill={buildColor("blue", "200")}
              rx="1"
            />
            <rect
              className="pulse"
              width="35"
              height="8"
              x="920"
              y="11"
              fill={buildColor("blue", "200")}
              rx="1"
            />
          </g>
        </SVG>
      </SVGContainer>
    );
  }
}
