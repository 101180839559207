import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  align-items: center;
`;

export const Block = styled.div`
  gap: 8px;
  display: flex;
`;
