import { gql } from "@apollo/client";
import searchByHorseFragment from "../fragments/SearchByHorse";
import searchByJockeyFragment from "../fragments/SearchByJockey";
import searchByTrainerFragment from "../fragments/SearchByTrainer";
import searchByOwnerFragment from "../fragments/SearchByOwner";

export const RACE_ENTITIES = gql`
  query getRaceEntities(
    $searchText: String!
    $horse: Boolean!
    $jockey: Boolean!
    $trainer: Boolean!
    $owner: Boolean!
  ) {
    raceEntities(searchText: $searchText) {
      ...searchByHorse @include(if: $horse)
      ...searchByJockey @include(if: $jockey)
      ...searchByTrainer @include(if: $trainer)
      ...searchByOwner @include(if: $owner)
    }
  }
  ${searchByHorseFragment.entry}
  ${searchByJockeyFragment.entry}
  ${searchByTrainerFragment.entry}
  ${searchByOwnerFragment.entry}
`;
