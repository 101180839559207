import React from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  margin: 12px auto;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const DesktopWallet = () => (
  <SVG
    width="978"
    height="557"
    viewBox="0 0 978 557"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_iii)">
      <path d="M1 5C1 2.79086 2.79086 1 5 1H321V112H1V5Z" fill="#FAFCFF" />
      <rect
        x="21"
        y="21"
        width="100"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="21"
        y="51"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter1_i)">
      <path
        d="M321 1H973C975.209 1 977 2.79086 977 5V112H321V1Z"
        fill="white"
      />
      <rect
        x="341"
        y="48"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter2_i)">
      <rect
        width="656"
        height="111"
        transform="translate(321 112)"
        fill="white"
      />
      <rect
        x="341"
        y="159"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter3_i)">
      <rect
        width="656"
        height="111"
        transform="translate(321 223)"
        fill="white"
      />
      <rect
        x="341"
        y="270"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter4_i)">
      <rect
        width="656"
        height="111"
        transform="translate(321 334)"
        fill="white"
      />
      <rect
        x="341"
        y="381"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter5_i)">
      <path
        d="M321 445H977V552C977 554.209 975.209 556 973 556H321V445Z"
        fill="white"
      />
      <rect
        x="341"
        y="492"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter6_iii)">
      <rect
        width="320"
        height="111"
        transform="translate(1 112)"
        fill="#FAFCFF"
      />
      <rect
        x="21"
        y="132"
        width="100"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="21"
        y="162"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter7_iii)">
      <rect
        width="320"
        height="111"
        transform="translate(1 223)"
        fill="#FAFCFF"
      />
      <rect
        x="21"
        y="243"
        width="100"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="21"
        y="273"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter8_iii)">
      <rect
        width="320"
        height="111"
        transform="translate(1 334)"
        fill="#FAFCFF"
      />
      <rect
        x="21"
        y="354"
        width="100"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="21"
        y="384"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <g filter="url(#filter9_ii)">
      <path
        d="M1 445H321V556H5C2.79086 556 1 554.209 1 552V445Z"
        fill="#FAFCFF"
      />
      <rect
        x="21"
        y="465"
        width="100"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
      <rect
        x="21"
        y="495"
        width="200"
        height="16"
        fill="#E9EFF5"
        className="pulse"
      />
    </g>
    <rect x="0.5" y="0.5" width="977" height="556" rx="4.5" stroke="#D6E3F0" />
    <defs>
      <filter
        id="filter0_iii"
        x="1"
        y="1"
        width="320"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.980392 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow"
          result="effect3_innerShadow"
        />
      </filter>
      <filter
        id="filter1_i"
        x="321"
        y="1"
        width="656"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter2_i"
        x="321"
        y="112"
        width="656"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter3_i"
        x="321"
        y="223"
        width="656"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter4_i"
        x="321"
        y="334"
        width="656"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter5_i"
        x="321"
        y="445"
        width="656"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter6_iii"
        x="1"
        y="112"
        width="320"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.980392 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow"
          result="effect3_innerShadow"
        />
      </filter>
      <filter
        id="filter7_iii"
        x="1"
        y="223"
        width="320"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.980392 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow"
          result="effect3_innerShadow"
        />
      </filter>
      <filter
        id="filter8_iii"
        x="1"
        y="334"
        width="320"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.980392 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow"
          result="effect3_innerShadow"
        />
      </filter>
      <filter
        id="filter9_ii"
        x="1"
        y="445"
        width="320"
        height="111"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="19" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.980392 0 0 0 0 0.988235 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.839216 0 0 0 0 0.890196 0 0 0 0 0.941176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"
        />
      </filter>
    </defs>
  </SVG>
);

export default DesktopWallet;
