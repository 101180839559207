import { useState, useEffect } from "react";

import type {
  PreviousWinnerWRCResponseLists,
  Race
} from "@tvg/ts-types/PreviousWinners";
import mediator from "@tvg/mediator";
import {
  BIGGEST_WINNERS_INDEX,
  OTHER_WINNERS_INDEX,
  ALL_WINNERS_INDEX
} from "../../constants";
import { buildPreviousWinners } from "../../utils";

export default function usePreviousWinnersWithRaces(
  races: Race[],
  winners: PreviousWinnerWRCResponseLists
) {
  const [previousWinners, setPreviousWinners] =
    useState<PreviousWinnerWRCResponseLists>([[], [], []]);
  const [racesResolved, setRacesResolved] = useState(false);

  useEffect(() => {
    if (races?.length) {
      // @ts-ignore
      setPreviousWinners(buildPreviousWinners(winners, races));
      setRacesResolved(true);
    }
  }, [winners, races]);

  useEffect(() => {
    if (hasBiggest(previousWinners) || hasPrevious(previousWinners))
      onPreviousWinnersGTM(previousWinners);
  }, [racesResolved]);

  const hasBiggest = (winnersList: PreviousWinnerWRCResponseLists) =>
    winnersList?.length > 0 && winnersList[BIGGEST_WINNERS_INDEX].length > 0;

  const hasPrevious = (winnersList: PreviousWinnerWRCResponseLists) =>
    winnersList?.length > 1 && winnersList[OTHER_WINNERS_INDEX].length > 0;

  const hasWinners = (winnersList: PreviousWinnerWRCResponseLists) =>
    winnersList?.length > 2 && winnersList[ALL_WINNERS_INDEX].length > 0;

  const onPreviousWinnersGTM = (winnersList: PreviousWinnerWRCResponseLists) =>
    mediator.base.dispatch({
      type: "PREVIOUS_WINNERS:SEEN",
      payload: {
        hasBiggest: hasBiggest(winnersList),
        hasPrevious: hasPrevious(winnersList)
      }
    });

  return {
    previousWinners,
    racesResolved,
    hasWinners: hasWinners(winners)
  };
}
