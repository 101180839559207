import { get } from "lodash";
// @ts-ignore
import { STORYBLOK_TOKENS } from "@tvg/conf/utils/fdr/storyblok";

import { STORYBLOK_TOKEN } from "./constraints/config";
import { StoryblokEnvironment, StoryblokVersion } from "./types";

export const getStoryblokToken = (
  overrideToken?: StoryblokEnvironment
): StoryblokVersion =>
  overrideToken
    ? get(STORYBLOK_TOKENS, overrideToken, STORYBLOK_TOKEN)
    : STORYBLOK_TOKEN;
