import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";

import pushToDataLayer, { processTrackName } from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("TOP_POOLS_SCROLL_INTERACTION", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload", "Right"),
      gaEventLabel: undefined,
      module: "Top Pools",
      tag: undefined,
      microApp: undefined
    });
  });

  const getPoolType = (pool) => {
    if (get(pool, "race.highlighted.pinnedOrder", 0) > 0) {
      return "pinned";
    }
    if (get(pool, "race.highlighted.style", "") === "Highlighted") {
      return "featured";
    }
    return "default";
  };

  const getPoolLabel = (pool) => {
    const bet = pool.wagerType.name;
    const { poolType } = pool;

    if (/jackpot/i.test(poolType)) {
      return `Jackpot ${bet}`;
    }
    if (/carryover/i.test(poolType)) {
      return `Carryover ${bet}`;
    }
    if (/guaranteed/i.test(poolType)) {
      return `Guaranteed ${bet}`;
    }

    return poolType;
  };

  mediatorChannels.base.subscribe("TOP_POOLS:GO_TO_POOL", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: `${processTrackName(
        get(data, "payload.pool.track")
      )} - R${get(data, "payload.pool.race.raceNumber")} - ${get(
        data,
        "payload.pool.race.mtp"
      )} - ${get(data, "payload.pool.amount")}`,
      module: "Top Pools",
      menu: undefined,
      sport: get(data, "payload.isGreyhound", false)
        ? "Greyhounds Racing"
        : "Horse Racing",
      tag: `${getPoolLabel(get(data, "payload.pool"))} - ${getPoolType(
        get(data, "payload.pool")
      )}`,
      microApp: undefined,
      destinationUrl: get(data, "payload.url")
    });
  });
};
