import React, { PureComponent } from "react";

import { has, noop } from "lodash";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { arrowRight, close, lock } from "../../_static/Icons/icons";
import messageBoxStylesDefault, {
  styleMapAlt as messageBoxStylesAlt
} from "./styles";

import {
  HorizontalMessageBox,
  VerticalMessageBox,
  IconTextWrapper,
  MessageIcon,
  TextWrapper,
  MessageTitle,
  MessageContent,
  CloseBtn,
  EditBtn,
  VerticalTextWrapper,
  LogSignupButtonWrapper,
  LogSignupButton,
  LogSignupButtonInner,
  LockIcon,
  ArrowIconContainer
} from "./styled-components";

export default class MessageBox extends PureComponent {
  renderLoginSignupButton() {
    const { onLoginCallback, onSignupCallback } = this.props;
    const LoginBtn = has(this.props, "onLoginCallback") &&
      typeof onLoginCallback === "function" && (
        <LogSignupButton
          qaLabel="messageBox-loginBtn"
          type="secondary"
          onClick={onLoginCallback}
          isBold={false}
        >
          <LockIcon size={16} icon={lock} color={buildColor("grey", "900")} />
          <LogSignupButtonInner>log in</LogSignupButtonInner>
        </LogSignupButton>
      );

    const SignupBtn = has(this.props, "onSignupCallback") &&
      typeof onSignupCallback === "function" && (
        <LogSignupButton
          qaLabel="messageBox-signUpBtn"
          onClick={onSignupCallback}
          isBold={false}
        >
          sign up
        </LogSignupButton>
      );

    return (
      (LoginBtn || SignupBtn) && (
        <LogSignupButtonWrapper>
          {LoginBtn}
          {SignupBtn}
        </LogSignupButtonWrapper>
      )
    );
  }

  renderMessage(style) {
    const { message: PropsMessage } = this.props;
    const message = React.isValidElement(PropsMessage) ? (
      <MessageContent color={style.color}>{PropsMessage}</MessageContent>
    ) : (
      // eslint-disable-next-line react/no-danger
      <MessageContent
        color={style.color}
        dangerouslySetInnerHTML={{ __html: PropsMessage }}
      />
    );
    return message;
  }

  getMsgSubtypeProps() {
    const msgSubtypePropsDefault = {
      hasBorderLeft: false,
      hasBorderRight: false,
      hasBorderBottom: false,
      hasBorderTop: false,
      hasRoundCorners: false,
      hasDropShadow: false
    };

    const msgSubtypeProps = {
      contextual: msgSubtypePropsDefault,
      fullWith: {
        hasBorderTop: true,
        hasBorderBottom: true
      },
      floating: {
        hasBorderLeft: true,
        hasBorderRight: true,
        hasBorderTop: true,
        hasBorderBottom: true,
        hasRoundCorners: true
      },
      scratchcard: {
        hasBorderLeft: true,
        hasBorderRight: true,
        hasBorderTop: true,
        hasBorderBottom: true,
        hasRoundCorners: true,
        hasDropShadow: true
      }
    };

    return this.props.subtype &&
      this.props.subtype !== "custom" &&
      msgSubtypeProps[this.props.subtype]
      ? { ...msgSubtypePropsDefault, ...msgSubtypeProps[this.props.subtype] }
      : {};
  }

  renderHorizontalMessage(style, hasIcon, hasTitle) {
    const {
      children,
      title,
      url,
      className,
      type,
      subtype,
      onClick,
      hasBorderBottom,
      hasBorderTop,
      hasMarginBottom,
      hasMarginTop,
      hasMarginsLarge,
      horizontalPadding,
      isAlt,
      onClose,
      hasEdit,
      editHandleClick,
      alignCenter,
      fullBoxOnClick
    } = this.props;

    const Message = url
      ? HorizontalMessageBox
      : HorizontalMessageBox.withComponent("div");
    const messageProps = url
      ? { ...this.getMsgSubtypeProps(), to: url, onClick }
      : { ...this.getMsgSubtypeProps() };

    return (
      <Message
        customStyle={style}
        hasIcon={hasIcon}
        hasTitle={hasTitle}
        type={type}
        className={className}
        data-qa-label={`messageBox-${type}`}
        hasBorderBottom={hasBorderBottom}
        hasBorderTop={hasBorderTop}
        hasMarginTop={hasMarginTop}
        hasMarginBottom={hasMarginBottom}
        hasMarginsLarge={hasMarginsLarge}
        onClick={fullBoxOnClick}
        isAlt={isAlt}
        {...messageProps}
      >
        <IconTextWrapper
          alignCenter={alignCenter}
          horizontalPadding={horizontalPadding}
        >
          {hasIcon && (
            <MessageIcon
              icon={style.icon}
              strokeColorList={style.strokeColorList || []}
              size={16}
              color={style.fill}
              colorList={style.colorList || []}
              qaLabel="messageBox-icon"
              viewBoxSize={style.viewBoxSize || 1024}
            />
          )}
          <TextWrapper
            hasIcon={hasIcon}
            data-qa-label="messageBox-text"
            isAlt={isAlt}
          >
            {title && (
              <MessageTitle
                data-qa-label="messageBox-title"
                isAlt={isAlt}
                isPromo={type === "promos" || type === "referral"}
                promosInvalid={type === "promosInvalid"}
                color={style.color}
              >
                {title}
              </MessageTitle>
            )}
            {this.renderMessage(style)}
          </TextWrapper>
          {onClose && (
            <CloseBtn onClick={onClose} data-qa-label="messageBox-closeBtn">
              <Icon icon={close} size={16} color={buildColor("blue", "800")} />
            </CloseBtn>
          )}
          {hasEdit && (
            <EditBtn
              type="button"
              onClick={editHandleClick}
              data-qa-label="messageBox-editBtn"
              typeButton={type}
            >
              {type === "promosInvalid" ? "Change" : "Edit"}
            </EditBtn>
          )}
          {url && (
            <ArrowIconContainer>
              <Icon
                icon={arrowRight}
                size={16}
                color={buildColor("blue", "800")}
              />
            </ArrowIconContainer>
          )}
        </IconTextWrapper>
        {this.renderLoginSignupButton()}
        {children}
      </Message>
    );
  }

  renderVerticalMessage(style) {
    const { children, title, url, className, type, hasIcon } = this.props;

    const Message = url
      ? VerticalMessageBox
      : VerticalMessageBox.withComponent("div");
    const messageProps = url ? { to: url } : {};

    return (
      <Message
        className={className}
        data-qa-label={`messageBox-${type}`}
        {...messageProps}
      >
        {hasIcon && (
          <MessageIcon
            icon={style.icon}
            size={16}
            color={style.fill}
            qaLabel="messageBox-icon"
          />
        )}
        <VerticalTextWrapper data-qa-label="messageBox-text">
          {title && (
            <MessageTitle
              isVertical
              data-qa-label="messageBox-title"
              color={style.color}
            >
              {title}
            </MessageTitle>
          )}
          {this.renderMessage(style)}
        </VerticalTextWrapper>
        {this.renderLoginSignupButton()}
        {children}
      </Message>
    );
  }

  render() {
    const { isAlt, addonStyle, type, isVertical, hasIcon, title } = this.props;

    const messageBoxStyles = isAlt
      ? messageBoxStylesAlt
      : messageBoxStylesDefault;

    const style = addonStyle
      ? {
          ...messageBoxStyles[type],
          addonStyle
        }
      : messageBoxStyles[type];

    if (isVertical) {
      return this.renderVerticalMessage(style);
    }

    return this.renderHorizontalMessage(style, hasIcon, !!title);
  }
}

MessageBox.defaultProps = {
  type: "default",
  subtype: "custom",
  message: "",
  className: "",
  hasIcon: true,
  hasBorderBottom: false,
  hasBorderTop: false,
  isVertical: false,
  title: null,
  children: null,
  onClick: noop,
  onClose: null,
  onLoginCallback: null,
  onSignupCallback: null,
  url: null,
  hasMarginTop: false,
  hasMarginBottom: false,
  hasEdit: false,
  addonStyle: null,
  isAlt: false
};
