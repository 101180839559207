const colors = [
  {
    number: 0,
    numberColor: "#FFFFFF",
    saddleColor: "#AF0000"
  },
  {
    number: 1,
    numberColor: "#FFFFFF",
    saddleColor: "#AF0000"
  },
  {
    number: 2,
    numberColor: "#FFFFFF",
    saddleColor: "#253C85"
  },
  {
    number: 3,
    numberColor: "#000000",
    saddleColor: "#B2B4B1"
  },
  {
    number: 4,
    numberColor: "#FFFFFF",
    saddleColor: "#1C5B1B"
  },
  {
    number: 5,
    numberColor: "#FFFFFF",
    saddleColor: "#252525"
  },
  {
    number: 6,
    numberColor: "#000000",
    saddleColor: "#B69E00"
  },
  {
    number: 7,
    numberColor: "#000000",
    saddleColor: "#B27492"
  },
  {
    number: 8,
    numberColor: "#FFFFFF",
    saddleColor: "#6B6B6B"
  },
  {
    number: 9,
    numberColor: "#FFFFFF",
    saddleColor: "#610F9A"
  },
  {
    number: 10,
    numberColor: "#FFFFFF",
    saddleColor: "#263D88"
  },
  {
    number: 11,
    numberColor: "#FFFFFF",
    saddleColor: "#3B5E90"
  },
  {
    number: 12,
    numberColor: "#FFFFFF",
    saddleColor: "#A90000"
  },
  {
    number: 13,
    numberColor: "#FFFFFF",
    saddleColor: "#1D5E1C"
  },
  {
    number: 14,
    numberColor: "#FFFFFF",
    saddleColor: "#725933"
  },
  {
    number: 15,
    numberColor: "#FFFFFF",
    saddleColor: "#C0C0C0"
  }
];

export default colors;
