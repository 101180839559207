import styled from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";

export const TvgLiveLink = styled(Link)`
  height: 100%;
  width: 100%;
  background-color: #2a4259;
  color: ${buildColor("white", "100")};
  border-radius: 2px;
  outline: none;
  flex-grow: 1;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;

  &:not(:first-child) {
    margin-left: 10px;
  }

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${buildColor("green", "500")};
    }
  }

  &:active {
    background-color: ${buildColor("green", "700")};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${buildColor("green", "500")};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  & > svg {
    flex-grow: 1;
  }
`;

export const LogoContainer = styled.div`
  height: 144px;
  padding: 20px;
`;

export const WatchLiveLabel = styled.div`
  background: #122b43;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  & > svg {
    padding-left: 10px;
    width: 13px;
  }
`;
