import gql from "graphql-tag";

const BettingInterestsFragment = {
  entry: gql`
    fragment TopRacesBettingInterests on BettingInterest {
      currentOdds {
        numerator
        denominator
      }
      morningLineOdds {
        numerator
        denominator
      }
      runners {
        horseName
        runnerId
        scratched
        masterPickNumber: raceNumber
        trainer
        jockey
        timeform {
          silkUrl
          silkUrlSvg
        }
      }
      isFavorite: favorite
      biNumber
      numberColor
      saddleColor
    }
  `
};

export default BettingInterestsFragment;
