import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }
  
  50% {
    opacity: 0.5
  }
  
  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const TalentFilterLoading = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" fill="none" width="65" height="65">
    <g
      clipPath="url(#clip1_11707_627917)"
      filter="url(#filter0_i_11707_627917)"
    >
      <circle cx="34" cy="32" r="30" fill="#E9EFF5" />
    </g>
  </SVG>
);

export default TalentFilterLoading;
