import React from "react";

const ArrowRightSVG = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6893 6.74999H0.5C0.223858 6.74999 0 6.52613 0 6.24999V5.74999C0 5.47384 0.223858 5.24999 0.5 5.24999H10.6893L7.32322 1.88386C7.12796 1.6886 7.12796 1.37202 7.32322 1.17676L7.67677 0.823205C7.87203 0.627942 8.18862 0.627942 8.38388 0.823204L13.0303 5.46965C13.3232 5.76254 13.3232 6.23742 13.0303 6.53031L8.38388 11.1768C8.18862 11.372 7.87203 11.372 7.67677 11.1768L7.32322 10.8232C7.12796 10.6279 7.12796 10.3114 7.32322 10.1161L10.6893 6.74999Z"
      fill="white"
    />
  </svg>
);

export default ArrowRightSVG;
