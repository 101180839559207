import React from "react";
import { AppRegistry } from "react-native-web";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { TVGThemeProviderWeb, TvgConfProvider } from "@tvg/design-system";

import configureStore from "./configureStore";
import SeoContent from "./pages/seo";

const preloadedState = window.__HOME_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
window.seoRendered = true;

if (window.homepageRendered) {
  delete window.__HOME_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
}

const store = configureStore(preloadedState);

const Main = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <Provider store={store}>
        <BrowserRouter>
          <SeoContent />
        </BrowserRouter>
      </Provider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);

const Render = () => {
  if (document.getElementById("seo")) {
    AppRegistry.registerComponent("App", () => Main);
    AppRegistry.runApplication("App", {
      initialProps: {},
      // @TODO check if we can improve the hydration
      // hydrate: true,
      rootTag: document.getElementById("seo")
    });
  }
};

export default Render();
