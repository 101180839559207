import React, { Component, Fragment } from "react";
import { graphql } from "@apollo/client/react/hoc";

import { connect } from "react-redux";
import {
  get,
  indexOf,
  noop,
  isEqual,
  attempt,
  flowRight as compose
} from "lodash";
import mediator from "@tvg/mediator";
import {
  setFavoriteTrack,
  removeFavoriteTrack
} from "@tvg/shared-actions/UserActions";
import ufcClient from "@tvg/api/ufc";

import TrackSplitter from "@tvg/atomic-ui/_atom/TrackSplitter";
import filtersService from "@tvg/tracks-header/service";
import { events as AlchemerEvents } from "@urp/alchemer";
import {
  setRaceFiltersFromPreferences,
  setRegionFiltersFromPreferences
} from "@tvg/tracks-header/actions";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getAccountNumber } from "@urp/store-selectors";
import ExpandableTrack from "./components/expandable-track";
import { toggleOpenTrackRow } from "./actions";

import getTracksType from "./TracksType";
import TracksComponents from "./TracksType/types";

const toggle = (array, valueToToggle) => {
  const index = indexOf(array, valueToToggle);
  const newStruct = array.slice(0);
  if (index !== -1) {
    newStruct.splice(index, 1);
  } else {
    newStruct.push(valueToToggle);
  }
  return newStruct;
};

const trackGTMTagging = (
  trackName,
  trackLabels,
  isOpen,
  module,
  isGreyhound
) => {
  const tag = trackLabels.length
    ? trackLabels.map((label) => label.text).join(", ")
    : "no tag";
  mediator.base.dispatch({
    type: "TRACKS_TRACK_CLICK",
    payload: {
      isOpen,
      trackName,
      tag,
      module,
      isGreyhound
    }
  });
};

export const createTopTracksFavoriteAction = (
  trackName,
  module,
  isAdding = true,
  isGreyhound = false
) => ({
  type: "TOP_TRACKS_FAVORITES_INTERACTION",
  payload: {
    trackName,
    isAdding,
    module,
    isGreyhound
  }
});

const addFavoritesIntoOpenTracks = (props, firstRender) => {
  if (props.tracks.length > 0 && firstRender) {
    const openTracks = props.openTracks.slice();
    props.tracks.forEach((track) => {
      if (track.isFavorite && openTracks.indexOf(track.code) < 0) {
        openTracks.push(track.code);
      }
    });
    if (!isEqual(openTracks, props.openTracks)) {
      props.toggleOpenTrackRow(openTracks);
    }
  }
};

export class TracksComponent extends Component {
  static defaultProps = {
    tracks: [],
    isLoading: false,
    device: "mobile",
    wagerProfile: "PORT-Generic",
    isLogged: false,
    accountId: "0",
    title: "Tracks",
    openTracks: [],
    featuredTracks: [],
    fetchFinishedTracks: false,
    amountOfFavoriteTracks: 0,
    shouldUpdate: false,
    setFavoriteTrack: noop,
    removeFavoriteTrack: noop,
    storedFavoriteTracks: [],
    toggleOpenTrackRow: noop,
    trackType: TracksComponents.TOP_TRACKS,
    raceTypeFilters: [],
    regionFilters: [],
    hasError: false,
    signalLoadedComponent: noop,
    clearRaceFilters: noop,
    clearRegionFilters: noop,
    refetch: noop,
    dispatch: noop,
    emptyMessages: {},
    useIsPromoTagShownFlag: false
  };

  TracksObject;

  lettersRendered = [];

  currentTrackChar;

  constructor(props) {
    super(props);
    this.state = {
      firstRender: true,
      innerUpdate: false,
      favorites: {},
      forceRefetch: false
    };
    this.TracksObject = getTracksType(props.trackType);
    this.currentTrackChar = get(props.tracks, "[0].name", "A").charAt(0);
  }

  handleForceRefetch(forceRefetch) {
    this.setState({ forceRefetch });
  }

  shouldComponentUpdate = (nextProps) =>
    nextProps.shouldUpdate &&
    (!isEqual(this.props.tracks, nextProps.tracks) ||
      !isEqual(this.props.isLogged, nextProps.isLogged) ||
      !isEqual(this.props.featuredTracks, nextProps.featuredTracks) ||
      !isEqual(
        this.props.amountOfFavoriteTracks,
        nextProps.amountOfFavoriteTracks
      ) ||
      !isEqual(this.props.openTracks, nextProps.openTracks) ||
      !isEqual(this.props.isLoading, nextProps.isLoading) ||
      this.props.shouldUpdate !== nextProps.shouldUpdate ||
      nextProps.hasError);

  componentDidUpdate = (prevProps) => {
    if (!prevProps.isLogged && this.props.isLogged) {
      this.handleForceRefetch(true);
    }

    if (!this.props.isLoading && prevProps.isLoading) {
      if (this.state.forceRefetch) {
        this.props.refetch();
        this.handleForceRefetch(false);
      }
      setTimeout(() => {
        this.setState({ firstRender: false });
      }, 5000);
    }
    this.signalLoadedComponent();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    addFavoritesIntoOpenTracks(nextProps, prevState.firstRender);

    if (
      !get(nextProps, "isLoading", false) &&
      (get(nextProps, "tracks.length", 0) ||
        get(nextProps, "featuredTracks.length", 0) ||
        nextProps.trackType === TracksComponents.FAVORITE_TRACKS ||
        nextProps.trackType === TracksComponents.RESULTED_TRACKS_AZ)
    ) {
      return {
        firstRender: false
      };
    }

    return null;
  }

  onTrackClickHandler = (
    trackName,
    trackCode,
    trackLabels,
    trackType,
    isOpen,
    isGreyhound
  ) => {
    const openTracks = this.toggleOpenTracks(trackCode);
    this.props.toggleOpenTrackRow(openTracks);
    trackGTMTagging(
      trackName,
      trackLabels,
      isOpen,
      this.props.trackType,
      isGreyhound
    );
  };

  signalLoadedComponent = () => {
    if (
      (!this.props.isLoading && this.props.shouldUpdate) ||
      get(this.props, "tracks.length") ||
      get(this.props, "featuredTracks.length.length") ||
      !(this.props.isLoading && this.state.firstRender)
    ) {
      this.props.signalLoadedComponent(this.props.trackType);
    }
  };

  trackDropdownIsOpen = (trackCode) =>
    this.props.openTracks.indexOf(trackCode) > -1;

  toggleOpenTracks = (trackCode) => toggle(this.props.openTracks, trackCode);

  handleFavoriteChange = (isAdding, trackCode) => {
    const track =
      this.props.isAccountCompliant ||
      !this.TracksObject.usesRDA ||
      this.props.trackType === TracksComponents.TRACKS_AZ ||
      this.props.trackType === TracksComponents.RESULTED_TRACKS_AZ
        ? this.props.tracks.find((tempTrack) => tempTrack.code === trackCode)
        : this.props.featuredTracks.find(
            (tempTrack) => tempTrack.code === trackCode
          );
    if (track && isAdding && this.props.isAccountCompliant) {
      return this.addTrackToFavorites(track);
    }
    if (track && this.props.isAccountCompliant) {
      return this.removeTrackFromFavorites(track);
    }
    if (track) {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) =>
            /* istanbul ignore next */
            !error && success && this.addFavoriteAfterLoginWall(success, track),
          triggerAction: "tracks_favorite_change"
        }
      });
    }

    return Promise.resolve();
  };

  handleClearFilters = () => {
    filtersService.setRaceFilterPreference([]);
    filtersService.setRegionFilterPreference([]);
    this.props.clearRaceFilters();
    this.props.clearRegionFilters();
  };

  addFavoriteAfterLoginWall = (success, track) => {
    if (get(success, "status", "fail") === "success") {
      const accountId = get(success, "data.userDetails.accountNumber", null);
      if (accountId) {
        AlchemerEvents.setTrackAsFavorite();
        ufcClient
          .addFavorite(accountId, "track", [get(track, "code")])
          .then(() => {
            mediator.base.dispatch(
              createTopTracksFavoriteAction(
                track.name,
                this.props.trackType,
                true,
                track.isGreyhound
              )
            );
            // add track to open tracks
            const openTracks = this.props.openTracks.slice();
            if (openTracks.indexOf(track.code) < 0) {
              openTracks.push(track.code);
            }
            this.props.toggleOpenTrackRow(openTracks);
            this.props.refetch();
          });
      }
    }
  };

  addTrackToFavorites = (track) =>
    ufcClient
      .addFavorite(this.props.accountId, "track", [track.code])
      .then((resp) => {
        const favoriteId = get(resp, "data[0].favoriteId");
        this.props.setFavoriteTrack(track.code, favoriteId);
        AlchemerEvents.setTrackAsFavorite();
        mediator.base.dispatch(
          createTopTracksFavoriteAction(
            track.name,
            this.props.trackType,
            true,
            track.isGreyhound
          )
        );
        mediator.base.dispatch({
          type: "UPDATE_SESSION_FAVORITE_TRACKS",
          payload: { isAddingFavorite: true, trackCode: track.code, favoriteId }
        });
        // add track to open tracks
        const openTracks = this.props.openTracks.slice();
        if (openTracks.indexOf(track.code) < 0) {
          openTracks.push(track.code);
        }
        this.props.toggleOpenTrackRow(openTracks);
        this.props.refetch();
      });

  removeTrackFromFavorites = (track) => {
    const favoriteId = get(track, "favorite.id");

    if (!favoriteId) {
      return Promise.reject();
    }

    return ufcClient
      .removeFavorite(this.props.accountId, favoriteId)
      .then(() => {
        this.props.removeFavoriteTrack(track.code);

        const isAdding = false;
        mediator.base.dispatch(
          createTopTracksFavoriteAction(
            track.name,
            this.props.trackType,
            isAdding,
            track.isGreyhound
          )
        );
        mediator.base.dispatch({
          type: "UPDATE_SESSION_FAVORITE_TRACKS",
          payload: {
            isAddingFavorite: isAdding,
            trackCode: track.code,
            favoriteId
          }
        });
        // remove track from open tracks
        const openTracks = this.props.openTracks.slice();
        if (openTracks.indexOf(track.code) >= 0) {
          openTracks.splice(openTracks.indexOf(track.code), 1);
        }
        this.props.toggleOpenTrackRow(openTracks);
        this.props.refetch();
      });
  };

  renderExpandableTrack = (track, index) => {
    const trackChar = track.name.charAt(0);
    const expandProps = {
      device: this.props.device,
      key: `track${track.code}`,
      trackType: this.props.trackType,
      idPrefix: this.props.trackType,
      track,
      openTracks: get(this.props, "openTracks", []),
      handleFavoriteChange: this.handleFavoriteChange,
      onTrackClickHandler: this.onTrackClickHandler,
      trackDropdownIsOpen: this.trackDropdownIsOpen,
      shouldUpdate: this.props.shouldUpdate,
      accountId: this.props.accountId,
      rdaClient: this.props.rdaClient,
      isFavorite: get(track, "isFavorite", false),
      isLogged: this.props.isLogged
    };
    const expandableTrack = (
      <ExpandableTrack
        {...expandProps}
        storedFavoriteTracks={this.props.storedFavoriteTracks}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
    );

    if (this.TracksObject.renderTracksLetters) {
      if (this.currentTrackChar !== trackChar || index === 0) {
        const inState = this.lettersRendered.indexOf(trackChar);
        if (inState === -1) {
          this.lettersRendered.push(trackChar);
          this.currentTrackChar = trackChar;
          return (
            <Fragment key={`TrackSplitter-${trackChar}`}>
              <TrackSplitter letter={trackChar} />
              {expandableTrack}
            </Fragment>
          );
        }
      }
    }
    return expandableTrack;
  };

  renderTracks = () => {
    const { isLogged, tracks, featuredTracks } = this.props;
    return isLogged ||
      !this.TracksObject.usesRDA ||
      this.props.trackType === TracksComponents.TRACKS_AZ ||
      this.props.trackType === TracksComponents.RESULTED_TRACKS_AZ
      ? tracks.map((track, index) => this.renderExpandableTrack(track, index))
      : featuredTracks.map((track, index) =>
          this.renderExpandableTrack(track, index)
        );
  };

  render() {
    const Template = this.TracksObject.template;
    this.lettersRendered = [];
    return (
      <Template
        device={this.props.device}
        templateTitle={this.props.title}
        isLoading={this.props.isLoading && this.state.firstRender}
        isLogged={this.props.isLogged}
        hasError={this.props.hasError}
        onClearFilters={this.handleClearFilters}
        numberOfFavorites={this.props.storedFavoriteTracks.length}
        hasActiveFilters={
          this.props.regionFilters.length || this.props.raceTypeFilters.length
        }
        tracks={this.props.tracks}
        emptyMessages={this.props.emptyMessages}
      >
        {this.props.trackType === TracksComponents.TOP_TRACKS
          ? this.renderTracks().slice(0, 10)
          : this.renderTracks()}
      </Template>
    );
  }
}

export const mapStateToProps = (store) => ({
  wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
  openTracks: get(store, "topTracks.openTracks"),
  isLogged: get(store, "userData.logged", false),
  accountId: getAccountNumber(store),
  raceTypeFilters: get(store, "raceFilters.raceTypeFilters"),
  regionFilters: get(store, "raceFilters.regionFilters"),
  amountOfFavoriteTracks: get(store, "userFavorites.tracks.length", 0),
  storedFavoriteTracks: get(store, "userFavorites.tracks", []),
  emptyMessages: attempt(() =>
    JSON.parse(get(store, "capi.messages.tracksEmptyMessages", {}))
  ),
  useIsPromoTagShownFlag: get(
    store,
    "capi.featureToggles.useIsPromoTagShownFlag",
    false
  ),
  isAccountCompliant: isAccountCompliantSelector(store)
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setFavoriteTrack: (trackCode, favoriteId) =>
    dispatch(setFavoriteTrack(trackCode, favoriteId)),
  removeFavoriteTrack: (trackCode) => dispatch(removeFavoriteTrack(trackCode)),
  toggleOpenTrackRow: (openTracks) => dispatch(toggleOpenTrackRow(openTracks)),
  clearRaceFilters: () => dispatch(setRaceFiltersFromPreferences([])),
  clearRegionFilters: () => dispatch(setRegionFiltersFromPreferences([]))
});

const getSkipQuery = (props) => getTracksType(props.trackType).skipQuery(props);
const getSkipRDAQuery = (props) =>
  getTracksType(props.trackType).skipRDAQuery(props);
const getQuery = (trackType) => {
  const component = getTracksType(trackType);
  return component.onlyUsesRDA ? component.rdaQuery : component.query;
};
const getRDAQuery = (trackType) => {
  const component = getTracksType(trackType);
  return component.usesRDA ? component.rdaQuery : component.query;
};
const getApolloOptions = (props) =>
  getTracksType(props.trackType).graphOptions.options(props);
const getApolloProps = (props) =>
  getTracksType(get(props, "ownProps.trackType")).graphOptions.props(props);

const connectAnComposeTracksComponent = (trackType) =>
  connect(
    /* istanbul ignore next */
    mapStateToProps,
    /* istanbul ignore next */
    mapDispatchToProps
  )(
    compose(
      graphql(getQuery(trackType), {
        options: getApolloOptions,
        props: getApolloProps,
        skip: getSkipQuery
      }),
      graphql(getRDAQuery(trackType), {
        options: getApolloOptions,
        props: getApolloProps,
        skip: getSkipRDAQuery
      })
    )(TracksComponent)
  );

export const TopTracks = connectAnComposeTracksComponent(
  TracksComponents.TOP_TRACKS
);
export const TracksAZ = connectAnComposeTracksComponent(
  TracksComponents.TRACKS_AZ
);
export const FavoriteTracks = connectAnComposeTracksComponent(
  TracksComponents.FAVORITE_TRACKS
);

export const ResultedTracksAZ = connectAnComposeTracksComponent(
  TracksComponents.RESULTED_TRACKS_AZ
);

export const ResultedFavoriteTracks = connectAnComposeTracksComponent(
  TracksComponents.RESULTED_FAVORITE_TRACKS
);
