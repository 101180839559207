import React, { type FC } from "react";
import { SeoContainer } from "./styled-components";
import { Header, Paragraph } from "../Typography";
import type { SeoAreaProps } from "./types";

export const SeoArea: FC<SeoAreaProps> = ({
  title,
  message,
  tag = "h1",
  qaLabel = "seo-area"
}) => (
  <SeoContainer data-qa-label={qaLabel}>
    <Header
      qaLabel="seo-title"
      tag={tag}
      fontSize={18}
      marginBottom="space-4"
      display="block"
      fontFamily="bold"
    >
      {title}
    </Header>
    <Paragraph
      qaLabel="seo-content"
      color="var(--fd-colors-core-grey)"
      lineHeight="s"
      whiteSpace="normal"
    >
      <span
        data-qa-label="seoMessage"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Paragraph>
  </SeoContainer>
);

export type { SeoAreaProps };
