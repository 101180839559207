export const captionOptions = {
  backgroundColor: "#000000",
  backgroundOpacity: 75,
  color: "#ffffff",
  edgeStyle: "none",
  fontFamily: "Arial",
  fontOpacity: 100,
  fontSize: 8,
  windowColor: "#000000",
  windowOpacity: 0
};
