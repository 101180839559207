import {
  Modal,
  Paragraph,
  breakpoints,
  useMediaQuery
} from "@tvg/design-system";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import React from "react";
import { Props } from "./types";
import VideoPlayer from "../VideoPlayer";
import { Body, Description } from "./styled-components";
import { CONTAINER, MODAL, TEXT } from "../../constants";

const VideoPlayerModal = ({ item, onClose, qaLabel = "" }: Props) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const colors = useColorTokens();

  const navigate = useNavigate();

  const isInternal = (url: string) =>
    url.includes(window.location.host) || url.startsWith("/");

  const extractInternalUrl = (url: string) =>
    url.startsWith(window.location.origin)
      ? url.substring(window.location.origin.length)
      : url;

  const handleClick = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const element = e.currentTarget as HTMLAnchorElement;
    const href = element.getAttribute("href") || "";
    const isInternalLink = isInternal(href);

    if (isInternalLink) {
      const internalUrl = extractInternalUrl(href);
      navigate(internalUrl);
    } else {
      openExternalLink(href);
    }
  };

  const parseWithTarget = (description: string) => {
    DOMPurify.addHook("afterSanitizeAttributes", (node) => {
      if ("target" in node) {
        const href = node.getAttribute("href") || "";
        node.setAttribute("id", href);

        setTimeout(() => {
          const element = document.getElementById(href);
          if (!element) return;

          element.addEventListener("click", handleClick);
        }, 50);
      }
    });

    return DOMPurify.sanitize(description);
  };

  return (
    <Modal
      isOpen={!!item}
      onClose={onClose}
      onOverlayClick={onClose}
      title={item?.description.title ?? ""}
      type={isMobile ? "fullscreen" : "lightbox"}
      padding={0}
      qaLabel={buildQaLabel([qaLabel, MODAL, CONTAINER])}
    >
      <>
        {item && (
          <div>
            <VideoPlayer item={item} qaLabel={buildQaLabel([qaLabel, MODAL])} />
            <Body>
              <Description
                data-qa-label={buildQaLabel([qaLabel, MODAL, TEXT])}
                dangerouslySetInnerHTML={{
                  __html:
                    typeof window !== "undefined"
                      ? parseWithTarget(item.description.description)
                      : ""
                }}
              />
              <Paragraph
                qaLabel={buildQaLabel([qaLabel, MODAL, TEXT])}
                fontFamily="condensedRegular"
                textTransform="uppercase"
                color={colors.content.subtle}
                fontSize={12}
                textAlign="right"
              >
                {item.description.uploadedAt}
              </Paragraph>
            </Body>
          </div>
        )}
      </>
    </Modal>
  );
};

export default VideoPlayerModal;
