import React, { PureComponent, Fragment } from "react";

import {
  WatchLiveLabel,
  ButtonContainer,
  LogoContainer,
  TvgLiveLink
} from "./styled-components";

import ArrowRightSVG from "../../_static/SvgImages/ArrowRight";

import { LogoFanduelTV, LogoFanduelRacing } from "../../_static/Logos";

export default class LiveButtonsGroup extends PureComponent {
  static defaultProps = {
    className: "",
    tvg1Link: null,
    tvg2Link: null
  };

  renderMainBlock = () => (
    <Fragment>
      {this.props.tvg1Link && (
        <TvgLiveLink to={this.props.tvg1Link} data-qa-label="tvg-live-channel1">
          <ButtonContainer>
            <LogoContainer>
              <LogoFanduelTV />
            </LogoContainer>
            <WatchLiveLabel>
              Watch Live <ArrowRightSVG />
            </WatchLiveLabel>
          </ButtonContainer>
        </TvgLiveLink>
      )}

      {this.props.tvg2Link && (
        <TvgLiveLink to={this.props.tvg2Link} data-qa-label="tvg-live-channel2">
          <ButtonContainer>
            <LogoContainer>
              <LogoFanduelRacing />
            </LogoContainer>
            <WatchLiveLabel>
              Watch Live <ArrowRightSVG />
            </WatchLiveLabel>
          </ButtonContainer>
        </TvgLiveLink>
      )}
    </Fragment>
  );

  render() {
    return this.props.className ? (
      <div className={this.props.className}>{this.renderMainBlock()}</div>
    ) : (
      this.renderMainBlock()
    );
  }
}
