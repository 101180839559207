import React, { PureComponent } from "react";
import { noop } from "lodash";

import { AlertLineContainer, Title, Image } from "./styled-components";
import Toggle from "../../_atom/Toggle";

export default class AlertLine extends PureComponent {
  static defaultProps = {
    pictureUrl: "",
    title: "",
    onToggleClick: noop,
    isActive: false,
    qaLabel: ""
  };

  render() {
    const qaLabel = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {})
    };
    return (
      <AlertLineContainer {...qaLabel}>
        {this.props.pictureUrl && (
          <Image src={this.props.pictureUrl} alt={this.props.title} />
        )}
        <Title>{this.props.title}</Title>
        <Toggle
          on={this.props.isActive}
          onClick={() => this.props.onToggleClick(this.props.isActive)}
        />
      </AlertLineContainer>
    );
  }
}
