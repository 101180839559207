import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { Mtp } from "@tvg/design-system";
import { MTPContainer } from "./styled-components";

import { Props } from "./types";
import { QA_LABEL } from "../../qaLabels";

const MtpValue = ({ race }: Props) => (
  <MTPContainer>
    <Mtp
      qaLabel={QA_LABEL.body.mtp}
      isCollapsed
      mtp={race.mtp}
      postTime={race.postTime}
      status={RaceStatusEnum.OPEN}
      paddingRight="space-4"
      fontWeight="medium"
      hasFullText={false}
      whiteSpace="nowrap"
    />
  </MTPContainer>
);

export default MtpValue;
