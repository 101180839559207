import { gql } from "@apollo/client";

export const TRACK = gql`
  fragment Track on Track {
    id
    code
    shortName
    name
    featured
    hasAboveTheLinePromo(product: $product, brand: $brand)
    numberOfRaces
    isGreyhound
    isFinished
    isPromoTagShown
  }

  query getTracksAZ(
    $wagerProfile: String
    $sortByName: TrackListSort
    $trackFilters: TrackListFilter
    $product: String
    $brand: String
  ) {
    tracks: tracks(
      profile: $wagerProfile
      sort: $sortByName
      filter: $trackFilters
    ) {
      ...Track
    }
  }
`;

export default TRACK;
