export enum WagerProfile {
  PORT_GENERIC = "PORT-Generic",
  FDR_GENERIC = "FDR-Generic",
  PORT_FL = "PORT-FL",
  PORT_NJ = "PORT-NJ",
  PORT_PA = "PORT-PA",
  PORT_IA = "PORT-IA"
}

export interface Address {
  streetNumber: string;
  address1: string;
  address2: string;
  city: string;
  stateAbbr: string;
  state: string;
  zipCode: string;
  country: string;
  type: AddressSelection;
}

export type AddressSelection = "RESIDENTIAL" | "MAILING";

export enum AccountRolesEnum {
  USER = "USER",
  TALENT = "TALENT",
  WAGEROPS = "WAGEROPS",
  ADMIN = "ADMIN"
}

export enum RolesMap {
  TALENT = "TALENT",
  WAGEROPS = "WAGEROPS",
  ADMIN = "ADMIN"
}

export interface UserInfo {
  accountNumber: string;
  emailAddress: string;
  firstName: string;
  homeAddress: Address;
  lastName: string;
  mailingAddress: Address;
  primaryPhone: string;
  profile: string;
  signalProviderId: string;
  status: string;
  transportId: string;
  userName: string;
  wagerStatus: number;
  accountRoles: Array<AccountRolesEnum>;
  currentLocationByState?: string;
  hasMadePreviousWager?: boolean | null;
}

export interface User {
  logged: boolean;
  user: UserInfo;
}

export interface UserPromo {
  id: number;
  optedIn: boolean;
  path: string;
}

export type UserOptedInPromos = Record<string, boolean>;

export type FavoriteTrackWithId = Record<string, string>;

export interface BrazeCardNumber {
  unviewed: number;
  totalCards: number;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  cards: Array<any>; // Array<BrazeContentCard>;
}

export interface SSNObject {
  firstName: string;
  lastName: string;
  yearOfBirth: number;
  monthOfBirth: number;
  dayOfBirth: number;
  address: string;
  zipCode: string;
  lastFourSSN: string;
}

export interface AddressParam {
  streetNumber: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export type UserDetailsSubscription = Record<"email", boolean>;

export interface UserDetailsSignalProvider {
  name: string;
  providerId: string;
}

export interface UserDetails {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  homeAddress: AddressParam;
  mailAddress: AddressParam;
  sameMailingAddress: boolean;
  socialSecurityNumber: string;
  phone: string;
  subscriptions: UserDetailsSubscription;
  signalProvider: UserDetailsSignalProvider;
}

export interface SignupDetails {
  promoCode: string;
  campaignCode: string;
  rfr: string;
}
