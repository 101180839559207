import styled from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space["space-5"]};
`;

export const ParagraphContainer = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
`;

export const DialogContainer = styled.div`
  padding: ${({ theme }) => theme.space["space-5"]};
  width: 100%;

  @media screen and ${breakpoints.desktop.min.sm} {
    width: 35%;
    height: 428px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
