import { gql } from "@apollo/client";

const SearchByOwnerFragment = {
  entry: gql`
    fragment searchByOwner on RaceEntities {
      owners {
        name
        dob
        entityRunnerId
      }
    }
  `
};

export default SearchByOwnerFragment;
