import { QuickLink } from "@tvg/ts-types/Links";
import { inspectURL, processURL } from "@tvg/formatter/url";
import tvgConf from "@tvg/conf";

const conf = tvgConf();

export const parseInternalURL = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.href.replace(urlObj.origin, "");
};

export const checkMarketingUrl = (
  url: string,
  enablePromosStoryblok?: boolean
): boolean => !enablePromosStoryblok && url.includes("/promos");

export const processLinksData = (
  links: QuickLink[],
  enablePromosStoryblok: boolean
): QuickLink[] => {
  const processedLinks: QuickLink[] = [];
  links.forEach((singleLink) => {
    const linkType = inspectURL(singleLink.url, conf.getDomains());
    switch (linkType) {
      case "isInternal":
        processedLinks.push({
          ...singleLink,
          url: parseInternalURL(processURL(singleLink.url)),
          newTab: checkMarketingUrl(singleLink.url, enablePromosStoryblok)
        });
        break;
      case "isExternal":
        processedLinks.push({
          ...singleLink,
          url: processURL(singleLink.url),
          newTab: true,
          isExternal: true
        });
        break;
      default:
        processedLinks.push({
          ...singleLink,
          newTab: checkMarketingUrl(singleLink.url, enablePromosStoryblok)
        });
    }
  });
  return processedLinks;
};
