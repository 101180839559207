import tvgConf from "@tvg/conf";
import { get, orderBy } from "lodash";

import { getPortByBrand } from "@tvg/utils/generalUtils";

const conf = tvgConf();

const POLL_INTERVAL = 60000;

const removeFalsePinned = (topPools) =>
  topPools.map((pool) =>
    get(pool, "race.highlighted.pinnedOrder", 0) === 0
      ? {
          ...pool,
          race: {
            ...pool.race,
            highlighted: { ...pool.race.highlighted, pinnedOrder: null }
          }
        }
      : pool
  );

const getPools = (topPools) =>
  orderBy(
    removeFalsePinned(topPools),
    [(pool) => get(pool, "race.highlighted.pinnedOrder", null), "amount"],
    ["asc", "desc"]
  );

export default {
  skip: (props) => !get(props, "shouldUpdate"),
  options: (props) => {
    const variables = {
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      ...conf.graphContext()
    };

    return {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables
    };
  },
  props: (result) => ({
    pools: getPools(get(result, "data.carryOverPools", [])),
    isLoading: result.data.loading
  })
};
