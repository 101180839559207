import styled from "styled-components";
import { Props, StyledProps } from "./types";

export const Container = styled.div<StyledProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.subtle}`};
  height: ${({ isOpen, contentHeight, minHeight }) =>
    isOpen
      ? typeof contentHeight === "number"
        ? `${contentHeight + minHeight}px`
        : "auto"
      : `${minHeight}px`};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  max-width: 100vw;
  width: 100%;
`;

export const Summary = styled.div`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-2"]} ${theme.space["space-4"]} ${theme.space["space-5"]}`};
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: 24px;
  cursor: pointer;
`;

export const Content = styled.div<Pick<Props, "isOpen">>`
  display: flex;
  height: auto;
  padding: ${({ theme }) => theme.space["space-4"]} 0;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: ${({ theme }) => theme.space["space-4"]};
  }
`;
