import React, { PureComponent } from "react";
import { noop } from "lodash";

import { alert, curvedArrow } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import {
  TalentPickFilterWrapper,
  AlertButton,
  IconHolder,
  AlertButtonDescription,
  TalentFilterSection,
  EmptyStateMessageContainer,
  Message,
  ArrowIcon
} from "./styled-components";
import TalentPickFilterSelector from "../../_molecule/TalentPickFilterSelector";
import Icon from "../../_static/Icons";

export default class TalentPickFilter extends PureComponent {
  static defaultProps = {
    talents: [],
    onClick: noop,
    activeFilters: [],
    device: "mobile",
    picksAlertsToggle: false,
    filterEmptyStateMessage: "",
    hasAlerts: false,
    enableTalentFilter: false,
    imageBaseURL: "//www.tvg.com"
  };

  renderAlertButton = () => (
    <AlertButton
      data-qa-label="talentPicksAlertsButton"
      device={this.props.device}
      onClick={() => this.props.onClick("open_alerts_modal")}
    >
      <IconHolder>
        <Icon icon={alert} color={buildColor("white", "100")} size={24} />
      </IconHolder>
      <AlertButtonDescription>Alerts</AlertButtonDescription>
    </AlertButton>
  );

  renderTalents = () =>
    this.props.enableTalentFilter && this.props.talents.length > 0
      ? this.props.talents.map((talent) => (
          <TalentPickFilterSelector
            key={talent.talentId}
            activeFilters={this.props.activeFilters}
            onClick={this.props.onClick}
            talent={talent}
            imageBaseURL={this.props.imageBaseURL}
          />
        ))
      : this.renderEmptyMessage();

  renderEmptyMessage = () =>
    this.props.hasAlerts && (
      <EmptyStateMessageContainer>
        <ArrowIcon
          device={this.props.device}
          icon={curvedArrow}
          color={buildColor("white", "100")}
          size={10}
        />
        <Message device={this.props.device}>
          {this.props.filterEmptyStateMessage}
        </Message>
      </EmptyStateMessageContainer>
    );

  render() {
    return (
      <TalentFilterSection data-qa-label="talentPicksFilterSection">
        <TalentPickFilterWrapper
          device={this.props.device}
          data-qa-label="talentPicksFilterList"
        >
          {this.props.picksAlertsToggle &&
            this.props.hasAlerts &&
            this.renderAlertButton()}
          {this.renderTalents()}
        </TalentPickFilterWrapper>
      </TalentFilterSection>
    );
  }
}
