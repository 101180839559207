import { gql } from "@apollo/client";

export const RACE = gql`
  fragment Race on TalentPickType {
    race {
      id
      tvgRaceId
      number
      track {
        id
        code
        name
      }
      mtp
      postTime
      isGreyhound
      status {
        code
      }
      type {
        code
      }
      video {
        onTvg
        onTvg2
      }

      highlighted(product: $product, device: $device, brand: $brand) {
        description
        style
      }
    }
  }
`;

export default RACE;
