import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

export const FilterSelectorWrapper = styled.button`
  width: 72px;
  position: relative;
  outline: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  ${(props) =>
    props.hasSelected &&
    !props.isActive &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    cursor: pointer;
  }
`;

export const TalentImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${buildColor("grey", "300")};
  object-fit: cover;
`;

export const TalentName = styled.span`
  padding-top: 3px;
  font-size: 10px;
  width: 68px;
  color: ${(props) =>
    props.isActive && props.hasSelected
      ? buildColor("white", "100")
      : buildColor("blue", "100")};
  text-align: center;
  line-height: 11px;
`;

export const TalentNumber = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "${(props) => props.number}";
    background-color: ${buildColor("red", "500")};
    color: ${buildColor("white", "100")};
    border-radius: 8px;
    margin-left: 4px;
    display: block;
    min-width: 12px;
    height: 16px;
    line-height: 16px;
    padding: 0 2px;
    text-align: center;
    font-weight: bold;
    font-size: 11px;
  }
`;
