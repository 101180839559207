import styled, { css } from "styled-components";

import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import ButtonDefault from "../Buttons/default";
import { generateTransition, cubic } from "../../_static/Transitions";

export const Selection = styled(ButtonDefault)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  background-color: ${buildColor("white", "0")};
`;

export const IconsWrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.span`
  display: inline-flex;
  width: 24px;
  height: 24px;
  border: 2px solid ${buildColor("green", "600")};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${buildColor("white", "100")};
  will-change: transform;
  transition: ${generateTransition(cubic, "transform")};
  transform: ${(props) => (props.checked ? "scale(0)" : "scale(1)")};
  position: relative;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: ${(props) =>
    !props.checked ? buildColor("green", "600") : buildColor("white", "100")};
  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        background-color: ${buildColor("green", "000")};
      }
    `}
`;

export const CircleChecked = styled(Circle)`
  position: absolute;
  background-color: ${buildColor("green", "600")};
  transform: ${(props) => (props.checked ? "scale(1)" : "scale(0)")};
`;

export const NumberIcon = styled(Icon)`
  position: absolute;
  border: 1px solid
    ${(props) =>
      !props.checked ? buildColor("green", "600") : buildColor("white", "100")};
  border-radius: 50%;
  top: -2px;
  right: -7px;
  background: ${(props) =>
    !props.checked ? buildColor("white", "100") : buildColor("green", "600")};
`;
