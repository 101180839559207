import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  padding-bottom: ${({ theme }) => theme.space["space-4"]};
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
  user-select: none;
`;

export const VideosRow = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 8px;

  & > :first-child {
    padding-left: ${({ theme }) => theme.space["space-5"]};
  }

  & > :last-child {
    padding-right: ${({ theme }) => theme.space["space-5"]};
  }
`;
