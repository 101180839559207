import { TracksFilterMsg, TracksSearchMsg } from "../types";

export const tracksFilterMsgDefault: TracksFilterMsg = {
  raceTypes: {
    title: "FILTER BY RACE TYPE",
    options: [
      {
        label: "T'Bred",
        value: "T"
      },
      {
        label: "Harness",
        value: "H"
      },
      {
        label: "Q'Horse",
        value: "Q"
      }
    ]
  },
  regions: {
    title: "BY REGION",
    options: [
      {
        label: "USA",
        value: "USA"
      },
      {
        label: "INT",
        value: "INT"
      }
    ]
  }
};

export const tracksSearchMsgDefault: TracksSearchMsg = {
  inputPlaceholder: "Search for a track or location...",
  buttonText: "Cancel"
};

export const tabsTracksPage = {
  tabs: [
    {
      id: "tracks",
      title: "Today",
      url: "/tracks",
      enabled: true
    },
    {
      id: "results",
      title: "Results",
      url: "/tracks/results",
      enabled: true
    },
    {
      id: "all-info",
      title: "All Tracks",
      url: "/tracks/all-info",
      enabled: true
    }
  ],
  tabsDesktop: [
    {
      id: "tracks",
      title: "Today",
      url: "/tracks",
      enabled: true
    },
    {
      id: "results",
      title: "Results",
      url: "/tracks/results",
      enabled: true
    },
    {
      id: "all-info",
      title: "All Tracks",
      url: "/tracks/all-info",
      enabled: true
    }
  ],
  todaysRacesEmpty: {
    title: "No races available",
    description:
      "Results will become available as soon as a race comes to an end."
  },
  resultsEmpty: {
    title: "No races available",
    description:
      "Results will become available as soon as a race comes to an end."
  },
  tracksInformationListEmpty: {
    title: "No tracks information available",
    description: "Currently there is not information on the tracks."
  }
};

export const topTracksConfig = {
  maxResults: 10
};
