import styled from "styled-components";
import { ContainerProps } from "./types";

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px 4px 0 0;
  gap: ${({ theme }) => theme.space["space-4"]};
  padding: ${({ theme }) => theme.space["space-5"]};
  border-bottom: 1px solid ${({ theme }) => theme.colorTokens.border.subtle};
  background-color: ${({ theme, hasDescription }) =>
    hasDescription
      ? theme.colorTokens.background.promotional
      : theme.colors.white["900"]};
`;
