export enum LoginModalMediatorEventType {
  START = "LOGIN_MODAL_START",
  ATTEMPT = "LOGIN_MODAL_LOGIN_ATTEMPT",
  SUCCESS = "LOGIN_MODAL_SUCCESS",
  ERROR = "LOGIN_MODAL_LOGIN_ERROR",
  FIELD_COMPLETE = "LOGIN_MODAL_FIELD_COMPLETE",
  SELECT_STATE = "LOGIN_MODAL_SELECT_STATE",
  FORGOT_CREDENTIALS = "LOGIN_MODAL_FORGOT_CREDENTIALS",
  LOCATION_MODAL_VIEWED = "LOGIN_MODAL_LOCATION_MODAL_VIEWED",
  LOCATION_MODAL_CLOSED = "LOGIN_MODAL_LOCATION_MODAL_CLOSED",
  LOCATION_MODAL_CTA_CLICKED = "LOGIN_MODAL_LOCATION_MODAL_CTA_CLICKED",
  LOGIN_SERVICE_LOADED = "OPEN_LOGIN_SUCCESS",
  LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPENED",
  LOGIN_ERROR_MODAL_VIEWED = "LOGIN_MODAL_DEFAULT_ERROR_VIEWD",
  LOGIN_ERROR_MODAL_CTA_CLICKED = "LOGIN_MODAL_DEFAULT_ERROR_CTA_CLICKED",
  LOGIN_ERROR_MODAL_CLOSED = "LOGIN_MODAL_DEFAULT_ERROR_CLOSED"
}

export enum ProgramPageMediatorEventType {
  URP_RUNNER_SELECTION = "PPM_TVG5_RUNNER_SELECTION",
  RUNNER_SELECTION = "PPM_RUNNER_SELECTION",
  SELECT_ALL_RUNNERS = "PPM_SELECT_ALL_RUNNERS",
  RESET_ALL_RUNNERS = "PPM_RESET_ALL_RUNNERS",
  SELECT_BET_TYPE = "PPM_SELECT_BET_TYPE",
  SET_BET_AMOUNT = "PPM_SET_BET_AMOUNT",
  BET_TIMEOUT = "PPM_BET_TIMEOUT",
  BET_NOW = "PPM_BET_NOW",
  DELETE_BET_TICKET = "PPM_DELETE_BET_TICKET",
  OPEN_RACE_NAVIGATION = "PPM_OPEN_RACE_NAVIGATION",
  HANDICAP_STORE_PRODUCT = "PPM_HANDICAP_STORE_PRODUC",
  HANDICAP_STORE_SEE_ALL = "PPM_HANDICAP_STORE_SEE_ALL",
  RUNNER_MODIFIER_FILTER_APPLIED = "PROGRAM_PAGE_RUNNER_MODIFIER_FILTER_APPLIED",
  RUNNER_MODIFIER_SORT_APPLIED = "PROGRAM_PAGE_RUNNER_MODIFIER_SORT_APPLIED",
  WILL_PAYS_SELECT_OPTION = "PPM_WILL_PAYS_SELECT_OPTION",
  PROBABLES_SELECT_OPTION = "PPM_PROBABLES_SELECT_OPTION",
  PROGRAM_RUNNER_INFO_MODAL_OPENED = "PROGRAM_RUNNER_INFO_MODAL_OPENED",
  PROGRAM_RUNNER_INFO_MODAL_CLOSED = "PROGRAM_RUNNER_INFO_MODAL_CLOSED",
  FAVORITE_INTERACTION = "PPM_FAVORITE_INTERACTION",
  POOLS_AND_INFO_MODAL_OPENED = "POOLS_AND_INFO_MODAL_OPENED",
  POOLS_AND_INFO_TAB_OPENED = "POOLS_AND_INFO_TAB_OPENED",
  HANDICAPPING_TAB_OPENED = "HANDICAPPING_TAB_OPENED",
  RUNNER_TAGS_EXPANDED = "RUNNER_TAGS_EXPANDED",
  PROGRAM_FREE_PP_DOWNLOAD = "PROGRAM_FREE_PP_DOWNLOAD",
  DROPDOWN_OPEN = "PPM_DROPDOWN_OPEN",
  DROPDOWN_RACE_SELECTED = "PPM_DROPDOWN_RACE_SELECTED"
}

export enum GlobalWalletMediatorEventType {
  BALANCE_EXPANDED = "BALANCE_EXPANDED",
  NAVIGATION = "MORE_NAVIGATION",
  ACCOUNT_NAVIGATION = "ACCOUNT_NAVIGATION",
  OPEN_CONTENT = "MORE_OPEN_CONTENT",
  SWITCH_TOGGLE = "MORE_SWITCH_TOGGLE",
  SUPPORT_TOGGLE = "SUPPORT_MODAL_TOGGLE",
  WITHDRAW_DEPOSIT = "WITHDRAWAL_AND_DEPOSIT_BUTTON",
  VIEW_BALANCE_DETAILS = "VIEW_BALANCE_DETAILS",
  PLAYABLE_BALANCE_MODAL = "PLAYABLE_BALANCE_MODAL"
}

export enum RaceCellMediatorEventType {
  CLICKED = "RACE_CELL_CLICKED"
}

export enum RaceCellModule {
  TRACKS_AZ = "tracks_az",
  FAVORITES = "favorites",
  UPCOMING_RACES = "upcoming_races",
  TIL = "tracks_information",
  POOLS = "top_pools",
  TOP_TRACKS = "top_tracks",
  WINNERS = "previous_winners"
}

export interface RaceCellEventData {
  type: RaceCellMediatorEventType;
  payload: {
    module: RaceCellModule;
    trackName: string;
    raceNumber: string;
    linkUrl: string;
    raceHasPromos: boolean;
    trackHasPromos: boolean;
    promoOptedIn: "true" | "false" | "none";
    isFDTV: boolean;
    trackCountry: string;
    tabActive?: string;
    activeFilters?: Record<string, string>[];
    filterName?: string;
    filterValue?: string;
  };
}

export enum TracksMediatorEventType {
  TRACK_CLICK = "TRACKS_TRACK_CLICK",
  RACE_NAVIGATION = "RACE_NAVIGATION",
  FILTER_CLICK = "TRACKS_FILTER_CLICK",
  TAB_OPENED = "TRACKS_TAB_OPENED",
  FILTER_APPLIED = "TRACKS_FILTER_APPLIED",
  SEARCH_APPLIED = "TRACKS_SEARCH_APPLIED",
  TRACKS_FAVORITE = "TRACKS_FAVORITE",
  ALL_FILTERS_MODAL_OPENED = "TRACKS_ALL_FILTERS_MODAL_OPENED"
}

export enum RaceTracksMediatorEventType {
  PAGE_VIEW = "RACETRACKS:PAGE_VIEW",
  TRACK_CLICK = "RACETRACKS:TRACK_CLICK",
  RACE_CLICK = "RACETRACKS:RACE_CLICK",
  ADD_FAVORITE_CLICK = "RACETRACKS:ADD_FAVORITE_CLICK",
  ADD_FAVORITE_SUCCESS = "RACETRACKS:ADD_FAVORITE_SUCCESS",
  SEE_ALL_TODAYS_RACING = "RACETRACKS:SEE_ALL_TODAYS_RACING",
  RETURN_TO_RACETRACKS = "RACETRACKS:RETURN_TO_RACETRACKS"
}

export interface RaceTracksDataPayload {
  trackName?: string;
  raceNumber?: number;
  mtp?: number;
  destinationUrl?: string;
}
