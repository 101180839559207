import React, { Component } from "react";
import { noop, get } from "lodash";

import buildRaceUrl, { buildTalentPickRaceUrl } from "@tvg/formatter/url";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { promo, promosolid } from "../../_static/Icons/icons";
import {
  RaceLinkAbbrNameWrapper,
  RaceLinkAbbrLink,
  LabelsWrapper,
  ResultsLabel,
  BetLabel,
  RaceLinkTitle,
  MtpContainer
} from "./styled-components";

import MTP from "../../_atom/MTP";

export default class RaceLinkAbbr extends Component {
  static defaultProps = {
    idPrefix: "",
    trackName: "",
    trackCode: "",
    number: "",
    hasBets: false,
    promo: null,
    hasResults: false,
    isGreyhound: false,
    postTime: null,
    mtp: null,
    showTrackCode: false,
    onRaceClick: noop,
    isTalentPicksContext: false,
    isMTPNewRules: false
  };

  renderLabels = () => {
    if (this.props.hasBets || this.props.promo) {
      const renderPromoIcon = () =>
        get(this.props.promo, "isOptedIn", false) ? (
          <Icon
            size={14}
            icon={promosolid}
            color={buildColor("yellow", "500")}
          />
        ) : (
          <Icon size={14} icon={promo} />
        );

      return (
        <LabelsWrapper>
          {this.props.hasBets && <BetLabel>B</BetLabel>}
          {get(this.props.promo, "isAboveTheLine", false) && renderPromoIcon()}
        </LabelsWrapper>
      );
    }

    return null;
  };

  render() {
    const id =
      this.props.idPrefix && this.props.trackCode && this.props.number
        ? `${this.props.idPrefix}_raceElement_${this.props.trackCode}_${this.props.number}`
        : "";

    const url = !this.props.isTalentPicksContext
      ? buildRaceUrl(
          this.props.trackCode,
          this.props.trackName,
          this.props.number
        )
      : buildTalentPickRaceUrl(
          this.props.trackCode,
          this.props.trackName,
          this.props.number
        );

    return (
      <RaceLinkAbbrLink
        onTouchStart={noop}
        id={id}
        data-qa-label="raceLinkAbbr"
        number={this.props.number}
        onClick={() => this.props.onRaceClick(url)}
        to={url}
      >
        <RaceLinkAbbrNameWrapper>
          <RaceLinkTitle>
            {this.props.showTrackCode && (
              <span
                data-qa-label="raceLinkAbbr-trackCode"
                style={{ marginRight: "3px" }}
              >
                {this.props.trackCode}
              </span>
            )}
            <span data-qa-label="raceLinkAbbr-raceNumber">
              R{this.props.number}
            </span>
          </RaceLinkTitle>
          {this.renderLabels()}
        </RaceLinkAbbrNameWrapper>
        <span>
          {this.props.hasResults ? (
            <ResultsLabel>RES</ResultsLabel>
          ) : (
            <MtpContainer smaller={this.props.raceStatus === "SK"}>
              <MTP
                mtp={this.props.mtp}
                postTime={this.props.postTime}
                status={this.props.raceStatus}
                paddingless
                isMTPNewRules={this.props.isMTPNewRules}
              />
            </MtpContainer>
          )}
        </span>
      </RaceLinkAbbrLink>
    );
  }
}
