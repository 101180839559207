import React from "react";
import { get } from "lodash";

import DerbyBanner from "@urp/derby-banner";
import { BannerContext, DerbyEventStatus } from "@urp/derby-banner/src/types";
import { useDerbyBannerConfig } from "../../hooks/useDerbyBannerConfig";

export const HomeHeader = () => {
  const { loading, derbyRace, bannerCfg, eventStatus, onBannerCtaClick } =
    useDerbyBannerConfig({
      context: BannerContext.HOME_PAGE
    });
  const postTimeAvailableRace = get(derbyRace, "postTime");

  if (loading) {
    return null;
  }

  return (
    <DerbyBanner
      raceStartDate={
        postTimeAvailableRace ? new Date(postTimeAvailableRace) : null
      }
      eventStatus={eventStatus as unknown as DerbyEventStatus}
      bannerCfg={bannerCfg}
      orientation="landscape"
      onCtaClick={onBannerCtaClick}
    />
  );
};

export default HomeHeader;
