import React, { FC, memo } from "react";
import { get } from "lodash";
import {
  StyledSaddle,
  StyledParagraph,
  WinnerIconWrapper,
  WinnerIcon,
  Scratcher,
  DiagonalLine
} from "./styled-components";
import {
  SaddleProps,
  Breed,
  HarnessVariation,
  ThoroughbredVariation,
  SaddleColor
} from "./types";
import { SADDLE_COLORS } from "./consts";

const getRunnerColors = (
  runnerNumber: number | string,
  breed: Breed = "thoroughbred"
): SaddleColor => {
  const runnerNumberInt =
    typeof runnerNumber === "string"
      ? parseInt(runnerNumber, 10)
      : runnerNumber;

  const breedFilter = get(SADDLE_COLORS, `[${breed}]`) ? breed : "thoroughbred";

  const { numberColor, saddleColor } = get(
    SADDLE_COLORS,
    `[${breedFilter}][${runnerNumberInt}]`,
    get(
      SADDLE_COLORS,
      breedFilter === "harness"
        ? `[${breedFilter}][15]`
        : `[${breedFilter}][20]`
    )
  );

  return {
    numberColor,
    saddleColor
  };
};

export const Saddle: FC<SaddleProps> = memo(
  ({
    all,
    number,
    qaLabel,
    numberColor,
    saddleColor,
    fullHeight,
    size,
    isWinner = false,
    breed = "thoroughbred",
    isScratched = false,
    raceFinished = false,
    enableMyBetsBehavior = false,
    ...rest
  }) => {
    const saddleNumber =
      typeof number === "number" ? number.toString() : number;

    const defaultColors = getRunnerColors(number, breed);
    const runnerColors: SaddleColor = {
      numberColor: numberColor || defaultColors.numberColor,
      saddleColor: saddleColor || defaultColors.saddleColor
    };

    return (
      <StyledSaddle
        data-qa-label={qaLabel || `saddle-container-${number}`}
        all={all}
        saddleColor={all ? "#C6D3E1" : runnerColors.saddleColor}
        fullHeight={fullHeight}
        size={size}
        isScratched={isScratched}
        raceFinished={raceFinished}
        enableMyBetsBehavior={enableMyBetsBehavior}
        {...rest}
      >
        {isScratched && size === "s" && enableMyBetsBehavior && (
          <Scratcher size={size}>
            <DiagonalLine />
          </Scratcher>
        )}

        {isWinner && (
          <WinnerIconWrapper>
            <WinnerIcon
              size="s"
              name="tick"
              lineColor="#FFFFFF"
              backgroundColor="#128000"
            />
          </WinnerIconWrapper>
        )}
        <StyledParagraph
          fontFamily="bold"
          fontSize="s"
          color={all ? "#0A0A0A" : runnerColors.numberColor}
          textTransform={all ? "capitalize" : "none"}
          qaLabel="saddle-number"
          size={size}
        >
          {all ? saddleNumber.toLowerCase() : saddleNumber}
        </StyledParagraph>
      </StyledSaddle>
    );
  }
);

export default Saddle;
export { SADDLE_COLORS, getRunnerColors };
export type { SaddleProps, Breed, HarnessVariation, ThoroughbredVariation };
