import { gql } from "@apollo/client";

const PastRaceBettingInterestsFragment = {
  entry: gql`
    fragment bettingInterestsFragment on PastRace {
      number
      description
      purse
      date
      postTime
      track {
        code
        name
      }
      surface {
        code
        name
      }
      distance {
        value
        code
        name
      }
      isGreyhound
      type {
        id
        code
        name
      }
      raceClass {
        code
        name
      }
      video {
        replayFileName
      }
    }
  `
};

export default PastRaceBettingInterestsFragment;
