import React, { Component, Fragment } from "react";
import { noop, times, get, isEqual } from "lodash";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { events as AlchemerEvents } from "@urp/alchemer";

import RunnerNumber from "../../_atom/RunnerNumber";
import RunnerOdds from "../../_atom/RunnerOdds";
import RunnerBio from "../RunnerBio";
import SelectionButton from "../../_atom/SelectionButton";
import {
  RunnerWrapper,
  RunnerNumberWrapper,
  RunnerOddWrapper,
  RunnerBioWrapper,
  RunnerSelectionWrapped,
  SecondRow,
  AnimateContainer,
  RunnerTrackMasterInfo,
  BioSelectionsWrapper,
  RunnerNumberContainer,
  SilkContainer,
  Silk
} from "./styled-components";

export default class RunnerLine extends Component {
  static defaultProps = {
    biNumber: 0,
    leg: 0,
    raceTypeCode: "T",
    currentOddsNumerator: 0,
    currentOddsDenominator: 0,
    morningLineOddsNumerator: 0,
    morningLineOddsDenominator: 0,
    jockeyKannelName: "",
    trainerName: "",
    isFavorite: false,
    selections: [false],
    isWagerable: true,
    onRunnerSelect: noop,
    numColumns: 1,
    isKey: false,
    isLeg: false,
    firstHandicapBold: false,
    isFavoriteSelectionActive: false,
    favoriteSelection: false,
    expandedRunner: "",
    expandLine: noop,
    expandInfo: noop,
    inLinePastPerformance: false,
    isRunnerTrackMasterPick: false,
    isRunnerNumberFirePick: false,
    isRunnerRacingAndSports: false,
    handicappingSortMode: "saddlecloth",
    redirectToRaceCard: false,
    raceUrl: "/",
    hasTrackMasterInfo: true,
    onRunnerSelection: noop,
    race: null,
    hasHover: false,
    showActiveState: true,
    trackDataSource: "",
    runners: [],
    currentRace: {},
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    scrollRunnerPP: false,
    scrollOffset: 0,
    doesSilkLoads: true
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { expandedRunner: expandedRunnerNext, ...restNext } = nextProps;
    const { expandedRunner: expandedRunnerProps, ...restProps } = this.props;
    const expandedRunnerChange =
      expandedRunnerNext !== expandedRunnerProps &&
      (expandedRunnerNext === restProps.runnerData.runnerId ||
        expandedRunnerProps === restProps.runnerData.runnerId);
    return (
      !isEqual(restNext, restProps) ||
      expandedRunnerChange ||
      !isEqual(this.props.currentRace, nextProps.currentRace)
    );
  }

  handleLineSelection = (direction) => {
    if (direction === "down") {
      AlchemerEvents.selectHandicapOptions();
    }
    this.props.expandLine({
      runnerId: this.props.runnerData.runnerId,
      selection: this.props.biNumber.toString(),
      column: this.props.leg,
      fromIcon: false,
      direction: direction.toString(),
      isScratched: this.props.runnerData.scratched
    });
  };

  handleSelectionButtonClick = (column, event) =>
    this.props.redirectToRaceCard && this.props.race
      ? this.props.onRunnerSelection(
          this.props.race,
          this.props.biNumber,
          this.props.raceUrl,
          event
        )
      : this.props.onRunnerSelect(
          this.props.biNumber.toString(),
          this.props.leg > 0 ? this.props.leg : column,
          true
        );

  handleLineClick = (direction) =>
    !this.props.redirectToRaceCard && this.handleLineSelection(direction);

  showRunnerSilk = (isWagerable, runnerTimeform) =>
    this.props.doesSilkLoads &&
    !isWagerable &&
    runnerTimeform &&
    runnerTimeform.silkUrlSvg;

  scrollIntoView = () => {
    const element = this.ref;
    const tabletColumn = document.querySelector(
      "#programpage-dualColumn-right"
    );

    const containerOffset = tabletColumn
      ? tabletColumn.scrollTop
      : window.pageYOffset;

    const scrollPosition =
      element.getBoundingClientRect().top +
      containerOffset -
      this.props.scrollOffset;

    if (tabletColumn) {
      const header = document.querySelector("#raceCardHeader");
      const headerHeight = header ? header.offsetHeight : 0;
      // this is because of the auto height from magnet scroll that is breaking scroll
      setTimeout(() => {
        tabletColumn.scrollTo({
          top: scrollPosition - headerHeight,
          behavior: "smooth"
        });
      }, 200);
    } else {
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  };

  ref;

  renderSelectionButtons = () => {
    const selectionType = (() => {
      if (!this.props.isKey) {
        return this.props.numColumns === 1 ? "default" : "number";
      }

      return "key";
    })();
    const columns = times(this.props.numColumns, (column) =>
      !this.props.redirectToRaceCard ? (
        <SelectionButton
          key={`selection-${column + 1}`}
          selectionType={
            this.props.isKey && column > 0 ? "default" : selectionType
          }
          number={column + 1}
          isChecked={!!this.props.selections[column]}
          isScratched={this.props.runnerData.scratched}
          onChange={() => this.handleSelectionButtonClick(column)}
          hasHover={this.props.hasHover}
        />
      ) : (
        <Link
          key={`selection-${column + 1}`}
          to={`${this.props.raceUrl}&selectedRunner=${get(
            this.props,
            "biNumber"
          )}&betType=10`}
          onClick={(event) => this.handleSelectionButtonClick(column, event)}
        >
          <SelectionButton
            selectionType={
              this.props.isKey && column > 0 ? "default" : selectionType
            }
            redirectToRacecard
            number={column + 1}
            isChecked={!!this.props.selections[column]}
            isScratched={this.props.runnerData.scratched}
            hasHover={this.props.hasHover}
          />
        </Link>
      )
    );

    return (
      <RunnerSelectionWrapped
        vertical={this.props.numColumns >= 3}
        data-qa-label="runnerLine-selectionButtons"
      >
        {columns}
      </RunnerSelectionWrapped>
    );
  };

  renderSingleRow = (direction) => {
    const runner = this.props.runners.find(
      (runnerBI) => get(runnerBI, "biNumber") === get(this.props, "biNumber")
    );
    const runnerTimeform = get(runner, "runners[0].timeform", {});
    const isSilkShown = this.showRunnerSilk(
      this.props.isWagerable,
      runnerTimeform
    );
    return (
      <RunnerWrapper
        ref={(comp) => (this.ref = comp)}
        onTouchStart={noop}
        key={`runner-${this.props.runnerData.runnerId}`}
        data-qa-label={`${this.props.runnerData.runnerId}-runnerLine${
          this.props.runnerData.runnerId.match(/[a-zA-Z]/i) ? "-coupled" : ""
        }${this.props.runnerData.scratched ? "-scratched" : ""}`}
        leg={this.props.isLeg}
        hasHover={this.props.hasHover}
        showActiveState={this.props.showActiveState}
      >
        <RunnerNumberWrapper onClick={() => this.handleLineClick(direction)}>
          <RunnerNumberContainer>
            <RunnerNumber
              raceTypeCode={this.props.raceTypeCode}
              runnerId={this.props.runnerData.runnerId}
              arrowDirection={direction}
            />
          </RunnerNumberContainer>
        </RunnerNumberWrapper>
        <RunnerOddWrapper onClick={() => this.handleLineClick(direction)}>
          <RunnerOdds
            currentOddsNumerator={this.props.currentOddsNumerator}
            currentOddsDenominator={this.props.currentOddsDenominator}
            morningLineOddsNumerator={this.props.morningLineOddsNumerator}
            morningLineOddsDenominator={this.props.morningLineOddsDenominator}
            isScratched={this.props.runnerData.scratched}
            isFavorite={this.props.isFavorite}
          />
        </RunnerOddWrapper>
        <RunnerBioWrapper
          removeVerticalPadding={
            this.props.numColumns >= 3 && this.props.isWagerable
          }
          onClick={() => this.handleLineClick(direction)}
          colSpan={this.props.isWagerable ? 2 : 1}
        >
          <BioSelectionsWrapper vertical={this.props.numColumns >= 3}>
            <RunnerBio
              raceTypeCode={this.props.raceTypeCode}
              firstHandicapBold={this.props.firstHandicapBold}
              runnerName={this.props.runnerData.horseName}
              handicappingData={
                this.props.isRunnerTrackMasterPick
                  ? []
                  : this.props.runnerData.handicapping
              }
              isScratched={this.props.runnerData.scratched}
              showFavorite={
                this.props.isWagerable && this.props.isFavoriteSelectionActive
              }
              isFavorite={this.props.favoriteSelection}
              runnerMasterPickNumber={this.props.runnerData.masterPickNumber}
              isRunnerTrackMasterPick={this.props.isRunnerTrackMasterPick}
              isRunnerNumberFirePick={this.props.isRunnerNumberFirePick}
              hasJockeyChanges={this.props.runnerData.hasJockeyChanges}
              handicappingSortMode={this.props.handicappingSortMode}
              hasTrackMasterInfo={this.props.hasTrackMasterInfo}
              trackDataSource={this.props.trackDataSource}
              runnerData={this.props.runnerData}
              showFlags={
                (this.props.expandedRunner === "" ||
                  this.props.expandedRunner !==
                    this.props.runnerData.runnerId) &&
                this.props.expandInfo !== noop
              }
              handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            />
            {this.props.isWagerable &&
              !this.props.runnerData.scratched &&
              this.renderSelectionButtons()}
          </BioSelectionsWrapper>
          {(this.props.isRunnerTrackMasterPick ||
            this.props.isRunnerNumberFirePick ||
            this.props.isRunnerRacingAndSports) && (
            <RunnerTrackMasterInfo vertical={this.props.numColumns >= 3}>
              {this.props.runnerData.masterPickInfo}
            </RunnerTrackMasterInfo>
          )}
        </RunnerBioWrapper>
        {isSilkShown && (
          <SilkContainer>
            <Silk src={runnerTimeform.silkUrlSvg} />
          </SilkContainer>
        )}
      </RunnerWrapper>
    );
  };

  renderExpandedRow = () => {
    const info = this.props.expandInfo(
      this.props.runnerData.horseName,
      get(this.props.runnerData, "dob", 0),
      this.props.runners,
      get(this.props.runnerData, "entityRunnerId", "")
    );

    return (
      !!info && (
        <td colSpan={4}>
          <AnimateContainer className="animatedContainer">
            {info}
          </AnimateContainer>
        </td>
      )
    );
  };

  render() {
    const showSecondRow =
      this.props.expandedRunner === this.props.runnerData.runnerId &&
      this.props.expandedRunner !== "" &&
      this.props.expandInfo !== noop;

    const direction = showSecondRow ? "up" : "down";

    return (
      <Fragment>
        {this.renderSingleRow(
          this.props.inLinePastPerformance ? direction : "none"
        )}
        <CSSTransition
          in={showSecondRow}
          classNames="expand"
          timeout={200}
          unmountOnExit
          onEntered={() => {
            if (
              this.ref instanceof Element &&
              typeof window !== "undefined" &&
              this.props.scrollRunnerPP
            ) {
              this.scrollIntoView();
            }
          }}
        >
          <SecondRow>{this.renderExpandedRow()}</SecondRow>
        </CSSTransition>
      </Fragment>
    );
  }
}
