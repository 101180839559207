import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { generateTransition, cubic } from "../../_static/Transitions";

export const RowContainer = styled.div`
  ${({ forceHeight }) =>
    forceHeight &&
    css`
      div:first-child {
        max-height: 108px;
      }
    `}
`;

export const Row = styled.div.attrs({
  "data-qa-label": "expandableRow"
})`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${buildColor("white", "100")};

  & > div:last-child {
    position: relative;
  }
`;

export const ExpandableHeader = styled.div`
  position: relative;

  &:active {
    background-color: ${buildColor("blue", "000")};
  }
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  z-index: 0;
  will-change: transform;
  transition: ${generateTransition(cubic, "transform")};
  transform: translateY(-8px)
    rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
`;

export const ClickableArea = styled.div`
  background: none;
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-right: 32px;
`;

export const RowLineBreak = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${buildColor("grey", "200")};
`;
