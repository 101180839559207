import styled, { css } from "styled-components";

export const ScrollButton = styled.button<{
  disabled: boolean;
  hasMarginRight?: boolean;
  hasMarginLeft?: boolean;
}>`
  display: flex;
  height: auto;
  align-items: center;
  ${({ theme, disabled, hasMarginLeft, hasMarginRight }) => css`
    margin-left: ${hasMarginLeft ? theme.space["space-5"] : 0};
    margin-right: ${hasMarginRight ? theme.space["space-5"] : 0};
    padding: ${theme.space["space-3"]};
    border-radius: ${theme.radii.s};
    border: 1px solid ${theme.colorTokens.component.button.tertiary.border.base};
    opacity: ${disabled ? 0.5 : 1};
    background-color: ${disabled
      ? theme.colorTokens.component.button.tertiary.background.disabled
      : theme.colorTokens.component.button.tertiary.background.base};

    &:hover {
      background-color: ${!disabled &&
      theme.colorTokens.component.button.tertiary.background.hover};
    }
  `}
`;

export const RaceListContainer = styled.div<{ hasScrollButtons: boolean }>`
  width: 100vw;
  display: flex;
  transform: translateZ(0);
  overflow: scroll hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  padding: 0 var(--fd-space-space-2, 8px);
  scroll-padding-left: var(--fd-space-space-2, 8px);
  gap: ${({ hasScrollButtons }) =>
    hasScrollButtons
      ? "var(--fd-space-space-3, 12px)"
      : "var(--fd-space-space-1, 4px)"};

  &::-webkit-scrollbar {
    display: none;
  }

  & > a {
    scroll-snap-align: start;
  }
`;
