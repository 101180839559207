import styled from "styled-components";
import { BorderColorProps, borderColor } from "styled-system";

export const Container = styled.div<BorderColorProps>`
  list-style: none;
  min-width: 325px;
  height: 102px;
  padding: 16px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${borderColor};
`;

export const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto 45px 1fr;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:last-child {
    justify-self: end;
  }
`;
