import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { generateTransition, easeInOut } from "../../_static/Transitions";

export const RunnerWrapper = styled.tr`
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.leg
      ? css`
          border-top: 1px solid ${buildColor("blue", "100")};
          border-bottom: 1px solid ${buildColor("blue", "100")};
          border-left: 1px solid ${buildColor("grey", "300")};
          border-right: 1px solid ${buildColor("grey", "300")};
        `
      : css`
          border-top: 1px solid ${buildColor("blue", "100")};
          border-bottom: 1px solid ${buildColor("blue", "100")};
        `};

  &:active {
    ${(props) =>
      props.showActiveState &&
      `background-color: ${buildColor("blue", "000")}`};
  }
`;

export const AnimateContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: ${generateTransition(easeInOut, "max-height")};
`;

export const SecondRow = styled.tr`
  & .animatedContainer {
    max-height: 1000px;
  }

  &.expand-enter .animatedContainer {
    max-height: 0;
  }

  &.expand-enter-active .animatedContainer {
    max-height: 1000px;
  }

  &.expand-enter-done .animatedContainer {
    max-height: 1000px;
  }

  &.expand-exit-active .animatedContainer {
    max-height: 0;
  }

  &.expand-exit-done .animatedContainer {
    max-height: 0;
  }
`;

export const RunnerCell = styled.td`
  height: 0;
`;

export const RunnerNumberWrapper = styled(RunnerCell)`
  width: 24px;
  position: relative;
`;

export const RunnerNumberContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const RunnerOddWrapper = styled(RunnerCell)`
  width: 40px;
  position: relative;
`;

export const RunnerBioWrapper = styled(RunnerCell)`
  padding: ${(props) => !props.removeVerticalPadding && "4px 0"};
  max-width: 225px;
  border-left: 1px solid ${buildColor("blue", "100")};
  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        background-color: ${buildColor("grey", "000")};
      }
    `}
`;

export const RunnerSelectionWrapped = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ vertical }) =>
    vertical
      ? css`
          min-height: 0;
          padding-bottom: 12px;
        `
      : css`
          min-height: 40px;
        `}
`;

export const RunnerTrackMasterInfo = styled.p`
  font-size: 12px;
  color: ${buildColor("grey", "800")};
  margin-top: -4px;
  ${({ vertical }) =>
    vertical
      ? css`
          padding: 0 8px 8px;
        `
      : css`
          padding: 0 8px 4px;
        `};
`;

export const BioSelectionsWrapper = styled.div`
  display: flex;
  ${({ vertical }) =>
    vertical
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `};

  & > :first-child {
    flex: 1;
    min-width: 0;
  }

  & > :not(:first-child):last-child {
    flex: 0 0 auto;
  }
`;

export const SilkContainer = styled(RunnerCell)`
  margin: 12px 0;
  padding-right: 8px;
  width: 31px;
  height: 100%;
  position: relative;
`;

export const Silk = styled.img`
  height: 31px;
  width: 31px;
`;
