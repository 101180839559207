import { Dispatch } from "redux";
import { get } from "lodash";

import type { Promo, PromosWidgetPROResponse } from "@tvg/ts-types/Promos";
import type { Device } from "@tvg/ts-types/Device";
import type { UnaryFn } from "@tvg/ts-types/Functional";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import promosWidgetService from "@tvg/api/pro";
import setUserPromos from "./redux/actions";

export const getPromos = (
  accountNumber: string | undefined,
  dispatch: Dispatch
) => {
  promosWidgetService
    .fetchTopPromotions(accountNumber)
    .then((promotions: PromosWidgetPROResponse) => {
      const fetchedPromos = get(promotions, "data.promotions", null);
      const validPromos: Array<Promo> =
        fetchedPromos?.filter((promo: Promo) => promo.isPromoTagShown) || [];
      const assignValue = (val: number | null | undefined): number =>
        val === null || val === undefined ? 20 : val;

      validPromos.sort(
        (a, b) =>
          assignValue(a.promoTypePriority) - assignValue(b.promoTypePriority)
      );
      dispatch(setUserPromos(validPromos));
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const checkQueryParams = (route: string): boolean => {
  const queryParams = ["?", "#"];

  return queryParams.some((param) => route.includes(param));
};

export const onPromoItemClick =
  (
    device: Device,
    route: string,
    promoName: string,
    navigateCallback: UnaryFn<string, void>
  ) =>
  (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const pattern = /^https?:\/\//;
    const isFullUrl = pattern.test(route);
    let destinationUrl = route;
    let path = "";
    let params = "";

    if (!isFullUrl) {
      const routeParts = route ? route.split("?") : [];
      path = get(routeParts, "[0]", "");
      params = get(routeParts, "[1]", "");
      const paramsFormatted = Object.fromEntries(new URLSearchParams(params));

      destinationUrl = tvgConf().buildUrl({
        path,
        params: paramsFormatted
      });
    } else if (device !== "desktop" && !checkQueryParams(destinationUrl)) {
      destinationUrl += destinationUrl.endsWith("/")
        ? tvgConf().buildUrl({ params, relative: true }).substr(1)
        : tvgConf().buildUrl({ params, relative: true });
    }

    mediator.base.dispatch({
      type: "PROMO_WIDGET:CLICK",
      payload: { promoName, destinationUrl }
    });

    if (device === "desktop") {
      if (route.includes("www")) {
        window.open(destinationUrl, "_blank");
      } else {
        const pathFormatted = path.startsWith("/") ? path : `/${path}`;
        navigateCallback(params ? `${pathFormatted}?${params}` : pathFormatted);
      }
    } else {
      window.location.assign(destinationUrl);
    }
  };
