import { gql } from "@apollo/client";

const SearchByJockeyFragment = {
  entry: gql`
    fragment searchByJockey on RaceEntities {
      jockeys {
        name
        dob
        entityRunnerId
      }
    }
  `
};

export default SearchByJockeyFragment;
