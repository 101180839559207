import React, { FC } from "react";
// After Popper migration needs to be replaced with web version
import Popper from "../../../src/components/popper";
import { Content as StyledContent } from "./styled-components";
import type { TooltipProps, TooltipContentProps } from "./types";
import { Paragraph } from "../Typography";

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  placement = "top-mid",
  isDisabled = false,
  isDarkMode = false,
  qaLabel,
  layer = 1999,
  ...rest
}) => (
  <Popper
    on="hover"
    placement={placement}
    isDisabled={isDisabled}
    layer={layer}
    content={
      <StyledContent
        data-qa-label={qaLabel}
        variant={isDarkMode ? "light" : "dark"}
        {...rest}
      >
        <Paragraph
          color={isDarkMode ? "content.default" : "--fd-colors-core-white"}
          qaLabel="tooltip-text"
          fontSize="xs"
        >
          {content}
        </Paragraph>
      </StyledContent>
    }
  >
    {children}
  </Popper>
);

export type { TooltipProps, TooltipContentProps };
export default Tooltip;
