import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isDesktop ? "12px" : 0)};
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("blue", "000")};
  }
  
  100% {
    fill: ${color("grey", "000")};
   }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 620px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${color("blue", "100")};
    stroke-width: 1;
  }
`;

/*
 TODO: Use buildColor to set the mask color
*/

export default class TopTracksMask extends PureComponent {
  static defaultProps = {
    isDesktop: false
  };

  render() {
    return (
      <SVGContainer
        data-qa-label="topTracksMask"
        isDesktop={this.props.isDesktop}
      >
        <SVG isDesktop={this.props.isDesktop}>
          {this.props.isDesktop ? (
            <g fill="none" fillRule="evenodd">
              <rect width="100%" height="620" className="pulse" />
              <rect
                width="100%"
                height="620"
                fill={color("white", "100")}
                fillRule="nonzero"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="117"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="99"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="213"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="195"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="88.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="184.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="73"
                height="8"
                x="12"
                y="20"
                className="pulse"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="65"
                className="pulse"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="161"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="309"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="291"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="280.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="257"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="405"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="387"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="376.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="353"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="501"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="483"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="472.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="449"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="12.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="91"
                height="47"
                x="52.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="60"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="60"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="152.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="160"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="160"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="252.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="260"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="260"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="352.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="360"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="360"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="452.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="460"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="460"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="552.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="560"
                y="597"
                className="pulse"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="560"
                y="579"
                className="pulse"
                rx="1"
              />
              <rect
                width="31"
                height="47"
                x="619.5"
                y="568.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="545"
                className="pulse"
                rx="1"
              />
            </g>
          ) : (
            <g fill="none" fillRule="evenodd">
              <rect width="100%" height="620" fill={color("white", "100")} />
              <rect
                width="100%"
                height="620"
                fill={color("white", "100")}
                fillRule="nonzero"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="80.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="109"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="91"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="176.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="205"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="187"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="73"
                height="8"
                x="12"
                y="20"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="57"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="153"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="272.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="301"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="283"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="249"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="368.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="397"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="379"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="345"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="464.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="493"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="475"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="441"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="12.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="20"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="20"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="112.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="120"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="120"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="212.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="219"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="220"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="312.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="320"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="320"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="412.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="420"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="420"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="512.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="520"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="520"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="612.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="620"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="620"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="91"
                height="47"
                x="712.5"
                y="560.5"
                fill={color("white", "100")}
                fillRule="nonzero"
                className="stroke"
                rx="4"
              />
              <rect
                width="20"
                height="8"
                x="720"
                y="589"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="41"
                height="8"
                x="720"
                y="571"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
              <rect
                width="99"
                height="8"
                x="12"
                y="537"
                className="pulse"
                fillRule="nonzero"
                rx="1"
              />
            </g>
          )}
        </SVG>
      </SVGContainer>
    );
  }
}
