import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div<{ bg: string | undefined }>`
  position: relative;
  display: flex;
  ${({ bg }) =>
    bg &&
    css`
      background-color: ${bg};
    `};
`;

export const BannerImageContainer = styled.div<{
  hasCTAs?: boolean;
  $orientation: "portrait" | "landscape";
}>`
  width: ${({ $orientation, hasCTAs }) =>
    $orientation === "landscape" && hasCTAs ? "80%" : "100%"};
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
`;

export const BannerImage = styled.img`
  width: 100%;
`;

export const DynamicContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextContainer = styled.div<{ bg: string | undefined }>`
  padding: ${({ theme }) =>
    `${theme.space["space-3"]} 0 ${theme.space["space-3"]} ${theme.space["space-5"]}`};
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ bg }) =>
    bg &&
    css`
      background-color: ${bg};
    `};
`;

export const ButtonsContainerMobile = styled.div<{ bg: string | undefined }>`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]} ${theme.space["space-5"]}`};
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${({ theme }) => theme.space["space-3"]};
  ${({ bg }) =>
    bg &&
    css`
      background-color: ${bg};
    `};
`;

export const ButtonsContainerDesktop = styled.div`
  width: 20%;
  margin: auto 0;
  padding: ${({ theme }) => theme.space["space-5"]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.space["space-3"]};
`;

export const BannerButton = styled.div`
  flex: 1;
`;
