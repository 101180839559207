import { noop } from "lodash";
import FavoriteTracksTemplate from "@tvg/atomic-ui/_templates/FavoriteTracks";

import RDAQuery from "../graphql/queries/TracksFavoritesRDAQuery";
import GraphOptions from "../graphql/tracks-favorites-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

export const skipQuery = () => true;

export const skipRDAQuery = ({ isAccountCompliant, shouldUpdate, accountId }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const tracksFavorite = {
  template: FavoriteTracksTemplate,
  query: null,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: true,
  onlyFetchResultedTracks: false,
  renderTracksLetters: false,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps,
  numberOfFavorites: 0,
  hasActiveFilters: false,
  onClearFilters: noop,
  tracks: []
};

export default tracksFavorite;
