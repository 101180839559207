import { gql } from "@apollo/client";

const SearchByHorseFragment = {
  entry: gql`
    fragment searchByHorse on RaceEntities {
      runners {
        name
        dob
        entityRunnerId
      }
    }
  `
};

export default SearchByHorseFragment;
