import React from "react";
import RowLetter from "./styled-components";

const TrackSplitter = ({ letter, isSEORacetracks }) => (
  <RowLetter isSEORacetracks={isSEORacetracks}>{letter}</RowLetter>
);

TrackSplitter.defaultProps = {
  letter: "",
  isSEORacetracks: false
};

export default React.memo(TrackSplitter);
