import React, { Component } from "react";

import { connect } from "react-redux";
import { noop, get, find } from "lodash";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";

import { isTvg5 } from "@tvg/utils/generalUtils";
import withRouter from "@tvg/utils/withCustomRouter";

import CPPService from "@tvg/sh-cpp";

import QuickLinks from "@tvg/atomic-ui/_templates/QuickLinks";
import QuickLinksMask from "@tvg/atomic-ui/_static/Masks/quicklinks";
import QuickLinksExpandedMask from "@tvg/atomic-ui/_static/Masks/quicklinks-expanded";
import { DesktopWrapper } from "@tvg/atomic-ui/_templates/QuickLinks/styled-components";
import { inspectURL, processURL } from "@tvg/formatter/url";
import tvgConf from "@tvg/conf";

import pes from "@tvg/api/pes";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import isMobile from "@tvg/utils/mobileUtils";
import {
  getPromosOnboardingToggle,
  hasPromoOnboardingActivated
} from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { setFromQuickLinks } from "@tvg/tracks/src/v2/redux/actions";
import { mapCPPPromos } from "@fdr/utils/PromoUtils";
import {
  hideQuickLinks,
  showQuickLinks
} from "../redux-manager/actions/rendering";
import { storeQuickLinks } from "../redux-manager/actions/content";

let isRequestingQuickLinks = false;

export class QuickLinksComponent extends Component {
  static defaultProps = {
    device: "mobile",
    storeQuickLinks: noop,
    hideQuickLinks: noop,
    showQuickLinks: noop,
    fromQuickLinks: noop,
    shouldUpdate: true,
    quickLinks: [],
    useQuickLinksExpanded: false,
    quickLinksData: [],
    enablePromosStoryblok: false,
    promoOnboardingToggle: false,
    hasPromoOnboarding: false,
    useNewHomepage: false
  };

  conf;

  tvgDomains;

  cppService;

  constructor(props) {
    super(props);
    this.conf = tvgConf();
    this.cppService = new CPPService();
    this.tvgDomains = this.conf.getDomains();
    this.state = {
      isMobile: this.props.device !== "desktop",
      isApp: isMobile(this?.conf?.product),
      isLoading: !this.props.quickLinksData.length,
      hasRequestFailed: false
    };
  }

  componentDidMount() {
    mediator.base.subscribe("FETCH_QUICKLINKS", this.fetchQuickLinks);

    return (
      (this.props.shouldUpdate || this.props.quickLinks.length) &&
      this.fetchQuickLinks()
    );
  }

  componentWillUnmount() {
    mediator.base.unsubscribe("FETCH_QUICKLINKS", this.fetchQuickLinks);
  }

  componentDidUpdate() {
    return (
      this.props.shouldUpdate &&
      get(this.props, "quickLinks", []).length === 0 &&
      !get(this.state, "hasRequestFailed", false) &&
      this.fetchQuickLinks()
    );
  }

  onLinkClick = (link, position, event) => {
    mediator.base.dispatch({
      type: "QUICKLINKS:CLICK",
      payload: {
        tag: link.tag,
        position,
        linkName: link.label,
        fullURL: link.url
      }
    });

    if (this.props.device === "desktop" && !isTvg5()) {
      mediatorClassic.dispatch("CLOSE_HAMBURGUER", {});

      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: link.url, fromQuickLinks: true }
      });
    }

    if (isTvg5()) {
      event.preventDefault();
      event.stopPropagation();
      this.props.fromQuickLinks();
      this.props.history.push(link.url);
    }
  };

  getColorByIcon = (icon) => {
    switch (icon) {
      case "contest":
        return "yellow";
      case "promotion":
        return "yellow";
      case "picks":
        return "green";
      case "reward":
        return "green";
      case "mybets":
        return "green";
      default:
        return "blue_accent";
    }
  };

  fetchQuickLinks = async () => {
    try {
      let processedLinks = [];
      if (!isRequestingQuickLinks) {
        isRequestingQuickLinks = true;
        const { enableCPPQuickLinks, enableSGWQuicklinks } = this.props;

        const [quickLinks, cppQuickLinks] = await Promise.allSettled([
          enableSGWQuicklinks ? pes.fetchQuickLinks() : { data: [] },
          enableCPPQuickLinks
            ? this.cppService.getPromos({
                isQuicklink: true
              })
            : []
        ]);

        processedLinks = [
          ...mapCPPPromos(cppQuickLinks.value),
          ...this.processLinksData(
            get(quickLinks.value.data[0], "quicklinks", [])
          )
        ];
      }
      this.props.storeQuickLinks(processedLinks);

      this.setState({
        isLoading: false,
        hasRequestFailed: !processedLinks.length
      });

      if (!processedLinks.length) {
        this.props.hideQuickLinks();
      } else {
        this.props.showQuickLinks();
      }
    } catch (error) {
      this.props.hideQuickLinks();
      this.setState({
        isLoading: false,
        hasRequestFailed: true
      });
    } finally {
      isRequestingQuickLinks = false;
    }
  };

  parseInternalURL = (url) => {
    const urlObj = new URL(url);
    return urlObj.href.replace(urlObj.origin, "");
  };

  // Handles branding TVG absolute paths
  parseUrlPerBrand = (url) => {
    let newUrl = url;
    const { brand } = tvgConf();
    const tvgDomainRegex =
      /^(https?:\/\/www|https?:\/\/www-qa|https?:\/\/www-staging|https?:\/\/)?\.?tvg\.com/i;

    if (
      tvgDomainRegex.test(url) &&
      brand &&
      brand !== "tvg" &&
      brand !== "fdr"
    ) {
      newUrl = tvgConf().buildUrl({
        path: url.replace(tvgDomainRegex, "")
      });
    }

    return newUrl;
  };

  openLinkInIOSApp = (link) => (e) => {
    mediator.ios.dispatch({
      type: "OPEN_EXTERNAL_APP",
      payload: { openExternalApp: link.url }
    });
    e.stopPropagation();
    e.preventDefault();
  };

  openLinkInDesktop = (link) => (e) => {
    const pattern = /^https?:\/\//;
    const isFullUrl = pattern.test(link.url);
    let destinationUrl = link.url;
    if (!isFullUrl) {
      const routeParts = destinationUrl ? destinationUrl.split("?") : [];
      const path = routeParts[0];
      const params = routeParts[1];
      destinationUrl = this.conf.buildUrl({ path, params });
    }
    e.stopPropagation();
    e.preventDefault();
    if (link.newTab) {
      window.open(destinationUrl, "_blank");
    } else {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: new URL(destinationUrl).pathname }
      });
    }
  };

  checkMarketingUrl = (url) =>
    !this.props.enablePromosStoryblok && url.includes("/promos");

  processLinksData = (links) => {
    const processedLinks = [];
    links.forEach((singleLink) => {
      const linkType = inspectURL(singleLink.url, this.tvgDomains);
      switch (linkType) {
        case "isInternal":
          processedLinks.push({
            ...singleLink,
            url: this.parseInternalURL(processURL(singleLink.url)),
            newTab: this.checkMarketingUrl(singleLink.url),
            isTVG4: !this.state.isMobile,
            onClick: !this.state.isMobile
              ? (link, e) => this.openLinkInDesktop(link)(e)
              : noop
          });
          break;
        case "isExternal":
          processedLinks.push({
            ...singleLink,
            url: this.parseUrlPerBrand(processURL(singleLink.url)),
            newTab: true,
            isTVG4: !this.state.isMobile,
            isExternal: true,
            onClick: this.state.isApp
              ? (link, e) => this.openLinkInIOSApp(link)(e)
              : noop
          });
          break;
        default:
          processedLinks.push({
            ...singleLink,
            newTab: this.checkMarketingUrl(singleLink.url),
            onClick: noop,
            isTVG4: !this.state.isMobile
          });
      }
    });
    return processedLinks;
  };

  formatQuickLinks = (quickLinks) =>
    quickLinks.map((link) => {
      const color = this.getColorByIcon(get(link, "icon", "video"));
      return {
        ...link,
        // Strip last slash to prevent mediator navigation loop
        url: link.url.endsWith("/") ? link.url.slice(0, -1) : link.url,
        patternColor: buildColor(color, "500"),
        backgroundColor: buildColor("tint", color)
      };
    });

  chooseVersionMask = () =>
    this.props.useQuickLinksExpanded ? (
      <QuickLinksExpandedMask device={this.props.device} />
    ) : (
      this.renderMask()
    );

  renderMask = () =>
    this.state.isMobile ? (
      <QuickLinksMask isVertical={false} device={this.props.device} />
    ) : (
      <DesktopWrapper>
        <QuickLinksMask isVertical device={this.props.device} />
      </DesktopWrapper>
    );

  render() {
    const processedLinks =
      this.props.quickLinks.length > 0
        ? this.props.quickLinks
        : this.processLinksData(this.props.quickLinksData);

    const shallRenderMask = this.state.isLoading || !processedLinks.length;

    if (this.props.promoOnboardingToggle && this.props.hasPromoOnboarding) {
      return null;
    }

    return !shallRenderMask ? (
      <QuickLinks
        hasNoQuickLinks={!(processedLinks.length > 0)}
        isVerticalLayout={
          !this.state.isMobile && !this.props.useQuickLinksExpanded
        }
        quickLinks={this.formatQuickLinks(processedLinks)}
        isExpanded={this.props.useQuickLinksExpanded}
        onLinkClick={this.onLinkClick}
        isInApp={this.state.isApp}
        device={this.props.device}
      />
    ) : (
      !this.state.hasRequestFailed && this.chooseVersionMask()
    );
  }
}

export const mapStateToProps = (store) => ({
  quickLinksData: get(store, "quickLinks.content.data", []),
  quickLinks: get(store, "quickLinks.content.links", []),
  useQuickLinksExpanded: get(
    store,
    "capi.featureToggles.showQuickLinksExpanded"
  ),
  enablePromosStoryblok: get(
    store,
    "capi.featureToggles.enablePromosStoryblok",
    false
  ),
  hasPromoOnboarding: hasPromoOnboardingActivated(store),
  promoOnboardingToggle: getPromosOnboardingToggle(store),
  enableCPPQuickLinks:
    get(store, "capi.featureToggles.enableCPPQuickLinks", false) ||
    get(
      find(
        store.lhnFeatureToggles?.featureToggles,
        (featureToggle) => featureToggle.name === "enableCPPQuickLinks"
      ),
      "enabled",
      false
    ),
  enableSGWQuicklinks:
    get(store, "capi.featureToggles.enableSGWQuicklinks", false) ||
    get(
      find(
        store.lhnFeatureToggles?.featureToggles,
        (featureToggle) => featureToggle.name === "enableSGWQuicklinks"
      ),
      "enabled",
      false
    )
});

export const mapDispatchToProps = (dispatch) => ({
  storeQuickLinks: (links) => dispatch(storeQuickLinks(links)),
  hideQuickLinks: () => dispatch(hideQuickLinks()),
  showQuickLinks: () => dispatch(showQuickLinks()),
  fromQuickLinks: () => dispatch(setFromQuickLinks(true))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuickLinksComponent));
