import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import type { Device } from "@tvg/ts-types/Device";
import type { Promo } from "@tvg/ts-types/Promos";
import {
  formatThreeLetterMonthDayDate,
  formatDayDate
} from "@tvg/formatter/dates";
import mediator from "@tvg/mediator";
import {
  Header,
  LinkItem,
  useTheme,
  breakpoints,
  useMediaQuery
} from "@tvg/design-system";
import { Tag } from "@tvg/design-system/web";
import { getAccountNumber } from "@urp/store-selectors";
import { PromosWidgetProps } from "./types";
import { getPromos, onPromoItemClick } from "./utils";
import { Container } from "./styled-components";

const PromosWidgetComponent: React.FC<PromosWidgetProps> = (props) => {
  const {
    title = props.title || "Promotions Calendar",
    device = "mobile" as Device,
    qaLabel = "promosWidget"
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const accountNumber = useSelector(getAccountNumber);
  const promosList = useSelector((store) => get(store, "app.promosList", []));

  useEffect(() => {
    getPromos(accountNumber, dispatch);

    if (accountNumber) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });
    }
  }, [accountNumber]);

  const processDate = (date: string): string =>
    `${formatThreeLetterMonthDayDate(date)} ${formatDayDate(date)}`;

  const renderPromoDates = (startDate: string, endDate: string | null) =>
    endDate
      ? `${processDate(startDate)} - ${processDate(endDate)}`
      : processDate(startDate);

  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);

  return promosList?.length > 0 ? (
    <Container data-qa-label={qaLabel} theme={theme} device={device}>
      {!isMobile && (
        <Header tag="h2" paddingX="space-4" paddingY="space-5">
          {title}
        </Header>
      )}
      <LinkItem.List qaLabel={`${qaLabel}-promosList`}>
        {promosList.map((promo: Promo) => (
          <LinkItem
            key={promo.id}
            qaLabel={`${qaLabel}-promoId-${promo.id}`}
            title={{ render: promo.title }}
            description={renderPromoDates(promo.startDate, promo.endDate) || ""}
            end={
              promo.optedIn ? (
                <Tag
                  qaLabel={`promoId-${promo.id}-optedIn`}
                  iconName="optedIn"
                  variant="positive"
                  label="Opted in"
                  size="medium"
                  marginRight="8px"
                />
              ) : undefined
            }
            // @ts-ignore
            onPress={onPromoItemClick(
              device,
              promo.path,
              promo.title,
              navigate
            )}
          />
        ))}
      </LinkItem.List>
    </Container>
  ) : null;
};

export default PromosWidgetComponent;
