import { gql } from "@apollo/client";
import { TalentPickTalentFragment } from "../fragments/TalentFragment";
import { TalentPickRaceFragment } from "../fragments/RaceFragment";
import { TalentPickWagerFragment } from "../fragments/WagerTypeFragment";

export const GET_TALENT_PICKS_SUBSCRIPTION = gql`
  subscription newTalentPickUpdate(
    $wagerProfile: String
    $product: String
    $device: String
    $brand: String
  ) {
    talentPicksList: newTalentPickUpdate(profile: $wagerProfile) {
      id
      ...Talent
      ...Race
      ...WagerType
      amount
      price
      selections {
        order
      }
      favorites {
        order
      }
    }
  }
  ${TalentPickTalentFragment.entry}
  ${TalentPickRaceFragment.entry}
  ${TalentPickWagerFragment.entry}
`;
