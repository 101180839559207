import { gql } from "@apollo/client";

export const OPTIN_USER_ON_PROMO = gql`
  mutation AddOptinUserOnPromotion(
    $accountNumber: Int!
    $promotionId: Int!
    $promotionCodeId: Int!
    $optInDate: DateTime!
    $source: OptInSource!
  ) {
    addOptInUserOnPromotion(
      accountId: $accountNumber
      input: {
        promotionCodeId: $promotionCodeId
        source: $source
        optInDate: $optInDate
      }
      promotionId: $promotionId
    ) {
      customerReferralCode
    }
  }
`;
