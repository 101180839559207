import React from "react";
import { noop } from "lodash";

import { getHasPromo } from "@tvg/utils/PromoUtils";
import {
  FavoriteIconWrapper,
  IconsWrapper,
  ResultedTag,
  TrackInfo,
  TrackName,
  TextEllipsis
} from "./styled-components";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import {
  promosolid,
  star,
  starOutline,
  greyhounds
} from "../../_static/Icons/icons";
import Tag from "../../_atom/Tag";

const renderFavoriteIcon = (isFavorite, qaLabel, isSEORacetracks) =>
  isFavorite ? (
    <Icon
      qaLabel={`${qaLabel}-FavoriteIcon`}
      icon={star}
      color={buildColor("yellow", "500")}
    />
  ) : (
    <Icon
      qaLabel={`${qaLabel}-StarOutlineIcon`}
      icon={starOutline}
      color={
        isSEORacetracks ? buildColor("grey", "800") : buildColor("blue", "200")
      }
    />
  );

const renderGreyhoundIcon = (isGreyhound) =>
  isGreyhound && (
    <Icon size={16} icon={greyhounds} data-qa-label="race-greyhound" />
  );

const renderTrackPromoTag = (
  hasPromo,
  qaLabel,
  isPromoTagShown,
  useIsPromoTagShownFlag
) => {
  const shouldRenderPromoTag = getHasPromo(
    useIsPromoTagShownFlag,
    hasPromo,
    isPromoTagShown
  );

  return (
    shouldRenderPromoTag && (
      <Tag
        qaLabel={`${qaLabel}-PromoTag`}
        icon={promosolid}
        tagType="PromoTag"
        name="Promo"
        iconSize={16}
      />
    )
  );
};

const renderTrackState = (
  hasResultedState,
  hasPromo,
  qaLabel,
  isPromoTagShown,
  useIsPromoTagShownFlag
) =>
  hasResultedState ? (
    <ResultedTag data-qa-label={`${qaLabel}-Resulted`}>Resulted</ResultedTag>
  ) : (
    renderTrackPromoTag(
      hasPromo,
      qaLabel,
      isPromoTagShown,
      useIsPromoTagShownFlag
    )
  );

const onFavoriteClickHandler = (
  e,
  { handleFavoriteChange, isFavorite, trackCode }
) => {
  e.stopPropagation();
  handleFavoriteChange(!isFavorite, trackCode);
};

const TrackLineHeader = (props) => (
  <TrackInfo data-qa-label={props.qaLabel}>
    <FavoriteIconWrapper
      data-qa-label={`${props.qaLabel}-FavoriteIcon`}
      onClick={(e) => onFavoriteClickHandler(e, props)}
    >
      {renderFavoriteIcon(
        props.isFavorite,
        props.qaLabel,
        props.isSEORacetracks
      )}
    </FavoriteIconWrapper>
    <TrackName data-qa-label={`${props.qaLabel}-TrackName`}>
      <TextEllipsis>{props.trackName}</TextEllipsis>
    </TrackName>
    <IconsWrapper>
      {renderGreyhoundIcon(props.isGreyhound)}
      {renderTrackState(
        props.hasResultedState,
        props.hasPromo,
        props.qaLabel,
        props.isPromoTagShown,
        props.useIsPromoTagShownFlag
      )}
    </IconsWrapper>
  </TrackInfo>
);

TrackLineHeader.defaultProps = {
  qaLabel: "",
  trackCode: "",
  trackName: "",
  isFavorite: false,
  isGreyhound: false,
  hasPromo: false,
  hasResultedState: false,
  handleFavoriteChange: noop,
  isSEORacetracks: false,
  isPromoTagShown: false,
  useIsPromoTagShownFlag: false
};

export default TrackLineHeader;
