import { gql } from "@apollo/client";
import { TalentPickTalentFragment } from "../fragments/TalentFragment";
import { TalentPickRaceFragment } from "../fragments/RaceFragment";
import { TalentPickWagerFragment } from "../fragments/WagerTypeFragment";

export const GET_GRAPH_TALENT_PICKS = gql`
  query getGraphTalentPicks(
    $wagerProfile: String
    $product: String
    $device: String
    $brand: String
    $trackCode: String
    $raceNumber: String
  ) {
    talentPicksList: talentPicks(
      sort: { byPostTime: ASC }
      profile: $wagerProfile
      trackCode: $trackCode
      raceNumber: $raceNumber
    ) {
      id
      ...Talent
      ...Race
      ...WagerType
      amount
      price
      selections {
        order
      }
      favorites {
        order
      }
    }
  }
  ${TalentPickTalentFragment.entry}
  ${TalentPickRaceFragment.entry}
  ${TalentPickWagerFragment.entry}
`;
