import type { SeoTrack } from "@tvg/ts-types/Track";
import mediator from "@tvg/mediator";
import { MediatorEventType as VideoMediatorEventType } from "@urp/amplitude/src/modules/video/types";
import {
  MediatorEventType as RaceResultsMediatorEventType,
  ResultRaceViewed
} from "@urp/amplitude/src/modules/raceResults/types";
import {
  Module,
  RaceCellData,
  MediatorEventType
} from "@urp/amplitude/src/modules/raceCell/types";
import { TracksMediatorEventType } from "@tvg/ts-types/Amplitude";
import { FilterType, FilterName } from "../types";

interface OpenEventPayload {
  isOpen: boolean;
  isResults?: boolean;
  trackName: string;
}

interface FavoriteEventPayload {
  isFavoriting: boolean;
  module: Module;
  trackName: string;
  activeTab: string;
}

interface TabOpenedEventPayload {
  tabName: string;
  url: string;
}

interface FilterEventPayload {
  module?: string;
  filterType: FilterType;
  filterName: FilterName | FilterName[];
  filterValue: string | string[];
  activeTab?: string;
}

interface AllFiltersModalPayload {
  module: string;
  activeTab: string;
}

interface ReplayVideoEventPayload {
  trackName: string;
  raceNumber: string;
  linkUrl: string;
  trackCountry: string;
  hideResultsSpoiler: string;
}

export const sendOpenAnalyticEvt = ({
  isOpen,
  isResults = false,
  trackName
}: OpenEventPayload) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.TRACK_CLICK,
    payload: {
      isOpen,
      isResults,
      trackName
    }
  });
};

export const sendTracksTabOpenedAnalyticEvt = ({
  tabName,
  url
}: TabOpenedEventPayload) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.TAB_OPENED,
    payload: {
      tabName,
      url
    }
  });
};

export const sendTracksFilterAnalyticEvt = ({
  module,
  filterType,
  filterName,
  filterValue,
  activeTab
}: FilterEventPayload) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.FILTER_APPLIED,
    payload: {
      module,
      filterType,
      filterName,
      filterValue,
      activeTab
    }
  });
};

export const sendTracksSearchAnalyticEvt = (
  analyticsEnrichment?: Record<string, unknown>
) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.SEARCH_APPLIED,
    payload: {
      ...(analyticsEnrichment || {}),
      filterType: FilterType.SEARCH
    }
  });
};

export const sendFavoriteAnalyticEvt = ({
  isFavoriting,
  module,
  trackName,
  activeTab
}: FavoriteEventPayload) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.TRACKS_FAVORITE,
    payload: {
      isFavoriting,
      module,
      trackName,
      activeTab
    }
  });
};

export const sendRaceCellClickedAnalyticEvt = ({
  module,
  trackName,
  raceNumber,
  linkUrl,
  raceHasPromos,
  trackHasPromos,
  promoOptedIn,
  isFDTV,
  trackCountry,
  tabActive,
  activeFilters,
  filterName,
  filterValue
}: RaceCellData["payload"]) => {
  const data: RaceCellData = {
    type: MediatorEventType.CLICKED,
    payload: {
      module,
      trackName,
      raceNumber,
      linkUrl,
      raceHasPromos,
      trackHasPromos,
      promoOptedIn,
      isFDTV,
      trackCountry,
      tabActive,
      activeFilters,
      filterName,
      filterValue
    }
  };
  mediator.base.dispatch(data);
};

export const sendAllFiltersModalOpenedAnalyticEvt = ({
  module,
  activeTab
}: AllFiltersModalPayload) => {
  mediator.base.dispatch({
    type: TracksMediatorEventType.ALL_FILTERS_MODAL_OPENED,
    payload: {
      module,
      activeTab
    }
  });
};

export const sendReplayVideoClickedAnalyticEvt = ({
  trackName,
  raceNumber,
  linkUrl,
  trackCountry,
  hideResultsSpoiler
}: ReplayVideoEventPayload) => {
  mediator.base.dispatch({
    type: VideoMediatorEventType.REPLAY_VIDEO,
    payload: {
      trackName,
      raceNumber,
      linkUrl,
      trackCountry,
      hideResultsSpoiler
    }
  });
};

export const sendViewResultsAnalyticEvt = ({
  trackName,
  raceNumber,
  linkUrl,
  trackCountry,
  hideResultsSpoiler
}: ResultRaceViewed) => {
  mediator.base.dispatch({
    type: RaceResultsMediatorEventType.VIEW_RACE_RESULTS,
    payload: {
      trackName,
      raceNumber,
      linkUrl,
      trackCountry,
      hideResultsSpoiler
    }
  });
};

export const isApplyingFilter = (filters: string[], filterType: string[]) =>
  filterType.some((filter) => filters.includes(filter));

export const setSeoTrackList = (seoTrackList: Array<SeoTrack>) => ({
  type: "SET_SEO_TRACK_LIST",
  payload: seoTrackList
});

export const setFromQuickLinks = (fromQuickLinks: boolean) => ({
  type: "SET_FROM_QUICK_LINKS",
  payload: fromQuickLinks
});
