import styled, { css } from "styled-components";

export const RunnerFlagsWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  & .runner-flag {
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const ContentWrapper = styled.div<{ hasSeparator: boolean }>`
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};
  padding: 12px;
  ${({ hasSeparator, theme }) =>
    hasSeparator
      ? css`
          border-top: 10px solid ${theme.colorTokens.border.default};
        `
      : css`
          border-bottom: 1px solid ${theme.colorTokens.border.default};
        `}
`;

export const IconWrapper = styled.span`
  margin-left: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RunnerNumberContainer = styled.div`
  display: inline-block;
  min-width: 20px;
  height: 20px;
  margin-right: 4px;
`;
