import { get } from "lodash";
import { fetchStoryblok } from "@tvg/sh-lib-storyblok/src/fetchStoryblok";
import { STORYBLOK_CONTENT_PATH } from "@tvg/sh-lib-storyblok/src/constraints/config";
import {
  StoryblokEnvironment,
  StoryblokStory,
  StoryblokVersion
} from "@tvg/sh-lib-storyblok/src/types";
import { HeaderBanner } from "@urp/derby-banner/src/types";
import {
  HomepageStoryblokContent,
  HomepageConfigContext,
  HomepageBannerConfig
} from "../types";

const formatDate = (storyblokDate: string) => {
  if (!storyblokDate) {
    return null;
  }

  const [date, time] = storyblokDate.split(" ");
  return new Date(`${date}T${time}:00Z`);
};

export const getDerbyBanner = (
  storyblokComponent: StoryblokStory<HomepageStoryblokContent>,
  eventType: string
): Array<HeaderBanner> =>
  get(storyblokComponent, `content.${eventType}_homepage_banner`, []).filter(
    (banner: { context: HomepageConfigContext }) =>
      banner.context.includes("desk")
  );

export const getDerbyBannerConfigByStoryblok = async (
  storyblokVersion = StoryblokVersion.DRAFT,
  overrideToken?: StoryblokEnvironment
): Promise<HomepageBannerConfig> => {
  try {
    const storyblokResponse = await fetchStoryblok<HomepageStoryblokContent>(
      STORYBLOK_CONTENT_PATH.HOMEPAGE_CONFIG,
      "homepage-config",
      storyblokVersion,
      overrideToken
    );

    const derbyBannerDefault = getDerbyBanner(storyblokResponse, "default");
    const derbyBannerEventDay = getDerbyBanner(storyblokResponse, "event_day");
    const derbyBannerPreEvent = getDerbyBanner(storyblokResponse, "pre_event");
    const derbyBannerFridayPreEvent = getDerbyBanner(
      storyblokResponse,
      "friday_pre_event"
    );
    const derbyBannerThursdayPreEvent = getDerbyBanner(
      storyblokResponse,
      "thursday_pre_event"
    );

    const eventDate = formatDate(get(storyblokResponse, "content.event_date"));
    const eventName: string = get(storyblokResponse, "content.event_name", "");
    const trackAbbr: string = get(storyblokResponse, "content.track_abbr", "");
    const raceNumber: number = +get(
      storyblokResponse,
      "content.event_race_number",
      ""
    );

    return {
      eventInfo: {
        eventDate,
        trackAbbr,
        raceNumber,
        eventName: {
          label: eventName
        }
      },
      derbyBanner: {
        default: derbyBannerDefault,
        eventDay: derbyBannerEventDay,
        fridayPreEvent: derbyBannerFridayPreEvent,
        thursdayPreEvent: derbyBannerThursdayPreEvent,
        preEvent: derbyBannerPreEvent
      }
    };
  } catch (e) {
    console.warn("Error retrieving homepage config by Storyblok", e);

    return {
      eventInfo: undefined,
      derbyBanner: undefined
    };
  }
};
