import React, { Component, Fragment } from "react";
import { noop, get } from "lodash";

import ScrollArea from "../../_static/ScrollArea";
import Pagination from "../../_molecule/Pagination";
import PaginationArea from "./styled-components";

export default class MagnetScrollArea extends Component {
  static defaultProps = {
    hasPagination: false,
    onHandleTransition: noop,
    children: [],
    activeIndex: 0,
    isLoading: false,
    cardsPerPage: 3,
    contextSA: "topraces",
    isFreeScroll: false,
    isRunnerScroll: false,
    hasTouchMove: true
  };

  constructor(props) {
    super(props);
    this.state = {
      isNextRacesButtonInactive:
        get(this.props, "children[0].length", 0) <= this.props.cardsPerPage - 1,
      isPreviousRacesButtonInactive: true
    };
    if (typeof window !== "undefined") {
      window.addEventListener("updateResizableArea", this.updateSwiper);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeIndex !== prevProps.activeIndex &&
      !this.swiper.animating &&
      this.props.activeIndex !== null
    ) {
      this.swiper.slideTo(
        this.props.activeIndex,
        this.props.isRunnerScroll ? 500 : 0
      );
    }
    if (
      get(this.props, "children[0]?.length") !==
      get(prevProps, "children[0]?.length")
    ) {
      this.updatePaginationState();
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("updateResizableArea", this.updateSwiper);
    }
  }

  setSwiper = (swiper) => {
    this.swiper = swiper;

    // This verification aims to prevent a bug where a stack of event listeners
    // was being created and causing
    // multiple triggers
    if (!get(this.swiper, "eventsListeners.transitionEnd")) {
      this.swiper.on("transitionEnd", () =>
        this.props.onHandleTransition(swiper.activeIndex)
      );
    }
    this.swiper.autoHeight = !this.props.isFreeScroll;
    this.swiper.freeMode = this.props.isFreeScroll;
    this.swiper.freeModeMomentumBounce = false;
    this.swiper.slideToClickedSlide = true;

    if (!this.props.hasTouchMove) {
      this.swiper.allowTouchMove = false;
    }

    if (this.props.hasPagination) {
      this.swiper.allowTouchMove = false;
    }

    if (this.props.hasPagination) {
      this.updatePaginationState();
    }

    if (!this.swiper.animating) {
      this.swiper.slideTo(
        this.props.activeIndex,
        this.props.isRunnerScroll ? 500 : 0
      );
    }
  };

  updateSwiper = () => {
    if (this.swiper) {
      this.swiper.updateAutoHeight(200, false);
    }
  };

  swiper;

  showNextCards = () => {
    this.swiper.slideTo(this.swiper.activeIndex + this.props.cardsPerPage, 500);
    this.updatePaginationState();
  };

  showPreviousCards = () => {
    this.swiper.slideTo(this.swiper.activeIndex - this.props.cardsPerPage, 500);
    this.updatePaginationState();
  };

  // A magnet scroll area will always require a last element
  // That's why this.props.children needs to be decoupled
  updatePaginationState = () => {
    this.setState({
      isNextRacesButtonInactive:
        this.swiper.activeIndex + this.props.cardsPerPage >=
        get(this.props, "children[0].length", 0) + 1,
      isPreviousRacesButtonInactive: this.swiper.activeIndex === 0
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.hasPagination && (
          <PaginationArea data-qa-label="top-races-paginationArea">
            <Pagination
              theme="topRaces"
              onForwardPage={this.showNextCards}
              onBackPage={this.showPreviousCards}
              isForwardDisabled={this.state.isNextRacesButtonInactive}
              isBackDisabled={this.state.isPreviousRacesButtonInactive}
            />
          </PaginationArea>
        )}
        <ScrollArea
          data-qa-label="top-races-scrollableArea"
          hasPagination={this.props.hasPagination}
          contextSA={this.props.contextSA}
          setSwiper={this.setSwiper}
          cardsPerPage={this.props.cardsPerPage}
          isFreeScroll={this.props.isFreeScroll}
        >
          {this.props.children}
        </ScrollArea>
      </Fragment>
    );
  }
}
