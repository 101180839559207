import React, { Component } from "react";

import { noop, bindAll } from "lodash";

import { Switch, Slider } from "./styled-components";

export default class Toggle extends Component {
  static defaultProps = {
    on: false,
    onClick: noop,
    qaLabel: ""
  };

  constructor(props) {
    super(props);
    bindAll(this, ["clickToggle"]);
  }

  clickToggle(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.props.onClick();
  }

  render() {
    return (
      <Switch $on={this.props.on} onClick={this.clickToggle}>
        <input
          type="checkbox"
          {...(this.props.qaLabel !== ""
            ? {
                "data-qa-label": this.props.qaLabel
              }
            : {})}
          data-qa-active={this.props.on}
        />
        <Slider $on={this.props.on} />
      </Switch>
    );
  }
}
