import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium } from "../../_static/Typography";

export const PromoItemContainer = styled.div.attrs((props) => ({
  itemType: props.itemType
}))`
  width: 100%;
  max-height: 83px;

  &:not(:last-child) {
    > div {
      border-bottom: 1px solid ${buildColor("blue", "000")};
    }
  }

  &:last-child {
    > div {
      border-bottom: 1px solid ${buildColor("white", "100")};
    }
  }

  &:last-child:hover {
    > div {
      border-bottom: 1px solid ${buildColor("blue_accent", "100")};
    }
  }

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const PromoWrapper = styled.div`
  display: flex;
  margin: 0 12px;

  &:hover {
    border-bottom: 1px solid ${buildColor("grey", "000")};
  }
`;

export const PromoLink = styled(Link).attrs((props) => ({
  itemProp: props.itemProp
}))`
  text-decoration: none;
  padding: 15px 0;
  display: flex;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    ${(props) =>
      props.to === "/" &&
      css`
        cursor: default;
      `}
  }
`;

export const PromoLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

export const PromoTitle = styled.p.attrs((props) => ({
  itemProp: props.itemProp,
  hasLargeWidth: props.hasLargeWidth
}))`
  max-width: ${(props) => (props.hasLargeWidth ? "715px" : "267px")};
  color: ${buildColor("grey", "900")};
  letter-spacing: 0;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: ${fontMedium};
  overflow: hidden;
`;

export const PromoDescription = styled.p.attrs((props) => ({
  itemProp: props.itemProp,
  hasLargeWidth: props.hasLargeWidth
}))`
  max-width: ${(props) => (props.hasLargeWidth ? "715px" : "267px")};
  color: ${buildColor("grey", "900")};
  letter-spacing: 0;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: ${fontMedium};
  margin-bottom: 2px;
  overflow: hidden;
`;

export const PromoLocation = styled.div.attrs((props) => ({
  itemProp: props.itemProp,
  itemType: props.itemType
}))`
  display: none;
`;

export const PromoAddress = styled.div.attrs((props) => ({
  itemProp: props.itemProp,
  itemType: props.itemtype
}))`
  display: none;
`;

export const PromosDates = styled.div`
  height: 16px;
  flex-grow: 1;
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
`;

export const OptinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
`;
