import { gql } from "@apollo/client";

const TrackDatesFragment = {
  entry: gql`
    fragment trackDatesFragment on PastTrack {
      dates
    }
  `
};

export default TrackDatesFragment;
