import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { noop, get } from "lodash";

import mediator from "@tvg/mediator";
import PromosWidget from "@tvg/atomic-ui/_templates/PromosWidget";
import tvgConf from "@tvg/conf";

import promosWidgetService from "@tvg/api/pro";
import withRouter from "@tvg/utils/withCustomRouter";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { getAccountNumber } from "@urp/store-selectors";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import setUserPromos, {
  setPromoWidgetModalStateAction
} from "../actions/user-promos";
import { PromoWidgetModal } from "./Modal";

export class PromosWidgetComponent extends PureComponent {
  static defaultProps = {
    setUserPromos: noop,
    setModalState: noop,
    promosWidgetActive: false,
    promosList: [],
    accountNumber: "",
    isWidget: false,
    hasRequestedLogin: false,
    hasLargeWidth: false,
    title: "Promotions Calendar",
    device: "mobile",
    isPromoWidgetModalOpen: false,
    enablePromosStoryblok: false,
    useIsPromoTagShownFlag: false,
    isAccountCompliant: false
  };

  conf;

  static checkQueryParams = (params) => {
    const queryParams = ["?", "#"];

    return queryParams.map((q) => params.includes(q))[1];
  };

  static decodeURLParams = (url) => {
    const hashes = PromosWidgetComponent.checkQueryParams(url)
      ? url
          .slice(0, url.indexOf("#"))
          .slice(url.indexOf("?") + 1)
          .split("&")
      : url.slice(url.indexOf("?") + 1).split("&");

    return hashes.reduce((params, hash) => {
      const split = hash.indexOf("=");

      if (split < 0) {
        return {};
      }

      const key = hash.slice(0, split).toLowerCase();
      const val = hash.slice(split + 1);

      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  };

  constructor(props) {
    super(props);
    this.conf = tvgConf();
    this.state = {
      promoTitle: "Promotions",
      promoPath: "/promos/MoneyBack/emerald/"
    };
  }

  componentDidMount() {
    if (this.props.accountNumber && typeof window !== "undefined") {
      this.getPromos(this.props.accountNumber);
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      accountNumber,
      promosWidgetActive,
      hasRequestedLogin,
      isAccountCompliant
    } = this.props;

    if (
      (promosWidgetActive &&
        (hasRequestedLogin !== get(prevProps, "hasRequestedLogin", false) ||
          accountNumber !== get(prevProps, "accountNumber", ""))) ||
      isAccountCompliant !== get(prevProps, "isAccountCompliant", false)
    ) {
      return this.getPromos(accountNumber || "");
    }
    return null;
  };

  componentWillUnmount() {
    this.props.setModalState(false);
  }

  onPromoItemClick = (e, route, promoName) => {
    const { history } = this.props;
    const pattern = /^https?:\/\//;
    const isFullUrl = pattern.test(route);
    let destinationUrl = route;

    let path = "";
    let params = "";
    let paramsFormatted = {};
    if (!isFullUrl) {
      const routeParts = route ? route.split("?") : [];
      path = get(routeParts, "[0]", "");
      params = get(routeParts, "[1]", "");
    }

    if (this.props.device !== "desktop" && !this.props.enablePromosStoryblok) {
      let context = "web";

      if (this.conf.product === "ios2") {
        context = "ios";
      } else if (this.conf.product === "androidwrapper") {
        context = "android";
      }

      paramsFormatted = {
        ...PromosWidgetComponent.decodeURLParams(params),
        mobile: "true",
        context
      };
    } else {
      paramsFormatted = { ...PromosWidgetComponent.decodeURLParams(params) };
    }

    if (!isFullUrl) {
      destinationUrl = this.conf.buildUrl({ path, params: paramsFormatted });
    } else if (this.props.device !== "desktop") {
      destinationUrl += destinationUrl.endsWith("/")
        ? this.conf.buildUrl({ params, relative: true }).substr(1)
        : this.conf.buildUrl({ params, relative: true });
    }

    mediator.base.dispatch({
      type: "PROMO_WIDGET:CLICK",
      payload: { promoName, destinationUrl }
    });

    if (this.props.isWidget || isFullUrl) {
      e.stopPropagation();
      e.preventDefault();

      if (
        this.props.device !== "desktop" &&
        !this.props.enablePromosStoryblok
      ) {
        this.setState({
          promoTitle: promoName,
          promoPath: destinationUrl
        });
        this.props.setModalState(true);
      } else if (this.props.device === "desktop") {
        if (this.props.enablePromosStoryblok && !route.includes("www")) {
          const hasFirstSlash = path.charAt(0) === "/";
          let pathFormatted = path;

          if (!hasFirstSlash) {
            pathFormatted = `/${pathFormatted}`;
          }

          // Strip last slash to prevent mediator navigation loop
          if (path.endsWith("/")) {
            pathFormatted = pathFormatted.slice(0, -1);
          }

          pathFormatted =
            params !== "" ? `${pathFormatted}?${params}` : pathFormatted;
          if (isTvg5()) {
            history.push(pathFormatted);
          } else {
            mediator.base.dispatch({
              type: "TVG4_NAVIGATION",
              payload: {
                route: pathFormatted
              }
            });
          }
        } else {
          window.open(destinationUrl, "_blank");
        }
      } else {
        window.location.assign(destinationUrl);
      }
    }
  };

  getPromos = (accountNumber) => {
    promosWidgetService
      .fetchTopPromotions(accountNumber)
      .then((promotions) => {
        const fetchedPromos = get(promotions, "data.promotions", null);
        const validPromos = this.props.useIsPromoTagShownFlag
          ? fetchedPromos.filter((promo) => promo.isPromoTagShown)
          : fetchedPromos;
        const assignValue = (val) =>
          val === null || val === undefined ? 20 : val;
        if (validPromos) {
          validPromos.sort(
            (a, b) =>
              assignValue(a.promoTypePriority) -
              assignValue(b.promoTypePriority)
          );
        }
        this.props.setUserPromos(validPromos);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render = () =>
    this.props.promosWidgetActive && (
      <Fragment>
        <PromosWidget
          promosWidgetList={this.props.promosList}
          onPromoItemClick={this.onPromoItemClick}
          title={this.props.title}
          device={this.props.device}
          hasLargeWidth={this.props.hasLargeWidth}
        />
        {this.props.device !== "desktop" && (
          <PromoWidgetModal
            isOpen={this.props.isPromoWidgetModalOpen}
            device={this.props.device}
            promoTitle={this.state.promoTitle}
            promoPage={this.state.promoPath}
            setModalState={this.props.setModalState}
          />
        )}
      </Fragment>
    );
}

const mapStateToProps = (store) => ({
  promosWidgetActive: get(
    store,
    "capi.featureToggles.promosWidgetActive",
    false
  ),
  enablePromosStoryblok: get(
    store,
    "capi.featureToggles.enablePromosStoryblok",
    false
  ),
  accountNumber: getAccountNumber(store),
  promosList: get(store, "app.promosList", []),
  hasRequestedLogin: get(store, "userData.logged", false),
  isAccountCompliant: isAccountCompliantSelector(store),
  isPromoWidgetModalOpen: get(store, "app.isPromoWidgetModalOpen", false),
  useIsPromoTagShownFlag: get(
    store,
    "capi.featureToggles.useIsPromoTagShownFlag",
    false
  )
});

const mapDispatchToProps = (dispatch) => ({
  setUserPromos: (promosList) => dispatch(setUserPromos(promosList)),
  setModalState: (value) => dispatch(setPromoWidgetModalStateAction(value))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PromosWidgetComponent)
);
