import React from "react";
import { Props } from "./types";
import MobilePoolCell from "../MobilePoolCell";

const MobileView = ({ pools, onPoolClick }: Props) => {
  const topPools = pools.slice(0, 10);

  if (!topPools.length) return null;

  return (
    <>
      {topPools.map((pool, index) => (
        <MobilePoolCell
          key={
            pool.trackCode +
            pool.race.raceNumber +
            pool.race.postTime +
            index.toString()
          }
          onClick={() => onPoolClick(pool)}
          pool={pool}
        />
      ))}
    </>
  );
};

export default MobileView;
