import React from "react";
import {
  ColorTokens,
  Icon,
  Paragraph,
  useColorTokens
} from "@tvg/design-system";
import { Props } from "./types";
import { Block, Container, Row } from "./styled-components";
import AmountValue from "../AmountValue";
import WagerTypeValue from "../WagerTypeValue";
import MtpValue from "../MtpValue";
import { QA_LABEL } from "../../qaLabels";

const MobilePoolCell = ({ onClick, pool }: Props) => {
  const { border } = useColorTokens() as ColorTokens;

  return (
    <Container
      onClick={onClick}
      borderColor={border.default}
      data-qa-label={QA_LABEL.body.mobile.pool}
    >
      <Block>
        <AmountValue amount={pool.amount} />
        <WagerTypeValue fontSize={12}>
          {pool.wagerType.name.toUpperCase()}
        </WagerTypeValue>
      </Block>
      <Row>
        <Block>
          <MtpValue race={pool.race} />
        </Block>

        <Block>
          <Paragraph
            qaLabel={QA_LABEL.body.race.name}
            fontFamily="medium"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            // eslint-disable-next-line react-native/no-inline-styles
            style={{ overflow: "hidden" }} // Had to force this inline style, component doesnt accept overflow nor classnames (to overwrite with SC)
          >
            {pool.track}
          </Paragraph>
        </Block>

        <Block>
          <Paragraph qaLabel={QA_LABEL.body.race.number} fontFamily="medium">
            {` R${pool.race.raceNumber}`}
          </Paragraph>
        </Block>
        <Block>
          <Icon
            name="chevronRight"
            qaLabel={QA_LABEL.body.mobile.icon}
            size="s"
          />
        </Block>
      </Row>
    </Container>
  );
};

export default MobilePoolCell;
