import { reduce, get } from "lodash";

export const mapSelections = (selections) =>
  selections.map((selection) =>
    selection.map((runner) => get(runner, "order", "").toString())
  );

export const mapSelectionsToString = (selections) =>
  selections
    .map((selection) => selection.map((runner) => runner).join(","))
    .join("-");

export const mapTalentPickFromGraph = (pick) => ({
  id: pick.id,
  selections: mapSelections(pick.selections),
  favorites: mapSelections(pick.favorites),
  amount: pick.amount,
  price: pick.price,
  wagerType: {
    id: pick.wagerType.type.id,
    abbreviation: pick.wagerType.type.code,
    legCount: pick.wagerType.legCount,
    isBox: pick.wagerType.isBox,
    isKey: pick.wagerType.isKey,
    isWheel: pick.wagerType.isWheel
  },
  track: pick.race.track.code,
  trackName: pick.race.track.name,
  race: +pick.race.number,
  raceId: pick.race.id,
  raceType: pick.race.type.code,
  raceStatus: pick.race.status.code,
  talent: pick.talent,
  published: true,
  racePostTime: pick.race.postTime,
  mtp: pick.race.mtp,
  highlighted: pick.race.highlighted
});

export const mapTalentPickFromService = (pick) => ({
  ...pick,
  selections: mapSelections(pick.selections),
  favorites: mapSelections(pick.favorites)
});

export const mapTalentPicksListFromGraph = (talentPicks) =>
  reduce(
    talentPicks,
    (list, pick) => {
      list.push(mapTalentPickFromGraph(pick));

      return list;
    },
    []
  );

export const mapTalentPicksListFromService = (talentPicks) =>
  reduce(
    talentPicks,
    (list, pick) => {
      list.push(mapTalentPickFromService(pick));

      return list;
    },
    []
  );
