import React from "react";
import { Container } from "./styled-components";
import TrackNameDisplay from "../TrackNameDisplay";
import TrackLocationDisplay from "../TrackLocationDisplay";
import { Props } from "./types";
import { mockRaceB } from "../../utils/mocks";
import { buildQaLabel } from "../../utils";
import { CONTAINER, HEADER } from "../../utils/constants";

const Header = ({ race = mockRaceB, qaLabel = "", onClick }: Props) => {
  const hasDescription = !!race?.highlighted?.description;

  return (
    <Container
      data-qa-label={buildQaLabel([qaLabel, HEADER, CONTAINER])}
      hasDescription={hasDescription}
      onClick={onClick}
    >
      <TrackNameDisplay race={race} qaLabel={buildQaLabel([qaLabel, HEADER])} />
      <TrackLocationDisplay
        race={race}
        qaLabel={buildQaLabel([qaLabel, HEADER])}
      />
    </Container>
  );
};

export default Header;
