import { gql } from "@apollo/client";

export const WAGER_TYPE = gql`
  fragment WagerType on TalentPickType {
    wagerType {
      type {
        id
        code
      }
      legCount
      isBox
      isKey
      isWheel
    }
  }
`;

export default WAGER_TYPE;
