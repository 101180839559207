import { get } from "lodash";

import { SeoTrack, Track } from "@tvg/ts-types/Track";
import { US_STATES, CANADA_STATES, COUNTRY_NAMES } from "../constants";

export const getUSStateFullName = (stateCode: string) =>
  get(US_STATES, stateCode, "US");

export const getCanStateFullName = (stateCode: string) =>
  get(CANADA_STATES, stateCode, "Canada");

export const getCountryFullName = (countryCode: string) =>
  get(COUNTRY_NAMES, countryCode, countryCode);

export const searchTracks = (
  tracks: Array<SeoTrack | Track>,
  searchQuery: string
) => {
  if (!searchQuery) {
    return tracks;
  }

  const searchTerm = searchQuery.toLowerCase();
  const newTracks = tracks.filter(
    (track) =>
      track.name.toLowerCase().includes(searchTerm) ||
      track.location?.state?.toLowerCase().includes(searchTerm) ||
      track.location?.country?.toLowerCase().includes(searchTerm) ||
      getCountryFullName(track.location?.country ?? "")
        .toLowerCase()
        .includes(searchTerm) ||
      (!!track.location?.state &&
        track.location?.country === "USA" &&
        getUSStateFullName(track.location?.state)
          .toLowerCase()
          .includes(searchTerm)) ||
      (!!track.location?.state &&
        track.location?.country === "CAN" &&
        getCanStateFullName(track.location?.state)
          .toLowerCase()
          .includes(searchTerm))
  );

  return newTracks;
};
