import { gql } from "@apollo/client";

export const TalentPickRaceFragment = {
  entry: gql`
    fragment Race on TalentPickType {
      race {
        id
        number
        track {
          id
          code
          name
        }
        mtp
        postTime
        isGreyhound
        numRunners
        status {
          code
        }
        type {
          code
        }
        highlighted(product: $product, device: $device, brand: $brand) {
          description
          style
        }
        bettingInterests {
          biNumber
          saddleColor
          numberColor
          runners {
            runnerId
            entityRunnerId
            scratched
          }
        }
      }
    }
  `
};
