import axios from "axios";
import { get } from "lodash";
import { getTime } from "date-fns";
import {
  getStoryblokSpaceUrl,
  STORYBLOK_CONTENT_PATH
} from "./constraints/config";
import { getStoryblokVersion } from "./getStoryblokVersion";
import { StoryblokDraft, StoryblokEnvironment } from "./types";
import { getStoryblokToken } from "./getStoryblokToken";

export const fetchStoryBlokCacheVersion = async (
  contentPath: STORYBLOK_CONTENT_PATH,
  draft?: StoryblokDraft,
  overrideToken?: StoryblokEnvironment
): Promise<number | null> => {
  const cacheTimestamp = getTime(Date.now());

  try {
    const response = await axios({
      method: "GET",
      url: getStoryblokSpaceUrl(contentPath),
      headers: {
        "content-type": "application/json"
      },
      params: {
        version: getStoryblokVersion(draft),
        token: getStoryblokToken(overrideToken)
      }
    });
    return get(response, "data.space.version", cacheTimestamp);
  } catch (error: unknown) {
    console.error("Error trying to get cache version in storyblok", error);
    return cacheTimestamp;
  }
};

export default fetchStoryBlokCacheVersion;
