import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "../../_static/ColorPalette";
import { buildTitle } from "../../_atom/Title";
import { buildTextHighlighted } from "../../_atom/TextHighlighted";

export const RaceLinkTitle = buildTitle({
  tag: "h6",
  fontSize: 14,
  bold: true,
  uppercase: true
});

export const BetLabel = buildTextHighlighted(
  { bgColor: buildColor("blue", "800"), textColor: buildColor("white", "100") },
  11,
  true,
  true,
  "raceLinkAbbr-betLabel"
);

export const ResultsLabel = styled(
  buildTextHighlighted(
    {
      bgColor: buildColor("grey", "100"),
      textColor: buildColor("grey", "900")
    },
    11,
    true,
    true,
    "raceLinkAbbr-resultsLabel"
  )
)`
  padding-top: 1px;
`;

export const LabelsWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 5px;

  > span {
    width: 12px;
    height: 12px;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
`;

export const RaceLinkAbbrLink = styled(Link).attrs((props) => ({
  id: props.id,
  "data-qa-label": "raceLinkAbbr"
}))`
  text-decoration: none;
  outline: none;
  color: ${buildColor("grey", "900")};
  background-color: ${buildColor("white", "100")};
  border-radius: 2px;
  border: 1px solid ${buildColor("grey", "300")};
  box-shadow: 0 1px 0 0 ${buildColor("grey", "300")};
  padding: 5px 8px;
  min-width: 64px;
  display: inline-flex;
  flex-direction: column;

  &:active {
    background-color: ${buildColor("blue", "000")};
  }
`;

export const RaceLinkAbbrNameWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MtpContainer = styled.span`
  ${(props) =>
    props.smaller &&
    css`
      & > time > span {
        width: 28px;
        height: 12px;
        font-size: 11px;
      }
    `};
`;
