import { get } from "lodash";
import { DeviceConfigParams, DeviceStyleConfig } from "./types";

export const generateQaLabel = (labels: string[]) => labels.join("-");

export const getConfig = ({
  headerHeight,
  titleFontSize,
  bodyPadding,
  bodyDirection,
  borderRadius,
  bodyGap
}: DeviceConfigParams): DeviceStyleConfig => ({
  borderRadius,
  header: {
    height: headerHeight,
    title: {
      fontSize: titleFontSize
    }
  },
  body: {
    padding: bodyPadding,
    direction: bodyDirection,
    gap: bodyGap
  }
});

const deviceConfig = {
  desktop: getConfig({
    headerHeight: 64,
    titleFontSize: 18,
    bodyPadding: "0px 0px 12px 0px",
    bodyDirection: "column",
    borderRadius: 4,
    bodyGap: 0
  }),
  mobile: getConfig({
    headerHeight: 48,
    titleFontSize: 16,
    bodyPadding: "0px 12px 12px 12px",
    bodyDirection: "row",
    borderRadius: 0,
    bodyGap: 12
  })
};

export const getDeviceStyleConfig = (isDesktop: boolean = true) =>
  get(deviceConfig, isDesktop ? "desktop" : "mobile");
