import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontBold, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const linkContainerCSS = `display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${fontMedium};
  color: ${buildColor("blue_accent", "500")};
  text-decoration: none;
  font-size: 12px;
  padding: 8px;
  `;

export const Container = styled.section`
  background: ${buildColor("white", "100")};
  width: 100%;
  padding-bottom: 10px;
  position: relative;
`;

export const Title = styled.h1`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  margin: 0;
  ${({ biggerTitle }) =>
    biggerTitle
      ? css`
          height: 21px;
          font-size: 18px;
        `
      : css`
          height: 19px;
          font-size: 16px;
        `};
`;

export const UpperSection = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  ${(props) =>
    props.isDesktop
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          height: 56px;
        `
      : css`
          height: 48px;
          width: 100%;
        `};
`;

export const LinkContainer = styled(Link)`
  ${linkContainerCSS}
`;

export const LinkContainerTVG4 = styled.div`
  ${linkContainerCSS}

  &:hover {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${buildColor("white", "100")};
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 0 0 20px;

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const TopRaceOutterContainer = styled.div`
  max-width: ${(props) => (!props.isDesktop ? "292px" : "auto")};
  margin: ${(props) => (!props.isDesktop ? " 0 0 0 8px" : "0")};
  height: auto;
  padding-bottom: 2px;

  &:first-of-type {
    margin-left: ${(props) => !props.isDesktop && "12px"};
  }
`;

export const LastElementContainer = styled.div`
  margin-right: calc(100% - 292px);
  max-width: ${(props) => (!props.isDesktop ? "292px" : "auto")};
  padding-left: ${({ isDesktop, isFirstElement }) => {
    if (!isDesktop && isFirstElement) {
      return "12px";
    }
    if (!isDesktop && !isFirstElement) {
      return "8px";
    }
    return "0px";
  }};
  height: auto;
  min-height: 320px;
`;

export const LastElement = styled.div`
  background: ${buildColor("white", "100")};
  min-height: 152px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
