import { gql } from "@apollo/client";
import { TOP_RACE } from "../fragments/Race";
import { TOP_RACES_BETTING_INTERESTS } from "../fragments/BettingInterests";

export const GET_FEATURED_RACES = gql`
  query getFeaturedRaces(
    $wagerProfile: String
    $brand: String!
    $product: String!
    $device: String!
    $results: Int!
  ) {
    races(
      filter: {
        status: ["O", "IC"]
        allRaceClasses: true
        isTopRace: { product: $product, device: $device, brand: $brand }
      }
      page: { current: 0, results: $results }
      profile: $wagerProfile
      sort: {
        byPinnedOrder: {
          product: $product
          device: $device
          brand: $brand
          order: ASC
        }
      }
    ) {
      ...TopRace
      promos(product: $product, brand: $brand) {
        isAboveTheLine
        rootParentPromoID
        isPromoTagShown
      }
      highlighted(product: $product, device: $device, brand: $brand) {
        description
        style
        pinnedOrder
      }
      bettingInterests(
        filter: { isWagerable: true }
        sort: { byCurrentOdds: ASC }
        page: { current: 0, results: 3 }
      ) {
        ...TopRacesBettingInterests
      }
    }
  }
  ${TOP_RACES_BETTING_INTERESTS}
  ${TOP_RACE}
`;

export default GET_FEATURED_RACES;
