import tvgConf from "@tvg/conf";
import { get } from "lodash/fp";
// @ts-ignore
import { v1 as generateUUID } from "uuid";
import getProtocol from "../protocolSetter";
import requester from "../requester";

const tpmService: string = "service.tpm";

const getTpmServiceUrl = (): string => `${tvgConf().config(tpmService)}`;

type raceType = {
  trackAbbr: string | undefined;
  raceNumber: string | undefined;
};

type pick = {
  id: string;
};

type TalentsParams = {
  isActive: boolean;
};

export type selectionsType = Array<
  Array<{
    order: number;
  }>
>;

const placePick = (
  selections: selectionsType,
  favoriteSelections: selectionsType,
  wagerType: string,
  betAmount: number,
  betCost: number,
  race: raceType,
  talentId?: number
): Promise<pick> => {
  if (betAmount <= 0 && betCost <= 0) {
    return Promise.reject(new Error("Invalid bet params"));
  }

  if (!talentId) {
    return Promise.reject(new Error("Invalid talent id"));
  }

  if (!selections.length) {
    return Promise.reject(new Error("Invalid selections"));
  }

  if (!get("trackAbbr", race) || !get("raceNumber", race)) {
    return Promise.reject(new Error("Invalid race params"));
  }

  const uuid = generateUUID();

  const postData = {
    id: uuid,
    selections,
    favorites: favoriteSelections,
    amount: betAmount,
    price: +betCost.toFixed(2),
    wagerType: { abbreviation: wagerType },
    track: race.trackAbbr,
    race: race.raceNumber,
    published: true
  };

  return requester()({
    method: "post",
    url: `${getProtocol()}${getTpmServiceUrl()}/talents/${talentId}/picks`,
    data: postData,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": uuid,
      "x-tvg-context": tvgConf().context()
    }
  }).then(get("data"));
};

const getAllTalents = (params?: TalentsParams) =>
  requester()({
    method: "get",
    url: `${getProtocol()}${getTpmServiceUrl()}/talents`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-tvg-context": tvgConf().context()
    },
    params
  });

const TPM_SERVICE = {
  placePick,
  getAllTalents
};

export default TPM_SERVICE;
