import React, { PureComponent } from "react";

import { noop } from "lodash";
import {
  Container,
  RaceTime,
  RaceInfo,
  RaceNumber,
  RacePromoIcon
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { promosolid, optedin } from "../../_static/Icons/icons";
import MTP from "../../_atom/MTP";

export default class TrackRaceLink extends PureComponent {
  static defaultProps = {
    raceTime: "",
    raceNumber: "",
    qaLabel: "",
    hasPromo: false,
    optedIn: false,
    mtp: 0,
    status: "O",
    to: "",
    onClickCallback: noop,
    idPrefix: "",
    trackCode: "",
    isMTPNewRules: false,
    isSEORacetracks: false
  };

  buildIdObject = () => {
    const id =
      this.props.idPrefix && this.props.trackCode && this.props.raceNumber
        ? `${this.props.idPrefix}_raceElement_${this.props.trackCode}_${this.props.raceNumber}`
        : null;
    return id ? {} : { id };
  };

  renderIcon = () =>
    this.props.hasPromo && (
      <RacePromoIcon>
        {this.props.optedIn ? (
          <Icon
            qaLabel={`${this.props.qaLabel}OptedInIcon`}
            icon={optedin}
            color={buildColor("grey", "900")}
          />
        ) : (
          <Icon
            qaLabel={`${this.props.qaLabel}PromoIcon`}
            icon={promosolid}
            color={buildColor("yellow", "500")}
          />
        )}
      </RacePromoIcon>
    );

  render() {
    const idObject = this.buildIdObject();

    return (
      <Container
        {...idObject}
        onClick={(event) => {
          this.props.onClickCallback(event);
        }}
        to={this.props.to}
        data-qa-label={`${this.props.qaLabel}Link`}
      >
        <RaceTime data-qa-label={`${this.props.qaLabel}RaceTime`}>
          <MTP
            mtp={this.props.mtp}
            postTime={this.props.raceTime}
            status={this.props.status}
            isPlainMTP
            isMTPNewRules={this.props.isMTPNewRules}
          />
        </RaceTime>
        <RaceInfo>
          <RaceNumber data-qa-label={`${this.props.qaLabel}RaceNumber`}>
            {this.props.isSEORacetracks
              ? `Race ${this.props.raceNumber}`
              : `R${this.props.raceNumber}`}
          </RaceNumber>
          {this.renderIcon()}
        </RaceInfo>
      </Container>
    );
  }
}
