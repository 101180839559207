import React, { Fragment } from "react";
import styled, { keyframes } from "styled-components";
import buildColor from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }
  
  50% {
    fill: ${buildColor("blue", "100")};
  }
  
  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

const MaskContainer = styled.div`
  margin-top: 4px;
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 85px 215px 175px 311px auto;
  border-radius: 4px;
  margin-bottom: 4px;
  background-color: ${buildColor("grey", "000")};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CellContainer = styled.div`
  padding: 0 12px;
`;

const SVG = styled.svg`
  rect {
    animation: ${pulseAnimation} 1.25s infinite;
  }
  svg {
    overflow: visible;
  }
`;

const Cell = ({ type }) => {
  const rectColor = buildColor("blue", "000");
  return (
    <CellContainer>
      <SVG width="100%" height="82" fill={buildColor("grey", "000")}>
        {type === "status" && (
          <rect y="12" width="41" height="14" rx="1" fill={rectColor} />
        )}
        {type === "raceDetails" && (
          <Fragment>
            <rect y="12" width="108" height="14" rx="1" fill={rectColor} />
            <rect y="30" width="154" height="14" rx="1" fill={rectColor} />
          </Fragment>
        )}
        {type === "betDetails" && (
          <Fragment>
            <rect y="12" width="138" height="14" rx="1" fill={rectColor} />
            <rect y="30" width="67" height="14" rx="1" fill={rectColor} />
          </Fragment>
        )}
        {type === "selections" && (
          <Fragment>
            <rect y="12" width="98" height="14" rx="1" fill={rectColor} />
            <svg x="100%" y="56" width="118" height="14">
              <rect
                width="118"
                height="14"
                x="-118"
                y="0"
                rx="1"
                fill={rectColor}
              />
            </svg>
          </Fragment>
        )}
        {type === "actions" && (
          <Fragment>
            <rect y="12" width="40" height="32" rx="1" fill={rectColor} />
            <svg x="50%" y="12" width="40" height="32">
              <rect
                width="40"
                height="32"
                x="-20"
                y="0"
                rx="1"
                fill={rectColor}
              />
            </svg>
            <svg x="100%" y="12" width="40" height="32">
              <rect
                width="40"
                height="32"
                x="-40"
                y="0"
                rx="1"
                fill={rectColor}
              />
            </svg>
          </Fragment>
        )}
      </SVG>
    </CellContainer>
  );
};

const MyBetsDesktopMask = ({ rowsAmount }) => {
  const renderContent = () => {
    const rowArray = [...Array(rowsAmount)].map((_value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <RowContainer key={`MyBetsRow-${index}`}>
        <Cell type="status" />
        <Cell type="raceDetails" />
        <Cell type="betDetails" />
        <Cell type="selections" />
        <Cell type="actions" />
      </RowContainer>
    ));

    return rowArray;
  };

  return (
    <MaskContainer data-qa-label="myBetsDesktopMask">
      {renderContent()}
    </MaskContainer>
  );
};

MyBetsDesktopMask.defaultProps = {
  rowsAmount: 4
};

export default MyBetsDesktopMask;
