export const initialState = {
  races: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_TOP_RACES": {
      return { ...state, races: action.payload.topRaces };
    }
    default: {
      return state;
    }
  }
}
