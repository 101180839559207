import { createStore, compose, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import reducers, { initialState } from "./reducers";

let composeEnhancers = compose;

/* eslint-disable */
composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

export default (state = initialState) => {
  const middlewares = [thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(reducers, state, enhancer);
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      return System.import("./reducers").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      );
    });
  }

  return store;
};
