export const initialState = {
  links: [],
  data: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "STORE_QUICK_LINKS":
      return {
        links: action.payload,
        data: state.data
      };
    case "STORE_QUICK_LINKS_DATA":
      return {
        links: state.links,
        data: action.payload
      };
    default:
      return state;
  }
}
