import type { Device } from "@tvg/conf/src/types";
import type { BinaryFn } from "@tvg/ts-types/Functional";
import { HeaderBanner } from "@urp/derby-banner/src/types";

type ComponentName =
  | "QUICK_LINKS"
  | "PROMO_ONBOARDING"
  | "TOP_RACES"
  | "TOP_TRACKS"
  | "UPCOMING_RACES"
  | "PROMO_WIDGET"
  | "SEO_FOOTER"
  | "PROMO_BANNER_TOP"
  | "PROMO_BANNER_BOTTOM"
  | "TOP_POOLS"
  | "TALENT_PICKS"
  | "PREVIOUS_WINNERS"
  | "DEFAULT";

export type HomeState = Array<{
  id: number;
  name: ComponentName;
  description: string;
}>;

type ComponentType =
  | "fixed"
  | "top"
  | "bottom"
  | "full"
  | "large"
  | "small"
  | "default";

export type HomepageOrder = Array<{
  id: number;
  name: ComponentName;
  description: string;
}>;

export type Props = {
  device: Device;
  shouldUpdate: boolean;
  quickLinksExpanded: boolean;
  useTopTracksDS: boolean;
  usePromosWidgetDS: boolean;
  usePreviousWinnersDS: boolean;
  title: string;
  message: string;
  useURPTipsAndPicks: boolean;
};

export type Context = {
  rdaClient: {};
};

export type ComponentMap = {
  [k in ComponentName]: {
    type: ComponentType;
    render: BinaryFn<Props, Context, React.ReactNode>;
  };
};

export enum HomepageConfigType {
  DEFAULT = "default",
  EVENT_DAY = "eventDay",
  PRE_EVENT = "preEvent",
  FRIDAY_PRE_EVENT = "fridayPreEvent",
  THURSDAY_PRE_EVENT = "thursdayPreEvent"
}

export enum HomepageConfigContext {
  DESKTOP = "desk",
  MOBILE = "mobile",
  X_SELL = "xSell",
  HOME_PAGE = "homePage",
  PROGRAM_PAGE = "programPage"
}

export type HomepageHeaderBanner = Record<
  HomepageConfigType,
  Array<HeaderBanner>
>;

export interface HomepageStoryblokContent {
  content: {
    event_day_homepage_banner?: Array<HeaderBanner>;
    pre_event_homepage_banner?: Array<HeaderBanner>;
    friday_pre_event_homepage_banner?: Array<HeaderBanner>;
    thursday_pre_event_homepage_banner?: Array<HeaderBanner>;
    event_date: string;
    event_name: string;
    track_abbr: string;
  };
}
export interface HomepageBannerTexts {
  label: string;
  size?: number;
}

export interface HomepageConfigEventInfo {
  eventDate: Date | null;
  eventName: HomepageBannerTexts;
  raceNumber: number;
  trackAbbr: string;
}

export interface HomepageBannerConfig {
  eventInfo?: HomepageConfigEventInfo;
  derbyBanner?: HomepageHeaderBanner;
}
