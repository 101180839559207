import { QuickLink } from "@tvg/ts-types/Links";

type SetQuickLinksType = {
  type: "STORE_QUICK_LINKS";
  payload: QuickLink[];
};

export const setQuickLinks = (quickLinks: QuickLink[]): SetQuickLinksType => ({
  type: "STORE_QUICK_LINKS",
  payload: quickLinks
});
