import { get } from "lodash";

import tvgConf from "@tvg/conf";
import { getPortByBrand } from "@tvg/utils/generalUtils";

const QUERY_VARIABLES = {
  wagerProfile: "PORT-Generic",
  accountId: ""
};

const POLL_INTERVAL = 60000;

const loggedInOptions = (props) => ({
  ...QUERY_VARIABLES,
  accountId: +get(props, "accountId", "-1"),
  wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
  product: tvgConf().product,
  brand: tvgConf().brand
});

const loggedOutOptions = (props) => ({
  wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
  product: tvgConf().product,
  brand: tvgConf().brand
});

// TODO: Import CombinedHeaderTracksActions
export const topTracksDataFeeder = (tracks = []) =>
  tracks.map((track) => ({
    name: track.name,
    shortName: track.shortName,
    code: track.code,
    isFavorite: track.isFavorite,
    hasPromo: track.hasAboveTheLinePromo || track.hasPromo,
    isFinished: track.isFinished,
    favorite: track.favorite,
    isGreyhound: track.isGreyhound,
    isOpen: false,
    isPromoTagShown: get(track, "isPromoTagShown", false)
  }));

export default {
  options: (props) => {
    const variables = {
      ...(props.isLogged ? loggedInOptions(props) : loggedOutOptions(props))
    };
    return {
      client:
        props.isAccountCompliant && !!get(props, "accountId")
          ? props.rdaClient
          : null,
      pollInterval: POLL_INTERVAL,
      fetchPolicy: "cache-and-network",
      ssr: true,
      variables
    };
  },
  props: (result) => ({
    tracks: topTracksDataFeeder(get(result, "data.topTracks", [])),
    featuredTracks: topTracksDataFeeder(get(result, "data.featureTracks", [])),
    isLoading: get(result, "data.loading", false),
    refetch: result.data.refetch
  })
};
