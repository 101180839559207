import { gql } from "@apollo/client";

export const GET_TRACKS_SEO = gql`
  query getTrackList($wagerProfile: String!) {
    pastTracks(profile: $wagerProfile) {
      code
      name
      location {
        city
        state
        country
      }
      imageName
      imageLink
      raceTypes
    }
  }
`;
