import styled from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const racecardLinkCss = `
  color: ${buildColor("blue_accent", "500")};
  font-family: ${fontMedium};
  font-size: 12px;
  height: 14px;
  text-decoration: none;
`;

export const Container = styled.div`
  background: ${buildColor("white", "100")};
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  min-height: ${(props) => (!props.isDesktop ? "152px" : "177px")};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Footer = styled.div`
  width: 100%;
`;

export const FooterWrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RunnerNumber = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 12px;
  height: 14px;
`;

export const RacecardLink = styled(Link)`
  ${racecardLinkCss}

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const RacecardLinkTVG4 = styled.div`
  ${racecardLinkCss}

  &:hover,
    &:active {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TopRaceRunnerList = styled.table`
  width: 100%;
`;
