import React, { PureComponent } from "react";
import { Container, LeftColumn, RightColumn } from "./styled-components";

export default class HomepageDesktopContainer extends PureComponent {
  static defaultProps = {
    leftContent: null,
    rightContent: null,
    hasStackedCards: false,
    qaLabel: "HomepageDesktopContainer"
  };

  render() {
    return (
      <Container
        data-qa-label={this.props.qaLabel}
        hasStackedCards={this.props.hasStackedCards}
      >
        <LeftColumn>{this.props.leftContent}</LeftColumn>
        <RightColumn>{this.props.rightContent}</RightColumn>
      </Container>
    );
  }
}
