import { gql } from "@apollo/client";
import TrackRaceFragment from "../fragments/Races";

export const GET_TRACK_RACES = gql`
  query getTrackRaces(
    $wagerProfile: String
    $sortByRaceNumber: RaceListSort
    $raceFilters: RaceListFilter
    $product: String
    $brand: String
  ) {
    races: races(
      profile: $wagerProfile
      filter: $raceFilters
      sort: $sortByRaceNumber
    ) {
      ...Race
    }
  }
  ${TrackRaceFragment.entry}
`;

export const GET_RDA_TRACK_RACES = gql`
  query getTrackRacesRDA(
    $accountId: Int!
    $wagerProfile: String!
    $sortByRaceNumber: RaceListSort
    $raceFilters: RaceListFilter
    $trackFilters: TrackListFilter
    $product: String
    $brand: String
  ) {
    tracks(
      accountId: $accountId
      profile: $wagerProfile
      trackFilter: $trackFilters
      raceFilter: $raceFilters
      raceSort: $sortByRaceNumber
      product: $product
      brand: $brand
    ) {
      id
      races {
        id
        tvgRaceId
        raceNumber: number
        number
        mtp
        postTime
        status {
          code
        }
        type {
          code
        }
        video {
          onTvg
          onTvg2
        }
      }
    }
  }
`;
