import React from "react";
import { useTheme } from "@tvg/design-system";
import { SVG, SVGContainer } from "./styled-components";
import { Props } from "./types";
import { QA_LABEL } from "../../qaLabels";

const LoadingMask = ({ isDesktop }: Props) => {
  const { colors } = useTheme();

  return (
    <SVGContainer data-qa-label={QA_LABEL.loading} isDesktop={isDesktop}>
      <SVG isDesktop={isDesktop}>
        {isDesktop ? (
          <g fill="none" fillRule="evenodd">
            <rect width="325" height="440" className="pulse" />
            <rect
              width="325"
              height="440"
              fill={colors.white[100]}
              fillRule="nonzero"
            />
            <rect
              width="110"
              height="8"
              x="12"
              y="25"
              className="pulse"
              rx="1"
            />
            <rect
              width="34"
              height="8"
              x="12"
              y="67"
              className="pulse"
              rx="1"
            />
            <rect
              width="124"
              height="8"
              x="64"
              y="67"
              className="pulse"
              rx="1"
            />
            <rect
              width="65"
              height="8"
              x="248"
              y="76"
              className="pulse"
              rx="1"
            />
            <rect
              width="90"
              height="8"
              x="64"
              y="87"
              className="pulse"
              rx="1"
            />
            <rect
              width="34"
              height="8"
              x="12"
              y="131"
              className="pulse"
              rx="1"
            />
            <rect
              width="34"
              height="8"
              x="12"
              y="195"
              className="pulse"
              rx="1"
            />
            <rect
              width="148"
              height="8"
              x="64"
              y="131"
              className="pulse"
              rx="1"
            />
            <rect
              width="124"
              height="8"
              x="64"
              y="195"
              className="pulse"
              rx="1"
            />
            <rect
              width="49"
              height="8"
              x="264"
              y="140"
              className="pulse"
              rx="1"
            />
            <rect
              width="49"
              height="8"
              x="264"
              y="204"
              className="pulse"
              rx="1"
            />
            <rect
              width="74"
              height="8"
              x="64"
              y="151"
              className="pulse"
              rx="1"
            />
            <rect
              width="74"
              height="8"
              x="64"
              y="215"
              className="pulse"
              rx="1"
            />
            <rect width="301" height="1" x="12" y="111" className="pulse" />
            <rect width="301" height="1" x="12" y="175" className="pulse" />
            <rect width="301" height="1" x="12" y="239" className="pulse" />
            <rect
              width="34"
              height="8"
              x="12"
              y="259"
              className="pulse"
              rx="1"
            />
            <rect
              width="140"
              height="8"
              x="64"
              y="259"
              className="pulse"
              rx="1"
            />
            <rect
              width="57"
              height="8"
              x="256"
              y="268"
              className="pulse"
              rx="1"
            />
            <rect
              width="90"
              height="8"
              x="64"
              y="279"
              className="pulse"
              rx="1"
            />
            <rect width="301" height="1" x="12" y="303" className="pulse" />
            <rect
              width="34"
              height="8"
              x="12"
              y="323"
              className="pulse"
              rx="1"
            />
            <rect
              width="140"
              height="8"
              x="64"
              y="323"
              className="pulse"
              rx="1"
            />
            <rect
              width="57"
              height="8"
              x="256"
              y="332"
              className="pulse"
              rx="1"
            />
            <rect
              width="90"
              height="8"
              x="64"
              y="343"
              className="pulse"
              rx="1"
            />
            <rect width="301" height="1" x="12" y="367" className="pulse" />
            <rect
              width="34"
              height="8"
              x="12"
              y="387"
              className="pulse"
              rx="1"
            />
            <rect
              width="164"
              height="8"
              x="64"
              y="387"
              className="pulse"
              rx="1"
            />
            <rect
              width="33"
              height="8"
              x="280"
              y="396"
              className="pulse"
              rx="1"
            />
            <rect
              width="90"
              height="8"
              x="64"
              y="407"
              className="pulse"
              rx="1"
            />
          </g>
        ) : (
          <g fill="none" fillRule="evenodd">
            <rect
              width="1506"
              height="172"
              fill={colors.white[100]}
              fillRule="nonzero"
            />
            <rect
              width="141"
              height="111"
              x="12.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="141"
              height="111"
              x="162.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="98"
              height="8"
              x="24"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="98"
              height="8"
              x="174"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="24"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="98"
              height="8"
              x="24"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="68"
              height="8"
              x="24"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="28"
              height="8"
              x="174"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="66"
              height="8"
              x="174"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="60"
              height="8"
              x="174"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="312.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="74"
              height="8"
              x="324"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="28"
              height="8"
              x="324"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="74"
              height="8"
              x="324"
              y="138"
              className="pulse"
              rx="1"
            />
            <rect
              width="28"
              height="8"
              x="324"
              y="120"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="462.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="90"
              height="8"
              x="474"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="474"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="114"
              height="8"
              x="474"
              y="137"
              className="pulse"
              rx="1"
            />
            <rect
              width="20"
              height="8"
              x="474"
              y="119"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="612.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="98"
              height="8"
              x="624"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="624"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="82"
              height="8"
              x="624"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="68"
              height="8"
              x="624"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="762.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="66"
              height="8"
              x="774"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="774"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="74"
              height="8"
              x="774"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="774"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="912.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="141"
              height="111"
              x="1062.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="82"
              height="8"
              x="924"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="82"
              height="8"
              x="1074"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="924"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="82"
              height="8"
              x="924"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="68"
              height="8"
              x="924"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1074"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="82"
              height="8"
              x="1074"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1074"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="141"
              height="111"
              x="1212.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="141"
              height="111"
              x="1362.5"
              y="48.5"
              fill={colors.white[100]}
              fillRule="nonzero"
              className="stroke"
              rx="4"
            />
            <rect
              width="82"
              height="8"
              x="1224"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="74"
              height="8"
              x="1374"
              y="82"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1224"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="106"
              height="8"
              x="1224"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="68"
              height="8"
              x="1224"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="36"
              height="8"
              x="1374"
              y="64"
              className="pulse"
              rx="1"
            />
            <rect
              width="58"
              height="8"
              x="1374"
              y="136"
              className="pulse"
              rx="1"
            />
            <rect
              width="68"
              height="8"
              x="1374"
              y="118"
              className="pulse"
              rx="1"
            />
            <rect
              width="121"
              height="8"
              x="12"
              y="20"
              className="pulse"
              rx="1"
            />
          </g>
        )}
      </SVG>
    </SVGContainer>
  );
};

export default LoadingMask;
