import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.section`
  min-width: 1024px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: ${(props) => (props.hasStackedCards ? "column" : "row")};
  background-color: ${buildColor("blue", "000")};
  padding: 12px;
  ${(props) =>
    props.hasStackedCards &&
    css`
      padding: 0 15px 12px;

      & > div {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        padding: 0;

        &:first-of-type {
          margin-bottom: 12px;
        }
      }
    `}
`;

export const LeftColumn = styled.div`
  min-height: 1px;
  width: 100%;
  padding-right: 7px;
`;

export const RightColumn = styled.div`
  min-width: 356px;
  padding-left: 6px;
`;
