import React from "react";
import { get } from "lodash";
import type { TrackLocation } from "@tvg/ts-types/Race";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { Paragraph } from "@tvg/design-system";
import { Flag } from "@tvg/design-system/web";
import type { Countries } from "@tvg/design-system/web/components/Flag/types";
import { Block, Container } from "./styled-components";
import type { Props } from "./types";
import { getFlagLabel } from "./utils";
import RaceIcons from "../RaceIcons";
import { buildQaLabel } from "../../utils";
import { CONTAINER, FLAG, LOCATION, TEXT } from "../../utils/constants";

const TrackLocationDisplay = ({ race, qaLabel = "" }: Props) => {
  const { content } = useColorTokens();

  const hasDescription = !!race.highlighted?.description;
  const location: TrackLocation | undefined = get(race, "track.location");

  return (
    <Container data-qa-label={buildQaLabel([qaLabel, LOCATION, CONTAINER])}>
      <Block>
        {!!location?.country && (
          <Flag
            country={location.country as Countries}
            size="s"
            qaLabel={buildQaLabel([qaLabel, LOCATION, FLAG])}
          />
        )}
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, LOCATION, FLAG, TEXT])}
          color={hasDescription ? content.onDark : content.subtle}
          fontFamily="regular"
          fontSize={12}
        >
          {getFlagLabel(location)}
        </Paragraph>
      </Block>
      <RaceIcons race={race} qaLabel={buildQaLabel([qaLabel, LOCATION])} />
    </Container>
  );
};

export default TrackLocationDisplay;
