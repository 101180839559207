import type { Promo } from "@tvg/ts-types/Promos";

export type SetPromosListType = {
  type: "PROMOS_LIST_SET";
  payload: Promo[];
};

export default (userPromos: Promo[]): SetPromosListType => ({
  type: "PROMOS_LIST_SET",
  payload: userPromos
});
