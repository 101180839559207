import React, { useState, useEffect, useRef } from "react";
import { get } from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import TopPools from "@tvg/atomic-ui/_templates/TopPools";

import buildRaceUrl, { buildPoolBetSlipUrl } from "@tvg/formatter/url";
import mediator from "@tvg/mediator";
import { isTvg5 } from "@tvg/utils/generalUtils";
import ApolloOptions from "./graphql/options.graph";
import TopPoolsQuery from "./graphql/queries/TopPools";

export const useRequested = (isLoading) => {
  const [hasRequested, setRequested] = useState(false);

  const prevLoadingState = useRef(false);

  useEffect(() => {
    if (prevLoadingState.current && !isLoading) {
      setRequested(true);
    }
    prevLoadingState.current = isLoading;
  }, [isLoading]);

  return hasRequested;
};

export const navigateToBetSlip = (pool) => {
  if (pool) {
    const url = buildRaceUrl(
      pool.trackCode,
      pool.track,
      pool.race.raceNumber,
      pool.race.isGreyhound
    );

    mediator.base.dispatch({
      type: "TVG4_NAVIGATION",
      payload: { route: url }
    });
    const newUrl = buildPoolBetSlipUrl(
      pool.trackCode,
      pool.race.raceNumber,
      pool.race.isGreyhound,
      pool.wagerType.id
    );
    window.open(newUrl, "", "width=535,height=778");
  }
};

export const sendGTMScrollEvent = (scrolledBack) => {
  mediator.base.dispatch({
    type: "TOP_POOLS_SCROLL_INTERACTION",
    payload: scrolledBack ? "Left" : "Right"
  });
};

export function TopPoolsComponent(props) {
  const { pools, isLoading, device, title, isLogged } = props;

  const hasRequested = useRequested(isLoading);

  const onPoolClick = (pool) => {
    mediator.base.dispatch({
      type: "TOP_POOLS:GO_TO_POOL",
      payload: {
        pool,
        isGreyhound: get(pool, "race.isGreyhound", false),
        url: `${buildRaceUrl(
          pool.trackCode,
          pool.track,
          pool.race.raceNumber,
          pool.race.isGreyhound
        )}&betType=${get(pool, "wagerType.id")}`
      }
    });
    if (device === "desktop" && !isTvg5()) {
      if (isLogged) {
        navigateToBetSlip(pool);
      } else {
        mediator.base.dispatch({
          type: "OPEN_LOGIN",
          payload: {
            callback: (error, success) => {
              if (get(success, "status", "fail") === "success") {
                navigateToBetSlip(pool);
              }
            },
            triggerAction: "top_pools_go_to_pool"
          }
        });
      }
    }
  };

  return (
    <TopPools
      pools={pools}
      isLoading={isLoading}
      device={device}
      title={title}
      hasRequested={hasRequested}
      onPoolClick={onPoolClick}
      sendGTMScrollEvent={sendGTMScrollEvent}
    />
  );
}

export default connect(
  (store) => ({
    wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
    isLogged: get(store, "userData.logged", false)
  }),
  () => ({})
)(graphql(TopPoolsQuery, ApolloOptions)(TopPoolsComponent));
