import React, { Component } from "react";
import { get } from "lodash";
import Spinner from "@tvg/atomic-ui/_static/Spinners";
import setIframeScrolling from "@tvg/utils/scrolling";

import styles from "../style/styles.css";

export default class Promos extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    setIframeScrolling();
    if (typeof window === "object") {
      window.addEventListener("message", (event) => {
        if (get(event, "data.type") === "promos_loaded") {
          this.setState({ isLoading: false });
        }
      });
    }
  }

  render = () => (
    <div className={styles.iframeScrollWrapper}>
      {this.state.isLoading ? (
        <div className={styles.loadingWrapper}>
          <Spinner width={24} height={24} />
        </div>
      ) : (
        ""
      )}
      <iframe
        src={this.props.promoPage}
        id="iframe"
        name="promos"
        title="Promos"
        scrolling="no"
        className={styles.iframeContent}
      />
    </div>
  );
}
