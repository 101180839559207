import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
// @ts-ignore
import CPPService from "@tvg/sh-cpp";
import pesService from "@tvg/api/pes";
import { QuickLink } from "@tvg/ts-types/Links";
import { mapCPPPromos } from "@fdr/utils/PromoUtils";
import mediator from "@tvg/mediator";
import type { QuickLinkPromo } from "@fdr-mar/promos-types/Promos";
import { processLinksData } from "../utils";
import { FetchQuickLinks } from "../types";
import { setQuickLinks } from "../redux/actions";
import { getQuickLinks } from "../redux/selectors";

const useFetchQuickLinks = ({
  enableCPPQuickLinks,
  enablePromosStoryblok,
  enableSGWQuicklinks,
  isFdr
}: FetchQuickLinks) => {
  const dispatch = useDispatch();
  const storedQuickLinks = useSelector(getQuickLinks);
  const [processedLinks, setProcessedLinks] = useState<QuickLink[] | []>([]);

  useEffect(() => {
    if (storedQuickLinks.length === 0) {
      fetchQuickLinks();
    }
    mediator.base.subscribe("FETCH_QUICKLINKS", fetchQuickLinks);
    return () => {
      mediator.base.unsubscribe("FETCH_QUICKLINKS", fetchQuickLinks);
    };
  }, []);

  const fetchQuickLinks = async () => {
    let quickLinks: QuickLink[] = [];
    if (isFdr) {
      try {
        const cppService = new CPPService();
        const [pesQuickLinksResult, cppQuickLinksResult] =
          await Promise.allSettled([
            enableSGWQuicklinks
              ? pesService.fetchQuickLinks()
              : Promise.resolve({ data: [] }),
            enableCPPQuickLinks
              ? cppService.getPromos({ isQuicklink: true })
              : Promise.resolve([])
          ]);

        const pesQuickLinks =
          pesQuickLinksResult.status === "fulfilled"
            ? pesQuickLinksResult.value
            : { data: [] };
        const cppQuickLinks =
          cppQuickLinksResult.status === "fulfilled"
            ? cppQuickLinksResult.value
            : [];

        quickLinks = [
          ...mapCPPPromos(cppQuickLinks as QuickLinkPromo[]),
          ...processLinksData(
            get(pesQuickLinks.data[0], "quicklinks", []),
            enablePromosStoryblok
          )
        ];
      } catch (error) {
        console.log("error", error);
      }
    } else {
      const pesQuickLinks = await pesService.fetchQuickLinks();
      quickLinks = processLinksData(
        get(pesQuickLinks.data[0], "quicklinks", []),
        enablePromosStoryblok
      );
    }

    setProcessedLinks(quickLinks);
    dispatch(setQuickLinks(quickLinks));
  };

  return { fetchQuickLinks, processedLinks };
};

export default useFetchQuickLinks;
