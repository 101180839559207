import { ColorTokens, Paragraph, useColorTokens } from "@tvg/design-system";
import React from "react";
import { FontSizeProps } from "styled-system";
import { QA_LABEL } from "../../qaLabels";

const WagerTypeValue = ({
  children,
  fontSize = 14
}: React.PropsWithChildren<FontSizeProps>) => {
  const { content } = useColorTokens() as ColorTokens;

  return (
    <Paragraph
      qaLabel={QA_LABEL.body.wagerType}
      color={content.subtle}
      fontFamily="regular"
      fontSize={fontSize}
    >
      <>{children}</>
    </Paragraph>
  );
};

export default WagerTypeValue;
