import { createGlobalStyle } from "styled-components";

export const JWPlayerFDRSkin = createGlobalStyle`
  :root {
    --jw-slider-height: 4px;
  }

  

  .jwplayer {
    .jw-controlbar {
      display: flex;
      flex-direction: column-reverse;
      
      .jw-icon {
        height: 100%;
      }

      .jw-reset {
        z-index: 1;
      }

      .jw-icon-fullscreen[aria-label="Fullscreen"]:last-child {
        display: block !important;
      }

      .jw-slider-container::before {
        height: 20px !important;
      }
    }

    .jw-display-controls {
      .jw-display-icon-rewind,
      .jw-display-icon-next,
      .jw-icon-display[aria-label="Play"],
      .jw-icon-display[aria-label="Pause"] {
        display: initial;
      }

      .jw-display-icon-display {
        display: flex;

      }
    }
  }
`;
