import React, { PureComponent } from "react";
import { get, noop } from "lodash";

import { isTvg5 } from "@tvg/utils/generalUtils";

import TopTrackLine from "../../_organism/TopTrackLine";
import {
  Container,
  ContainerHeader,
  ContainerTitle,
  SeeAllLinkMobile,
  SeeAllLinkDesktop
} from "./styled-components";
import TopTracksMask from "../../_static/Masks/topTracks";

export default class TopTracks extends PureComponent {
  static defaultProps = {
    device: "mobile",
    templateTitle: "Top Tracks",
    children: null,
    isLoading: true,
    topTracks: [],
    sendGTMScrollEvent: noop,
    handleFavoriteChange: noop,
    onRaceSelect: noop,
    isMTPNewRules: false
  };

  renderSeeAllTracksButton = () => {
    const renderSeeAllTracksButtonDesktop = () =>
      isTvg5() ? (
        <SeeAllLinkMobile
          to="/races?view=bytrack"
          data-qa-label="topTracksSeeAllButton"
        >
          See all
        </SeeAllLinkMobile>
      ) : (
        <SeeAllLinkDesktop
          href="/races?view=bytrack"
          data-qa-label="topTracksSeeAllButton"
        >
          See all
        </SeeAllLinkDesktop>
      );

    return this.props.device !== "desktop" ? (
      <SeeAllLinkMobile to="/races" data-qa-label="topTracksSeeAllButton">
        See all
      </SeeAllLinkMobile>
    ) : (
      renderSeeAllTracksButtonDesktop()
    );
  };

  renderTopTracks = () =>
    this.props.topTracks.map((track) => (
      <TopTrackLine
        key={`topTrack${track.code}`}
        device={this.props.device}
        hasPromo={track.hasAboveTheLinePromo}
        hasResultedState={
          !track.races.some((race) => get(race, "status.code") !== "RO")
        }
        isFavorite={track.isFavorite}
        trackCode={track.code}
        trackName={track.name}
        isGreyhound={track.isGreyhound}
        trackRaces={track.races}
        qaLabel="topTrackLine"
        sendGTMScrollEvent={this.props.sendGTMScrollEvent}
        handleFavoriteChange={this.props.handleFavoriteChange}
        onRaceSelect={this.props.onRaceSelect}
        isMTPNewRules={this.props.isMTPNewRules}
      />
    ));

  render() {
    const isDesktop = this.props.device === "desktop";
    const renderComponent =
      React.Children.count(this.props.children) > 0 ||
      !!this.props.topTracks.length;
    return this.props.isLoading ? (
      <TopTracksMask isDesktop={isDesktop} />
    ) : (
      renderComponent && (
        <Container data-qa-label="topTracksContainer" isDesktop={isDesktop}>
          <ContainerHeader
            isDesktop={isDesktop}
            data-qa-label="topTracksHeader"
          >
            <ContainerTitle
              isDesktop={isDesktop}
              data-qa-label="topTracksTitle"
            >
              {this.props.templateTitle}
            </ContainerTitle>
            {this.renderSeeAllTracksButton()}
          </ContainerHeader>
          {React.Children.count(this.props.children) > 0
            ? this.props.children
            : this.renderTopTracks()}
        </Container>
      )
    );
  }
}
