import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import { getDefaultPlaylistSettings } from "../../redux/selectors";
import type { Playlist } from "../../types";
import { getJWPlaylistVideos } from "../../utils";
import VideoPlaylist from "../VideoPlaylist";
import { Props } from "./types";

const isDesktop = tvgConf().device === "desktop";

const TipsAndPicks = ({ qaLabel = "", title }: Props) => {
  const { playlistUrl, playerScript, licenseKey } = useSelector(
    getDefaultPlaylistSettings
  );

  const componentTitle = isDesktop ? title : "";

  const [playlist, setPlaylist] = useState<Playlist[]>([]);

  useEffect(() => {
    getJWPlaylistVideos(playlistUrl, playerScript, licenseKey).then((data) =>
      setPlaylist(data.playlist)
    );
  }, [playlistUrl]);

  return (
    <VideoPlaylist
      title={componentTitle}
      qaLabel={qaLabel}
      playlist={playlist}
    />
  );
};

export default TipsAndPicks;
