import React, { PureComponent } from "react";
import { get, noop } from "lodash";

import { hasPromoFromGraph, getRDAOptinStatus } from "@tvg/utils/PromoUtils";
import {
  PanelContainer,
  Country,
  InformationTitle,
  PanelLink,
  TrackName,
  TagsWrapper,
  TagWrapper,
  CountryInformation,
  InformationWrapper,
  MetaDescript,
  TrackNameWrapper,
  RaceNumberWrapper,
  RaceTitleWrapper,
  PanelLinkTVG4,
  OnTVG,
  PanelFooter
} from "./styled-components";
import Icon from "../../_static/Icons";
import {
  greyhounds,
  promosolid,
  talent,
  optedin,
  bets
} from "../../_static/Icons/icons";
import { LogoFDTV } from "../../_static/Logos";
import Tag from "../../_atom/Tag";
import buildColor from "../../_static/ColorPalette";
import MTP from "../../_atom/MTP";

const getTrackName = (trackName, isNationalTrack) => {
  const matchString = " - ";
  if (!isNationalTrack && trackName.includes(matchString)) {
    const name = trackName.split(matchString);

    if (name[0].length < 4) {
      return name.length > 2 ? name.slice(1).join(matchString) : name[1];
    }
  }

  return trackName;
};

export default class RaceInfoPanel extends PureComponent {
  static defaultProps = {
    race: {
      country: "",
      isGreyhound: false,
      mtp: 0,
      raceId: 0,
      trackName: "",
      number: "",
      promos: null,
      talentPicks: [],
      postTime: "",
      trackCode: "",
      highlighted: null
    },
    onCardClick: noop,
    raceCardUrl: "",
    optedInPromos: {},
    context: "topRaces",
    isDesktop: false,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  checkTagAttributes = (e) => {
    const tagContext = get(e, "target.attributes.data-bubble-event.value");

    if (tagContext !== undefined) {
      return this.props.onCardClick(
        this.props.race,
        this.props.raceCardUrl,
        this.props.context === "topRaces"
          ? "flags-area"
          : this.props.optedInPromos
      );
    }

    return !this.props.isDesktop
      ? this.props.onCardClick(
          this.props.race,
          this.props.raceCardUrl,
          this.props.context === "topRaces"
            ? "Header"
            : this.props.optedInPromos
        )
      : this.props.onCardClick(
          this.props.race,
          this.props.raceCardUrl,
          "Header"
        );
  };

  hasCountry = (country) => country !== "US" && country !== "USA";

  renderRaceTitleWrapper = (trackName, raceNumber = "") => (
    <RaceTitleWrapper
      data-qa-label={`race-trackName${
        this.props.race.highlighted ? "_highlighted" : ""
      }`}
    >
      <TrackNameWrapper>{trackName}</TrackNameWrapper>
      <RaceNumberWrapper>&nbsp;{`R${raceNumber}`}</RaceNumberWrapper>
    </RaceTitleWrapper>
  );

  renderRaceInformation = () => {
    const { postTime, country, raceNumber, mtp, trackName, highlighted } =
      this.props.race;
    const hasCountry = this.hasCountry(country);
    const highlightedStyle = get(highlighted, "style", "");
    const highlightedDescription = get(highlighted, "description", "");
    const raceDescript = this.renderRaceTitleWrapper(
      getTrackName(trackName, !hasCountry),
      raceNumber
    );
    return (
      <InformationWrapper data-qa-label="race-trackInformation">
        <InformationTitle>
          <MTP
            isPlainMTP
            mtp={mtp}
            postTime={postTime}
            isMTPNewRules={this.props.isMTPNewRules}
          />
          {this.renderOnFDTV()}
        </InformationTitle>
        <TrackName highlightedStyle={highlightedStyle}>
          {highlightedDescription && highlightedStyle === "Highlighted"
            ? highlightedDescription
            : raceDescript}
        </TrackName>
        {highlightedDescription && (
          <MetaDescript
            highlightedStyle={highlightedStyle}
            data-qa-label="meta-description"
          >
            {highlightedStyle === "Highlighted"
              ? raceDescript
              : highlightedDescription}
          </MetaDescript>
        )}
      </InformationWrapper>
    );
  };

  renderTags = (hasPromos, hasTalentPicks, betsCount, isCollapsed) => (
    <TagsWrapper
      isCollapsed={isCollapsed}
      data-qa-label="race-tags-Area"
      data-bubble-event="flags-area"
    >
      {hasPromos && (
        <TagWrapper isCollapsed={isCollapsed} zIndex={99}>
          {getRDAOptinStatus(this.props.race) ? (
            <Tag
              data-qa-label="race-optedInTag"
              data-bubble-event="flags-area"
              icon={optedin}
              tagType="OptedInTag"
              color={buildColor("grey", "900")}
              isCollapsed={isCollapsed}
              name="Opted in"
              iconSize={16}
            />
          ) : (
            <Tag
              data-qa-label="race-promoTag"
              data-bubble-event="flags-area"
              icon={promosolid}
              name="Promo"
              tagType="PromoTag"
              isCollapsed={isCollapsed}
              iconSize={16}
            />
          )}
        </TagWrapper>
      )}
      {betsCount > 0 && (
        <TagWrapper isCollapsed={isCollapsed} zIndex={98}>
          <Tag
            data-qa-label="race-betsTag"
            data-bubble-event="flags-area"
            icon={bets}
            isCollapsed={isCollapsed}
            name={`${betsCount} bet${betsCount > 1 ? "s" : ""}`}
            iconSize={16}
          />
        </TagWrapper>
      )}
      {hasTalentPicks && (
        <TagWrapper isCollapsed={isCollapsed} zIndex={97}>
          <Tag
            data-qa-label="race-talentPicksTag"
            data-bubble-event="flags-area"
            icon={talent}
            isCollapsed={isCollapsed}
            name="Picks"
            iconSize={16}
          />
        </TagWrapper>
      )}
    </TagsWrapper>
  );

  renderCountryInformation = () => {
    const { isGreyhound, country } = this.props.race;
    const hasCountry = this.hasCountry(country);

    return (
      (hasCountry || isGreyhound) && (
        <CountryInformation>
          {isGreyhound && (
            <Icon size={16} icon={greyhounds} data-qa-label="race-greyhound" />
          )}
          {hasCountry && (
            <Country data-qa-label="race-country">{country}</Country>
          )}
        </CountryInformation>
      )
    );
  };

  renderOnFDTV = () => {
    const onTvg = get(this.props.race, "video.onTvg", false);
    const onTvg2 = get(this.props.race, "video.onTvg2", false);

    return (onTvg || onTvg2) && <OnTVG>{LogoFDTV()}</OnTVG>;
  };

  render() {
    const { race, raceCardUrl, isDesktop, context } = this.props;
    const hasPromos = hasPromoFromGraph(
      this.props.race,
      this.props.useIsPromoTagShownFlag
    );
    const hasTalentPicks = get(race, "talentPicks.length", 0) > 0;
    const betsCount = get(race, "betsCount", 0);
    const isCollapsed = context === "upcomingRaces";
    const RenderedTags = this.renderTags(
      hasPromos,
      hasTalentPicks,
      betsCount,
      isCollapsed
    );

    return !isDesktop ? (
      <PanelLink
        data-qa-label="races-headerLink"
        to={raceCardUrl}
        onClick={this.checkTagAttributes}
      >
        <PanelContainer
          isUpcoming={context === "upcomingRaces"}
          data-qa-label="race-header"
        >
          {this.renderRaceInformation()}
          <PanelFooter>
            {(hasPromos || hasTalentPicks || betsCount > 0) && RenderedTags}
            {this.renderCountryInformation()}
          </PanelFooter>
        </PanelContainer>
      </PanelLink>
    ) : (
      <PanelLinkTVG4
        data-qa-label="races-headerLink"
        onClick={this.checkTagAttributes}
      >
        <PanelContainer
          isUpcoming={context === "upcomingRaces"}
          data-qa-label="race-header"
        >
          {this.renderRaceInformation()}
          <PanelFooter>
            {(hasPromos || hasTalentPicks || betsCount > 0) && RenderedTags}
            {this.renderCountryInformation()}
          </PanelFooter>
        </PanelContainer>
      </PanelLinkTVG4>
    );
  }
}
