import React, { PureComponent, Fragment } from "react";
import { get, isNumber } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import buildRaceUrl from "@tvg/formatter/url";

import {
  DesktopPoolLink,
  MobilePoolLink,
  RaceContainer,
  PoolContainer,
  TrackRace,
  Track,
  Race,
  Pool,
  WagerType,
  RacePool,
  RaceMTP
} from "./styled-components";

const getPoolValue = (value) => {
  let pool = value;

  if (pool >= 999500) {
    const millions = pool / 1000000;
    pool = parseInt(millions, 10);
    pool += millions - pool >= 0.5 ? 1 : 0;
    return `${formatCurrency(pool, "USD", 0)}m`;
  }
  if (pool >= 1000) {
    const thousands = pool / 1000;
    pool = parseInt(thousands, 10);
    pool += thousands - pool >= 0.5 ? 1 : 0;
    return `${formatCurrency(pool, "USD", 0)}k`;
  }

  return `${formatCurrency(pool, "USD", 0)}`;
};

const getPoolLabel = (betType, betTypeCode, poolType, isDesktop) => {
  const bet = !isDesktop ? betTypeCode : betType;

  if (/jackpot/i.test(poolType)) {
    return `<strong>Jackpot</strong> ${bet}`;
  }
  if (/carryover/i.test(poolType)) {
    return `<strong>Carryover</strong> ${bet}`;
  }
  if (/guaranteed/i.test(poolType)) {
    return `<strong>Guaranteed</strong> ${bet}`;
  }

  return betType;
};

const isFeaturedRace = (poolHighlight) => poolHighlight === "Highlighted";

const isPinnedRace = (poolPinOrder) =>
  isNumber(poolPinOrder) && poolPinOrder > 0;

export default class PoolInfoPanel extends PureComponent {
  static defaultProps = {
    pool: {
      track: "",
      trackCode: "",
      amount: 0,
      wagerType: {
        id: 0,
        name: "",
        code: ""
      },
      race: {
        raceNumber: "",
        postTime: "",
        mtp: 0,
        isGreyhound: false,
        highlighted: {
          description: "",
          pinnedOrder: 0,
          style: ""
        }
      },
      poolType: ""
    },
    isDesktop: false
  };

  renderMobile = () => {
    const isFeatured = isFeaturedRace(
      get(this.props, "pool.race.highlighted.style")
    );
    const { mtp, postTime, raceNumber } = this.props.pool.race;
    const { track, trackCode, amount, poolType } = this.props.pool;
    const { name, code } = this.props.pool.wagerType;
    return (
      <Fragment>
        <RaceContainer>
          <RaceMTP isPlainMTP mtp={mtp} postTime={postTime} />
          <TrackRace isFeatured={isFeatured}>
            <Track>
              {isFeatured
                ? get(this.props, "pool.race.highlighted.description")
                : track}
            </Track>
            <Race>
              {isFeatured && `- ${trackCode} `}
              {`R${raceNumber}`}
            </Race>
          </TrackRace>
        </RaceContainer>
        <PoolContainer>
          <Pool>{getPoolValue(amount)}</Pool>
          <WagerType
            dangerouslySetInnerHTML={{
              __html: getPoolLabel(name, code, poolType)
            }}
          />
        </PoolContainer>
      </Fragment>
    );
  };

  renderDesktop = () => {
    const isFeatured = isFeaturedRace(
      get(this.props, "pool.race.highlighted.style")
    );
    const { mtp, postTime, raceNumber } = this.props.pool.race;
    const { track, trackCode, amount, poolType } = this.props.pool;
    const { name, code } = this.props.pool.wagerType;

    return (
      <Fragment>
        <RaceMTP isPlainMTP mtp={mtp} postTime={postTime} isDesktop />
        <RacePool>
          <TrackRace isFeatured={isFeatured}>
            <Track>
              {isFeatured
                ? get(this.props, "pool.race.highlighted.description")
                : track}
            </Track>
            <Race>
              {isFeatured && `- ${trackCode} `}
              {`R${raceNumber}`}
            </Race>
          </TrackRace>
          <WagerType
            isDesktop
            dangerouslySetInnerHTML={{
              __html: getPoolLabel(name, code, poolType, true)
            }}
          />
        </RacePool>
        <Pool isDesktop>{getPoolValue(amount)}</Pool>
      </Fragment>
    );
  };

  render() {
    const url = `${buildRaceUrl(
      get(this.props, "pool.trackCode"),
      get(this.props, "pool.track"),
      get(this.props, "pool.race.raceNumber"),
      get(this.props, "pool.race.isGreyhound")
    )}&betType=${get(this.props, "pool.wagerType.id")}`;

    return this.props.isDesktop ? (
      <DesktopPoolLink href={url} data-qa-label="pools-Link">
        {this.renderDesktop()}
      </DesktopPoolLink>
    ) : (
      <MobilePoolLink
        to={url}
        data-qa-label="pools-Link"
        $isPinned={isPinnedRace(
          get(this.props, "pool.race.highlighted.pinnedOrder", 0)
        )}
        $isFeatured={isFeaturedRace(
          get(this.props, "pool.race.highlighted.style", "")
        )}
      >
        {this.renderMobile()}
      </MobilePoolLink>
    );
  }
}
