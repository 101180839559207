import { gql } from "@apollo/client";

export const TalentPickTalentFragment = {
  entry: gql`
    fragment Talent on TalentPickType {
      talent {
        talentId
        name
        pictureUrl
        phrases
        pickCount
      }
    }
  `
};
