import styled, { css } from "styled-components";

export const Container = styled.div<{ isFavorite: boolean }>`
  margin-right: ${({ theme }) => theme.space["space-3"]};

  button {
    width: 20px;
    height: 20px;
  }

  svg {
    path {
      transition:
        fill 0.15s ease-in-out,
        stroke 0.15s ease-in-out;
      ${({ isFavorite }) =>
        isFavorite &&
        // To-do: change tokens
        css`
          fill: var(--fd-colors-core-yellow-l-3);
        `}
    }
  }
`;
