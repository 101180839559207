import React, { PureComponent } from "react";

import { noop } from "lodash";
import {
  FilterSelectorWrapper,
  TalentImage,
  TalentName,
  TalentNumber
} from "./styled-components";

export default class TalentPickFilterSelector extends PureComponent {
  static defaultProps = {
    talent: { talentId: "0", name: "", pictureUrl: "", pickCount: 0 },
    onClick: noop,
    activeFilters: [],
    imageBaseURL: "//www.tvg.com"
  };

  isTalentActive = (talentId) => this.props.activeFilters.includes(talentId);

  render() {
    return (
      <li data-qa-label={`talent_${this.props.talent.talentId}`}>
        <FilterSelectorWrapper
          onClick={() =>
            this.props.onClick(
              this.props.talent.talentId,
              this.props.talent.name
            )
          }
          hasSelected={this.props.activeFilters.length !== 0}
          isActive={this.isTalentActive(this.props.talent.talentId)}
          data-qa-label="talentFilterButton"
        >
          <TalentNumber
            number={this.props.talent.pickCount}
            data-qa-label="talentFilterNumber"
          />
          <TalentImage
            src={`${this.props.imageBaseURL}/${this.props.talent.pictureUrl}`}
            data-qa-label="talentFilterImage"
          />
          <TalentName
            isActive={this.isTalentActive(this.props.talent.talentId)}
            hasSelected={this.props.activeFilters.length !== 0}
            data-qa-label="talentFilterName"
          >
            {this.props.talent.name}
          </TalentName>
        </FilterSelectorWrapper>
      </li>
    );
  }
}
