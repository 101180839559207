import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import Button from "../../_atom/Buttons/button";
import Icon from "../../_static/Icons";
import { fontNormal, fontMedium } from "../../_static/Typography";

import buildColor from "../../_static/ColorPalette";

export const HorizontalMessageBox = styled(Link)`
  ${({ customStyle }) =>
    customStyle.addonStyle
      ? `
      background-color: ${customStyle.addonStyle.backgroundColor};
      color: ${customStyle.addonStyle.color};
    `
      : `
      background-color: ${customStyle.background};
      color: ${buildColor("grey", "900")};
    `}
  padding: ${(props) => {
    if (props.isAlt) return "12px";
    if (
      props.type === "promos" ||
      props.type === "referral" ||
      props.type === "promosInvalid"
    )
      return "12px";
    return props.hasIcon && !props.hasTitle ? "12px" : "8px";
  }};
  border-radius: ${({ customStyle }) =>
    customStyle.borderRadius ? `${customStyle.borderRadius}` : "2px"};
  display: flex;
  text-decoration: none;
  flex-direction: column;
  ${(props) =>
    props.hasBorderTop &&
    css`
      border-top: 1px solid
        ${props.isAlt && props.customStyle.borderColor
          ? props.customStyle.borderColor
          : buildColor("grey", "300")};
    `};
  ${(props) =>
    props.hasBorderBottom &&
    css`
      border-bottom: 1px solid
        ${props.isAlt && props.customStyle.borderColor
          ? props.customStyle.borderColor
          : buildColor("grey", "300")};
    `};
  ${(props) =>
    props.hasMarginTop &&
    css`
      margin-top: ${(props.hasMarginsLarge && "20") || "8"}px;
    `};
  ${(props) =>
    props.hasMarginBottom &&
    css`
      margin-bottom: ${(props.hasMarginsLarge && "20") || "8"}px;
    `};
  ${(props) => css`
    border: ${props.customStyle.borderColor
      ? `1px solid ${props.customStyle.borderColor}`
      : "none"};
  `}
  ${(props) =>
    props.customStyle.onlyBorderBottom
      ? css`
          border: none;
          border-bottom: ${props.customStyle.borderColor
            ? `1px solid ${props.customStyle.borderColor}`
            : "none"};
        `
      : ""}
  ${(props) =>
    props.isAlt &&
    css`
      border: ${props.customStyle.borderColor
        ? `1px solid ${props.customStyle.borderColor}`
        : "none"};
      ${!props.hasBorderLeft && "border-left: none;"};
      ${!props.hasBorderRight && "border-right: none;"};
      ${!props.hasBorderTop && "border-top: none;"};
      ${!props.hasBorderBottom && "border-bottom: none;"};
      border-radius: ${props.hasRoundCorners ? "4px" : "0"};
      ${props.hasDropShadow &&
      css`
        box-shadow:
          0 4px 4px rgb(0 0 0 / 10%),
          0 4px 6px rgb(0 0 0 / 5%);
      `};
      color: ${props.customStyle.fill || "none"};
      font-family: ${fontNormal};

      span {
        font-family: ${fontNormal};
      }
    `};
  ${(props) =>
    props.customStyle &&
    props.customStyle.iconBackground &&
    css`
      svg {
        border-radius: 50%;
        background-color: ${({ customStyle }) =>
          customStyle && customStyle.iconBackground
            ? customStyle.iconBackground
            : "transparent"};
      }
    `};
`;

export const IconTextWrapper = styled.div`
  display: flex;
  ${({ horizontalPadding }) =>
    horizontalPadding ? `padding: 0 ${horizontalPadding}px;` : ""}
  ${({ alignCenter }) => alignCenter && `align-items: center;`}
`;

export const MessageIcon = styled(Icon).attrs({
  "data-qa-label": "messageBox-icon"
})`
  flex-shrink: 0;
`;

export const VerticalMessageBox = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  justify-content: center;
  text-decoration: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: 14px;
  color: ${(props) => (props.isAlt ? "inherit" : buildColor("grey", "900"))};
  text-align: left;
  margin-left: ${(props) => props.hasIcon && "12px"};
  line-height: 17px;
  min-height: ${(props) => props.hasIcon && "16"};
  font-family: ${fontNormal};

  a {
    text-decoration: underline;
    color: initial;
  }

  b {
    vertical-align: bottom;
  }
`;

export const VerticalTextWrapper = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${buildColor("grey", "900")};
  text-align: center;
  margin-top: 8px;
`;

export const MessageTitle = styled.div`
  ${({ isAlt }) =>
    isAlt
      ? css`
          font-weight: 500;
          font-weight: normal;
          text-transform: none;
          font-family: ${fontMedium};
          margin-bottom: 4px;
        `
      : css`
          text-transform: uppercase;
          font-weight: bold;
        `};
  ${({ isPromo }) =>
    isPromo &&
    css`
      font-family: ${fontNormal};
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      color: ${buildColor("green", "600")};
    `};
  ${({ promosInvalid }) =>
    promosInvalid &&
    css`
      font-family: ${fontNormal};
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
      color: ${buildColor("blue_accent", "600")};
    `};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

export const MessageContent = styled.span`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background: none;
`;

export const EditBtn = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  ${({ typeButton }) =>
    (typeButton === "promos" && `color: ${buildColor("green", "900")}`) ||
    (typeButton === "referral" && `color: ${buildColor("green", "900")}`) ||
    typeButton === "promosInvalid"
      ? css`
          color: ${buildColor("blue_accent", "500")};
          font-family: ${fontMedium};
          font-weight: 500;
          font-size: 14px;
        `
      : ""}
`;

export const ArrowIconContainer = styled.div`
  width: 16px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LogSignupButtonWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
`;

export const LogSignupButton = styled(Button)`
  margin-left: 8px;
  font-size: 14px;
  text-transform: capitalize;
  font-family: ${fontMedium};
`;

export const LogSignupButtonInner = styled.span`
  line-height: 14px;
  height: 12px;
`;

export const LockIcon = styled(Icon)`
  margin-right: 4px;
`;
