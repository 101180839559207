import React, { PureComponent } from "react";
import { noop } from "lodash";

import {
  Container,
  ContainerHeader,
  ContainerTitle
} from "./styled-components";
import EmptyStateMessages from "../../_molecule/EmptyStateMessages";

const renderEmptyStateMessages = (
  hasError,
  numberOfFavorites,
  onClearFilters,
  hasActiveFilters,
  tracks,
  emptyMessages
) => {
  let messageType;
  if (hasError) {
    messageType = "error";
  } else if (hasActiveFilters && numberOfFavorites > 0) {
    messageType = "info";
  } else if (
    tracks.length === 0 &&
    !hasActiveFilters &&
    numberOfFavorites > 0
  ) {
    messageType = "noraces";
  } else {
    messageType = "favorite";
  }
  return (
    <EmptyStateMessages
      onClearFilters={onClearFilters}
      messageType={messageType}
      componentName="favorite"
      emptyMessages={emptyMessages}
    />
  );
};

const renderFavoriteTracks = (
  children,
  isLogged,
  device,
  hasError,
  numberOfFavorites,
  onClearFilters,
  hasActiveFilters,
  tracks,
  emptyMessages
) => {
  if (isLogged) {
    return (
      <Container data-qa-label="favoriteTracksContainer">
        <ContainerHeader
          data-qa-label="favoriteTracksHeader"
          isDesktop={device === "desktop"}
          style={{
            marginBottom:
              hasError || (children && children.length === 0) ? "12px" : "0"
          }}
        >
          <ContainerTitle data-qa-label="favoriteTracksTitle">
            Favorites
          </ContainerTitle>
        </ContainerHeader>
        {children && children.length > 0
          ? children
          : renderEmptyStateMessages(
              hasError,
              numberOfFavorites,
              onClearFilters,
              hasActiveFilters,
              tracks,
              emptyMessages
            )}
      </Container>
    );
  }
  return null;
};

export default class FavoriteTracks extends PureComponent {
  static defaultProps = {
    device: "mobile",
    children: null,
    isLoading: true,
    isLogged: false,
    hasError: false,
    onClearFilters: noop,
    numberOfFavorites: 0,
    hasActiveFilters: false,
    tracks: [],
    emptyMessages: {}
  };

  render() {
    const {
      isLoading,
      children,
      isLogged,
      device,
      hasError,
      numberOfFavorites,
      onClearFilters,
      hasActiveFilters,
      tracks,
      emptyMessages
    } = this.props;
    return isLoading
      ? null
      : renderFavoriteTracks(
          children,
          isLogged,
          device,
          hasError,
          numberOfFavorites,
          onClearFilters,
          hasActiveFilters,
          tracks,
          emptyMessages
        );
  }
}
