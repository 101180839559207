import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontBold, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.section`
  width: 100%;
  background-color: ${buildColor("white", "100")};
  margin-bottom: ${(props) => (props.isDesktop ? "12px" : 0)};
  border-radius: ${(props) => (props.isDesktop ? "4px" : 0)};

  ul > li {
    list-style-type: none;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isDesktop ? "17px 12px 18px" : "15px 12px 0")};
`;

export const ContainerTitle = styled.span`
  display: flex;
  align-items: center;
  height: ${(props) => (props.isDesktop ? "21px" : "19px")};
  flex-grow: 1;
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: ${(props) => (props.isDesktop ? "18px" : "16px")};
  font-weight: bold;
  letter-spacing: 0;
`;

const linkStyle = css`
  color: ${buildColor("blue_accent", "500")};
  text-decoration: none;
  text-transform: none;
  font-family: ${fontMedium};
  font-size: 14px;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const SeeAllLinkMobile = styled(Link)`
  ${linkStyle}
`;

export const SeeAllLinkDesktop = styled.a`
  ${linkStyle}
`;
