import {
  Race,
  RaceStatusEnum,
  RaceTypeCodeEnum,
  BettingInterest,
  RaceCardBettingInterest,
  RacePanelLink
} from "@tvg/ts-types/Race";
import { Track } from "@tvg/ts-types/Track";

export const mockRaceA: Race = {
  highlighted: { description: "highlighted-description", style: "" },
  id: "A",
  isGreyhound: false,
  mtp: 100,
  number: "1",
  postTime: "2023-11-03T20:09:41+0000",
  raceNumber: "1",
  status: {
    code: RaceStatusEnum.OPEN
  },
  track: {
    code: "KKH23",
    name: "GB sd45",
    location: {
      country: "UK"
    }
  },
  type: { code: RaceTypeCodeEnum.THOROUGHBRED },
  video: { onTvg: true, onTvg2: false, liveStreaming: true },
  bettingInterests: [],
  promos: [
    {
      isAboveTheLine: true,
      rootParentPromoID: 123,
      promoPath: "",
      isPromoTagShown: true
    }
  ]
};

export const mockRaceB: Race = {
  highlighted: { description: "highlighted-description", style: "" },
  id: "B",
  isGreyhound: false,
  mtp: 23,
  number: "1",
  postTime: "2023-11-06T20:09:41+0000",
  raceNumber: "1",
  status: {
    code: RaceStatusEnum.OPEN
  },
  track: {
    code: "AL23",
    name: "CA - track1",
    location: {
      country: "USA",
      state: "CA"
    }
  },
  type: { code: RaceTypeCodeEnum.HARNESS },
  video: { onTvg: false, onTvg2: false },
  bettingInterests: []
};

export const mockRaceC: Race = {
  highlighted: { description: "highlighted-description", style: "" },
  id: "C",
  tvgRaceId: 1,
  isGreyhound: false,
  mtp: 523,
  number: "21",
  postTime: "2023-11-06T20:09:41+0000",
  raceNumber: "1",
  status: {
    code: RaceStatusEnum.RACE_OFFICIAL
  },
  track: {
    code: "AL24",
    name: "CB - track1",
    location: {
      country: "USA",
      state: "CA"
    }
  },
  type: { code: RaceTypeCodeEnum.HARNESS },
  video: { onTvg: false, onTvg2: false, hasReplay: true },
  bettingInterests: []
};

export const mockRaceD: Race = {
  highlighted: { description: "highlighted-description", style: "" },
  id: "D",
  isGreyhound: false,
  mtp: 523,
  number: "21",
  postTime: "2023-11-06T20:09:41+0000",
  raceNumber: "2",
  status: {
    code: RaceStatusEnum.RACE_OFFICIAL
  },
  track: {
    code: "AL24",
    name: "CB - track1",
    location: {
      country: "USA",
      state: "CA"
    }
  },
  type: { code: RaceTypeCodeEnum.HARNESS },
  video: { onTvg: true, onTvg2: false },
  bettingInterests: [],
  promos: [
    {
      isAboveTheLine: true,
      rootParentPromoID: 123,
      promoPath: "",
      isPromoTagShown: true
    }
  ]
};

export const mockRaceE: Race = {
  id: "E",
  isGreyhound: false,
  mtp: 4,
  number: "2",
  postTime: "2023-11-06T20:09:41+0000",
  raceNumber: "5",
  status: {
    code: RaceStatusEnum.OPEN
  },
  track: {
    code: "AL23",
    name: "CB - track1"
  },
  type: { code: RaceTypeCodeEnum.HARNESS },
  video: { onTvg: true, onTvg2: false },
  bettingInterests: []
};

export const mockTrackA: Track = {
  id: "UBD",
  code: "UBD",
  shortName: "GB - Market Rasen",
  name: "GB - Market Rasen",
  featured: true,
  hasAboveTheLinePromo: true,
  numberOfRaces: 1,
  isFinished: false,
  isGreyhound: false,
  races: [mockRaceA]
};

export const mockTrackB: Track = {
  id: "ZAD",
  code: "ZAD",
  shortName: "GB - Southwell",
  name: "GB - Southwell",
  featured: true,
  hasAboveTheLinePromo: false,
  numberOfRaces: 2,
  isFinished: false,
  isGreyhound: false,
  races: [mockRaceB, mockRaceC]
};

export const mockTrackC: Track = {
  id: "WND",
  code: "WND",
  shortName: "GB - Wincanton",
  name: "GB - Wincanton",
  featured: true,
  hasAboveTheLinePromo: false,
  numberOfRaces: 3,
  isFinished: false,
  isGreyhound: false,
  races: [mockRaceD, mockRaceE]
};

export const mockedRunnerBI = {
  biNumber: 1,
  number: "1",
  numberColor: "black",
  saddleColor: "red",
  isScratched: false,
  silkImage: "silk.jpg",
  runnerName: "Boom Shakalaka Shakalaka",
  jockey: "Ruiz, Jose M",
  trainer: "Sanches, Hector",
  currentOdd: "10",
  morningLineOdd: "60",
  runnerDob: 1,
  entityRunnerId: "id",
  isFavorite: false,
  runners: [
    {
      age: 3,
      dob: 2016,
      hasJockeyChanges: false,
      horseName: "Consus Victory",
      entityRunnerId: "123",
      jockey: "Ginsburg, Vinc",
      med: "",
      runnerId: "5",
      scratched: false,
      sex: "C",
      timeform: {},
      trainer: "Czernyson, Jon",
      weight: 0
    }
  ]
};

export const mockedRunnerBI2: BettingInterest = {
  biNumber: 1,
  numberColor: "black",
  saddleColor: "red",
  currentOdds: {
    numerator: 10,
    denominator: null
  },
  morningLineOdds: {
    numerator: 20,
    denominator: null
  },
  isFavorite: false,
  runners: [
    {
      horseName: "Boom Shakalaka Shakalaka",
      jockey: "Ruiz, Jose M",
      trainer: "Sanches, Hector",
      runnerId: "1",
      scratched: false,
      timeform: {
        silkUrl: "silk.jpg",
        silkUrlSvg: "silk.jpg"
      }
    }
  ]
};

export const bettingInterestMock: RaceCardBettingInterest[] = [
  {
    currentOdds: { numerator: 15, denominator: 1 },
    morningLineOdds: { numerator: 15, denominator: null },
    runners: [
      {
        horseName: "Blessed Honour",
        runnerId: "1",
        scratched: false,
        masterPickNumber: 1,
        handicapping: ["Hernandez Harry", "Legall Ricardo E"],
        trainer: "Legall Ricardo E",
        jockey: "Hernandez Harry",
        timeform: {
          silkUrl: "silk.jpg",
          silkUrlSvg: "silk.jpg"
        }
      }
    ],
    isFavorite: false,
    biNumber: 1
  },
  {
    currentOdds: { numerator: 3, denominator: 2 },
    morningLineOdds: { numerator: 5, denominator: 2 },
    runners: [
      {
        horseName: "Noble Behavior",
        runnerId: "2",
        scratched: false,
        masterPickNumber: 1,
        handicapping: ["Hernandez Harry", "Matties Gregg M"],
        trainer: "Matties Gregg M",
        jockey: "Hernandez Harry",
        timeform: {
          silkUrl: "silk.jpg",
          silkUrlSvg: "silk.jpg"
        }
      }
    ],
    isFavorite: false,
    biNumber: 2
  },
  {
    currentOdds: { numerator: 4, denominator: 2 },
    morningLineOdds: { numerator: 4, denominator: 5 },
    runners: [
      {
        horseName: "Run For Boston",
        runnerId: "3",
        scratched: false,
        masterPickNumber: 1,
        handicapping: ["Hernandez Harry", "Ryerson James T"],
        trainer: "Ryerson James T",
        jockey: "Hernandez Harry"
      },
      {
        horseName: "Smiles From Sadie",
        runnerId: "3A",
        scratched: false,
        masterPickNumber: 1,
        handicapping: ["Hernandez Harry", "Ryerson James T"],
        trainer: "Rodriguez Rudy R",
        jockey: "Franco Manuel"
      }
    ],
    isFavorite: true,
    biNumber: 3
  }
];

export const raceMock: RacePanelLink = {
  country: "IT",
  raceNumber: "1",
  trackCode: "TP",
  isGreyhound: false,
  runnerNumber: "10",
  mtp: 2,
  raceId: "19",
  postTime: "2017-10-29T09:28:00.000Z",
  promos: [
    {
      rootParentPromoID: 1,
      isAboveTheLine: true,
      promoPath: "string",
      isOptedIn: false
    }
  ],
  trackName: "Track - Name",
  talentPicks: [],
  bettingInterests: bettingInterestMock
};
