import { History } from "@tvg/ts-types/History";
import { goToTracksPage } from "@tvg/utils/routeRedirectUtils";
import { PromoCTA } from "../../types";

export const getBetCTA = (history: History, isDesktop: boolean) =>
  ({
    CTALabel: "Bet Now",
    onCTAPress: () => {
      goToTracksPage(history, isDesktop);
    },
    CTAShow: true
  }) as PromoCTA;
