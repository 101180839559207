import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import color from "../ColorPalette";

const SVGContainer = styled.div`
  width: 100%;
`;

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "000")};
  }
  
  50% {
    fill: ${color("grey", "100")};
  }
  
  100% {
    fill: ${color("grey", "000")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  background: ${color("white", "100")};

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }

  rect.stroke {
    stroke: ${color("grey", "100")};
    stroke-width: 1;
  }
`;

export default class ProgramPageMask extends PureComponent {
  render() {
    return (
      <SVGContainer data-qa-label="ProgramPageMask">
        <SVG>
          <rect
            fill={color("blue", "700")}
            x="0"
            y="0"
            width="100%"
            height="120"
          />
          <rect
            fill={color("white", "100")}
            x="0"
            y="120"
            width="100%"
            height="48"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="8"
            y="140"
            width="56"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="80"
            y="140"
            width="56"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="152"
            y="140"
            width="56"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="224"
            y="140"
            width="56"
            height="8"
            rx="1"
          />
          <rect
            fill={color("grey", "100")}
            x="0"
            y="167"
            width="100%"
            height="1"
          />
          <rect
            fill={color("white", "100")}
            x="0"
            y="208"
            width="100%"
            height="503"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="263"
            width="100%"
            height="1"
          />
          <rect
            fill={color("blue_accent", "000")}
            x="0"
            y="168"
            width="100%"
            height="40"
          />
          <rect
            fill={color("grey", "100")}
            x="0"
            y="207"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="224"
            width="144"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="240"
            width="176"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="319"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="280"
            width="64"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="296"
            width="168"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="375"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="336"
            width="56"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="352"
            width="112"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="431"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="392"
            width="72"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="408"
            width="128"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="487"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="448"
            width="104"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="464"
            width="160"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="504"
            width="104"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="560"
            width="80"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="520"
            width="152"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="576"
            width="128"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="543"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="599"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="8"
            y="184"
            width="48"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="184"
            width="192"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="616"
            width="72"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="632"
            width="184"
            height="8"
            rx="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="0"
            y="655"
            width="100%"
            height="1"
          />
          <rect
            className="pulse"
            fill={color("blue", "000")}
            x="72"
            y="672"
            width="120"
            height="8"
            rx="1"
          />
          <rect fill="#F3CFCF" x="0" y="208" width="24" height="55" />
          <rect fill="#F2F4F4" x="0" y="264" width="24" height="55" />
          <rect fill="#D8DCE8" x="0" y="320" width="24" height="55" />
          <rect fill="#F6EFD8" x="0" y="376" width="24" height="55" />
          <rect fill="#CFE0CF" x="0" y="432" width="24" height="55" />
          <rect fill="#D6D6D6" x="0" y="488" width="24" height="55" />
          <rect fill="#F7DED5" x="0" y="544" width="24" height="55" />
          <rect fill="#F9EAEE" x="0" y="600" width="24" height="55" />
          <rect fill="#D4EEED" x="0" y="656" width="24" height="55" />
          <rect
            fillOpacity="0.1"
            fill={color("white", "100")}
            x="8"
            y="60"
            width="216"
            height="8"
            rx="1"
          />
          <rect
            fillOpacity="0.1"
            fill={color("white", "100")}
            x="8"
            y="20"
            width="40"
            height="8"
            rx="1"
          />
          <rect
            fillOpacity="0.1"
            fill={color("white", "100")}
            x="64"
            y="20"
            width="176"
            height="8"
            rx="1"
          />
          <rect
            fillOpacity="0.1"
            fill={color("white", "100")}
            x="8"
            y="80"
            width="46%"
            height="32"
            rx="2"
          />
          <rect
            fillOpacity="0.1"
            fill={color("white", "100")}
            x="51%"
            y="80"
            width="46%"
            height="32"
            rx="2"
          />
        </SVG>
      </SVGContainer>
    );
  }
}
