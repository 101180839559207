import { get } from "lodash";

import { RaceCardBettingInterest, BettingInterest } from "@tvg/ts-types/Race";

export const doesSilkLoads = async (
  bettingInterests: Array<RaceCardBettingInterest | BettingInterest>,
  isRacecard: boolean = false
): Promise<Array<boolean>> => {
  const shouldShowSilks = await Promise.all(
    (isRacecard ? get(bettingInterests, "[0]", []) : bettingInterests).map(
      async (bi: BettingInterest) => {
        const biRunners = get(bi, "runners", []);
        const silkUrl = get(biRunners, "[0].timeform.silkUrlSvg", "");
        if (silkUrl) {
          return doesImageLoads(silkUrl);
        }
        return false;
      }
    )
  );

  return shouldShowSilks;
};

const doesImageLoads = (url: string): Promise<boolean> => {
  if (url) {
    return new Promise((resolve) => {
      const img = new Image();
      // eslint-disable-next-line
      img.onerror = img.onabort = () => {
        resolve(false);
      };
      img.onload = () => {
        resolve(true);
      };
      img.src = url;
    });
  }

  return Promise.resolve(false);
};
