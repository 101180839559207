import React, { Fragment, ReactNode } from "react";

import { Tag } from "@tvg/design-system/src/components/tag";
import { TagProps as TagFlowType } from "@tvg/design-system/src/components/tag/types";

import {
  Container,
  TitleElement,
  Description,
  Separator
} from "./styled-components";

type Props = {
  title?: string | ReactNode;
  tags: TagFlowType[];
  qaLabel: string;
};

const TagList = (props: Props) => (
  <Container data-qa-label={props.qaLabel}>
    {props.title && <TitleElement>{props.title}</TitleElement>}
    {props.tags.map((tag: TagFlowType) => {
      const key = `tag-${tag.label.replace(/ /g, "-")}`;
      return (
        <Fragment key={key}>
          <Separator />
          <Tag qaLabel={key} {...tag} />
          <Description
            data-qa-label={`${key}-description`}
            dangerouslySetInnerHTML={{ __html: tag.description || "" }}
          />
        </Fragment>
      );
    })}
  </Container>
);

TagList.defaultProps = {
  title: "",
  tags: [],
  qaLabel: "tagList"
};

export default TagList;
