import React, { Component, Fragment } from "react";
import { map, throttle, get, noop } from "lodash";

import { isTvg5 } from "@tvg/utils/generalUtils";

import buildRaceUrl from "@tvg/formatter/url";

import Button from "../../_atom/Buttons/button";
import Toggle from "../../_atom/Toggle";
import TalentPickCardHeader from "../../_molecule/TalentPickCardHeader";
import BetSummary from "../../_molecule/BetSummary";
import {
  CardWrapper,
  SelectionsWrapper,
  TogglesWrapper,
  ActionsWrapper,
  SeeAllSelections
} from "./style-components";

export default class TalentPickCard extends Component {
  static defaultProps = {
    talentPick: {
      id: "0",
      selections: [],
      favorites: [],
      amount: "0",
      price: "0",
      wagerType: {
        abbreviation: "",
        legCount: 1,
        isBox: false,
        isKey: false,
        isWheel: false
      },
      track: "",
      trackName: "",
      race: 0,
      raceType: "T",
      status: "RO",
      talent: {
        talentId: "",
        name: "",
        pictureUrl: "",
        popularity: 0,
        tvgAccountId: "",
        phrases: [],
        isActive: true
      },
      published: true,
      racePostTime: "2018-06-06T21:51:00.000Z",
      mtp: 474
    },
    isRacePick: false,
    buttons: [],
    onPublishToggle: null,
    imageBaseURL: "//www.tvg.com",
    isDesktop: false,
    showButtonsCallBack: () => true,
    onSeeAllSelections: null,
    isHorizontalLayout: false,
    onClickHeader: noop
  };

  publish = throttle(
    () => {
      get(
        this.props,
        "onPublishToggle",
        noop
      )({
        id: this.props.talentPick.id,
        published: this.props.talentPick.published
      });
    },
    1500,
    { trailing: false }
  );

  renderPick() {
    const showSeeAllBtn =
      typeof this.props.onSeeAllSelections === "function" &&
      this.props.talentPick.selections.length > 5;

    return (
      <SelectionsWrapper data-qa-label="body">
        <BetSummary
          className="talentPickCard"
          betTotal={+this.props.talentPick.price}
          wagerAmount={+this.props.talentPick.amount}
          selectionTypeCode={this.props.talentPick.wagerType.abbreviation}
          isKey={this.props.talentPick.wagerType.isKey}
          isLeg={this.props.talentPick.wagerType.legCount > 1}
          selections={this.props.talentPick.selections}
          favoriteRunners={this.props.talentPick.favorites}
          raceTypeCode={this.props.talentPick.raceType}
          raceNumber={this.props.talentPick.race}
          isVertical
          layout="simple"
          showAllSelections={!showSeeAllBtn}
        />
        {showSeeAllBtn && (
          <SeeAllSelections
            onClick={() =>
              typeof this.props.onSeeAllSelections === "function" &&
              this.props.onSeeAllSelections(this.props.talentPick)
            }
          >
            See all selections
          </SeeAllSelections>
        )}
      </SelectionsWrapper>
    );
  }

  renderToggles() {
    return (
      typeof this.props.onPublishToggle === "function" && (
        <Fragment>
          <TogglesWrapper data-qa-label="publish-button" onClick={this.publish}>
            Talent pick published
            <Toggle
              on={this.props.talentPick.published}
              onClick={this.publish}
            />
          </TogglesWrapper>
        </Fragment>
      )
    );
  }

  renderButtons() {
    return (
      this.props.buttons.length > 0 && (
        <ActionsWrapper data-qa-label="footer">
          {map(this.props.buttons, (button, index) => (
            <Button
              key={`actionbutton-${+index}`}
              type={button.type}
              qaLabel={button.qaLabel}
              onClick={() => button.onClick(this.props.talentPick)}
              size="big"
            >
              {button.label(+get(this.props, "talentPick.price", 0))}
            </Button>
          ))}
        </ActionsWrapper>
      )
    );
  }

  render() {
    const showButtons = this.props.showButtonsCallBack(this.props.talentPick);
    const {
      track,
      race,
      talent,
      wagerType,
      trackName,
      raceType,
      mtp,
      racePostTime,
      raceStatus
    } = this.props.talentPick;
    const raceUrl = buildRaceUrl(track, trackName, race, raceType === "G");
    return (
      <CardWrapper
        isHorizontalLayout={this.props.isHorizontalLayout}
        data-qa-label={`pick_${talent.talentId}_${track}_${race}_${wagerType.abbreviation}`}
        isDesktop={this.props.isDesktop}
        isTvg5={isTvg5()}
      >
        <TalentPickCardHeader
          {...this.props.talentPick.talent}
          imageBaseURL={this.props.imageBaseURL}
          raceUrl={raceUrl}
          mtp={mtp}
          postTime={racePostTime}
          status={raceStatus}
          trackCode={track}
          trackName={trackName}
          race={race}
          isFeatured={
            get(this.props, "talentPick.highlighted.style", "") ===
            "Highlighted"
          }
          featuredText={get(
            this.props,
            "talentPick.highlighted.description",
            ""
          )}
          isDesktop={this.props.isDesktop}
          onClick={
            this.props.isDesktop
              ? () =>
                  this.props.onClickHeader(
                    this.props.talentPick,
                    raceUrl,
                    "header"
                  )
              : () =>
                  this.props.onClickHeader(
                    this.props.talentPick,
                    "edit",
                    "header"
                  )
          }
        />
        {this.renderPick()}
        {this.renderToggles()}
        {showButtons && this.renderButtons()}
      </CardWrapper>
    );
  }
}
