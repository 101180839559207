import axios from "axios";
import { get } from "lodash";
import {
  StoryblokDraft,
  StoryblokEnvironment,
  StoryblokStory,
  StoryblokVersion
} from "./types";
import { STORYBLOK_URL, STORYBLOK_CONTENT_PATH } from "./constraints/config";
import fetchStoryBlokCacheVersion from "./fetchStoryBlokCacheVersion";
import { getStoryblokVersion } from "./getStoryblokVersion";
import { getStoryblokToken } from "./getStoryblokToken";

export const fetchStoryblok = async <T = unknown>(
  contentPath: STORYBLOK_CONTENT_PATH,
  slug: string,
  draft: StoryblokDraft = StoryblokVersion.PUBLISHED,
  overrideToken?: StoryblokEnvironment,
  extraParams?: Object
): Promise<StoryblokStory<T>> => {
  const storyblokUrl = `${STORYBLOK_URL}/${contentPath}/${slug}`;
  const cacheVersion = await fetchStoryBlokCacheVersion(
    contentPath,
    draft,
    overrideToken
  );
  return axios
    .get<StoryblokStory<T>>(storyblokUrl, {
      headers: {
        "content-type": "application/json"
      },
      params: {
        version: getStoryblokVersion(draft),
        token: getStoryblokToken(overrideToken),
        resolve_relations: "global_ref.reference",
        cv: cacheVersion,
        ...extraParams
      }
    })
    .then((response) => get(response, "data.story"));
};
