import styled from "styled-components";
import { buildText } from "../Text";

const createTextHighlightComponent = (
  type,
  fontSize,
  bold,
  uppercase,
  qaLabel
) => styled(
  buildText({ fontSize, color: type.textColor, bold, uppercase, qaLabel })
)`
  background-color: ${type.bgColor};
  padding: 0 2px;
  border-radius: 30px;
  display: inline-flex;

  @media (pointer: coarse) {
    padding: 0 6px;
  }
`;

export default createTextHighlightComponent;
