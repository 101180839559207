import React from "react";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { SeparatedPools, TopPool } from "@tvg/ts-types/Pool";
import { LinkItem, Paragraph } from "@tvg/design-system";
import { get } from "lodash";
import MtpValue from "../MtpValue";
import { QA_LABEL } from "../../qaLabels";
import AmountValue from "../AmountValue";
import { PoolItem } from "./styled-components";
import { ARRAY_LIMIT } from "./constants";

export const renderTitle = (title: string) => (
  <Paragraph
    qaLabel={`${title}-title`}
    fontFamily="medium"
    textTransform="uppercase"
    color="grey.800"
    p="space-4"
    pb={0}
    fontSize="xs"
  >
    {title}
  </Paragraph>
);

export const renderPool = (
  pool: TopPool,
  index: number,
  onPoolClick: UnaryFn<TopPool, void>
) => (
  <PoolItem>
    <LinkItem
      key={`${pool.trackCode}-${pool.race.raceNumber}-${pool.race.postTime}-${index}`}
      start={<MtpValue race={pool.race} />}
      end={<AmountValue amount={pool.amount} />}
      title={{
        render: pool.track
      }}
      description={pool.wagerType.name}
      onPress={() => onPoolClick(pool)}
      qaLabel={QA_LABEL.body.desktop.pool}
      hasArrowRight={false}
    />
  </PoolItem>
);

export const renderPoolsArray = (
  poolsArr: TopPool[],
  onPoolClick: UnaryFn<TopPool, void>,
  highlightsTitle: string,
  upcomingTitle: string
) => {
  const getSeparedPools = poolsArr.reduce(
    (pools: SeparatedPools, pool) => {
      const isPinned = get(pool, "race.highlighted.pinnedOrder") !== null;
      const selectedPool = !isPinned
        ? pools.upComingPools
        : pools.highligthedPools;
      selectedPool.push(pool);
      return pools;
    },
    { highligthedPools: [], upComingPools: [] }
  );

  return (
    <>
      {getSeparedPools.highligthedPools.length > 0 && (
        <>
          {renderTitle(highlightsTitle)}
          {getSeparedPools.highligthedPools
            .slice(0, ARRAY_LIMIT)
            .map((pool: TopPool, index: number) =>
              renderPool(pool, index, onPoolClick)
            )}
        </>
      )}

      {getSeparedPools.highligthedPools.length < 6 &&
        getSeparedPools.upComingPools.length && (
          <>
            {renderTitle(upcomingTitle)}
            {getSeparedPools.upComingPools
              .slice(0, ARRAY_LIMIT - getSeparedPools.highligthedPools.length)
              .map((pool: TopPool, index: number) =>
                renderPool(pool, index, onPoolClick)
              )}
          </>
        )}
    </>
  );
};
