import styled from "styled-components";
import { ThumbnailProps } from "./types";

export const Container = styled.div`
  overflow: hidden;
  min-width: 292px;
  width: 292px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const Duration = styled.label`
  padding: 1px 4px 0 4px;
  height: 15px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colorTokens.background.secondary};
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-size: 12px;
  line-height: 125%;
  width: min-content;
  justify-self: flex-start;
  align-self: flex-end;
`;

export const Thumbnail = styled.div<ThumbnailProps>`
  position: relative;
  height: 168px;
  background-image: ${({ source }) => `url(${source})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: ${({ theme }) => theme.space["space-5"]};
  display: grid;
`;

export const IconContainer = styled.div`
  position: absolute;
  align-self: center;
  justify-self: center;
`;
