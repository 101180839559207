import React, { PureComponent, Fragment } from "react";
import { noop } from "lodash";

import {
  Card,
  RedirectLink,
  RedirectLinkTVG4,
  IconWrapper
} from "./styled-components";
import Icon from "../../_static/Icons";
import { arrowForward } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

export default class RedirectCard extends PureComponent {
  static defaultProps = {
    text: "See all",
    cardWidth: 292,
    cardHeight: 292,
    isDesktop: false,
    url: "",
    onClick: noop,
    qaLabel: "seeAllCard"
  };

  onClick = (event) => this.props.onClick(event, this.props.url);

  renderLinkContent = () => (
    <Fragment>
      <IconWrapper>
        <Icon
          size={16}
          icon={arrowForward}
          color={buildColor("blue_accent", "500")}
        />
      </IconWrapper>
      {this.props.text}
    </Fragment>
  );

  render() {
    return (
      <Card
        cardWidth={this.props.cardWidth}
        cardHeight={this.props.cardHeight}
        data-qa-label={this.props.qaLabel}
      >
        {this.props.isDesktop ? (
          <RedirectLinkTVG4 href={this.props.url} onClick={this.onClick}>
            {this.renderLinkContent()}
          </RedirectLinkTVG4>
        ) : (
          <RedirectLink to={this.props.url} onClick={this.onClick}>
            {this.renderLinkContent()}
          </RedirectLink>
        )}
      </Card>
    );
  }
}
